import React from 'react';
import {graphql, useFragment, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import QRCode from 'qrcode.react';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import qs from 'qs';
import {graphqlWhoamiNextQuery} from '../../graphql';

const Qrs = ({impulse, position, aIndex}) => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const fImpulse = useFragment(
        graphql`
            fragment QrsImpulseImpl_impulse on Impulse {
                id
                impulseEndpoints {
                    numbers
                }
                qrs
                version
                merchant {
                    id
                }
            }
        `,
        impulse
    );
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation QrsQDownloadMutation {
                handle {
                    accessToken
                }
            }
    `);
    const [index, setIndex] = React.useState(aIndex || 0);
    React.useEffect(() => {
        setIndex(aIndex || 0);
    }, [aIndex]);
    const [take, applyingTake] = useMutation(
        graphql`
            mutation QrsImpulseImplTakeMutation($request: TakeImpulseRequestInput) {
                takeImpulse(request: $request) {
                    id
                    version
                    impulseEndpoints {
                        numbers
                    }
                }
            }
    `);
    const onTake = () => take({
        variables: { request: {
            id: fImpulse.id,
            version: fImpulse.version,
            position,
            index
        }},
        onCompleted: () => {},
        onError: () => {}
    });
    const [numberFlag, setNumberFlag] = React.useState(false);
    const {register: numberRegister, handleSubmit: numberHandleSubmit, formState: {errors: numberErrors}} = useForm();
    const [applyNumbers, applyingNumbers] = useMutation(
        graphql`
            mutation QrsImpulseImplNumbersMutation($request: UpdateImpulseNumbersRequestInput) {
                updateImpulseNumbers(request: $request) {
                    id
                    version
                    impulseEndpoints {
                        numbers
                    }
                }
            }
    `);
    const numberSubmit = numberHandleSubmit(data => applyNumbers({
        variables: {request: {id: fImpulse.id, version: fImpulse.version, position, index, number: data.number}},
        onCompleted: () => {setNumberFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='margin-top-0dot5rem'><QRCode size={192} value={`plant://${fImpulse.qrs[position][index]}`}/></div>
        <div className='display-flex margin-top-0dot5rem'>
            <div
                className='button'
                onClick={() => !isMutationInFlight && commit({
                    onCompleted: (data) => {
                        const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;
                        const suffix = qs.stringify({
                            handle: data.handle.accessToken,
                            id: fImpulse.id,
                            position,
                            index
                        });
                        window.location.href = RAZZLE_APP_PREFIX + `/api/impulses/plant/download?` + suffix;
                    },
                    onError: () => {}
                })}
            >
                <FormattedMessage defaultMessage='Download'/> #{index}{isMutationInFlight && '...'}
            </div>
        </div>
        <div className='display-flex align-items-center margin-top-0dot5rem'>
            {fImpulse.qrs[position].length > 1 &&
            <>
            <div
                onClick={() => index && setIndex(e => --e)}
                className={classNames('button', 'primary', {
                    'disabled': index === 0
                })}
            >&#60;</div>
            <div
                className={classNames('margin-left-0dot5rem', {'background-gold': index === aIndex})}
            >#{index}</div>
            <div
                onClick={() => index < fImpulse.qrs[position].length - 1 && setIndex(e => ++e)}
                className={classNames('button', 'primary', 'margin-left-0dot5rem', 'margin-right-0dot5rem', {
                    'disabled': index === fImpulse.qrs[position].length - 1
                })}
            >&#62;</div>
            </>
            }
            {whoamiNext.id === fImpulse.merchant.id ?
                <div
                    className='button'
                    onClick={() => !applyingTake && onTake()}
                >
                    <FormattedMessage defaultMessage='Take'/>
                    {applyingTake && '...'}
                    {(fImpulse.impulseEndpoints[position].numbers && !!fImpulse.impulseEndpoints[position].numbers[index]) &&
                    <span className='badge orangered margin-left-0dot25rem'>
                        {fImpulse.impulseEndpoints[position].numbers[index]}
                    </span>
                    }
                </div>
                :
                <>
                {(fImpulse.impulseEndpoints[position].numbers && !!fImpulse.impulseEndpoints[position].numbers[index]) &&
                <>
                <FormattedMessage defaultMessage='Take'/>
                <span className='badge orangered margin-left-0dot25rem'>
                    {fImpulse.impulseEndpoints[position].numbers[index]}
                </span>
                </>
                }
                </>
            }
        </div>
        {whoamiNext.id === fImpulse.merchant.id &&
        <>
        <div className='display-flex margin-top-0dot5rem'>
            <div
                className='button'
                onClick={() => !applyingNumbers && setNumberFlag((flag) => !flag)}
            >
                {numberFlag ?
                    <FormattedMessage defaultMessage='Cancel'/>
                    :
                    <FormattedMessage defaultMessage='Edit numbers'/>
                }{applyingNumbers && '...'}
            </div>
        </div>
        {numberFlag &&
        <div className='inline'>
            <form key={index} onSubmit={numberSubmit}>
                <div className='input'>
                    <input
                        className='margin-0dot5rem-0 width-100percent'
                        defaultValue={fImpulse.impulseEndpoints[position].numbers && fImpulse.impulseEndpoints[position].numbers[index]}
                        {...numberRegister('number', {required: true})}
                    />
                </div>
                {numberErrors.number && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                <div className='display-flex'>
                    <div
                        className='button primary'
                        onClick={() => !applyingNumbers && numberSubmit()}
                    >
                        <FormattedMessage defaultMessage='Submit'/>{applyingNumbers && '...'}
                    </div>
                </div>
            </form>
        </div>
        }
        </>
        }
        </>
    );
};

export default React.memo(Qrs);

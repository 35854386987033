import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {graphqlWhoamiNextQuery} from '../../components/graphql';

const UserEmail = ({setValue}) => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    React.useEffect(() => {
        if (whoamiNext.username || whoamiNext.email) {
            setValue('email', whoamiNext.username || whoamiNext.email);
        }
    }, [whoamiNext]);
    return (
        <div className='display-none'>
            {whoamiNext.username || whoamiNext.email}
        </div>
    )
}

export default React.memo(UserEmail);
import React from "react";
import Skeleton from "./Skeleton";

const SuspensLazy = ({children}) => {
    return (
        <React.Suspense fallback={<div className='padding-1rem'><Skeleton/></div>}>
            {children}
        </React.Suspense>
    )
}

export default React.memo(SuspensLazy);
import React from 'react';
import {useSubscriptionState} from './components/SubscriptionState';

const useSubscriptionStatus = () => {
    const [subscriptionStatus, setSubscriptionStatus] = React.useState();
    const subscription = useSubscriptionState();
    React.useEffect(() => {
        subscription.onConnected(() => setSubscriptionStatus(0));
        subscription.onDisconnected(() => setSubscriptionStatus());
        subscription.onError((e) => console?.log(e));
    }, 
    [subscription]);
    return subscriptionStatus;
};

export default useSubscriptionStatus;
import React from 'react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {useLocation} from 'react-router-dom';
import {RouterLinkButton} from '../../../components/button/button';
import ChevronUp from '../../../assets/icons/chevron-up-cart.svg';
import searchFilter from '../../../searchFilter';
import splitPrice from '../splitPrice';
import './footer.scss';

const Footer = ({request, index, pendingRequest}) => {
    const [flag, setFlag] = React.useState(false);
    const location = useLocation();

    const data = useLazyLoadQuery(
        graphql`
            query footerCartPagesDeferredQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        pickExs {
                            index
                        }
                    }
                    solves {
                        amount
                        amountAmount
                        serviceFeeAmount
                    }
                }
            }
        `,
        {request}
    );

    const serviceFee = React.useMemo(() => splitPrice(data.landImpulse.solves[index].serviceFeeAmount), [data]);
    const total = React.useMemo(() => splitPrice(data.landImpulse.solves[index].amount), [data]);
    const orderAmount = React.useMemo(() => splitPrice(data.landImpulse.solves[index].amountAmount), [data]);
    
    return (
        <div className='cart-footer mw768-margin-left-2rem mw768-box-shadow-2xl box-shadow-rv-2xl-light mw768-position-initial background-color-white'>
            {serviceFee.whole &&
            <div className={classNames('cart-footer__details', {'cart-footer__details--visible': flag})}>
                {/* <div className='display-flex padding-bottom-0dot25rem'>
                    <div className='text-sm color-gray-500 flex-1'>
                        <FormattedMessage defaultMessage='Order'/>
                    </div>
                    <div className={classNames('text-md medium color-gray-700', {'opacity-0dot4 pointer-events-none': pendingRequest})}>
                        {serviceFee.whole && <span><span>{orderAmount.whole}.</span><span className='text-xs'>{orderAmount.fraction} </span></span>}
                        {!serviceFee.whole && <span><span>{total.whole}.</span><span className='text-xs'>{total.fraction} </span></span>}
                        <FormattedMessage defaultMessage='UAH' />
                    </div>
                </div> */}
                <div className='display-flex'>
                    <div className='text-sm color-gray-500 flex-1'>
                        <span><FormattedMessage defaultMessage='Services fee'/></span>
                    </div>
                    <div className={classNames('text-md medium color-gray-700', {'opacity-0dot4 pointer-events-none': pendingRequest})}>
                        <span><span>{serviceFee.whole}.</span><span className='text-xs'>{serviceFee.fraction} </span></span>
                        <FormattedMessage defaultMessage='UAH'/>
                    </div>
                </div>   
            </div>
            }
            <div className='cart-footer__payment display-flex align-items-center flex-direction-row-column'>
                <div className='cursor-pointer flex-1 display-flex' onClick={() => setFlag(!flag)}>
                    {serviceFee.whole &&
                    <ChevronUp className={classNames('cart-footer__indicator color-primary-500 align-self-center width-0dot625rem', {'cart-footer__indicator--active': flag})}/>
                    }
                    <div className='display-block flex-1 mw768-display-flex mw768-justify-content-space-between mw768-padding-bottom-0dot75rem'>
                        <div className='text-sm color-gray-700'>
                            <FormattedMessage defaultMessage='Total'/>
                        </div>
                        <div className={classNames('text-xl semibold color-gray-900', {'opacity-0dot4 pointer-events-none': pendingRequest})}>
                            <span>{total.whole}.</span>
                            <span className='text-md bold'>{total.fraction}</span>
                            <span>{' '}</span>
                            <FormattedMessage defaultMessage='UAH'/>
                        </div>
                    </div>
                </div>
                <RouterLinkButton {...{color: 'primary-gradient', mw768Size: 'xl', size: '2xl', to: `../ticketing/impulse${searchFilter(location.search, {landId: index})}`, fluid: 'desktop'}}>
                    <FormattedMessage defaultMessage='Checkout'/>
                </RouterLinkButton>
            </div>
        </div>
    )
};

export default React.memo(Footer);
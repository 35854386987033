/**
 * @generated SignedSource<<d6d96b264017b6490d90e559b4535580>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JwtAuthenticationResponse",
    "kind": "LinkedField",
    "name": "handle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "impulseViewQrsPopupDownloadMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "impulseViewQrsPopupDownloadMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "5b39ed026a2f60b08c999cf0cabb4a1f",
    "id": null,
    "metadata": {},
    "name": "impulseViewQrsPopupDownloadMutation",
    "operationKind": "mutation",
    "text": "mutation impulseViewQrsPopupDownloadMutation {\n  handle {\n    accessToken\n  }\n}\n"
  }
};
})();

node.hash = "a47a7c14f5baa812f416c3892aef464d";

module.exports = node;

import React from 'react';
import {FormattedMessage} from 'react-intl';
import Popover from '../../../components/popover/popover';
import InformationCircle from '../../../assets/icons/information-circle.svg';
import './order.scss';
import splitPrice from '../splitPrice';
import {isSet} from '../../../utility';

const PopoverContent = ({orderAmount, serviceFee, total}) => {
    return (
        <div className='text-xs'>
            {/* <p>
                <FormattedMessage defaultMessage='Order'/><span>{` - ${serviceFee ? orderAmount : total} `}</span><FormattedMessage defaultMessage='UAH'/>
            </p> */}
            <p><FormattedMessage defaultMessage='Services fee'/><span>{` - ${serviceFee || 0} `}</span><FormattedMessage defaultMessage='UAH'/></p>
        </div>
    )
}

const Order = ({total, serviceFee, amount, children, coupon, basis, discountAmount}) => {
    const splittedTotal = splitPrice(total);
    return (
        <div className='order-container padding-top-2rem'>
            <div className='divider border-bottom-color-gray-200'/>
            {children}
            <div className='padding-top-1dot5rem padding-bottom-1rem'>
                {(isSet(serviceFee) || isSet(coupon)) &&
                <div className='padding-bottom-1rem'>
                    <div className='display-flex justify-content-space-between align-items-center'>
                        <div className='text-sm color-gray-500'><FormattedMessage defaultMessage='Sum'/></div>
                        <div className='text-sm color-gray-500'>{basis} <FormattedMessage defaultMessage='UAH'/></div>
                    </div>
                    {isSet(serviceFee) && 
                    <div className='padding-top-0dot5rem display-flex justify-content-space-between align-items-center'>
                        <div className='text-sm color-gray-500'><FormattedMessage defaultMessage='Services fee'/></div>
                        <div className='text-sm color-gray-500'>{serviceFee} <FormattedMessage defaultMessage='UAH'/></div>
                    </div>
                    }
                    {(isSet(coupon)) &&
                    <div className='padding-top-0dot5rem display-flex justify-content-space-between align-items-center'>
                        <div className='text-sm color-gray-500'><FormattedMessage defaultMessage='Coupon discount'/></div>
                        <div className='text-sm color-gray-500'>-{discountAmount} <FormattedMessage defaultMessage='UAH'/></div>
                    </div>
                    }
                </div>
                }
                <div className='display-flex'>
                    <p className='text-lg semibold color-gray-700 flex-1 padding-right-0dot625rem'>
                        <FormattedMessage defaultMessage='Total'/>
                    </p>
                    {/* <div className='display-flex'> */}
                        {/* <div>
                            <Popover {...{content: <PopoverContent {...{orderAmount: amount, serviceFee, total}}/>, size: 'md', position: {bottom: '2rem', left: '-5.3125rem'}, arrow: 'bottom-middle'}}>
                                <InformationCircle className='width-1dot25rem padding-top-0dot25rem information-circle-popover margin-right-0dot5rem'/>
                            </Popover>
                        </div> */}
                        <p className='text-lg semibold color-gray-700'>
                            <span>{splittedTotal.whole}.</span>
                            <span className='text-sm bold'>{splittedTotal.fraction}</span>
                            {' '}
                            <FormattedMessage defaultMessage='UAH'/>
                        </p>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
};

export default React.memo(Order);
import React from 'react';
import './seat-map.scss';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import IconX from '../../../assets/icons0/x.svg';
import SEAT_MAPS from './seat-maps';

const Seat = ({endpoint, colorIndex, selected, toggleEndpoint}) => {
    const row = endpoint.tags.find((e) => e.includes('row')).replace('row=', '');
    const seat = endpoint.tags.find((e) => e.includes('seat')).replace('seat=', '');
    const priceTag = endpoint.tags.find((e) => e.includes('price'));
    const price = priceTag ? priceTag.replace('price=', '') : null;
    return (
        <div className='seat-container'>
            <div onClick={() => toggleEndpoint(endpoint.id)} className={classNames(`seat seat--color-${colorIndex}`, {'seat--selected': selected, 'seat--disabled': endpoint.quantity === 0})}>
                {endpoint.quantity === 0 && <IconX className='display-block height-1rem width-1rem color-gray-800'/>}
                {selected && <span>{seat}</span>}
            </div>
            <div className='seat__tooltip text-xs medium'>
                <p>{row} <FormattedMessage defaultMessage='row'/>, {seat} <FormattedMessage defaultMessage='seat'/></p>
                <p><FormattedMessage defaultMessage='Price'/>:
                    {price && <> {price}</>}
                    {!price && <> {endpoint.price} <FormattedMessage defaultMessage='UAH'/></>}
                </p>
            </div>
        </div>
    )
}

const SeatMap = ({service, toggleEndpoint, localCartState, seatMapName}) => {
    const name = seatMapName.split('-').join('_').toUpperCase();
    const {ROWS_AMOUNT, MAX_SEATS_AMOUNT, SEATS_LEFT_TO_RIGHT} = SEAT_MAPS[name];
    const seatMap = new Array(ROWS_AMOUNT);
    const priceCategories = [];
    for (let index = 0; index < ROWS_AMOUNT; index++) {
        seatMap[index] = new Array(MAX_SEATS_AMOUNT).fill(null);   
    }
    service.endpoints.forEach((item) => {
        const rowTag = item.tags.find((e) => e.includes('row'));
        const seatTag = item.tags.find((e) => e.includes('seat'));
        if (rowTag && seatTag  && (rowTag.replace('row=', '') <= ROWS_AMOUNT) && (seatTag.replace('seat=', '') <= MAX_SEATS_AMOUNT)) {
            seatMap[rowTag.replace('row=', '') - 1][seatTag.replace('seat=', '') - 1] = {...item};
            if (!priceCategories.includes(item.price)) {
                priceCategories.push(item.price);
            }
        }
    });
    priceCategories.sort((a, b) => a - b);
    return(
        <div className={`seat-map ${seatMapName}`}>
            <div className='padding-top-1rem padding-bottom-1rem mw768-padding-bottom-2rem mw768-padding-top-1dot5rem display-grid gap-1rem-1rem price-grid'>
                {priceCategories.map((price, index) =>
                    <div key={index} className='display-flex align-items-center'>
                        <div className={`seat pointer-events-none seat--color-${index}`}></div>
                        <p className='padding-left-0dot5rem text-md color-gray-200 white-space-nowrap'>{price} ₴</p>
                    </div>
                ).reverse()}
            </div>
            <div className='seat-map-container'>
                <div className='seat-map-container__block padding-top-1dot5rem margin-0-auto'>
                    <div className='padding-top-0dot75rem padding-bottom-0dot75rem color-white background-color-gray-700 text-align-center text-transform-uppercase semibold display-xs margin-bottom-3rem'>
                        <FormattedMessage defaultMessage='Stage'/>
                    </div>
                    <div className='grid'>
                        {seatMap.map((row, rowIndex) =>
                            <div key={rowIndex} className='row display-flex justify-content-space-between'>
                                <div className='margin-right-1dot5rem numeric-font row-index'>{rowIndex + 1}</div>
                                <div className={classNames('seats-container display-flex flex-1-0', {'flex-direction-row-reverse': !SEATS_LEFT_TO_RIGHT})}>
                                    {
                                        row.map((endpoint, eIndex) =>
                                            endpoint
                                            ?
                                            <Seat key={eIndex} {...{endpoint, toggleEndpoint, selected: localCartState.includes(endpoint.id), colorIndex: priceCategories.indexOf(endpoint.price)}} />
                                            :
                                            <div key={eIndex} className='seat-container'><div className='seat seat--missing'></div></div>
                                        )
                                    }
                                </div>
                                <div className='margin-left-1dot5rem numeric-font row-index'>{rowIndex + 1}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(SeatMap);
/**
 * @generated SignedSource<<89aba44921bb20ac81f773e2915c5cd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameUk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "PlantResponse",
    "kind": "LinkedField",
    "name": "plantImpulse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "position",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "index",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Impulse",
        "kind": "LinkedField",
        "name": "impulse",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "ImpulseUser",
            "kind": "LinkedField",
            "name": "impulseClient",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ImpulseAddl",
            "kind": "LinkedField",
            "name": "impulseAddls",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Addl",
                "kind": "LinkedField",
                "name": "addl",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ImpulseService",
            "kind": "LinkedField",
            "name": "impulseService",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "demo",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Service",
                "kind": "LinkedField",
                "name": "service",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "target",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ImpulseEndpoint",
            "kind": "LinkedField",
            "name": "impulseEndpoints",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "price",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "quantity",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numbers",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "number",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "qrs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "merchant",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "impulseViewImpulseQuery",
    "selections": (v5/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "impulseViewImpulseQuery",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "e65f0857bd3b5aef05dee22bf2428266",
    "id": null,
    "metadata": {},
    "name": "impulseViewImpulseQuery",
    "operationKind": "query",
    "text": "query impulseViewImpulseQuery(\n  $request: PlantRequestInput\n) {\n  plantImpulse(request: $request) {\n    position\n    index\n    impulse {\n      id\n      impulseClient {\n        email\n      }\n      impulseAddls {\n        addl {\n          id\n        }\n        nameUk\n        name\n        value\n      }\n      impulseService {\n        name\n        nameUk\n        demo\n        service {\n          target\n        }\n      }\n      impulseEndpoints {\n        name\n        nameUk\n        price\n        quantity\n        numbers\n      }\n      status\n      version\n      number\n      qrs\n      merchant {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "ff2a8f9a39e03f20945b392fecf88a5c";

module.exports = node;

import React from 'react'
import {ErrorBoundary} from 'react-error-boundary';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import ErrorFallback from '../../../components/ErrorFallback';
import SafetyMark from '../../../assets/icons/safety-mark.svg';
import ShoppingBag03 from '../../../assets/icons0/shopping-bag-03.svg';
import ChevronUp from '../../../assets/icons0/chevron-up.svg';
import ServiceFee from './service-fee';
import Amount from './amount';
import Button from '../../../components/button/button';

import './cart-footer.scss';

export const LocalCartFooterDesktop = ({localCartState, deferred, serviceById, checkout}) => {
    return (
        <div className='width-inherit z-index-1 box-shadow-rv-2xl-light background-color-white border-top-left-radius-0dot75rem border-top-right-radius-0dot75rem padding-1dot5rem'>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <div className='local-cart-footer__details local-cart-footer__details--visible'>
                    <div className='display-flex align-items-center'>
                        <div className='text-sm color-gray-500 flex-1'>
                            <span><FormattedMessage defaultMessage='Services fee'/></span>
                        </div>
                        <React.Suspense fallback={
                            <div className='text-md medium color-gray-700 opacity-0dot4 pointer-events-none'>
                                <span>0.<span className='text-xs'>00 </span></span>
                                <FormattedMessage defaultMessage='UAH'/>
                            </div>
                        }>
                            <ServiceFee {...{localCartState, serviceId: serviceById.id, deferred}}/>
                        </React.Suspense>
                    </div>
                </div>
                <div className='display-flex flex-direction-row justify-content-space-between align-items-center flex-1 padding-bottom-0dot75rem'>
                    <div className='text-sm color-gray-700'>
                        <FormattedMessage defaultMessage='Total'/>
                    </div>
                    <React.Suspense fallback={<span className='text-lg semibold color-gray-900 opacity-0dot4'>0.00 <FormattedMessage defaultMessage='UAH'/></span>}>
                        <Amount {...{localCartState, serviceId: serviceById.id, deferred, classes: 'text-lg semibold color-gray-900'}}/>
                    </React.Suspense>
                </div>
                <div className='z-index-2 background-color-white padding-bottom-0dot75rem'>
                    <Button {...{color: 'primary-gradient', size: 'xl', fluid: 'always', clickHandler: () => checkout()}}>
                        <FormattedMessage defaultMessage='Checkout'/>
                    </Button>
                </div>
                <div className='padding-top-1rem padding-bottom-1rem display-flex justify-content-center'>
                    <SafetyMark className='display-block margin-right-0dot75rem'/>
                    <p className='text-md color-gray-900'><FormattedMessage defaultMessage='100% Safe and Secure'/></p>
                </div>
            </ErrorBoundary>
        </div>
    )
}

export const LocalCartFooterMobile = ({flag, localCartState, serviceById, deferred, setFlag, checkout}) => {
    return (
        <div className='position-fixed bottom-0 left-0 width-100percent z-index-1 box-shadow-rv-2xl-light padding-1dot25rem background-color-white border-top-left-radius-0dot75rem border-top-right-radius-0dot75rem'>
            <div className={classNames('local-cart-footer__details', {'local-cart-footer__details--visible': flag})}>
                <div className='display-flex'>
                    <div className='text-sm color-gray-500 flex-1'>
                        <span><FormattedMessage defaultMessage='Services fee'/></span>
                    </div>
                    <React.Suspense fallback={
                        <div className='text-md medium color-gray-700 opacity-0dot4 pointer-events-none'>
                            <span>0.<span className='text-xs'>00 </span></span>
                            <FormattedMessage defaultMessage='UAH'/>
                        </div>
                    }>
                        <ServiceFee{...{localCartState, serviceId: serviceById.id, deferred}}/>
                    </React.Suspense>
                </div>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <div className='z-index-2 background-color-white display-flex align-items-center flex-direction-row-column'>
                    <div className='cursor-pointer flex-1 display-flex' onClick={() => setFlag(!flag)}>
                        <div className='padding-top-0dot1875rem'>
                            <ChevronUp className={classNames('local-cart-footer__indicator color-primary-500 height-1dot25rem width-1dot25rem display-block', {'local-cart-footer__indicator--active': flag})}/>
                            <ShoppingBag03 className='color-primary-500 height-1dot25rem width-1dot25rem display-block'/>
                        </div>
                        <div className='display-block flex-1'>
                            <div className='text-sm color-gray-700'>
                                <FormattedMessage defaultMessage='Total'/>
                            </div>
                            <React.Suspense fallback={<span className='text-lg semibold color-gray-900 opacity-0dot4'>0.00 <FormattedMessage defaultMessage='UAH'/></span>}>
                                <Amount {...{localCartState, serviceId: serviceById.id, deferred, classes: 'text-lg semibold color-gray-900'}}/>
                            </React.Suspense>
                        </div>
                    </div>
                    <Button {...{color: 'primary-gradient', size: '2xl', clickHandler: () => checkout()}}>
                        <FormattedMessage defaultMessage='Checkout'/>
                    </Button>
                </div>
            </ErrorBoundary>
        </div>
    )
}
import React from 'react';
import {isSet, minTwoDigits} from '../../../utility';
import {adopt, checkShowCountdown} from '../../../math';
import {IN, OUT_NEGATIVE, OUT_POSITIVE, ENTITY_TYPE} from '../../../constants';
import {FormattedMessage} from 'react-intl';
import useNow from '../../../useNow';
import Browser from '../../../components/Browser';
import CalendarIcon from '../../../assets/icons0/calendar.svg';
import IconFeature from '../../../components/icon-feature/icon-feature';
import ClockIcon from '../../../assets/icons0/clock.svg';
import XCloseIcon from '../../../assets/icons0/x-close.svg';
import AlarmClockIcon from '../../../assets/icons0/alarm-clock.svg';
import classNames from 'classnames';

export const SERVICE_DESCRIPTION = {
    [OUT_POSITIVE]: {
        showCountdown: {
            title: <FormattedMessage defaultMessage='Sales opening in'/>,
            icon: <ClockIcon/>,
            light: 'primary',
            dark: 'gray-dark'
        },
        default: {
            title: <FormattedMessage defaultMessage='Sales opening'/>,
            icon: <CalendarIcon/>,
            light: 'primary',
            dark: 'gray-dark'
        }
    },
    [IN]: {
        showCountdown: {
            title: <FormattedMessage defaultMessage='Sales closing in'/>,
            icon: <AlarmClockIcon/>,
            light: 'warning',
            dark: 'gray-dark'
        },
    },
    [OUT_NEGATIVE]: {
        default: {
            title: <FormattedMessage defaultMessage='Sales closed'/>,
            icon: <XCloseIcon/>,
            light: 'error',
            dark: 'gray-dark'
        }
    }
}

export const ServiceScheduleCard = ({title, icon, light, dark, theme, children}) => {
    return (
        <div className={classNames('padding-top-1rem padding-bottom-1rem padding-left-1dot5rem padding-right-1dot5rem border-radius-0dot75rem', {'background-color-gray-900': theme === 'dark', 'background-color-white': theme === 'light'})}>
            <div className='display-flex flex-direction-column align-items-center'>
                <IconFeature {...{size: 'md', color: theme === 'light' ? light : dark, feature: 'outline'}}>
                    {icon}
                </IconFeature>
                <div className={classNames('text-md padding-top-0dot75rem text-align-center', {'color-gray-100': theme === 'dark', 'color-gray-500': theme === 'light'})}>
                    <span>{title}</span>
                    <div className={classNames({'white': theme === 'dark', 'color-gray-700': theme === 'light'})}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ServiceScheduleImpl = React.memo(({now, start, end, strategy, every, gapAmount, gapUnits, theme}) => {
    const {timeLeft, state, from, to} = React.useMemo(() => adopt(now, start, end, strategy, every), [now, start, end, strategy, every]);
    const showCountdown = React.useMemo(() => checkShowCountdown(now, state, to, from, ENTITY_TYPE.service, gapAmount, gapUnits), [now, state, to, from, gapAmount, gapUnits]);
    const serviceDescription = SERVICE_DESCRIPTION[state][showCountdown ? 'showCountdown' : 'default'];
    return (
        <>
            {serviceDescription &&
                <div className={state === IN ? 'padding-bottom-1dot5rem' : 'padding-bottom-2rem'}>
                    <ServiceScheduleCard {...{...serviceDescription, theme}}>
                        {state === OUT_POSITIVE && !showCountdown && <span className='semibold padding-left-0dot5rem'>{`${from.format('DD.MM.YYYY')}`}</span>}
                        {state === OUT_POSITIVE && showCountdown && <p className='semibold padding-top-0dot5rem'>
                            {isSet(timeLeft.hours) && minTwoDigits(timeLeft.hours)}
                            :
                            {isSet(timeLeft.minutes) && minTwoDigits(timeLeft.minutes)}
                            :
                            {isSet(timeLeft.seconds) && minTwoDigits(timeLeft.seconds)}
                        </p>}
                        {state === IN && showCountdown && <p className='semibold padding-top-0dot5rem'>
                            {isSet(timeLeft.hours) && minTwoDigits(timeLeft.hours)}
                            :
                            {isSet(timeLeft.minutes) && minTwoDigits(timeLeft.minutes)}
                            :
                            {isSet(timeLeft.seconds) && minTwoDigits(timeLeft.seconds)}
                        </p>}
                    </ServiceScheduleCard>
                </div>
            }
        </>
    );
});

const ServiceScheduleDef = React.memo(({start, end, strategy, every, gapAmount, gapUnits, theme}) => {
    const now = useNow();
    const nowDeferred = React.useDeferredValue(now);
    return (
        <div>
            <ServiceScheduleImpl {...{now: nowDeferred, start, end, strategy, every, gapAmount, gapUnits, theme}} />
        </div>
    );
});

const ServiceSchedule = ({start, end, strategy, every, gapAmount, gapUnits, theme}) => {
    if (!start && !end) return null;
    return (
        <Browser>
            <ServiceScheduleDef {...{start, end, strategy, every, gapAmount, gapUnits, theme}}/>
        </Browser>
    );
};

export default React.memo(ServiceSchedule);
import React from 'react';
import './Services.scss';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../searchFilter';
import classNames from 'classnames';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../Skeleton';
import useQuery from '../../useQuery';
import {FormattedMessage} from 'react-intl';
import ErrorFallback from '../ErrorFallback';

const Services = () => {
    const location = useLocation();
    const {size = 8, page = 0} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query ServicesQuery($size: Int!, $page: Int!) {
                services(size: $size, page: $page) {
                    totalPages,
                    content {
                        id
                        name
                        description
                    }
                }
            }
        `,
        {size, page}
    );
    return (
        <>
        <div className='services__list'>
            {data.services.content.map((service) =>
                <div key={service.id} className='services__card'>
                    <div key={service.id} className='services__card-content'>
                        <div className='services__card-description'>
                            <Link to={service.id}>
                                {service.description || service.id}
                            </Link>
                        </div>
                        <div className='services__card-meta'>
                            {service.name}
                        </div>
                    </div>
                </div>
            )}
        </div>
        <div className='services__pager'>
            {[...new Array(data.services.totalPages)].map((x, i) =>
                <Link
                    key={i}
                    className={classNames('services__pager-item', {
                        'services__pager-item-active': parseInt(page) === i
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page: i}, false)}`}
                >
                    {i + 1}
                </Link>
            )}
            <div className='services__pager-item'>{'| '}page</div>
        </div>
        <div className='services__pager'>
            <Link
                className={classNames('services__pager-item', {
                    'services__pager-item-active': parseInt(size) === 2
                })}
                to={`${location.pathname}${searchFilter(location.search, {page:0, size: 2}, false)}`}
            >2</Link>
            <Link
                className={classNames('services__pager-item', {
                    'services__pager-item-active': parseInt(size) === 4
                })}
                to={`${location.pathname}${searchFilter(location.search, {page:0, size: 4}, false)}`}
            >4</Link>
            <Link
                className={classNames('services__pager-item', {
                    'services__pager-item-active': parseInt(size) === 8
                })}
                to={`${location.pathname}${searchFilter(location.search, {page:0, size: 8}, false)}`}
            >8</Link>
            <div className='services__pager-item'>{'| '}per page</div>
        </div>
        </>
    );
};

export default React.memo(() =>
    <div className='margin-top-1dot5rem'>
        <div className='font-size-2dot5rem font-weight-bold margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Services'/>
        </div>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense
                fallback={
                    <>
                    <div className='services__list'>
                        {[1,2,3].map(i =>
                            <div key={i} className='services__card'>
                                <div className='services__card-content'>
                                    <Skeleton/>
                                    <div className='services__card-meta'>&nbsp;</div>
                                </div>
                            </div>
                        )}
                    </div>
                    </>
                }
            >
                <Services/>
            </React.Suspense>
        </ErrorBoundary>
    </div>
);

/**
 * @generated SignedSource<<c5ce0b6c9ae586b66d9bcaa601dcba14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endpointId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "serviceId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameUk",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "endpointId"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "endpointById",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "serviceId"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "serviceById",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "target",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "orderItemCartPagesQuery",
    "selections": (v4/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "orderItemCartPagesQuery",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "a351c88b6941cf40a7b9a3c0849eefaa",
    "id": null,
    "metadata": {},
    "name": "orderItemCartPagesQuery",
    "operationKind": "query",
    "text": "query orderItemCartPagesQuery(\n  $endpointId: String\n  $serviceId: String\n) {\n  endpointById(id: $endpointId) {\n    id\n    name\n    price\n    nameUk\n  }\n  serviceById(id: $serviceId) {\n    id\n    name\n    nameUk\n    target\n  }\n}\n"
  }
};
})();

node.hash = "67c286a0ec3ba8003a97663df829b44e";

module.exports = node;

import React from 'react';
import {Route, Routes} from 'react-router-dom';
import {EndpointShell, RecurringShell} from './shell';
import Endpoint from './endpoint';
import ActivateRecurring from './activate-recurring';
import ConfirmRecurring from './confirm-recurring';
import {PaymentOptionsRecurringCreate, PaymentOptionsRecurringUpdate} from './payment-options-recurring';
import Recurring from './recurring';
import CancelRecurring from './cancel-recurring';
import Private from '../../components/private';

const SubscriptionsRoutes = () => {
    return (
        <div className='pages'>
            <div className='subscriptions'>
                <Routes>
                    <Route path='service/:serviceId/endpoint/:endpointId' element={<EndpointShell/>}>
                        <Route index element={<Endpoint/>}/>
                        {/* Private routes */}
                        <Route path='activate' element={<Private><ActivateRecurring/></Private>}/>
                        <Route path='confirm/:poolId' element={<Private><ConfirmRecurring/></Private>}/>
                        <Route path='payment-options/:currentPoolId' element={<Private><PaymentOptionsRecurringCreate/></Private>}/>
                    </Route>
                    <Route path='/recurring/:recurringId' element={<Private><RecurringShell/></Private>}>
                        <Route index element={<Recurring/>}/>
                        <Route path='cancel' element={<CancelRecurring/>}/>
                        <Route path='payment-options/:currentPoolId' element={<PaymentOptionsRecurringUpdate/>}/>
                    </Route>
                </Routes>
            </div>
        </div>
    )
}

export default React.memo(SubscriptionsRoutes);

import React from 'react';
import {graphql, useFragment} from 'react-relay/hooks';
import useQuery from '../../useQuery';
import i18n from '../../i18n';

const EndpointName = ({endpoint}) => {
    const {locale = 'uk'} = useQuery();
    const fEndpoint = useFragment(
        graphql`
            fragment EndpointName_endpoint on Endpoint {
                id
                name
                nameUk
                untie
                price
            }
        `,
        endpoint
    );
    return (
        <>
        {fEndpoint.untie
            ? <span>{i18n(fEndpoint, 'name', locale)}</span>
            : <span>{i18n(fEndpoint, 'name', locale)} {fEndpoint.price} UAH</span>
        }
        </>
    );
};

export default React.memo(EndpointName);

import React from 'react';

export const ScrollStateContext = React.createContext({resetScroll: true});
export const ScrollDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'prevent-scroll':
            return {...state, resetScroll: false};
        case 'allow-scroll':
            return {...state, resetScroll: true};
        default: 
            return state;
    }
};

export const ScrollProvider = React.memo(({children}) => {
    const [state, setState] = React.useState({resetScroll: true});
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            return nextState;
        });
    }, []);
    return (
        <ScrollStateContext.Provider value={state}>
            <ScrollDispatchContext.Provider value={dispatch}>
                {children}
            </ScrollDispatchContext.Provider>
        </ScrollStateContext.Provider>
    );
});

export const useScrollState = () => React.useContext(ScrollStateContext);
export const useScrollDispatch = () => React.useContext(ScrollDispatchContext);

import React from 'react';

const RulesEn = () => {
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-1dot5rem font-weight-bold'>
                ПУБЛІЧНИЙ ДОГОВІР 
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                про надання послуг в мережі Інтернет
            </div>
            <div className='margin-top-1rem'>
                Товариство з обмеженою відповідальністю «Оттрі» (далі - «Оттрі», «Компанія») здійснює свою діяльність відповідно до законодавства України і зареєстроване за адресою: 04050, м. Київ, вул. Глибочицька, 16, офіс 13, керуючись ст. 633, 634 Цивільного кодексу України, публічно пропонує необмеженому колу осіб Онлайн-сервіс здійснення підприємницької діяльності в мережі Інтернет, для чого публікує даний Договір про наступне:
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                1. ПРЕДМЕТ ДОГОВОРУ
            </div>
            <div className='margin-top-1rem'>
                1.1. Цей Договір регулює відносини Підприємства і Компанії, згідно з якими Підприємство з метою реалізації своїх товарів і послуг в мережі Інтернет розміщує на своєму web-сайті Онлайн-сервіс Компанії разом з платіжним механізмом Фінансового Партнера Оттрі, а Компанія забезпечує технологічне обслуговування Підприємства по функціонуванню Онлайн-сервісу.1.2. Цей Договір публічно поширюється на необмежене коло осіб для сприяння в реалізації товарів і послуг в мережі Інтернет за допомогою Онлайн-сервісу Компанії шляхом його розміщення (публікації) на web-сайті Підприємства. Онлайн-сервіс також може бути доступним на web-сайті Компанії: https://ottry.com.
            </div>
            <div className='margin-top-1rem'>
                1.3. Цей Договір набуває чинності з моменту згоди Підприємства з умовами про приєднання до ПУБЛІЧНОГО ДОГОВОРУ про надання Онлайн-сервісу здійснення підприємницької діяльності в мережі Інтернет оформленому в електронному вигляді на сайті https://ottry.com.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                2. ОСНОВНІ ТЕРМІНИ І ПОНЯТТЯ
            </div>
            <div className='margin-top-1rem'>
                2.1. Терміни, які не вказані в даному розділі, вживаються в тексті цього Договору, в значеннях відповідно до законодавства України.
            </div>
            <div className='margin-top-1rem'>
                2.2. Підприємство - суб'єкт підприємницької діяльності, який на умовах цього Договору розміщує на Сайті шляхом програмних засобів Оттрі онлайн-сервіс Оттрі з метою реалізації своїх товарів і послуг, а також на умовах Договору з Фінансовим Партнером приймає Карти з метою здійснення оплати вартості товарів / послуг на Сайті .
            </div>
            <div className='margin-top-1rem'>
                2.3. Фінансовий Партнер - компанія-партнер Оттрі, що надає фінансові послуги, зокрема, здійснення інтернет-платежів через міжнародні платіжні системи Visa, Master Card і ін. Фінансовим Партнером може бути тільки компанія, що має відповідні ліцензії на надання фінансових послуг.
            </div>
            <div className='margin-top-1rem'>
                2.4. Маркетинговий Партнер - компанія-партнер Оттрі, яка розміщує рекламу своїх послуг і товарів шляхом програмних засобів Оттрі через Онлайн-сервіс Оттрі, який розміщується на web-сайті Підприємства.
            </div>
            <div className='margin-top-1rem'>
                2.5. Відвідувач Сайту - будь-яка фізична особа - користувач мережі Інтернет, що здійснює доступ до web-сайту Підприємства.
            </div>
            <div className='margin-top-1rem'>
                2.6. Покупець - особа, яка замовляє та оплачує за допомогою Карти товари або послуги Підприємства через Інтернет.
            </div>
            <div className='margin-top-1rem'>
                2.7.Онлайн-сервіс здійснення підприємницької діяльності, далі Онлайн-сервіс - програмний засіб Компанії, який надає можливість онлайн бронювання послуг і товарів в мережі Інтернет та/або відображення доступності Ендпоінта. 
            </div>  
            <div className='margin-top-1rem'>
                2.8. Ендпоінт - кінцева послуга або товар Підприємства, що відображається на його web-сайті, яка може бути доступна для бронювання або придбання.
            </div>
            <div className='margin-top-1rem'>
                2.9. Менеджмент Кіт - електронний кабінет Підприємства, в якому доступна інформація про Замовлення послуг і товарів, статус бронювання, а також проведених Транзакцій. В даному кабінеті також доступні персональні дані Покупців.
            </div>
            <div className='margin-top-1rem'>
                2.10. Платіжний механізм - сервіс Фінансового Партнера для організації прийому платежів в мережі Інтернет, що надходять на рахунок Підприємства.
            </div>
            <div className='margin-top-1rem'>
                2.11. Замовлення - сукупність товарів і послуг Підприємства, їх вартості, способу і вартості їх доставки, розміщених на Сайті, а також персональних даних Покупця.
            </div>
            <div className='margin-top-1rem'>
                2.12. Платіжна карта, далі Карта, - спеціальний платіжний засіб у вигляді емітованої в установленому законодавством порядку пластикової карти, яка використовується для ініціювання переказу коштів з рахунку платника або з відповідного рахунку в банку з метою оплати вартості товарів або послуг або здійснення інших операцій, передбачених чинним законодавством.2.13. Транзакція - сукупність операцій між Покупцем, Підприємством і Фінансовим Партнером з придбання товарів або послуг на Сайті з використанням Картки як платіжного засобу.2.14. Операція - сукупність реєстраційних дій Підприємства при створенні облікового запису на web-сайті Компанії, і Покупця при заповненні відповідних даних в Онлайн-сервісі з метою перегляду доступності Ендпоінта, бронювання та/або купівлі товару або послуги.
            </div>
            <div className='margin-top-1rem'>
                2.15. Web-сайт - сукупність інформації і сервісних функцій, доступних користувачам мережі Інтернет, зібраних на одному або декількох серверах.
            </div>
            <div className='margin-top-1rem'>
                2.16. Web-сайт Підприємства, далі Сайт - web-сайт, керований і контрольований Підприємством.
            </div>
            <div className='margin-top-1rem'>
                2.17. Обліковий запис - збережена в комп'ютерній системі Оттрі сукупність даних про Підприємство, необхідних для його розпізнавання (автентифікації) і надання доступу до його особистих даних і налаштувань в електронному кабінеті. 
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                3. ПРАВА ТА ОБОВ'ЯЗКИ ПІДПРИЄМСТВА
            </div>
            <div className='margin-top-1rem'>
                3.1. Підприємство має право:
            </div>
            <div className='margin-top-1rem'>
                3.1.1. Отримувати від Компанії консультації щодо порядку підключення Онлайн-сервісу до web-інтерфейсу і розміщення його на web-сайті витратних і інформаційних матеріалів, інструктажу персоналу по роботі з сервісом Оттрі. 
            </div>
            <div className='margin-top-1rem'>
                3.1.2. Отримувати від Компанії інформацію про статус бронювання послуги або товару, а також проведеної транзакції, звіряти дані Фінансового Партнера про проведений платіж з даними, наданими Компанією. 
            </div>
            <div className='margin-top-1rem'>
                3.1.3. Повідомляти Компанію про те, що реклама Маркетингових Партнерів Оттрі, яка показується через Онлайн-сервіс на Сайті не відповідає чинному законодавству України про рекламу. 
            </div>
            <div className='margin-top-1rem'>
                3.2. Підприємство зобов'язане:
            </div>
            <div className='margin-top-1rem'>
                3.2.1. При проведенні Операції дотримуватися порядку роботи, викладеному в цьому Договорі. Підприємство самостійно вибирає серед усіх наявних у нього поточних рахунків, рахунок на який буде здійснюватися перерахування грошових коштів що надходять від Покупців. При укладанні Договору з Фінансовим Партнером вказувати правильні реквізити свого поточного рахунку, на який буде здійснюватися перерахування грошових коштів, що надходять від Покупців. Компанія не несе відповідальності за повноту і достовірність наданих Підприємством реквізитів, а також за проведення Транзакції. Відповідальність за проведення Транзакції і всі супутні операції за проведенням платежів лежить на Фінансовому Партнері Компанії. 
            </div>
            <div className='margin-top-1rem'>
                3.2.2. Контролювати проходження платежів по Транзакціях Підприємства і в разі виявлення заборгованості негайно повідомити про це Фінансового Партнера та/або Компанію.
            </div>
            <div className='margin-top-1rem'>
                3.2.3. Розмістити на Сайті повну інформацію про реалізовані товари та послуги, що надаються Підприємством.
            </div>
            <div className='margin-top-1rem'>
                3.2.4. Забороняється продаж товарів/послуг, якщо:
                <div>
                    а) такий продаж є незаконним, або може зашкодити діловій репутації, або негативно відбитися на бренді Оттрі.
                </div>
                <div>
                    б) продаж, в тому числі зображення, яке є явно образливим і не має художньої цінності (наприклад, зображення сексуального характеру, сексуальною експлуатацією неповнолітніх, каліцтва обличчя або органів, також прояв жорстокості);
                </div>
                <div>
                    в) продаж будь-якого іншого товару/послуг, який Підприємство, на свій розсуд, вважає неприйнятними для продажу зі своїм брендом;
                </div>
                <div>
                    г) товари / послуги відносяться до заборонених, відповідно до законодавства України.
                </div>
            </div>
            <div className='margin-top-1rem'>
                3.2.5. Підприємство зобов'язується допускати показ реклами послуг Компанії, Фінансового Партнера, Маркетингового Партнера Оттрі на Сайті відповідно до чинного законодавства України про рекламу.
            </div>
            <div className='margin-top-1rem'>
                3.2.6. Підприємство погоджується з тим, що Компанія має право збирати, зберігати і обробляти дані про будь-які дії Покупців на Сайті, формувати статистику і аналітику щодо таких даних.
            </div>
            <div className='margin-top-1rem'>
                3.2.7. Забезпечити конфіденційність і нерозголошення персональних даних Покупців, інформації по Операціям і Транзакціях, котрі проходять через Онлайн-сервіс Оттрі і Платіжний механізм Фінансового Партнера, розміщених на web-сайті Підприємства. 
            </div>
            <div className='margin-top-1rem'>
                3.2.8. Забезпечити збереження конфіденційності всієї переданої Компанією Підприємству інформації за цим Договором.
            </div>
            <div className='margin-top-1rem'>
                3.2.9. У разі будь-яких збоїв в роботі Онлайн-сервісу звернутися в службу підтримки Оттрі. У разі будь-яких збоїв, пов'язаних з здійсненням оплати послуг або товарів, замовлених через Онлайн-сервіс Оттрі - звернутися в службу підтримки Фінансового Партнера та/або в службу підтримки Оттрі.
            </div>
            <div className='margin-top-1rem'>
                3.2.10. При виникненні обставин, що перешкоджають виконанню умов цього Договору, повідомити Компанію в триденний термін.
            </div>
            <div className='margin-top-1rem'>
                3.2.11. У разі пред'явлення претензій Компанії з боку третіх осіб, Підприємство зобов'язане відшкодувати в повному обсязі Компанії понесені їй збитки, що виникли в результаті діяльності Підприємства та/або його контрагента. Компанія не несе відповідальності за якість наданих послуг і товарів Підприємством Покупцеві.
            </div>
            <div className='margin-top-1rem'>
                3.2.12. Відслідковувати будь-які зміни в даному Публічному договорі шляхом вивчення офіційних повідомлень Компанії, опублікованих на web-сайті Оттрі в системі Менеджмент Кіт за 30 днів до вступу в силу будь-яких змін в існуючий Договір. Якщо протягом 30 днів Підприємство не повідомить Компанію про відмову співпрацювати на умовах нового Публічного договору в письмовому вигляді (шляхом електронної кореспонденції), дія цього Договору автоматично продовжується на нових умовах згідно змін, які раніше були опубліковані на web-сайті Оттрі.
            </div>
            <div className='margin-top-1rem'>
                3.3. Права і обов'язки Компанії
            </div>
            <div className='margin-top-1rem'>
                3.3.1 Компанія має право:
            </div>
            <div className='margin-top-1rem'>
                3.3.2. Обмежити кількість замовлень (бронювання, покупок) послуг і товарів через Онлайн-сервіс за один день на Сайті з повідомленням про це Підприємства факсом або електронною поштою.
            </div>
            <div className='margin-top-1rem'>
                3.3.3. Співпрацювати з Маркетинговими Партнерами в цілях реклами наданих послуг і товарів Компанії та Маркетинговим Партнером шляхом відповідних показів на web-сайті Підприємства (можуть бути використані посилання, логотипи, інші відгалуження, спливаючі вікна і кнопки на програмних засобах Онлайн-сервісу).
            </div>
            <div className='margin-top-1rem'>
                3.3.4. Компанія має право призупинити проведення Операцій, що здійснюються через Онлайн-сервіс Оттрі і Транзакцій, що здійснюються через web-інтерфейс Фінансового Партнера (повідомивши про це Підприємство за день до дати призупинення Операцій способом електронної кореспонденції) в наступних випадках:
                <div>
                     - У разі якщо Операції і Транзакції на момент їх здійснення викликають підозру щодо їх правомірності (також на підставі інформації, отриманої від Фінансового Партнера в результаті антіфрод-моніторингу операцій);
                </div>
                <div>
                     - У разі отримання Компанією повідомлення від Фінансового Партнера про те, що проведені в Системі Підприємства операції є шахрайськими;
                </div>
            </div>
            <div className='margin-top-1rem'>
                3.3.5. Перевіряти дотримання Підприємством пунктів цього Договору.
            </div>
            <div className='margin-top-1rem'>
                3.3.6. Змінювати умови цього Договору.
            </div>
            <div className='margin-top-1rem'>
                3.4. Компанія зобов'язана:
            </div>
            <div className='margin-top-1rem'>
                3.4.1. Надавати технологічне обслуговування по розміщенню і підтримці функціональності Онлайн-сервісу на web-сайті Підприємства.
            </div>
            <div className='margin-top-1rem'>
                3.4.2. Надавати інформацію про статус бронювання послуги або товару, а також здійсненної Транзакції. Надавати доступ Підприємству до персональних даних Покупців.
            </div>
            <div className='margin-top-1rem'>
                3.4.3. Забезпечити конфіденційність і нерозголошення персональних даних Покупців, інформації про Операції і транзакцій, що здійснюються через Онлайн-сервіс Оттрі і Платіжний механізм Фінансового Партнера, розміщених на web-сайті Підприємства.
            </div>
            <div className='margin-top-1rem'>
                3.4.4. Забезпечити збереження конфіденційності всієї переданої Підприємством Компанії інформації за цим Договором.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                4. ПРАВИЛА І ПОРЯДОК ВИКОРИСТАННЯ ПОСЛУГ
            </div>
            <div className='margin-top-1rem'>    
                4.1. Надання Підприємству Онлайн сервісу здійснення підприємницької діяльності шляхом його розміщення на Сайті можливо за умови створення Підприємством на web-сайті Компанії відповідного Облікового запису. Обліковий запис повинен містити адресу електронної пошти Підприємства. Компанія має право вимагати надання додаткових персональних даних Підприємства шляхом заповнення відповідного профілю в Менеджмент Кіті через Обліковий запис. 
            </div>
            <div className='margin-top-1rem'>
                4.2. Підприємство несе відповідальність за конфіденційність пароля. При встановленні Підприємством фактів несанкціонованого доступу до свого облікового запису, воно зобов'язується в найкоротші строки повідомити про цю обставину службу підтримки Компанії за адресою: info@ottry.com
            </div>
            <div className='margin-top-1rem'>
                4.3. Після створення облікового запису і відповідно приєднання до цього Договору, за допомогою програмних засобів Компанії, Онлайн-сервіс разом з платіжним механізмом Фінансового Партнера розміщуються на Сайті.
            </div>
            <div className='margin-top-1rem'>
                4.4. Web-сайт Оттрі доступний за адресою: https://ottry.com
            </div>
            <div className='margin-top-1rem'>
                4.5. Вибір товарів і послуг, формування їх переліку та кількості, вибір способу доставки, перегляд доступності Ендпоінта, замовлення на зворотній зв'язок, Відвідувач здійснює програмними засобами Оттрі через Онлайн-сервіс, розміщений на Сайті.
            </div>
            <div className='margin-top-1rem'>
                4.6. У разі проведення покупки товару і послуги через Онлайн-сервіс, Відвідувач при переході на web-сайт Фінансового Партнера погоджується з умовами Публічного договору Фінансового Партнера, створює обліковий запис, вводить дані платіжної карти і здійснює оплату через платіжний механізм Фінансового Партнера. 
            </div>
            <div className='margin-top-1rem'>
                4.7. Перерахування суми Замовлення, за вирахуванням комісійних Фінансовим Партнером, з карткового рахунку Покупця на рахунок Підприємства проводиться Фінансовим Партнером відповідно до технологій, прийнятих при проведенні взаєморозрахунків за допомогою міжнародних платіжних систем. 
            </div>
            <div className='margin-top-1rem'>
                4.8. Відвідувач Сайту стає Покупцем в разі успішної реєстрації Замовлення і його оплати.
            </div>
            <div className='margin-top-1rem'>
                4.9. У разі звернення Покупця в службу підтримки Підприємства, Підприємство з метою вирішення питання Покупця запитує у Компанії відповідну інформацію про Операції, статус бронювання або покупки товару/послуги. 
            </div>
            <div className='margin-top-1rem'>
                4.10. Відповідальність за Факт доставки Замовлення Покупцю або факт повернення (частини) Замовлення Покупцем лежить на Підприємстві.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                5. ФІНАНСОВІ УМОВИ
            </div>
            <div className='margin-top-1rem'>
                5.1. Комісія за користування онлайн сервісом підприємницької діяльності і мережі Інтернет, що надається Компанією стягується Компанією в таких розмірах:
            </div>
            <div className='margin-top-1rem'>
                5.1.1. для суб’єктів господарювання, які здійснюють діяльність з проведення концертних, розважальних та інших заходів – 5 %.
            </div>
            <div className='margin-top-1rem'>
                5.1.2. у виключних випадках може бути встановлений інший розмір комісії, що погоджується шляхом підписання між Підприємством і Компанією Додаткової угоди.
            </div>
            <div className='margin-top-1rem'>
                5.2. Комісія за розрахункове обслуговування транзакцій по Картах стягується Фінансовим Партнером в розмірі, встановленому публічним договором оферти Фінансового Партнера.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                6. ВІДПОВІДАЛЬНІСТЬ СТОРІН
            </div>
            <div className='margin-top-1rem'>
                6.1. Компанія несе відповідальність виключно за технологічне обслуговування та підтримку Онлайн-сервісу, розміщеного на Сайті, а також за обробку та зберігання персональних даних Покупців, Підприємства та іншої інформації, наданої Підприємством Компанії.6.2. Компанія не несе відповідальності за повноту і достовірність наданих Підприємством реквізитів для проведення Транзакції. Відповідальність за проведення Транзакції і всі супутні операції за здійсненням платежів лежать на Фінансовому Партнері Компанії.6.3. Компанія не несе відповідальність за якість послуг і товарів, що надаються Підприємством Покупцеві.
            </div>
            <div className='margin-top-1rem'>
                6.4. У разі пред'явлення претензій Компанії з боку третіх осіб, Підприємство зобов'язане відшкодувати в повному обсязі Компанії понесені їй збитки, що виникли в результаті діяльності Підприємства та/або його контрагента.
            </div>
            <div className='margin-top-1rem'>
                6.5. При невиконанні або неналежному виконанні своїх зобов'язань однією із Сторін, інша сторона має право вимагати від винної сторони виконання прийнятих на себе зобов'язань, а також відшкодування завданих їй збитків.
            </div>
            <div className='margin-top-1rem'>
                6.6. При невиконанні умов п. 3.2.4. Підприємство виплачує Компанії штраф в розмірі 30 000,00 грн. (тридцять тисяч гривень) 00 коп. за кожне встановлене порушення (бронювання, замовлення, здійснений платіж).
            </div>
            <div className='margin-top-1rem'>
                6.7. Підприємство несе повну відповідальність за інформацію, що міститься на його Сайті.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                7. ВРЕГУЛЮВАННЯ РОЗБІЖНОСТЕЙ І ВИРІШЕННЯ СПОРІВ
            </div>
            <div className='margin-top-1rem'>
                7.1. Всі суперечки і розбіжності, що виникають з цього Договору або в зв'язку з ним, будуть, по можливості, вирішуватися шляхом переговорів між Сторонами.
            </div>
            <div className='margin-top-1rem'>
                7.2. Якщо Сторони не дійдуть згоди, спори і розбіжності підлягають вирішенню в установленому чинним законодавством України порядку.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                8. СТРОКИ ДОГОВОРУ І УМОВИ ЙОГО ПРИПИНЕННЯ
            </div>
            <div className='margin-top-1rem'>    
                8.1. Цей Договір набуває чинності з моменту приєднання до Договору Підприємства шляхом згоди в електронному вигляді на web-сайті Компанії, і діє протягом одного року. Якщо протягом одного місяця до закінчення терміну дії цього Договору жодна із Сторін не виступила з ініціативою про припинення його дії, Договір продовжує діяти протягом такого ж терміну. 
            </div>
            <div className='margin-top-1rem'>
                8.2 Припинення цього Договору здійснюється у відповідності з положеннями цього Договору та чинного законодавства України. При наявності у однієї із Сторін фінансових чи інших претензій один до одного, розірвання Договору відкладається до врегулювання спірних питань.
            </div>
            <div className='margin-top-1rem'>
                8.3. При розірванні цього Договору Підприємство зобов'язується зняти з усіх сторінок сайту логотипи Оттрі, посилання https://ottry.com, і іншу інформацію про співпрацю з Компанією за цим Договором.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                9. ФОРС-МАЖОРНІ ОБСТАВИНИ
            </div>
            <div className='margin-top-1rem'>
                9.1. Сторони звільняються від відповідальності за повне або часткове невиконання зобов'язань за цим Договором, якщо це невиконання стало наслідком заборонних заходів держави, місцевих органів влади або обставин непереборної сили, до яких відносяться події, на які Сторони не можуть вплинути і не несуть відповідальності за їх виникнення, наприклад: пожежа, повінь, землетрус, війна, страйк і т. п.
            </div>
            <div className='margin-top-1rem'>
                9.2. Підприємство зобов'язується в письмовій формі (шляхом електронної кореспонденції) повідомити Компанію про існування форс-мажорних обставин протягом 10 (десяти) днів з моменту їх настання. Компанія буде публікувати інформацію про існування форс-мажорних обставин на web-сайті протягом 10 (десяти) днів з моменту їх настання.
            </div>
            <div className='margin-top-1rem'>
                9.3. Якщо форс-мажорні обставини та їх наслідки продовжують діяти більше шести місяців, сторони в найкоротші строки повинні провести переговори з метою виявлення прийнятних для обох Сторін альтернативних способів виконання умов цього Договору та досягнення відповідних письмових (і/або в електронному вигляді) домовленостей.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                10. ІНШІ УМОВИ
            </div>
            <div className='margin-top-1rem'>
                10.1. У випадках, не передбачених цим Договором, Сторони керуються чинним законодавством України.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                11. РЕКВІЗИТИ КОМПАНІЇ
            </div>
            <div className='margin-top-1rem'>
                <div>
                    ТОВ «Оттрі»
                </div>
                <div>
                    Юр. адреса: 04050, м. Київ, вул. Глибочицька, 16, офіс 13
                </div>
                <div>
                    ЄДРПОУ: 41385243
                </div>
                <div>
                    Генеральний директор
                </div>
                <div>
                    Ковальчук В.В.
                </div>
            </div>
        </div>
    );
};

export default React.memo(RulesEn);
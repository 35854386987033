import React from 'react';
import {useLocation} from 'react-router-dom';
import {queryParameters} from '../qs';
import searchUnFilter from '../searchUnFilter';
import Skeleton from './Skeleton';
import {useMoveDispatch} from './Move';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const SignInRedirect = () => {
    const move = useMoveDispatch();
    const location = useLocation();
    const locationRef = React.useRef();
    locationRef.current = location;
    React.useEffect(() => {
        move();
        const qps = queryParameters(locationRef.current);
        setTimeout(() => window.location.href = RAZZLE_APP_ACCOUNTS 
            + '/endpoint?cache=0&new=true&continue='
            + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
            + (qps.length ? '&' + qps.join('&') : ''),
            500);
    }, []);
    return (
        <Skeleton/>
    );
};

export default React.memo(SignInRedirect);

import React from 'react';

import './icon-feature.scss';

// color: primary, success, error, warning, gray, gray-dark
// size: xs, sm, md, lg, xl
// feature: outline

const IconFeature = ({color, size, children, feature}) => {
    return (
        <div className={`icon-feature icon-feature--${color} icon-feature--${feature} icon-feature--${size}`}>
            {children}
        </div>
    )
}

export default React.memo(IconFeature);
import React from 'react';
import './Button.scss';
import {FormattedMessage} from 'react-intl';

function Button({reference, flag, toggle}) {
    return (
        <div ref={reference} className='button' onClick={toggle}>

            Ottry <FormattedMessage defaultMessage='services'/>
            &nbsp;<div className='products-button__icon'>{flag ? <>..</> : <>...</>}</div>
        </div>
    );
}

export default React.memo(Button);
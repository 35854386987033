import React from 'react';
import {useParams, useLocation} from 'react-router-dom';
import {useLazyLoadQuery} from 'react-relay/hooks';
import i18n from '../../i18n';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import Button, {RouterLinkButton} from '../../components/button/button';
import {queryParameters} from '../../qs';
import searchUnFilter from '../../searchUnFilter';
import classNames from 'classnames';
import UserMenu from './user-menu';
import useQuery from '../../useQuery';
import {useAuthorizationState} from '../../contexts/Authorization';
import {graphqlSubscriptionEndpointByIdQuery} from './graphql';
import getPeriodFromCron from '../../utilities/get-period-from-cron';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const Endpoint = () => {
    const {endpointId} = useParams();
    const {locale = 'uk'} = useQuery();
    const authorizationState = useAuthorizationState();
    const {endpointById} = useLazyLoadQuery(graphqlSubscriptionEndpointByIdQuery, {endpointId: endpointId});
    const cronPeriod = getPeriodFromCron(endpointById.cron);
    const location = useLocation();
    const qps = queryParameters(location);
    const signIn = (e) => {
        e.preventDefault();
        setTimeout(() => {
            window.location.href = RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&new=true&continue='
                + encodeURIComponent(location.pathname + '/activate' + searchUnFilter(document.location.search))
                + (qps.length ? '&' + qps.join('&') : '');
        }, 500);
    };
    
    return (
        <div className='view'>
            <div className={classNames({'mw768-padding-top-7dot5rem': !!authorizationState, 'mw768-padding-top-8rem': !authorizationState})}>
                {authorizationState &&
                    <div className='margin-bottom-1dot5rem mw768-margin-bottom-2rem'>
                        <UserMenu/>
                    </div>
                }
                <div className='display-flex flex-direction-column mw768-flex-direction-row mw768-justify-content-space-between'>
                    <div className='mw768-flex-1 mw768-padding-right-2rem mw768-max-width-26dot5rem'>
                        <p className='text-xl semibold color-gray-900'>{i18n(endpointById, 'name', locale)}</p>
                        <p className='text-xl color-gray-900 margin-top-0dot25rem mw768-margin-top-0dot5rem'>
                            {endpointById.price}
                            {' UAH '}
                            {i18n(cronPeriod, 'title', locale)}
                        </p>
                        <p className='text-md color-gray-500 margin-top-1rem'>
                            <FormattedMessage defaultMessage='Funds will be debited after subscription.'/>
                            {' '}
                            <FormattedMessage defaultMessage='The next withdrawal will be made on'/>
                            {' '}
                            {moment(endpointById.tick).locale(locale).format('DD MMMM YYYY')}
                        </p>
                    </div>
                    <div className='margin-top-2rem mw768-margin-top-0 mw768-width-14dot375rem'>
                        {authorizationState &&
                            <div>
                                <RouterLinkButton {...{color: 'primary-dark', size: 'lg', fluid: 'always', to: 'activate'}}>
                                    <FormattedMessage defaultMessage='Subscribe'/>
                                </RouterLinkButton>
                            </div>
                        }
                        {!authorizationState &&
                           <>
                                <div>
                                    <Button {...{color: 'primary-dark', size: 'lg', fluid: 'always', clickHandler: signIn}}>
                                        <FormattedMessage defaultMessage='Subscribe'/>
                                    </Button>
                                </div>
                                <div className='margin-top-0dot75rem'>
                                    <Button {...{color: 'secondary-gray', size: 'lg', fluid: 'always', clickHandler: signIn}}>
                                        <FormattedMessage defaultMessage='Manage subscription'/>
                                    </Button>
                                </div>
                           </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(Endpoint);
import qs from 'qs';

export const getSearchOrderArray = (currentParams, inlineParams, release) => {
    const filter = [...new Set(
        ['locale', 'currency', 'more', 'mode', 'apply', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'fbclid'].concat(Object.keys(inlineParams)).concat(release ? [] : Object.keys(currentParams))
    )];
    return filter;
}

export default (search, inline = {}, release = true) => {
    const current = qs.parse(search, {ignoreQueryPrefix: true});
    return qs.stringify(
        {
            ...current,
            ...inline
        },
        {
            filter: getSearchOrderArray(current, inline, release),
            addQueryPrefix: true
        }
    );
};
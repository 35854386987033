import React from 'react';
import usePageVisibility from '../usePageVisibility';
import {v4} from 'uuid';

export const InstantStateContext = React.createContext([]);
export const InstantDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'add':
            return [{quantity: 1, key: v4(), ...action.payload}];
        case 'bulkRemove':
            return state.filter((entry) => !action.payload.includes(entry.key));
        case 'reset':
            return action.payload;
        case 'add-several':
            return [{quantity: 1, key: v4(), ...action.payload}, ...state];
        case 'increment':
            return state.map((entry) => {
                if (entry.key !== action.payload) return entry;
                return {...entry, quantity: entry.quantity + 1};
            });
        case 'decrement':
            return state.map((entry) => {
                if (entry.key !== action.payload) return entry;
                return {...entry, quantity: entry.quantity > 1 ? entry.quantity - 1 : entry.quantity};
            });
        default: throw new Error();
    }
};

const instant__ = 'instant__0';
const deserialize = () => window.localStorage.getItem(instant__) && JSON.parse(window.localStorage.getItem(instant__)) || [];
const serialize = (instant) => window.localStorage.setItem(instant__, JSON.stringify(instant));

export const InstantProvider = React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible)
            setState(e => reducer(e, {type: 'reset', payload: deserialize()}));
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            serialize(nextState);
            return nextState;
        });
    }, []);
    return (
        <InstantStateContext.Provider value={state}>
            <InstantDispatchContext.Provider value={dispatch}>
                {children}
            </InstantDispatchContext.Provider>
        </InstantStateContext.Provider>
    );
});

export const useInstantState = () => React.useContext(InstantStateContext);
export const useInstantDispatch = () => React.useContext(InstantDispatchContext);

/**
 * @generated SignedSource<<513ada41806626cc99176416f805d3b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endpointId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "endpointId"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "endpointById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameUk",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "tick",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cron",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "graphqlSubscriptionEndpointByIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "graphqlSubscriptionEndpointByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "29ad5e423a76f60213ef7594c52cbe63",
    "id": null,
    "metadata": {},
    "name": "graphqlSubscriptionEndpointByIdQuery",
    "operationKind": "query",
    "text": "query graphqlSubscriptionEndpointByIdQuery(\n  $endpointId: String\n) {\n  endpointById(id: $endpointId) {\n    id\n    name\n    nameUk\n    price\n    tick\n    cron\n  }\n}\n"
  }
};
})();

node.hash = "b329c68afe1686b20280951a23c133bf";

module.exports = node;

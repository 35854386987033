import React from 'react';
import './Lol.scss';
import searchFilter from '../searchFilter';
import {Link, useLocation} from 'react-router-dom';

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='lol__footer-line'>
            <div className='lol__footer-line-left'>&nbsp;</div>
            <Link
                to={`/${searchFilter(location.search)}`}
            >Ottry</Link>
            <div className='lol__footer-line-right'>&nbsp;</div>
        </div>
    );
});
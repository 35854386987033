import React from 'react';
import classNames from 'classnames';

import IconFeature from '../icon-feature/icon-feature';
import AlertCircle from '../../assets/icons0/alert-circle.svg';
import IconAlertTriangle from '../../assets/icons0/alert-triangle.svg';

import './pop-up.scss';

const PopUp = ({isVisible, mode, title, icon, message, children}) => {
    return (
        <div className={classNames("pop-up-container", {'pop-up-container--visible': isVisible})}>
            {mode === 'default' && 
                <div className="pop-up">
                    {icon === 'alert' &&
                        <IconFeature size='lg' color='error' feature='outline' >
                            <AlertCircle />
                        </IconFeature>
                    }
                    {icon === 'alert-triangle' &&
                        <IconFeature size='lg' color='warning' feature='outline' >
                            <IconAlertTriangle />
                        </IconFeature>
                    }
                    {title && <p className="pop-up__title padding-top-1rem mw768-padding-top-1dot25rem text-xl medium color-gray-900">{title}</p>}
                    {message && <p className="pop-up__message padding-top-0dot5rem text-md color-gray-500">{message}</p>}
                    <div className="pop-up__actions display-flex padding-top-1dot5rem mw768-padding-top-2rem">
                        {children}
                    </div>
                </div>
            }
            {!mode && children}
        </div>
    )
};

export default React.memo(PopUp);
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {RouterLinkButton} from '../../../components/button/button';
import EmptyCartImage from '../../../assets/cart-empty-state.svg';
import searchFilter from '../../../searchFilter';
import './empty-cart.scss';

const EmptyCart = () => {
    const location = useLocation();

    return (
        <div className='empty-cart text-align-center'>
            <div>
                <EmptyCartImage className='empty-cart__svg'/>
            </div>
            <div className='text-2xl color-gray-900 semibold padding-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='Сart is empty'/>
            </div>
            <div className='text-md color-gray-500 padding-bottom-2rem empty-cart__content'>
                <FormattedMessage defaultMessage="Visit marketplace and see today's deals"/>
            </div>
            <div className='empty-cart__content'>
                <RouterLinkButton {...{color: 'primary-gradient', size: 'lg-wide', fluid: 'desktop', to: `/${searchFilter(location.search)}`}}>
                    <FormattedMessage defaultMessage='Visit marketplace'/>
                </RouterLinkButton>
            </div>
        </div>
    )
};

export default React.memo(EmptyCart);
import React from 'react';
import DoneIcon from '../../../assets/icons/done.svg';

const CompleteImpulseStateIcon = () => {
    return (
        <div className='text-align-center'>
            <DoneIcon/>
        </div>
    )
}

export default React.memo(CompleteImpulseStateIcon);
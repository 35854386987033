import React from 'react'

const CompleteContent = ({children}) => {
    return (
        <div className='flex-1'>
            {children}
        </div>
    )
}

export default React.memo(CompleteContent);
import React from 'react';

const RulesEn = () => {
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-1dot5rem font-weight-bold'>
                CONSENT FOR PROCESSING OF PERSONAL DATA 
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                Notice of rights
            </div>
            <div className='margin-top-1rem'>
                Pursuant to the requirements of the Ukrainian Law of Protection of Personal Data, this is to inform you that your personal data will become a part of personal data bases of Ottry, Limited Liability Company (legal entity identification code: 41385243) acting under the laws of Ukraine and registered at: 16, Glibochitskaya Str., office 13, Kiev, 04050 (hereinafter referred to as the Company). Both the Company and the Enterprise (subject of entrepreneurial activity), on the website of which business activity is carried out through the Ottry Online Service have access to the personal data base of Ottry, LLC.
            </div>
            <div className='margin-top-1rem'>
                The purpose of receipt of your personal data is necessity to process and keep it for booking of a service or product through the booking module of the Company, placed on the website of the Enterprise (subject of entrepreneurial activity, the service or product of which is subject to booking).
            </div>
            <div className='margin-top-1rem'>
                This is to inform you that the Ukrainian Law of Protection of Personal Data provides the rights allowing you to:
            </div>
            <div className='margin-top-1rem'>
                1) know about location of the Company personal data base containing your personal data, its purpose and name, location of the Company or the manager of this database, or entrust your authorised persons to receive this information, except as provide by the law;
            </div>
            <div className='margin-top-1rem'>
                2) receive information about the terms of provision of access to personal data, including information about the third parties, to which your personal data are transferred;
            </div>
            <div className='margin-top-1rem'>
                3) access your personal data;
            </div>
            <div className='margin-top-1rem'>
                4) receive a response whether your personal data are process or not, as well as the contents of your personal data at most within thirty calendar days from the date of request, except as provided by the law;
            </div>
            <div className='margin-top-1rem'>
                5) submit a reasoned claim with objection to processing of your personal data;
            </div>
            <div className='margin-top-1rem'>
                6) submit a reasoned claim for the Company to change or destruct your personal data, if these data are processed illegally or are incorrect;
            </div>
            <div className='margin-top-1rem'>
                7) protect your personal data from illegal processing and accidental loss, destruction, damage due to wilful concealment, non-provision, or later provision of them, and protect them from provision of information being incorrect or denigrating your honour, dignity, or business reputation;
            </div>
            <div className='margin-top-1rem'>
                8) contact the Ukrainian Parliamentary Commissioner or the court for protection of your personal data rights;
            </div>
            <div className='margin-top-1rem'>
                9) apply remedies in case of breach of the laws on protection of personal data.
            </div>
            <div className='margin-top-1rem'>
                10) include clauses on restriction of the right to process your personal data upon provision of consent;
            </div>
            <div className='margin-top-1rem'>
                11) withdraw your consent for processing of your personal data;
            </div>
            <div className='margin-top-1rem'>
                12) know the mechanism of automatic processing of personal data;
            </div>
            <div className='margin-top-1rem'>
                13) protection from an automated decision having legal consequences.
            </div>
            <div className='margin-top-1rem'>
                We think that it is necessary to inform you that your personal data can be transferred to certain bodies or persons on the grounds provided by the law, and / or due to exercise of rights and obligations in the area of relations between us and the areas of other relations created in accordance with the current laws and / or the terms of contracts concluded in accordance with the law, other deals, particularly
            </div>
            <div className='margin-top-1rem'>
                - Tax authorities;
            </div>
            <div className='margin-top-1rem'>
                - Bodies and economic entities that carry out or will carry out inspection of the Company or realise the requirements of the law or the contract concluded with the Company in accordance with the current laws;
            </div>
            <div className='margin-top-1rem'>
                - Other persons or bodies in the area of exercise your rights and obligations as a consumer and a company as owners of the database containing your personal data.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                Consent
            </div>
            <div className='margin-top-1rem'>
                By signing this document, I consent to processing of my personal data by Ottry, Limited Liability Company (hereinafter referred to as the Company) for the purpose given in this document.
            </div>
            <div className='margin-top-1rem'>
                The personal data received upon signing of this document include: name, patronymic, surname, contact telephone number, e-mail and other data.
            </div>
            <div className='margin-top-1rem'>
                By signing this document, I represent and warrant that:
            </div>
            <div className='margin-top-1rem'>
                - My personal data are provided to the Company voluntarily, by which I confirm lawfulness of receipt of them; the personal data about me are correct and their composition, volume and contents conform to the laws and the processing purpose above;
            </div>
            <div className='margin-top-1rem'>
                - The Company may perform cross-border transfer of personal data about me to its subsidiary and affiliated companies and partners according to the purpose specified in the document without notice of me about each case of such acts;
            </div>
            <div className='margin-top-1rem'>
                - The Company may, according to the current laws, transfer to the personal database manager the right to process my personal data contained in these personal data bases within a certain purpose;
            </div>
            <div className='margin-top-1rem'>
                - I confirm receipt on the date of signing of this document of a written notice of my rights, the purpose of collection and processing of my personal data, as well as information about the persons, to whom my personal data can be transferred for processing;
            </div>
            <div className='margin-top-1rem'>
                - The Company may keep my personal data for an unlimited period of time starting from the date of receipt of my consent for processing and formation of the respective database, unless otherwise arises out of the requirements of the current laws and / or the peculiarities of relations between me and the company.
            </div>
        </div>
    );
};

export default React.memo(RulesEn);
import React from 'react';
import classNames from 'classnames';
import Body from './Body';

function Small({reference, flag, toggle}) {
    return (
        <div ref={reference}>
            <div className={classNames('display-none hide-large', {
                'display-block': flag
            })}>
                <Body/>
            </div>
        </div>
    );
}

export default React.memo(Small);

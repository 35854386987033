import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../searchFilter';

export default React.memo(({error}) => {
  const location = useLocation();
  if (error?.toString()?.includes('Access Denied')) {
    return (
      <div className='padding-2rem'>
        Looks like your token expired. <Link to={`/log-out${searchFilter(location.search)}`}>Please sign in again.</Link>
      </div>
    );
  }
  throw error;
});


import React from 'react';
import classNames from 'classnames';
import {useFragment, useLazyLoadQuery, graphql} from 'react-relay/hooks';
import ChevronDownIcon from '../../../assets/icons/chevron-down.svg';
import CalendarIcon from '../../../assets/icons0/calendar.svg';
import useQuery from '../../../useQuery';
import i18n from '../../../i18n';
import moment from 'moment';
import Button from '../../../components/button/button';
import {FormattedMessage} from 'react-intl';
import QrCodeIcon from '../../../assets/icons0/qr-code-02.svg';
import EyeIcon from '../../../assets/icons0/eye.svg';
import useWindowDimensions from '../../../useWindowDimentions';
import IconFeature from '../../../components/icon-feature/icon-feature';
import {Link, useNavigate} from 'react-router-dom';

const dim = (value) => value ? value.split(',').map(Number) : [];

const EndpointView = React.memo(({endpoint}) => {
    const {locale = 'uk'} = useQuery();
    let scanedAmount = 0;
    if (endpoint.numbers) {
        Object.keys(endpoint.numbers).forEach((index) => {
            if (endpoint.numbers[index] > 0) {
                scanedAmount++;
            }
        });
    }
    return (
        <div className='display-flex align-items-baseline padding-bottom-0dot5rem endpoint-view-item'>
            <div className={classNames('display-block text-sm medium border-radius-1rem padding-top-0dot125rem padding-bottom-0dot125rem width-2dot75rem text-align-center margin-right-0dot75rem', {'color-success-600 medium background-color-success-50': scanedAmount < endpoint.quantity, 'color-gray-700 background-color-gray-100': scanedAmount === endpoint.quantity})}>
                {scanedAmount}/{endpoint.quantity}
            </div>
            <p className='text-md color-gray-700'>
                {i18n(endpoint, 'name', locale)}
            </p>
        </div>
    )
});

const EmptyImpulsesList = React.memo(() => {
    return (
        <div className='border-1px-solid border-color-gray-200 background-color-white empty-impulses-container text-align-center'>
            <div className='padding-bottom-1rem'>
                <IconFeature {...{color: 'gray', size: 'lg', feature: 'outline'}}>
                    <CalendarIcon/>
                </IconFeature>
            </div>
            <p className='padding-bottom-0dot5rem mw768-padding-bottom-0dot75rem color-gray-700 text-lg medium'>
                <FormattedMessage defaultMessage='There are no completed orders'/>
            </p>
            <p className='color-gray-500 text-md mw768-padding-left-2dot375rem mw768-padding-right-2dot375rem'>
                <span><FormattedMessage defaultMessage='You have no orders yet. Visit'/></span>
                {' '}
                <span className='color-primary-600 mw768-medium'>
                    <Link to='/'>
                        <FormattedMessage defaultMessage='Ottry Marketplace'/>
                    </Link>
                </span>
                {' '}
                <span><FormattedMessage defaultMessage='and see what events await you.'/></span>
            </p>
        </div>
    )
});

const OrderCard = React.memo(({event}) => {
    const {locale = 'uk'} = useQuery();
    const navigate = useNavigate();
    const impulse = useFragment(
        graphql`
            fragment Orders_impulse on Impulse {
                id
                amount
                impulseService {
                    name
                    nameUk
                    service {
                        id
                    }
                }
                impulseEndpoints {
                    name
                    nameUk
                    quantity
                    numbers
                }
                modified
            }
        `,
        event
    );
    const navigateToTickets = (impulseId) => {
        navigate(`ticket/${impulseId}`)
    }
    return (
        <Link to={`ticket/${impulse.id}`}>
            <div className='background-color-white padding-1dot5rem border-radius-0dot75rem shadow-light-md'>
                <p className='text-lg medium color-gray-900 padding-bottom-1rem'>{i18n(impulse.impulseService, 'name', locale)}</p>
                <div className='display-flex'>
                    <CalendarIcon className='display-block margin-right-0dot75rem color-gray-400 width-1dot25rem height-1dot25rem'/>
                    <p className='text-sm medium color-gray-500'>{moment(impulse.modified).format('DD.MM.YYYY')}</p>
                </div>
                <div className='display-flex flex-direction-column-row padding-top-1dot5rem'>
                    <div className='padding-bottom-1dot25rem mw768-padding-bottom-0 mw768-flex-1'>
                        {impulse.impulseEndpoints.map((endpoint, index) => (
                            <EndpointView key={index} {...{endpoint}}/>
                        ))}
                    </div>
                    <div className='mw768-display-none divider border-color-gray-200'></div>
                    <div className='padding-top-1dot25rem mw768-padding-top-0 mw768-align-self-flex-end'>
                        <Button {...{clickHandler: () => navigateToTickets(impulse.id), size: 'sm', color: 'primary-inverse', gtm: `show-tickets impulse-id-${impulse.id} service-id-${impulse.impulseService.service.id}`}}>
                            <QrCodeIcon className='display-block height-1dot25rem width-1dot25rem margin-right-0dot5rem'/>
                            <p className='text-md medium'><FormattedMessage defaultMessage='Tickets'/></p>
                        </Button>
                    </div>
                </div>
            </div>
        </Link>
    )
});

const Orders = React.memo(({category, requestOptions, setNextRequestOptions, isPending}) => {
    const [impulses, setImpulses] = React.useState([]);
    const [totalElements, setTotalElements] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const {windowWidth} = useWindowDimensions();
    const data = useLazyLoadQuery(
        graphql`
            query OrdersImpulsesQuery($request: ImpulsesRequestInput) {
                impulses(request: $request) {
                    totalPages
                    totalElements
                    content {
                        id
                        ...Orders_impulse
                    }
                }
            }
        `,
        requestOptions.variables,
        requestOptions.options
    );
    React.useEffect(() => {
        setImpulses([...impulses, ...data.impulses.content]);
        setTotalElements(data.impulses.totalElements);
    }, [data]);
    // For stacked cards
    // const [stacked, setStacked] = React.useState(windowWidth < 768);
    // React.useEffect(() => {
    //     setStacked(windowWidth < 768);
    // }, [windowWidth]);
    const [stacked, setStacked] = React.useState(false);
    React.useEffect(() => {
        if (page !== 0) {
            setNextRequestOptions(page);
        }
    }, [page]);
    const toggleStacked = () => {
        // For stacked cards
        // if (windowWidth < 768) {
        //     setStacked(!stacked);
        // }
    }

    return (
        <div className='display-flex margin-bottom-2dot5rem mw768-margin-bottom-3rem flex-direction-column-row' data-gtm={category.name} key={category.name}>
            <div className='display-flex align-items-center mw768-align-items-flex-start mw768-width-17dot5rem padding-left-0dot5rem mw768-margin-right-2rem mxw767-flex-1 padding-bottom-1rem cursor-pointer mw768-cursor-auto' data-gtm='open-tickets-stack mobile' onClick={toggleStacked}>
                <div className='height-1dot5rem width-1dot5rem color-primary-600 margin-right-0dot75rem'>
                    {category.icon}
                </div>
                <p className='text-lg medium color-gray-700 flex-1'>
                    {category.title}
                </p>
                {/* For stacked cards */}
                {/* {totalElements > 1 &&
                    <div className='mw768-display-none'>
                        <div className={classNames('background-color-gray-100 padding-0dot5rem border-radius-50percent color-gray-500 rotate', {'rotate-180deg': !stacked})}>
                            <ChevronDownIcon className='display-block height-0dot75rem width-0dot75rem'/>
                        </div>
                    </div>
                } */}
            </div>
            {totalElements > 0 &&
                <div className='position-relative mw768-max-width-43dot5rem width-100percent'>
                    {impulses.map((event, index) => (
                        <div className={classNames('margin-bottom-1rem mw768-margin-bottom-1dot5rem position-relative', {'display-none': index > 0 && stacked, 'z-index-3': stacked})} key={event.id}>
                            <OrderCard {...{event}}/>
                        </div>
                    ))}
                    <div className={classNames('stacked-card', {'stacked-card-one': impulses.length > 1, 'stacked-card-two': impulses.length > 2, 'display-none': !stacked})}></div>
                    {/* Button for previous events */}
                    {/* {!stacked && totalElements !== impulses.length && 
                        <div className='padding-top-1rem display-flex justify-content-flex-end mw768-justify-content-center'>
                            <button className='border-radius-1rem background-color-gray-100 color-gray-500 padding-top-0dot25rem padding-bottom-0dot25rem padding-left-0dot75rem padding-right-0dot625rem display-flex align-items-center text-sm medium cursor-pointer' onClick={() => setPage(page + 1)} data-gtm={`show-more-tickets page-${page}`}>
                                <EyeIcon className='height-0dot75rem width-0dot75rem display-block margin-right-0dot25rem'/>
                                <FormattedMessage defaultMessage='More'/>
                            </button>
                        </div>
                    } */}
                    {!stacked && totalElements !== impulses.length && 
                        <div className='padding-top-1rem display-flex justify-content-center'>
                            <Button {...{size: 'xl', color:'secondary-gray', clickHandler: () => setPage(page + 1), gtm: `show-more-tickets page-${page}`, isLoading: isPending}}>
                                <FormattedMessage defaultMessage='More'/>
                            </Button>
                        </div>
                    }
                </div>
            }
            {totalElements === 0 &&
                <div className='position-relative mw768-max-width-43dot5rem width-100percent' data-gtm='empty-impulses-list'>
                    <EmptyImpulsesList/>
                </div>
            }
        </div>
    )
});

const OrdersContainer = ({category}) => {
    const size = 10;
    const status = '3,4';
    const [requestOptions, setRequestOptions] = React.useState({
        options: {fetchKey: 0},
        variables: {
            request: {
                size: size,
                page: 0,
                status: dim(status),
                sent: true,
                fetchKey: 0,
            }
        }
    });
    const [isPending, startTransition] = React.useTransition();
    const setNextRequestOptions = React.useCallback((page) => {
        startTransition(() => {
            setRequestOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    request: {
                        ...prev.variables.request,
                        page,
                        fetchKey: prev.variables.request.fetchKey + 1
                    }
                }
            }));
        });
    }, []);
    return (
        <Orders {...{category, requestOptions, setNextRequestOptions, isPending}}/>
    )
}

export default React.memo(OrdersContainer);
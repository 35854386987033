import React from 'react';
import './Endpoint.scss';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {Link, useParams, useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../Skeleton';
import useQuery from '../../useQuery';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../searchFilter';
import EndpointImpl from './EndpointImpl';
import EndpointName from './EndpointName';
import Marked from './../Marked';
import {isTrue} from '../../utility';
import {IN, ENTITY_TYPE} from '../../constants';
import useNow from '../../useNow';
import {adopt} from '../../math';
import Mini from './mini/Mini';
import ErrorFallback from '../ErrorFallback';

const Endpoint = () => {
    const location = useLocation();
    const {id} = useParams();
    const {serviceId, more} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query EndpointQuery($id: String) {
                endpointById(id: $id) {
                    id
                    name
                    details
                    start
                    end
                    strategy
                    every
                    ...EndpointName_endpoint
                    ...EndpointImpl_endpoint
                }
            }
        `,
        {id}
    );
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, data.endpointById.start, data.endpointById.end, data.endpointById.strategy, data.endpointById.every),
        [now, data.endpointById]);
    return (
        <>
            <Helmet>
                <title>{data.endpointById.name}</title>
                <meta name='description' content={data.endpointById.name}/>
            </Helmet>
            {serviceId &&
            <div className='margin-bottom-1rem'>
                <Link
                    className='focus'
                    to={`/services/${serviceId}${searchFilter(location.search)}`}
                >&#60; <FormattedMessage defaultMessage='Service'/></Link>
                {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{serviceId}</div>}
            </div>
            }
            <Marked {...{content: data.endpointById.details}}/>
            <div className='font-weight-bold margin-top-1rem'>
                <EndpointName {...{endpoint: data.endpointById}}/>
            </div>
            {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{data.endpointById.id}</div>}
            <Mini {...{
                start: data.endpointById.start,
                end: data.endpointById.end,
                strategy: data.endpointById.strategy,
                every: data.endpointById.every,
                entityType: ENTITY_TYPE.endpoint,
            }}/>
            {state === IN &&
            <EndpointImpl {...{endpoint: data.endpointById, serviceId}}/>
            }
        </>
    );
};

export default React.memo(() =>
    <div className='margin-top-1dot5rem'>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<Skeleton/>}>
                <Endpoint/>
            </React.Suspense>
        </ErrorBoundary>
    </div>
);

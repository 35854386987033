import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {CartStateContext} from '../../../contexts/cart';
import classNames from 'classnames';
import searchFilter from '../../../searchFilter';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';

const CartLandDeferred = React.memo(({request, flag}) => {
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query CartLandDeferredQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        pickExs {
                            index
                        }
                    }
                    solves {
                        amount
                    }
                }
            }
        `,
        {request}
    );
    return (
        <>
        {data.landImpulse.lands.length > 1 &&
        <span className={classNames('font-weight-bold', {'opacity-0dot6': flag})}>
            <FormattedMessage defaultMessage='We will have multiple impulses here'/>
        </span>
        }
        {data.landImpulse.lands.map((land, index) =>
            <div
                key={index}
                className={classNames('margin-top-0dot5rem', {'opacity-0dot6': flag})}
            >
                {data.landImpulse.lands.length > 1 &&
                <span className='font-weight-bold'>-{' '}</span>
                }
                <FormattedMessage defaultMessage='Amounts'/>
                {' '}
                <span className='font-weight-bold'>
                    {data.landImpulse.solves[index].amount}
                </span>
                {' '}
                UAH
                {flag ?
                    <span className='margin-left-0dot5rem link font-weight-bold'>
                        <FormattedMessage defaultMessage='Checkout'/>
                    </span>
                    :
                    <Link
                        className='margin-left-0dot5rem link font-weight-bold'
                        to={`/checkout${searchFilter(location.search, {landId: index})}`}
                    ><FormattedMessage defaultMessage='Checkout'/></Link>
                }
            </div>
        )}
        </>
    );
});

export default React.memo(() =>{
    const state = React.useContext(CartStateContext);
    const request = React.useMemo(() => ({
        picks: state.map((e) => ({
            endpoint: e.endpointId,
            service: e.serviceId,
            quantity: e.quantity,
            ...(e.untie && {untie: {
                currency: e.untie.currency,
                price: e.untie.price
            }})
        }))
    }), [state]);
    const requestDeferred = React.useDeferredValue(request);
    return (
        <CartLandDeferred {...{
            request: requestDeferred,
            flag: request !== requestDeferred
        }}/>
    );
});
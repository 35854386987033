import React from 'react';
import MarkerPin04Svg from '../../assets/icons0/marker-pin-04.svg';
import ClockFastForwardSvg from '../../assets/icons0/clock-fast-forward.svg';
import QrCode02Svg from '../../assets/icons0/qr-code-02.svg';
import ChevronRight02Svg from '../../assets/icons0/chevron-right.svg';
import {Helmet} from 'react-helmet-async';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import SuspenseImageStaticSize from '../../components/SuspenseImageStaticSize';
import Skeleton from '../../components/Skeleton';
import {Link, useParams, useLocation} from 'react-router-dom';
import searchFilter from '../../searchFilter';
import i18n from '../../i18n';
import useQuery from '../../useQuery';
import Browser from '../../components/Browser';
import ErrorFallback from '../../components/ErrorFallback';

const fancyServiceId = process.env.NODE_ENV === 'development' ? null : null;

const Service = React.memo(() => {
    const {id} = useParams();
    const iy = fancyServiceId || id;
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query serviceMaster_washPagesQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    details
                    endpoints {
                        id
                        name
                        nameUk
                    }
                }
            }
        `,
        {id: iy}
    );
    return (
        <>
        <Helmet>
            <title>{i18n(data.serviceById, 'name', locale)}</title>
            <meta name='description' content={i18n(data.serviceById, 'name', locale)}/>
        </Helmet>
        <div className='hide-large'>
            <Browser>
                <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                    <SuspenseImageStaticSize
                        className='display-block width-100percent'
                        src='/assets/master-wash-375-176@3x.png'
                    />
                </ErrorBoundary>
            </Browser>
        </div>
        <div className='background-color-gray-25 mw768-background-color-white'>
            <div className='view'>
                <div className='mw768-padding-left-2rem mw768-padding-right-2rem mw768-margin-top-4rem mw768-margin-bottom-4rem mw768-display-flex mw768-justify-content-space-between'>
                    <div className='mw768-padding-right-1rem flex-1'>
                        <div className='padding-top-2rem mw768-padding-top-0 padding-bottom-2dot5rem mw768-padding-bottom-0 padding-left-1dot5rem mw768-padding-left-0 padding-right-1dot5rem mw768-padding-right-0'>
                            <div className='display-sm mw768-display-lg semibold color-gray-900'>
                                {i18n(data.serviceById, 'name', locale)}
                            </div>
                            <div className='margin-top-0dot25rem mw768-margin-top-0dot5rem text-lg mw768-text-xl color-gray-500'>Мийка самообслуговування</div>
                            <div className='padding-top-1rem mw768-padding-top-1dot5rem display-flex'>
                                <div className='display-flex align-items-center'>
                                    <MarkerPin04Svg className='display-block width-1dot25rem height-1dot25rem color-gray-400'/>
                                    <div className='margin-left-0dot5rem text-md mw768-text-lg medium color-gray-500'>Митницька 18, м. Черкаси</div>
                                </div>
                                <div className='margin-left-1dot25rem mw768-margin-left-1dot5rem display-flex align-items-center'>
                                    <ClockFastForwardSvg className='display-block width-1dot25rem height-1dot25rem color-gray-400'/>
                                    <div className='margin-left-0dot5rem text-md mw768-text-lg medium color-gray-500'>24/7</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hide-small padding-left-1rem flex-1'>
                        <Browser>
                            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                <SuspenseImageStaticSize
                                    className='display-block width-100percent'
                                    src='/assets/master-wash-592-240@3x.png'
                                />
                            </ErrorBoundary>
                        </Browser>
                    </div>
                </div>  
            </div>
        </div>
        <div className='background-color-gray-50'>
            <div className='view'>
                <div className='padding-top-2rem padding-bottom-3rem mw768-padding-bottom-4rem  padding-left-1dot5rem padding-right-1dot5rem'>
                    <div className='display-xs mw768-display-md semibold color-gray-900'>Пости</div>
                    <div 
                        className='padding-top-1dot5rem mw768-display-grid'
                        style={{
                            gridTemplateColumns: '1fr 1fr 1fr',
                            columnGap: '2rem',
                            rowGap: '2rem',
                        }}
                    >
                        {data.serviceById.endpoints.map((endpoint) =>
                            <Link
                                className='display-block margin-top-1rem first-child:margin-top-0 mw768-margin-top-0'
                                key={endpoint.id}
                                to={`/master-wash/endpoints/${endpoint.id}${searchFilter(location.search, {serviceId: iy})}`}
                            >
                                <div
                                    style={{boxShadow: '0px 16px 32px -4px rgba(16, 24, 40, 0.05)'}}
                                    className='border-radius-0dot75rem padding-1rem display-flex align-items-center justify-content-space-between background-color-white'
                                >
                                    <div className='display-flex align-items-center'>
                                        <div className='width-2dot5rem height-2dot5rem border-radius-0dot5rem background-color-success-500 display-flex justify-content-center align-items-center'>
                                            <QrCode02Svg className='display-block width-1dot25rem height-1dot25rem color-white'/>
                                        </div>
                                        <div className='margin-left-1rem text-lg medium color-gray-700'>
                                            {i18n(endpoint, 'name', locale)}
                                        </div>
                                    </div>
                                    <div>
                                        <ChevronRight02Svg className='display-block width-1dot25rem height-1dot25rem color-gray-400'/>
                                    </div>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
        </>
    );
});

export default React.memo(() =>
    <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
        <React.Suspense fallback={
            <>
            <div className='background-color-gray-25 mw768-background-color-white'>
                <div className='view'>
                    <div className='mw768-padding-left-2rem mw768-padding-right-2rem mw768-margin-top-4rem mw768-margin-bottom-4rem mw768-display-flex mw768-justify-content-space-between'>
                        <div className='mw768-padding-right-1rem flex-1'>
                            <div className='padding-top-2rem mw768-padding-top-0 padding-bottom-2dot5rem mw768-padding-bottom-0 padding-left-1dot5rem mw768-padding-left-0 padding-right-1dot5rem mw768-padding-right-0'>
                                <Skeleton/>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
            <div className='background-color-gray-50'>
                <div className='view'>
                    <div className='padding-top-2rem padding-bottom-3rem mw768-padding-bottom-4rem  padding-left-1dot5rem padding-right-1dot5rem'>
                        <Skeleton/>
                    </div>
                </div>
            </div>
            </>
        }>
            <Service/>
        </React.Suspense>
    </ErrorBoundary>
);
import React from 'react';
import './Scan.scss';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../Skeleton';
import ImpulseImpl from '../cabinet/impulse/ImpulseImpl';
import {graphql, useLazyLoadQuery, useMutation, useFragment} from 'react-relay/hooks';
import classNames from 'classnames';
import useQuery from '../../useQuery';
import {FormattedMessage} from 'react-intl';
import {isNullOrUndefined, isSet, isTrue} from '../../utility';
import ErrorFallback from '../ErrorFallback';
import {graphqlWhoamiNextQuery} from '../graphql';
import {useAuthorizationState} from '../../contexts/Authorization';

const AuthorizedUser = ({fPlantImpulse, mode, applyingTake, onTake}) => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    return (
        <>
            {whoamiNext.id === fPlantImpulse.impulse.merchant.id
                ?
                <div className={classNames({'display-none': mode === 'auto'})}>
                    <div className='flex-1 display-flex justify-content-flex-end align-items-flex-start'>
                        <div
                            className='button'
                            onClick={() => !applyingTake && onTake()}
                        >
                            <FormattedMessage defaultMessage='Take'/>
                            {applyingTake && '...'}
                            {(
                                fPlantImpulse.impulse.impulseEndpoints[fPlantImpulse.position].numbers &&
                                !!fPlantImpulse.impulse.impulseEndpoints[fPlantImpulse.position].numbers[fPlantImpulse.index]
                            ) &&
                            <span className='badge orangered margin-left-0dot25rem'>
                                {fPlantImpulse.impulse.impulseEndpoints[fPlantImpulse.position].numbers[fPlantImpulse.index]}
                            </span>
                            }
                        </div>
                    </div>
                </div>
                :
                <></>
            }
        </>
    )
}

const PlantBaseImpl = React.memo(({plantImpulse}) => {
    const {mode} = useQuery();
    const authorized = useAuthorizationState();
    const fPlantImpulse = useFragment(
        graphql`
            fragment PlantScanBaseImpl_plantImpulse on PlantResponse {
                position
                index
                impulse {
                    id
                    impulseService {
                        name
                        demo
                    }
                    impulseEndpoints {
                        name
                        price
                        quantity
                        numbers
                    }
                    status
                    version
                    number
                    merchant {
                        id
                    }
                    ...ImpulseImpl_impulse
                }
            }
        `,
        plantImpulse
    );
    const [take, applyingTake] = useMutation(
        graphql`
            mutation PlantScanBaseImplTakeMutation($request: TakeImpulseRequestInput) {
                takeImpulse(request: $request) {
                    id
                    version
                    impulseEndpoints {
                        numbers
                    }
                }
            }
    `);
    const onTake = () => take({
        variables: { request: {
            id: fPlantImpulse.impulse.id,
            version: fPlantImpulse.impulse.version,
            position: fPlantImpulse.position,
            index: fPlantImpulse.index
        }},
        onCompleted: () => {},
        onError: () => {}
    });
    return (
        <>
        <div className='display-flex border-bottom-1px-dotted-black padding-bottom-1rem'>
            <div className='flex-3'>
                <div className='font-weight-bold'>
                    {fPlantImpulse.impulse.impulseService.name}
                </div>
                <div className='margin-top-1rem'>
                    <div className='font-weight-bold'>
                        <div>
                            #{fPlantImpulse.position}#{fPlantImpulse.index} {fPlantImpulse.impulse.impulseEndpoints[fPlantImpulse.position].name}
                        </div>
                        <div>
                            {fPlantImpulse.impulse.impulseEndpoints[fPlantImpulse.position].price} UAH
                        </div>
                    </div>
                </div>
            </div>
            {authorized &&
                <AuthorizedUser {...{fPlantImpulse, mode, applyingTake, onTake}}/>
            }
        </div>
        <div className='margin-top-1rem'>
            <div className='font-weight-bold font-size-1dot4rem'>Impulse</div>
            <ImpulseImpl
                impulse={fPlantImpulse.impulse}
                aPosition={fPlantImpulse.position}
                aIndex={fPlantImpulse.index}
            />
        </div>
        </>
    );
});

const PlantAutoImpl = React.memo(({payload, count, fetchKey}) => {
    const {more} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query PlantScanAutoImplQuery($request: PlantRequestInput) {
                plantImpulse(request: $request) {
                    position
                    index
                    impulse {
                        id
                        impulseEndpoints {
                            numbers
                        }
                        number
                    }
                    ...PlantScanBaseImpl_plantImpulse
                }
            }
        `,
        {request: {value: payload, auto: true}},
        {fetchKey, fetchPolicy: 'network-only'}
    );
    const valueRef = React.useRef();
    if (isNullOrUndefined(valueRef.current)) valueRef.current = count;
    const numbersIndex = data.plantImpulse.impulse.impulseEndpoints[data.plantImpulse.position].numbers &&
        data.plantImpulse.impulse.impulseEndpoints[data.plantImpulse.position].numbers[data.plantImpulse.index];
    return (
        <>
        <div className='display-flex'>
            <div className={classNames('scan__tag flex-1 border-1px-solid-black', {
                'green': numbersIndex === 1 && !data.plantImpulse.impulse.number,
                'red': numbersIndex > 1 || !!data.plantImpulse.impulse.number
            })}>
                <div className='display-flex justify-content-center align-items-center width-100percent height-100percent'>
                    <span className='font-weight-bold'>
                        {(numbersIndex === 1 && !data.plantImpulse.impulse.number) && <>GO</>}
                        {(numbersIndex > 1 || !!data.plantImpulse.impulse.number) && <>STOP</>}
                    </span>
                    {isTrue(more) &&
                    <span className='font-size-0dot8rem font-style-italic color-grey'>
                        &nbsp;/ {isSet(numbersIndex) ? numbersIndex : ''}
                        &nbsp;/ {data.plantImpulse.impulse.number}
                    </span>
                    }
                </div>
            </div>
            {(count - valueRef.current) > 0 &&
            <div className='scan__tag flex-2 orange margin-left-1rem border-1px-solid-black'>
                <div className='display-flex justify-content-center align-items-center width-100percent height-100percent'>
                    <span className='font-weight-bold'>NEXT</span>
                    {isTrue(more) &&
                    <span className='font-size-0dot8rem font-style-italic color-grey'>
                        &nbsp;/ {count - valueRef.current}
                    </span>
                    }
                </div>
            </div>
            }
        </div>
        <div className='margin-top-1rem'><PlantBaseImpl plantImpulse={data.plantImpulse}/></div>
        </>
    );
});

const PlantImpl = React.memo(({payload, fetchKey}) => {
    const data = useLazyLoadQuery(
        graphql`
            query PlantScanImplQuery($request: PlantRequestInput) {
                plantImpulse(request: $request) {
                    ...PlantScanBaseImpl_plantImpulse
                }
            }
        `,
        {request: {value: payload}},
        {fetchKey, fetchPolicy: 'network-only'}
    );
    return (
        <PlantBaseImpl plantImpulse={data.plantImpulse}/>
    );
});

export default React.memo(({payload, count, fetchKey}) => {
    const {mode} = useQuery();
    return (
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<Skeleton/>}>
                {mode === 'auto' ?
                    <PlantAutoImpl {...{payload, count, fetchKey}}/>
                    :
                    <PlantImpl {...{payload, fetchKey}}/>
                }
            </React.Suspense>
        </ErrorBoundary>
    );
});
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import moment from 'moment';
import Breadcrumbs from './breadcrumbs';
import UserMenu from './user-menu';
import Button, {RouterLinkButton} from '../../components/button/button';
import EditIcon from '../../assets/icons0/edit-03.svg';
import EffectRedirect from '../../components/EffectRedirect';
import {graphqlWhoamiNextQuery} from '../../components/graphql';
import {graphqlSubscriptionEndpointByIdQuery, graphqlSubscriptionServiceByIdQuery} from './graphql';
import i18n from '../../i18n';
import useQuery from '../../useQuery';
import searchFilter from '../../searchFilter';
import useMountedState from '../../useMountedState';

const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;
const RAZZLE_APP_DEMO_MERCHANT_ID = process.env.RAZZLE_APP_DEMO_MERCHANT_ID;
const breadcrumbs = [
    {name: 'Subscription', nameUk: 'Підписка', route: '../'},
    {name: 'Ordering', nameUk: 'Оформлення', route: '../activate'},
    {name: 'Confirm', nameUk: 'Підтвердити'}
];

const ConfirmRecurring = () => {
    const {endpointId, poolId, serviceId} = useParams();
    const {locale = 'uk'} = useQuery();
    const navigate = useNavigate();
    const location = useLocation();
    const [grecaptcha, setGrecaptcha] = useMountedState(false);
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const {endpointById} = useLazyLoadQuery(graphqlSubscriptionEndpointByIdQuery, {endpointId: endpointId});
    const {serviceById} = useLazyLoadQuery(graphqlSubscriptionServiceByIdQuery, {serviceId: serviceId});
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {request: {
            size: 100500,
            page: 0,
            merchantId: serviceById.demo ? RAZZLE_APP_DEMO_MERCHANT_ID : serviceById.mrchntd,
            fetchKey: 0
        }}
    });
    const {poolsByViewer} = useLazyLoadQuery(
        graphql`
            query confirmRecurringPoolsQuery($request: PoolsRequestInput) {
                poolsByViewer(request: $request) {
                    content {
                        id
                        cardPan
                        cardType
                    }
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    const activePool = poolsByViewer.content.find(item => item.id === poolId);
    if (!activePool) {
        return <EffectRedirect to={`subscription/service/${serviceId}/endpoint/${endpointId}/activate${searchFilter(location.search)}`}/>
    }
    const cardPan = activePool.cardPan.split('XXXXXX')[1];
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation confirmRecurringMutation($request: RecurringRequestInput!) {
                createRecurring(request: $request) {
                    id
                    claim
                }
            }
    `);
    
    const confirmRecurring = () => {
        submit();
    };

    const submit = (data) => {
        setGrecaptcha(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
                commit({
                    variables: {
                        request: {
                            locale,
                            email: whoamiNext.username || whoamiNext.email,
                            pool: poolId,
                            reCaptcha: token,
                            service: serviceId,
                            recurringEndpoints: [{
                                endpoint: endpointId,
                                quantity: 1,
                            }],
                            recurringAddls: []
                        }
                    },
                    onCompleted: (data) => {
                        navigate(`/subscriptions/recurring/${data.createRecurring.id}${searchFilter(location.search)}`);
                    },
                    onError: () => {},
                    updater: store => {
                        const latestRecurrings =
                            store.get('client:root:viewer:__Recurrings_recurringsByViewer_connection');
                        latestRecurrings && latestRecurrings.invalidateRecord();
                    }
                });
                setGrecaptcha(false);
            });
        });
    };
    
    return (
        <div className='view'>
            <div className='padding-bottom-2rem mw768-padding-top-2rem mw768-padding-bottom-4rem'>
                <Breadcrumbs {...{breadcrumbs}}/>
            </div>
            <h1 className='color-gray-900 text-title semibold padding-bottom-2rem mw768-padding-bottom-3rem'>
                <FormattedMessage defaultMessage='Confirm subscription'/>
            </h1>
            <div className='margin-bottom-1dot5rem mw768-margin-bottom-2rem'>
                <UserMenu/>
            </div>
            <div className='mw768-flex-1 mw768-max-width-26dot5rem'>
                <p className='text-xl semibold color-gray-900 margin-bottom-1rem'>{i18n(endpointById, 'name', locale)}</p>
                <div className='display-flex'>
                    <div className='mw768-text-md flex-1dot5 padding-right-0dot5rem mw768-padding-right-1dot25rem'>
                        <p className='text-md color-gray-900'><FormattedMessage defaultMessage='Total'/></p>
                    </div>
                    <div className='flex-1 text-align-right padding-left-0dot25rem mw768-padding-left-0dot75rem'>
                        <p className='text-md color-gray-900 medium'>{endpointById.price} UAH</p>
                    </div>
                </div>
                <div className='display-flex padding-top-0dot375rem align-items-baseline'>
                    <div className='mw768-text-md flex-1dot5 padding-right-0dot5rem mw768-padding-right-1dot25rem'>
                        <p className='text-xs medium color-gray-400'><FormattedMessage defaultMessage='Renew on'/> {moment().locale(locale).format('DD.MM.YYYY')}</p>
                    </div>
                    <div className='flex-1 text-align-right padding-left-0dot25rem mw768-padding-left-0dot75rem'>
                        <div className='text-align-right'>
                            <RouterLinkButton {...{size: 'xs', plain: true, to: `../payment-options/${poolId}`, color: 'pale', additionalClasses: 'width-max-content'}}>
                                <span className='text-transform-capitalize'>{activePool.cardType.toLowerCase()} </span>
                                <span className='padding-left-0dot25rem padding-right-0dot5rem'>&#8226;&#8226;&#8226;&#8226; {cardPan}</span>
                                <EditIcon className='height-1rem width-1rem color-gray-400'/>
                            </RouterLinkButton>
                        </div>
                    </div>
                </div>
                <div className='margin-top-2rem mw768-margin-top-3rem'>
                    <div className='margin-bottom-0dot75rem'>
                        <Button {...{size: 'lg', color: 'primary-dark', fluid: 'always', clickHandler: confirmRecurring, isLoading: isMutationInFlight}}>
                            <FormattedMessage defaultMessage='Confirm'/>
                        </Button>
                    </div>
                    <RouterLinkButton {...{size: 'lg', color: 'secondary-gray', fluid: 'always', to: '../activate'}}>
                        <FormattedMessage defaultMessage='Back'/>
                    </RouterLinkButton>
                </div>
            </div>
        </div>
    )
};

export default React.memo(ConfirmRecurring);
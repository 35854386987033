import React from 'react';
import './Shell.scss';
import QrCode02Svg from '../../../assets/icons0/qr-code-02.svg';
import Ticket01Svg from '../../../assets/icons0/ticket-01.svg';
import ShoppingCart01Svg from '../../../assets/icons0/shopping-cart-01.svg';
import CreditCardRefreshSvg from '../../../assets/icons0/credit-card-refresh.svg';
import classNames from 'classnames';
import searchFilter from '../../../searchFilter';
import {Link, useLocation} from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const MenuForPartners = React.memo(({menuForPartnersRef, menuForPartnersFlag, toggleMenuForPartners}) => {
    const location = useLocation();
    return (
        <div
            ref={menuForPartnersRef}
            className={classNames('display-none', {
                'display-block': menuForPartnersFlag
            })}
        >
            <div className='position-absolute background-color-white border-1px-solid-black border-top-color-gray-200 border-bottom-color-gray-200 border-left-color-gray-200 border-right-color-gray-200 border-radius-0dot5rem z-index-1' style={{top: 38, left: 96}}>
                <div className='padding-2rem'>
                    <div>
                        <Link to={`/promo/tickets${searchFilter(location.search)}`} onClick={toggleMenuForPartners}>
                            <div className='padding-top-0dot75rem padding-bottom-0dot75rem display-flex align-items-center'>
                                <div><Ticket01Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/></div>
                                <div className='margin-left-1rem text-md medium color-gray-900 min-width-10rem'><FormattedMessage defaultMessage="Electronic tickets" /></div>
                            </div>
                        </Link>
                        <div className='opacity-0dot4 margin-top-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem display-flex align-items-center'>
                            <div><QrCode02Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/></div>
                            <div className='margin-left-1rem text-md medium color-gray-900 min-width-10rem'><FormattedMessage defaultMessage="QR terminal" /></div>
                        </div>
                        <Link to={`/promo/micromarkets${searchFilter(location.search)}`} onClick={toggleMenuForPartners}>
                            <div className='margin-top-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem display-flex align-items-center'>
                                <div><ShoppingCart01Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/></div>
                                <div className='margin-left-1rem text-md medium color-gray-900 min-width-10rem'><FormattedMessage defaultMessage="Micromarkets" /></div>
                            </div>
                        </Link>
                        <Link to={`/promo/subscriptions${searchFilter(location.search)}`} onClick={toggleMenuForPartners}>
                            <div className='margin-top-0dot5rem padding-top-0dot75rem padding-bottom-0dot75rem display-flex align-items-center'>
                                <div><CreditCardRefreshSvg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/></div>
                                <div className='margin-left-1rem text-md medium color-gray-900 min-width-10rem'><FormattedMessage defaultMessage="Recurring payments" /></div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default React.memo(MenuForPartners);
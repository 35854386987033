import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../../Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import useSubscription from '../../../../useSubscription';
import classNames from 'classnames';
import ImpulseImpl from '../../../../components/cabinet/impulse/ImpulseImpl';
import searchFilter from '../../../../searchFilter';
import {Link, useLocation} from 'react-router-dom';
import ErrorFallback from '../../../ErrorFallback';
import useSubscriptionStatus from '../../../../useSubscriptionStatus';

const DashboardImpl = React.memo(({refreshedQueryOptions, flag}) => {
    const data = useLazyLoadQuery(
        graphql`
            query DashboardDashboardImplQuery($request: ImpulsesRequestInput) {
                impulses(request: $request) {
                    content {
                        id
                        ...ImpulseImpl_impulse
                    }
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    return (
        <div className={classNames({'opacity-0dot6': flag})}>
            <div className='display-flex flex-wrap-wrap'>
                {data.impulses.content.map((impulse) =>
                    <div key={impulse.id} className='impulses__card'>
                        <div className='border-bottom-1px-dotted-black padding-bottom-1rem height-100percent'>
                            <ImpulseImpl impulse={impulse} />
                        </div>
                    </div>
                )}
                {!data.impulses.content.length &&
                <span><FormattedMessage defaultMessage='No data for now'/></span>
                }
            </div>
        </div>
    );
});

const Dashboard = React.memo(({id}) => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {request: {
            size: 100500,
            page: 0,
            inbox: true,
            status: [3, 4],
            service: [id],
            gap: true,
            fetchKey: 0
        }}
    });
    const [isPending, startTransition] = React.useTransition();
    const refresh = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    request: {
                        ...prev.variables.request,
                        fetchKey: prev.variables.request.fetchKey + 1
                    }
                }
            }));
        });
    }, []);
    const payload = React.useMemo(() => ({
        channel: `/services/${id}`,
        onNext: () => refresh(),
        onError: (error) => {}
    }), [refresh, id]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    return (
        <DashboardImpl {...{
            refreshedQueryOptions,
            flag: isPending
        }}/>
    );
});

export default React.memo(({id}) => {
    const location = useLocation();
    const subscriptionStatus = useSubscriptionStatus();
    return (
        <>
        <div className='font-weight-bold font-size-2dot5rem text-align-center margin-top-1rem'>
            <span className={classNames('dot margin-right-0dot2rem', {
                'green': subscriptionStatus === 0
            })}/>
            <FormattedMessage defaultMessage='Dashboard'/>
        </div>
        <div className='text-align-center'>
            {' '}<FormattedMessage defaultMessage='or'/>{' '}
            <Link
                className='focus'
                to={`/cabinet/impulses${searchFilter(location.search)}`}
            ><FormattedMessage defaultMessage='Show all'/></Link>
        </div>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<div className='margin-top-1rem'><Skeleton/></div>}>
                <Dashboard {...{id}}/>
            </React.Suspense>
        </ErrorBoundary>
        </>
    );
});

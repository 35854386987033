import qs from 'qs';
import {getSearchOrderArray} from '../searchFilter';

const getLocaleQueryParams = (search, locale) => {
    const params = qs.parse(search, {ignoreQueryPrefix: true});
    if (locale === 'uk') {
        delete params.locale;
    } else {
        params.locale = 'en';
    }
    return qs.stringify(params, {addQueryPrefix: true, filter: getSearchOrderArray(params, {}, false)});
};

export default getLocaleQueryParams;
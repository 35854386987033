import React from 'react';
import {FormattedMessage} from 'react-intl';
import CompleteContainer from '../complete/container';
import CompleteContent from '../complete/content';
import CompleteImpulseStateIcon from '../complete/impulse-state-icon';
import CompleteDescription from '../complete/description';
import CompleteAction from '../complete/action';
import CompleteFooter from '../complete/footer';

const PaymentLinkCompletePage = () => {
    const title = <FormattedMessage defaultMessage='Thank you for your order'/>;
    const message = <FormattedMessage defaultMessage='We will send an email with your order as soon as the payment is processed'/>;
    
    return (
        <CompleteContainer>
            <CompleteContent>
                <CompleteImpulseStateIcon/>
                <CompleteDescription {...{title, message}}/>
                <CompleteAction/>
            </CompleteContent>
            <CompleteFooter/>
        </CompleteContainer>
    )
};

export default React.memo(PaymentLinkCompletePage);

import React, {useState} from 'react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import i18n from '../../i18n';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import UserMenu from './user-menu';
import Button, {RouterLinkButton} from '../../components/button/button';
import EditIcon from '../../assets/icons0/edit-03.svg';
import LogoVisa from '../../assets/subscriptions/logo-visa.svg';
import LogoMastercard from '../../assets/subscriptions/logo-mastercard.svg';
import PaymentHistory from './payment-history';
import useQuery from '../../useQuery';
import {useLocation, useParams} from 'react-router-dom';
import getPeriodFromCron from '../../utilities/get-period-from-cron';
import searchFilter from '../../searchFilter';

const Recurring = ({refreshedQueryOptions, refreshQueryOptions}) => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const {recurringId} = useParams();
    const {viewer} = useLazyLoadQuery(
        graphql`
            query recurringQuery($first: Int!, $after: String) {
                viewer {
                    recurringsByViewer(first: $first, after: $after) @connection(key: "Recurrings_recurringsByViewer") {
                        edges {
                            node {
                                id
                                tick
                                recurringtick {
                                    id
                                    status
                                    tick
                                }
                                pool {
                                    id
                                    cardPan
                                    cardType
                                }
                                recurringEndpoints {
                                    endpoint {
                                        id
                                        name
                                        nameUk
                                        price
                                        tick
                                        cron
                                    }
                                }
                                recurringService {
                                    service {
                                        id
                                        name
                                        nameUk
                                    }
                                }   
                            }
                        }
                    }
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    const recurring = viewer.recurringsByViewer.edges.find(item => item.node.id === recurringId);
    const endpointById = recurring.node.recurringEndpoints[0].endpoint;
    const cronPeriod = getPeriodFromCron(endpointById.cron);
    const activePool = recurring.node.pool;
    const cardPan = activePool.cardPan.split('XXXXXX')[1];
    let cardIconComponent = null;
    if (/Visa/i.test(activePool.cardType)) {
        cardIconComponent = <LogoVisa className='display-block width-1dot5rem margin-auto'/>;
    } else if (/MasterCard/i.test(activePool.cardType)) {
        cardIconComponent = <LogoMastercard className='display-block width-1dot5rem margin-auto'/>
    }

    const [play, playing] = useMutation(
        graphql`
            mutation recurringPlayMutation($request: RecurringPlayRequestInput) {
                playRecurring(request: $request) {
                    id
                    recurringtick {
                        id
                        status
                        tick
                    }
                    tick
                }
            }
    `);

    const renewRecurring = () => {
        play({
            variables: {
                request: {
                    recurring: recurringId
                }
            },
            onCompleted: () => {
                refreshQueryOptions();
            },
            onError: () => {}
        })
    }

    const RECURRING_STATUS = {
        0: {status: 'active', tagComponent: null},
        1: {status: 'active', tagComponent: null},
        2: {status: 'active', tagComponent: null},
        3: {
            status: 'paused',
            actionComponent: <Button {...{size: 'lg', color: 'success-gradient', fluid: 'always', clickHandler: renewRecurring, isLoading: playing}}>
                                <FormattedMessage defaultMessage='Renew subscription'/>
                            </Button>,
            messageComponent: <FormattedMessage defaultMessage='Your subscription will expire on'/>,
            tagComponent: <div className='text-transform-uppercase text-sm medium color-gray-700 padding-top-0dot125rem padding-bottom-0dot125rem padding-left-0dot25rem padding-right-0dot25rem background-color-gray-100 width-max-content border-radius-0dot375rem margin-bottom-1rem'>
                            <span><FormattedMessage defaultMessage='Canceled'/></span>
                        </div>
    
        },
        4: {
            status: 'failed',
            tagComponent: <div className='text-transform-uppercase text-sm medium color-error-600 padding-top-0dot125rem padding-bottom-0dot125rem padding-left-0dot25rem padding-right-0dot25rem background-color-error-100 width-max-content border-radius-0dot375rem margin-bottom-1rem'>
                            <span><FormattedMessage defaultMessage='Payment failure'/></span>
                        </div>
        }
    }

    const [recurringStatus, setRecurringStatus] = useState(RECURRING_STATUS[recurring.node.recurringtick.status]);
    const [firstImpulseFailed, setFirstImpulseFailed] = useState(false);
    React.useEffect(() => {
        if (recurring.node.recurringtick.status !== 3 && firstImpulseFailed) {
            setRecurringStatus(RECURRING_STATUS[4]);
        } else {
            setRecurringStatus(RECURRING_STATUS[recurring.node.recurringtick.status]);
        }
    }, [recurring, firstImpulseFailed]);
    const setFirstImpulseStatusFailed = React.useCallback(() => {
        setFirstImpulseFailed(true);
    }, []);

    return (
        <div className='view'>
            <div className='mw768-padding-top-7dot5rem'>
                <div className='margin-bottom-1dot5rem mw768-margin-bottom-2rem'>
                    <UserMenu/>
                </div>
                {recurringStatus.tagComponent}
                <div className='display-flex flex-direction-column mw768-max-width-26dot5rem'>
                    <p className='text-xl semibold color-gray-900'>{i18n(endpointById, 'name', locale)}</p>
                    <div className='mw768-flex-1 mw768-padding-right-3rem'>
                        <p className='text-xl color-gray-900 margin-top-0dot25rem mw768-margin-top-0dot5rem'>
                            {endpointById.price}
                            {' UAH '}
                            {i18n(cronPeriod, 'title', locale)}
                        </p>
                        <p className='text-md color-gray-500 margin-top-1rem'>
                            {recurringStatus.messageComponent
                                ? recurringStatus.messageComponent
                                : <FormattedMessage defaultMessage='The next withdrawal will be made on'/>
                            }
                        </p>
                        
                        <p className='text-md color-gray-500'>
                            {moment(recurring.node.tick).locale(locale).format('DD MMMM YYYY')}
                        </p>
                        <div className='margin-top-1rem'>
                            <RouterLinkButton {...{size: '2xl', plain: true, to: `payment-options/${activePool.id}${searchFilter(location.search)}`, color: 'secondary-gray'}}>
                                <div className='width-2dot125rem height-1dot5rem border-1px-solid border-color-gray-100 border-radius-0dot25rem display-flex margin-right-0dot75rem'>
                                    {cardIconComponent}
                                </div>
                                <span className='text-transform-capitalize'>{activePool.cardType.toLowerCase()} </span>
                                <span className='padding-left-0dot25rem padding-right-1dot5rem regular'>&#8226;&#8226;&#8226;&#8226; {cardPan}</span>
                                <EditIcon className='height-1dot25rem width-1dot25rem color-gray-500'/>
                            </RouterLinkButton>
                        </div>
                    </div>
                    <div className='margin-top-2rem mw768-margin-top-3rem margin-bottom-2rem mw768-max-width-22dot5rem'>
                        {recurringStatus.actionComponent
                            ? recurringStatus.actionComponent
                            : <RouterLinkButton {...{size: 'lg', color: 'secondary-gray', fluid: 'always', to: 'cancel'}}>
                                <FormattedMessage defaultMessage='Cancel subscription'/>
                            </RouterLinkButton>
                        }
                    </div>
                </div>
                <div className='margin-top-1rem'>
                    <PaymentHistory {...{setFirstImpulseStatusFailed}}/>
                </div>
            </div>
        </div>
    )
};

const RecurringContainer = () => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0},
        variables: {
            first: 100, fetchKey: 0
        }
    });
    const [isPending, startTransition] = React.useTransition({timeoutMs: 5000});
    const refreshQueryOptions = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    ...prev.variables,
                    fetchKey: prev.variables.fetchKey + 1
                }
            }));
        });
    }, []);
    return (
        <Recurring {...{refreshedQueryOptions, refreshQueryOptions}}/>
    )
};

export default React.memo(RecurringContainer);
/**
 * @generated SignedSource<<76320352e7f00b8e6d3a93f972797446>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "request",
    "variableName": "request"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "position",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "index",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numbers",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlantScanAutoImplQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PlantResponse",
        "kind": "LinkedField",
        "name": "plantImpulse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Impulse",
            "kind": "LinkedField",
            "name": "impulse",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ImpulseEndpoint",
                "kind": "LinkedField",
                "name": "impulseEndpoints",
                "plural": true,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlantScanBaseImpl_plantImpulse"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlantScanAutoImplQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PlantResponse",
        "kind": "LinkedField",
        "name": "plantImpulse",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Impulse",
            "kind": "LinkedField",
            "name": "impulse",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ImpulseEndpoint",
                "kind": "LinkedField",
                "name": "impulseEndpoints",
                "plural": true,
                "selections": [
                  (v5/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Untie",
                    "kind": "LinkedField",
                    "name": "untie",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ImpulseService",
                "kind": "LinkedField",
                "name": "impulseService",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "demo",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "merchantAccount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "independenceType",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "merchant",
                "plural": false,
                "selections": [
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImpulseAddl",
                "kind": "LinkedField",
                "name": "impulseAddls",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "nameUk",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImpulseUser",
                "kind": "LinkedField",
                "name": "impulseClient",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orderReference",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "modified",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "qrs",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "orderReferences",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7d6486d4c311485bf7ea50f56abab333",
    "id": null,
    "metadata": {},
    "name": "PlantScanAutoImplQuery",
    "operationKind": "query",
    "text": "query PlantScanAutoImplQuery(\n  $request: PlantRequestInput\n) {\n  plantImpulse(request: $request) {\n    position\n    index\n    impulse {\n      id\n      impulseEndpoints {\n        numbers\n      }\n      number\n    }\n    ...PlantScanBaseImpl_plantImpulse\n  }\n}\n\nfragment ImpulseImpl_impulse on Impulse {\n  id\n  amount\n  impulseService {\n    name\n    demo\n    merchantAccount\n  }\n  impulseEndpoints {\n    name\n    price\n    quantity\n    untie {\n      price\n      currency\n    }\n  }\n  impulseAddls {\n    name\n    nameUk\n    value\n  }\n  impulseClient {\n    email\n  }\n  orderReference\n  modified\n  status\n  qrs\n  version\n  number\n  merchant {\n    id\n  }\n  ...QrsImpulseImpl_impulse\n  ...IntegrationImpulseImpl_impulse\n}\n\nfragment IntegrationImpulseImpl_impulse on Impulse {\n  id\n  orderReference\n  orderReferences\n  impulseService {\n    independenceType\n  }\n  merchant {\n    id\n  }\n}\n\nfragment PlantScanBaseImpl_plantImpulse on PlantResponse {\n  position\n  index\n  impulse {\n    id\n    impulseService {\n      name\n      demo\n    }\n    impulseEndpoints {\n      name\n      price\n      quantity\n      numbers\n    }\n    status\n    version\n    number\n    merchant {\n      id\n    }\n    ...ImpulseImpl_impulse\n  }\n}\n\nfragment QrsImpulseImpl_impulse on Impulse {\n  id\n  impulseEndpoints {\n    numbers\n  }\n  qrs\n  version\n  merchant {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "d5f187402863a729c3dff6d81313e118";

module.exports = node;

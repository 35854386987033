import React from 'react';
import ScanHeader from './header';
import SearchMdIcon from '../../assets/icons0/search-md.svg';
import {Link, useLocation} from 'react-router-dom';
import {QrReader} from 'react-qr-reader';
import './scan.scss';
import QrScan from '../../assets/qr-scan.svg';
import searchFilter from '../../searchFilter';
import ClockRewindIcon from '../../assets/icons0/clock-rewind.svg';
import EmptyScan from '../../assets/empty-scan.svg';
import {ErrorBoundary} from 'react-error-boundary';
import {v4} from 'uuid';
import ScanImpulseView from './impulse-view';
import LogoLoading from '../../assets/logo-loading.svg';
import {useScanSettingsState} from './settings-context';
import {SCAN_MODE_DEFAULT, SCAN_MODE_FAST, SCAN_MODE_ROCKET} from '../../constants';
import {ImpulseViewBasicHeader, ImpulseViewIndicatorHeader} from './impulse-view-header';
import {FormattedMessage} from 'react-intl';
import TapAndScanIcon from '../../assets/tap-and-scan.svg';
import classNames from 'classnames';
import useWindowDimentions from '../../useWindowDimentions';
import {useAuthorizationState} from '../../contexts/Authorization';
import SignInRedirect from '../../components/sign-in-redirect';
import {useCurrentScanDispatch, useCurrentScanState} from './current-scan-context';
import RefreshCw01Icon from '../../assets/icons0/refresh-cw-01.svg';
import Maximize02Icon from '../../assets/icons0/maximize-02.svg';
import Speedometer03Icon from '../../assets/icons0/speedometer-03.svg';
import Rocket02Icon from '../../assets/icons0/rocket-02.svg';

const EmptyScanState = ({isPending}) => {
    return (
        <div className={classNames('scan-impulse position-absolute width-100percent z-index-4 top-100percent', {'scan-impulse--hidden': isPending})}>
            <div className='background-color-white padding-bottom-1dot25rem padding-top-2rem padding-left-1dot25rem padding-right-1dot25rem border-top-right-radius-0dot75rem border-top-left-radius-0dot75rem'>
                <EmptyScan className='height-8rem width-10dot75rem display-block margin-0-auto'/>
            </div>
        </div>
    )
}

const DefaultScanView = ({hasError, count}) => {
    return () => {
        const location = useLocation();
        const scanSettings = useScanSettingsState();
        return (
            <div className=''>
                <Link className='position-absolute top-1rem left-1dot25rem z-index-2 color-white padding-0dot5rem cursor-pointer display-block' to={`settings${searchFilter(location.search)}`}>
                    {scanSettings.mode === SCAN_MODE_DEFAULT && <Maximize02Icon className='height-1dot5rem width-1dot5rem display-block'/>}
                    {scanSettings.mode === SCAN_MODE_ROCKET && <Rocket02Icon className='height-1dot5rem width-1dot5rem display-block'/>}
                </Link>
                <div className={classNames('position-absolute z-index-2 top-1dot5rem left-50percent transform-translateX-center height-1dot75rem padding-left-0dot75rem padding-right-0dot625rem background-color-gray-700 color-white display-flex border-radius-1rem align-items-center', {'display-none': count < 1})}>
                    <RefreshCw01Icon className='display-block width-0dot75rem height-0dot75rem'/>
                    <p className='text-sm medium padding-left-0dot25rem'>{count + 1}</p>
                </div>
                <Link className='position-absolute top-1rem right-1dot25rem z-index-2 color-white padding-0dot5rem cursor-pointer display-block' to={`history${searchFilter(location.search)}`}>
                    <ClockRewindIcon className='height-1dot5rem width-1dot5rem display-block'/>
                </Link>
                <div className='position-absolute top-6dot625rem left-50percent transform-translateX-center z-index-1 border-radius-0dot625rem box-shadow-scan-custom width-15rem height-15rem'></div>
                <QrScan className='position-absolute width-15dot25rem height-15dot25rem top-6dot5rem left-50percent transform-translateX-center z-index-2 border-radius-0dot625rem'/>
                {hasError && 
                    <div className='text-xs color-white text-align-center width-100percent position-absolute top-23dot25rem z-index-1'>
                        <FormattedMessage defaultMessage='Founded QR code is not registered in Ottry'/>
                    </div>
                }
            </div>
        )
    }
}

const FastScanView = ({toggleScanAvailable, scanAvailable, hasError, count}) => {
    return () => {
        const location = useLocation();
        const [needTap, setNeedTap] = React.useState(!scanAvailable);
        const hideTap = () => {
            toggleScanAvailable();
            setNeedTap(false);
        }
        return (
            <div className=''>
                <Link className='position-absolute top-1rem left-1dot25rem z-index-2 color-white padding-0dot5rem cursor-pointer display-block' to={`settings${searchFilter(location.search)}`}>
                    <Speedometer03Icon className='height-1dot5rem width-1dot5rem display-block'/>
                </Link>
                <div className={classNames('position-absolute z-index-2 top-1dot5rem left-50percent transform-translateX-center height-1dot75rem padding-left-0dot75rem padding-right-0dot625rem background-color-gray-700 color-white display-flex border-radius-1rem align-items-center', {'display-none': count < 1})}>
                    <RefreshCw01Icon className='display-block width-0dot75rem height-0dot75rem'/>
                    <p className='text-sm medium padding-left-0dot25rem'>{count + 1}</p>
                </div>
                <Link className='position-absolute top-1rem right-1dot25rem z-index-2 color-white padding-0dot5rem cursor-pointer display-block' to={`history${searchFilter(location.search)}`}>
                    <ClockRewindIcon className='height-1dot5rem width-1dot5rem display-block'/>
                </Link>
                <div className='position-absolute top-6dot625rem left-50percent transform-translateX-center z-index-1 border-radius-0dot625rem box-shadow-scan-custom width-15rem height-15rem'></div>
                <QrScan className='position-absolute width-15dot25rem height-15dot25rem top-6dot5rem left-50percent transform-translateX-center z-index-2 border-radius-0dot625rem'/>
                {hasError && 
                    <div className='text-xs color-white text-align-center width-100percent position-absolute bottom-22rem z-index-1'>
                        <FormattedMessage defaultMessage='Founded QR code is not registered in Ottry'/>
                    </div>
                }
                <div className={classNames('position-absolute top-6dot625rem left-50percent transform-translateX-center z-index-2 border-radius-0dot625rem width-15rem height-15rem fast-scan-background cursor-pointer', {'display-none': !needTap})} onClick={() => hideTap()}>
                    <TapAndScanIcon className='display-block color-gray-500 width-6rem height-6rem margin-0-auto margin-top-3rem'/>
                    <div className='text-xl semibold color-gray-700 padding-top-2dot25rem text-align-center'><FormattedMessage defaultMessage='Tap and scan'/></div>
                </div>
            </div>
        )
    }
}

const DefaultScanner = ({onScan, hasError, count}) => {
    return (
        <QrReader
            // delay={1000}
            className='scan-video'
            constraints={{facingMode: 'environment'}}
            onResult={onScan}
            ViewFinder={DefaultScanView({hasError, count})}
            containerStyle={{height: '100%'}}
            videoContainerStyle={{height: '100%', paddingTop: '0', width: 'auto'}}
            videoStyle={{width: 'auto', left: '50%', height: '100%'}}
        />
    )
};

const FastScanner = ({onScan, hasError, count}) => {
    const scanAvailable = React.useRef(true);
    const toggleScanAvailable = React.useCallback(() => {
        scanAvailable.current = !scanAvailable.current;
    }, []);
    const onFastScan = React.useCallback((e) => {
        if (!e) return;
        if (scanAvailable.current) {
            onScan(e);
            scanAvailable.current = false;
        };
    }, []);
    return (
        <QrReader
            className='scan-video'
            constraints={{facingMode: 'environment'}}
            onResult={onFastScan}
            ViewFinder={FastScanView({toggleScanAvailable, scanAvailable: scanAvailable.current, hasError, count})}
            containerStyle={{height: '100%'}}
            videoContainerStyle={{height: '100%', paddingTop: '0', width: 'auto'}}
            videoStyle={{width: 'auto', left: '50%', height: '100%'}}
        />
    )
};

const Scan = () => {
    const location = useLocation();
    const [item, setItem] = React.useState({count: 0, payload: null});
    const {impulseId} = useCurrentScanState();
    const currentScanDispatch = useCurrentScanDispatch();
    const valueRef = React.useRef();
    const scanSettings = useScanSettingsState();
    const {initialHeight} = useWindowDimentions();
    const autorized = useAuthorizationState();
    const [hasError, setHasError] = React.useState(false);
    const [isPending, startTransition] = React.useTransition();
    const header = React.useRef();
    const [headerHeight, setHeaderHeight] = React.useState(0);

    React.useEffect(() => {
        setHeaderHeight(header.current.clientHeight);
    }, [])
    if (!autorized) {
        return <SignInRedirect/>
    }
    const onScan = React.useCallback((e) => {
        if (!e || !autorized || isPending) return;
        if (valueRef.current === e.text) {
            setItem(h => ({...h, count: h.count + 1}));
        } else {
            // If counter needed
            // if (valueRef.current === e.text) {
            //     setItem(h => ({...h, count: h.count + 1})); // Same content counter
            // } else {
            //     valueRef.current = e.text;
            //     // ++fetchKey won't work as we could have cached 0-3 and on leave and return to the page we will
            //     // get 0-3 cached state.
            //     // Component here is not unmounted it is mounted all the time and will
            //     // reuse cache all the time.
            //     const fetchKey = v4();
                
            //     setItem({count: 0, payload: e.text, fetchKey});
                
            // }
            startTransition(() => {
                valueRef.current = e.text;
                // ++fetchKey won't work as we could have cached 0-3 and on leave and return to the page we will
                // get 0-3 cached state.
                // Component here is not unmounted it is mounted all the time and will
                // reuse cache all the time.
                
                setItem({count: 0, payload: e.text, fetchKey: v4()});
                setHasError(false);
            });
        }
    }, []);
    const resetImpulse = () => {
        setItem({count: 0, payload: null});
        currentScanDispatch({type: 'reset', payload: {impulseId: null, index: null, position: null}});
        valueRef.current = null;
    };
    return (
        <div className='scan position-relative' style={{minHeight: initialHeight + 'px'}}>
            <div className='position-sticky top-0 z-index-3' ref={header}>
                <ScanHeader>
                    <Link to={`search${searchFilter(location.search)}`} className='padding-0dot25rem'>
                        <SearchMdIcon className='height-1dot5rem width-1dot5rem color-gray-500 display-block'/>
                    </Link>
                </ScanHeader>
            </div>
            <div style={{height: initialHeight - headerHeight + 'px', position: 'fixed', top: `${headerHeight}px`, width: '100%'}}>
                {(scanSettings.mode === SCAN_MODE_DEFAULT || scanSettings.mode === SCAN_MODE_ROCKET) && <DefaultScanner {...{onScan, hasError, count: item.count, mode: scanSettings.mode}}/>}
                {scanSettings.mode === SCAN_MODE_FAST && <FastScanner {...{onScan, hasError, count: item.count}}/>}
            </div>
            {isPending &&
                <div className='scan-loading position-absolute bottom-4dot5rem left-50percent z-index-1 transform-translateX-center'>
                    <LogoLoading className='display-block width-3rem height-3rem'/>
                </div>
            }
            <ErrorBoundary {...{onError: () => setHasError(true), fallback: <EmptyScanState/>}}>
                {!item.payload && !impulseId &&
                    <EmptyScanState {...{isPending}}/>
                }
                {(!!item.payload || !!impulseId) &&
                    <ScanImpulseView {...{...item, isPending, payload: item.payload, setHasError}}>
                        {({impulse, endpointsAmount, position, index, take, isTaking, scansAmount, isMerchant, qrsArray, showCheckInConfirmation}) =>
                            <>
                                {scanSettings.mode === SCAN_MODE_DEFAULT && <ImpulseViewBasicHeader {...{impulse, endpointsAmount, position, index, take, isTaking, scansAmount, qrsArray, isMerchant, showCheckInConfirmation, close: () => resetImpulse()}}/>}
                                {(scanSettings.mode === SCAN_MODE_FAST || scanSettings.mode === SCAN_MODE_ROCKET) && <ImpulseViewIndicatorHeader {...{impulse, color: scansAmount && scansAmount > 1 ? 'error' : 'success', endpointsAmount, position, index, qrsArray, isMerchant, close: () => resetImpulse()}}/>}
                            </>
                        }
                    </ScanImpulseView>
                }
            </ErrorBoundary>
        </div>
    )
};

export default React.memo(Scan);
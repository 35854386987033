const translations = {
    daily: {
        title: 'per day',
        titleUk: 'щодня'
    },
    monthly: {
        title: 'per month',
        titleUk: 'щомісяця'
    },
    yearly: {
        title: 'per year',
        titleUk: 'щороку'
    }
}

const getPeriodFromCron = (cron) => {
    if (!cron) return {}
    const cronItems = cron.split(' ');
    const [minutes, hours, days, months, weekDays] = cronItems;
    if (days === '*' && months === '*' && weekDays === '*') {
        return translations.daily
    } else if (days.match(/\d+/gm) && months === '*' && weekDays === '*') {
        return translations.monthly
    } else if (days.match(/\d+/gm) && months.match(/\d+/gm) && weekDays === '*') {
        return translations.yearly
    } else {
        return {}
    }
}

export default getPeriodFromCron;


import React from 'react';
import {isSet, minTwoDigits} from '../../../utility';
import {adopt, checkShowCountdown} from '../../../math';
import {IN, OUT_NEGATIVE, OUT_POSITIVE, ENTITY_TYPE} from '../../../constants';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import useNow from '../../../useNow';
import Browser from '../../../components/Browser';

const EndpointTimetableImpl = React.memo(({now, start, end, strategy, every, gapAmount, gapUnits, theme}) => {
    const {timeLeft, state, from, to} = React.useMemo(() => adopt(now, start, end, strategy, every), [now, start, end, strategy, every]);
    const showCountdown = React.useMemo(() => checkShowCountdown(now, state, to, from, ENTITY_TYPE.endpoint, gapAmount, gapUnits), [now, state, to, from, gapAmount, gapUnits]);

    return (
        <div
            className={classNames('text-sm', {
                'color-success-500': state === OUT_POSITIVE,
                'color-warning-500': state === IN,
                'color-error-400': state === OUT_NEGATIVE && theme ==='dark',
                'color-error-500': state === OUT_NEGATIVE && theme ==='light',
            })}
        >
            {state === IN && showCountdown && isSet(timeLeft.days) && isSet(timeLeft.hours) && isSet(timeLeft.minutes) && isSet(timeLeft.seconds) &&
                <span className='padding-top-0dot5rem'><FormattedMessage defaultMessage='Sales closing in'/> <FormattedMessage defaultMessage='left'/></span>
            }
            {state === OUT_POSITIVE && showCountdown && <><FormattedMessage defaultMessage='Sales opening in'/></>}
            {(state === IN || state === OUT_POSITIVE) && showCountdown &&
            <span className='padding-top-0dot5rem'>
            {' '}
            {isSet(timeLeft.hours) && minTwoDigits(timeLeft.hours)}
            :
            {isSet(timeLeft.minutes) && minTwoDigits(timeLeft.minutes)}
            :
            {isSet(timeLeft.seconds) && minTwoDigits(timeLeft.seconds)}
            </span>
            }
            {state === OUT_NEGATIVE && <span className='padding-top-0dot5rem'><FormattedMessage defaultMessage='Sales closed'/></span>}
        </div>
    );
});

const EndpointTimetableDef = React.memo(({start, end, strategy, every, gapAmount, gapUnits,theme}) => {
    const now = useNow();
    const nowDeferred = React.useDeferredValue(now);
    return (
        <>
            <EndpointTimetableImpl {...{now: nowDeferred, start, end, strategy, every, gapAmount, gapUnits, theme}} />
        </>
    );
});

const EndpointTimetable = ({start, end, strategy, every, gapAmount, gapUnits, theme}) => {
    if (!start && !end) return null;
    return (
        <Browser>
            <EndpointTimetableDef {...{start, end, strategy, every, gapAmount, gapUnits, theme}}/>
        </Browser>
    );
};

export default React.memo(EndpointTimetable);
import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import splitPrice from '../../checkout/splitPrice';

const ServiceFee = ({deferred, localCartState}) => {
    const request = React.useMemo(() => ({
        picks: localCartState.map((e) => ({endpoint: e.endpointId, service: e.serviceId, quantity: e.quantity}))
    }), [localCartState]);
    const {landImpulse} = useLazyLoadQuery(
        graphql`
            query serviceFeeLandImpulseQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        pickExs {
                            index
                        }
                    }
                    solves {
                        serviceFeeAmount
                    }
                }
            }
        `,
        {request}
    );
    const serviceFee = React.useMemo(() => landImpulse.solves[0] ? splitPrice(landImpulse.solves[0].serviceFeeAmount) : {whole: '0', fraction: '00'}, [landImpulse]);
    return (
        <div className={classNames('text-sm mw768-text-md medium color-gray-700', {'opacity-0dot4 pointer-events-none': deferred})}>
            <span><span>{serviceFee.whole}.</span><span className='text-xs'>{serviceFee.fraction} </span></span>
            <FormattedMessage defaultMessage='UAH'/>
        </div>
    )
}

export default React.memo(ServiceFee);
import React from 'react';
import {accessToken__, refreshToken__, tokenType__, useAuthorizationDispatch, useAuthorizationStore} from '../contexts/Authorization';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
const RAZZLE_APP_SIGNOUT = process.env.RAZZLE_APP_SIGNOUT;

const LogOut = () => {
    const authorizationDispatch = useAuthorizationDispatch();
    const authorizationStore = useAuthorizationStore();
    React.useEffect(() => {
        authorizationStore.remove(tokenType__);
        authorizationStore.remove(accessToken__);
        authorizationStore.remove(refreshToken__);
        authorizationDispatch();
        setTimeout(
            () => window.location.href =
                RAZZLE_APP_ACCOUNTS + '/' + RAZZLE_APP_SIGNOUT + '.html?cache=0&continue='
                + encodeURIComponent(document.location.origin + document.location.search),
            500
        );
    }, []);
    return (
        <div>
            Loading...
        </div>
    );
}

export default React.memo(LogOut);

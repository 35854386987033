import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import moment from 'moment';
import {IMPULSE_STATUS, IMPULSE_STATUS_COLOR} from '../../../constants';
import {graphql, useFragment, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import {length} from '../../../utility';
import Qrs from './Qrs';
import Integration from './Integration';
import useQuery from '../../../useQuery';
import i18n from '../../../i18n';
import {graphqlWhoamiNextQuery} from '../../graphql';

const ImpulseImpl = ({impulse, aPosition, aIndex}) => {
    const {more, locale = 'uk'} = useQuery();
    const location = useLocation();
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const fImpulse = useFragment(
        graphql`
            fragment ImpulseImpl_impulse on Impulse {
                id
                amount
                impulseService {
                    name
                    demo
                    merchantAccount
                }
                impulseEndpoints {
                    name
                    price
                    quantity
                    untie {
                        price
                        currency
                    }
                }
                impulseAddls {
                    name
                    nameUk
                    value
                }
                impulseClient {
                    email
                }
                orderReference
                modified
                status
                qrs
                version
                number
                merchant {
                    id
                }
                ...QrsImpulseImpl_impulse
                ...IntegrationImpulseImpl_impulse
            }
        `,
        impulse
    );
    const [stub, applyingStub] = useMutation(
        graphql`
            mutation ImpulseImplStubMutation($id: String, $version: Int) {
                stubImpulse(id: $id, version: $version) {
                    id
                    version
                    number
                }
            }
    `);
    const onStub = () => stub({
        variables: {id: fImpulse.id, version: fImpulse.version},
        onCompleted: () => {},
        onError: () => {}
    });
    const [send, applyingSend] = useMutation(
        graphql`
            mutation ImpulseImplSendMutation($id: String, $version: Int) {
                sendImpulse(id: $id, version: $version) {
                    id
                }
            }
    `);
    const onSend = () => send({
        variables: {id: fImpulse.id, version: fImpulse.version},
        onCompleted: () => {},
        onError: () => {}
    });
    const [statusFlag, setStatusFlag] = React.useState(false);
    const {register: statusRegister, handleSubmit: statusHandleSubmit, formState: {errors: statusErrors}} = useForm();
    const [applyStatus, applyingStatus] = useMutation(
        graphql`
            mutation ImpulseImplStatusMutation($request: UpdateImpulseStatusRequestInput) {
                updateImpulseStatus(request: $request) {
                    id
                    status
                    version
                }
            }
    `);
    const statusSubmit = statusHandleSubmit(data => applyStatus({
        variables: {request: {id: fImpulse.id, version: fImpulse.version, status: data.status}},
        onCompleted: () => {setStatusFlag(false)},
        onError: () => {},
        updater: store => {
            const latestImpulses = store.get('client:root:viewer:__LatestImpulses_impulsesByViewer_connection(sent:true,status:[3,4])');
            latestImpulses && latestImpulses.invalidateRecord();
        }
    }));
    const [numberFlag, setNumberFlag] = React.useState(false);
    const {register: numberRegister, handleSubmit: numberHandleSubmit, formState: {errors: numberErrors}} = useForm();
    const [applyNumber, applyingNumber] = useMutation(
        graphql`
            mutation ImpulseImplNumberMutation($request: UpdateImpulseNumberRequestInput) {
                updateImpulseNumber(request: $request) {
                    id
                    number
                    version
                }
            }
    `);
    const numberSubmit = numberHandleSubmit(data => applyNumber({
        variables: {request: {id: fImpulse.id, version: fImpulse.version, number: data.number}},
        onCompleted: () => {setNumberFlag(false)},
        onError: () => {}
    }));

    const [emailFlag, setEmailFlag] = React.useState(false);
    const {register: emailRegister, handleSubmit: emailHandleSubmit, formState: {errors: emailErrors}} = useForm();
    const [applyEmail, applyingEmail] = useMutation(
        graphql`
            mutation ImpulseImplEmailMutation($request: UpdateImpulseEmailRequestInput) {
                updateImpulseEmail(request: $request) {
                    id
                    version
                    impulseClient {
                        email
                    }
                }
            }
    `);
    const emailSubmit = emailHandleSubmit(data => applyEmail({
        variables: {request: {id: fImpulse.id, version: fImpulse.version, email: data.email}},
        onCompleted: () => {setEmailFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        {fImpulse.impulseService.demo &&
        <span className='tag tag-magenta margin-right-0dot5rem font-size-0dot8rem'><FormattedMessage defaultMessage='Demo'/></span>
        }
        <span className={`tag tag-${IMPULSE_STATUS_COLOR[fImpulse.status]} font-size-0dot8rem`}>
            {IMPULSE_STATUS[fImpulse.status]}
        </span>
        <div>
            <Link
                className='focus'
                to={`/cabinet/impulses/${fImpulse.id}${searchFilter(location.search)}`}
            >{fImpulse.id}</Link>
        </div>
        {whoamiNext.id === fImpulse.merchant.id &&
        <>
        <div className='display-flex'>
            <div
                className='button margin-top-0dot5rem'
                onClick={() => !applyingStatus && setStatusFlag((flag) => !flag)}
            >
                {statusFlag ? <FormattedMessage defaultMessage='Cancel'/> : <FormattedMessage defaultMessage='Edit status'/>}{applyingStatus && '...'}
            </div>
        </div>
        {statusFlag &&
        <div className='inline'>
            <form onSubmit={statusSubmit}>
                <div className='select'>
                    <select
                        className='margin-0dot5rem-0'
                        defaultValue={fImpulse.status}
                        {...statusRegister('status', {required: true})}
                    >
                        <option value='4'>{IMPULSE_STATUS[4]}</option>
                        <option value='6'>{IMPULSE_STATUS[6]}</option>
                    </select>
                </div>
                {statusErrors.status && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                <div className='display-flex'>
                    <div
                        className='button primary'
                        onClick={() => !applyingStatus && statusSubmit()}
                    >
                        <FormattedMessage defaultMessage='Submit'/>{applyingStatus && '...'}
                    </div>
                </div>
            </form>
        </div>
        }
        </>
        }
        <div className='font-size-0dot8rem color-grey'>
            {fImpulse.orderReference}
        </div>
        <div className='font-weight-bold padding-top-1rem'>
            <span className='font-weight-bold highlight palevioletred'>
                &nbsp;{fImpulse.impulseService.name}&nbsp;
            </span>
        </div>
        {fImpulse.impulseEndpoints.map((e, i) =>
            <div key={i} className='margin-top-1rem'>
                <div>
                    <span className={classNames({'background-gold': i === aPosition})}>
                        <span className='highlight lightslategrey'>
                            &nbsp;#{i} {e.name}&nbsp;
                        </span>
                        {Math.ceil(e.price) > 0 ? ` ${e.price} UAH` : ''}
                        {!e.untie && <span className='badge margin-left-0dot25rem'>{e.quantity}</span>}
                    </span>
                </div>
                <div className='margin-top-0dot5rem'>
                    <Qrs impulse={fImpulse} position={i} aIndex={i === aPosition && aIndex}/>
                </div>
            </div>
        )}
        {fImpulse.amount && Math.ceil(fImpulse.amount) > 0 &&
        <div className='margin-top-1rem'>
            <FormattedMessage defaultMessage='Amounts'/> <span className='font-weight-bold'>{fImpulse.amount}</span> UAH
        </div>
        }
        <div className='margin-top-1rem font-weight-bold'>
            {fImpulse.impulseClient.email}
        </div>
        {whoamiNext.id === fImpulse.merchant.id &&
        <>
        <div className='display-flex'>
            <div
                className='button margin-top-0dot5rem'
                onClick={() => !applyingEmail && setEmailFlag((flag) => !flag)}
            >
                {emailFlag ? <FormattedMessage defaultMessage='Cancel'/> : <FormattedMessage defaultMessage='Edit email'/>}{applyingEmail && '...'}
            </div>
        </div>
        {emailFlag &&
        <div className='inline'>
            <form onSubmit={emailSubmit}>
                <div className='input'>
                    <input
                        className='margin-0dot5rem-0 width-100percent'
                        defaultValue={fImpulse.impulseClient.email}
                        {...emailRegister('email', {required: true, pattern: /^\S+@\S+\.\S+$/})}
                    />
                </div>
                {emailErrors.email && emailErrors.email.type === 'required' && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                {emailErrors.email && emailErrors.email.type === 'pattern' && <div className='input-error'><FormattedMessage defaultMessage='This field is not valid'/></div>}
                <div className='display-flex'>
                    <div
                        className='button primary'
                        onClick={() => !applyingEmail && emailSubmit()}
                    >
                        <FormattedMessage defaultMessage='Submit'/>{applyingEmail && '...'}
                    </div>
                </div>
            </form>
        </div>
        }
        </>
        }
        {length(fImpulse.impulseAddls) &&
        <div className='margin-top-1rem'>
            {fImpulse.impulseAddls.map((e, i) =>
                <div key={i} className='margin-top-0dot5rem'>
                    {e.value &&
                    <>
                        <div className='margin-top-0dot5rem font-weight-bold'>{i18n(e, 'name', locale)}</div>
                        <div className='margin-top-0dot5rem'>{e.value}</div>
                    </>
                    }
                </div>
            )}
        </div>
        }
        <div className='margin-top-1rem font-weight-bold'>
            {moment(fImpulse.modified).format('YYYY-MM-DD HH:mm:ss')}
        </div>
        {whoamiNext.id === fImpulse.merchant.id ?
            <>
            <div className='display-flex margin-top-1rem'>
                <div
                    className='button margin-right-0dot5rem'
                    onClick={() => !applyingStub && onStub()}
                >
                    <FormattedMessage defaultMessage='Stub'/>{applyingStub && '...'}
                    {!!fImpulse.number && <span className='badge orangered margin-left-0dot25rem'>{fImpulse.number}</span>}
                </div>
                <div
                    className='button primary'
                    onClick={() => !applyingSend && onSend()}
                ><FormattedMessage defaultMessage='Send'/>{applyingSend && '...'}</div>
            </div>
            <div className='display-flex margin-top-0dot5rem'>
                <div
                    className='button'
                    onClick={() => !applyingNumber && setNumberFlag((flag) => !flag)}
                >
                    {numberFlag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit number'/>}{applyingNumber && '...'
                    }
                </div>
            </div>
            {numberFlag &&
            <div className='inline'>
                <form onSubmit={numberSubmit}>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fImpulse.number}
                            {...numberRegister('number', {required: true})}
                        />
                    </div>
                    {numberErrors.number && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applyingNumber && numberSubmit()}
                        >
                            <FormattedMessage defaultMessage='Submit'/>{applyingNumber && '...'}
                        </div>
                    </div>
                </form>
            </div>
            }
            </>
            :
            <>
            {!!fImpulse.number &&
            <>
            <FormattedMessage defaultMessage='Stub'/>
            <span className='badge orangered margin-top-1rem margin-left-0dot25rem'>{fImpulse.number}</span>
            </>
            }
            </>
        }
        {fImpulse.impulseService.merchantAccount &&
        <div className='margin-top-1rem'><Integration impulse={fImpulse}/></div>
        }
        </>
    );
};

export default React.memo(ImpulseImpl);

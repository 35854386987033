import React from 'react';
import './Cabinet.scss';
import {Link, useLocation} from 'react-router-dom';
import MenuButton from '../menu/MenuButton';
import {ErrorBoundary} from 'react-error-boundary';
import classNames from 'classnames';
import {Outlet} from 'react-router-dom';
import CabinetFooter from './CabinetFooter';
import MenuSmall from './menu/MenuSmall';
import MenuLarge from './menu/MenuLarge';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../searchFilter';
import ErrorFallback from '../ErrorFallback';

const impulseLink = 'https://impulse.ottry.com/cabinet/search';

function Cabinet() {
    const location = useLocation();
    const [menuFlag, setMenuFlag] = React.useState(false);
    const closeMenu = () => setMenuFlag((flag) => !flag);
    const menuButtonRef = React.useRef();
    const menuLargeRef = React.useRef();
    const menuSmallRef = React.useRef();
    React.useEffect(() => {
        const onClickOutsideHandler = (event) => {
            if (menuFlag && !(
                    menuButtonRef.current.contains(event.target)
                    || menuLargeRef.current.contains(event.target)
                    || menuSmallRef.current.contains(event.target)
                ))
                setMenuFlag(false);
        };
        document.addEventListener('click', onClickOutsideHandler, true);
        return () => {
            document.removeEventListener('click', onClickOutsideHandler, true);
        };
    }, [menuFlag, menuLargeRef, menuSmallRef]);
    return (
        <div className='cabinet'>
            <div className={`cabinet__header-divider ${menuFlag ? 'cabinet__header-divider--sticky' : ''}`}>
                <div className='cabinet__header'>
                    <div className='cabinet__menu-button'>
                        <MenuButton {...{menuButtonRef, menuFlag, closeMenu}}/>
                    </div>
                    <div className='cabinet__logo cabinet__accent '>
                        <Link
                            to={`/business${searchFilter(location.search)}`}
                        ><span className='cabinet__accent'>Ottry </span><span className='cabinet__accent font-weight-bold'>
                            <FormattedMessage defaultMessage='Business'/>
                        </span></Link>
                    </div>
                    <div className='cabinet__marketplace'>
                        <Link
                            className='focus font-weight-bold'
                            to={`/${searchFilter(location.search)}`}
                        ><FormattedMessage defaultMessage='Marketplace'/></Link>
                    </div>
                </div>
            </div>
            <MenuLarge {...{menuLargeRef, menuFlag, closeMenu}}/>
            <MenuSmall {...{menuSmallRef, menuFlag, closeMenu}}/>
            <div className={classNames({'hide-small': menuFlag})}>
                <div className='view'>
                    <div className='block'>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <React.Suspense fallback={'Loading...'}>
                                <Outlet/>
                            </React.Suspense>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <div className={`${menuFlag ? 'hide-small' : ''}`}>
                <CabinetFooter/>
            </div>
        </div>
    );
}

export default React.memo(Cabinet);
import React from 'react';
import './Endpoints.scss';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../searchFilter';
import classNames from 'classnames';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../Skeleton';
import useQuery from '../../useQuery';
import {FormattedMessage} from 'react-intl';
import ErrorFallback from '../ErrorFallback';

const Endpoints = () => {
    const location = useLocation();
    const {size = 8, page = 0} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query EndpointsQuery($size: Int!, $page: Int!) {
                endpoints(size: $size, page: $page) {
                    totalPages,
                    content {
                        id
                        name
                        description
                    }
                }
            }
        `,
        {size, page}
    );
    return (
        <>
        <div className='endpoints__list'>
            {data.endpoints.content.map((endpoint) =>
                <div key={endpoint.id} className='endpoints__card'>
                    <div key={endpoint.id} className='endpoints__card-content'>
                        <div className='endpoints__card-description'>
                            <Link to={endpoint.id}>
                                {endpoint.description || endpoint.id}
                            </Link>
                        </div>
                        <div className='endpoints__card-meta'>
                            {endpoint.name}
                        </div>
                    </div>
                </div>
            )}
        </div>
        <div className='endpoints__pager'>
            {[...new Array(data.endpoints.totalPages)].map((x, i) =>
                <Link
                    key={i}
                    className={classNames('endpoints__pager-item', {
                        'endpoints__pager-item-active': parseInt(page) === i
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page: i}, false)}`}
                >
                    {i + 1}
                </Link>
            )}
            <div className='endpoints__pager-item'>{'| '}page</div>
        </div>
        <div className='endpoints__pager'>
            <Link
                className={classNames('endpoints__pager-item', {
                    'endpoints__pager-item-active': parseInt(size) === 2
                })}
                to={`${location.pathname}${searchFilter(location.search, {page:0, size: 2}, false)}`}
            >2</Link>
            <Link
                className={classNames('endpoints__pager-item', {
                    'endpoints__pager-item-active': parseInt(size) === 4
                })}
                to={`${location.pathname}${searchFilter(location.search, {page:0, size: 4}, false)}`}
            >4</Link>
            <Link
                className={classNames('endpoints__pager-item', {
                    'endpoints__pager-item-active': parseInt(size) === 8
                })}
                to={`${location.pathname}${searchFilter(location.search, {page:0, size: 8}, false)}`}
            >8</Link>
            <div className='endpoints__pager-item'>
                {'| '}per page
            </div>
        </div>
        </>
    );
};

export default React.memo(() =>
    <div className='margin-top-1dot5rem'>
        <div className='font-size-2dot5rem font-weight-bold margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Endpoints'/>
        </div>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense
                fallback={
                    <div className='endpoints__list'>
                        {[1,2,3].map(i =>
                            <div key={i} className='endpoints__card'>
                                <div className='endpoints__card-content'>
                                    <Skeleton/>
                                    <div className='endpoints__card-meta'>&nbsp;</div>
                                </div>
                            </div>
                        )}
                    </div>
                }
            >
                <Endpoints/>
            </React.Suspense>
        </ErrorBoundary>
    </div>
);

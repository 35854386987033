import React from 'react';
import './CartMetaLink.scss';
import {CartStateContext} from '../../contexts/cart';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../searchFilter';
import Browser from '../Browser';

function CartMetaLink() {
    const location = useLocation();
    const state = React.useContext(CartStateContext);
    const quantity = React.useMemo(() => state.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0), [state]);
    return (
        <div className='cart-meta-link'>
            <Link
                className='focus'
                to={`cart${searchFilter(location.search)}`}
            >
                <span><FormattedMessage defaultMessage='Cart'/></span>
                {!!state.length && 
                    <Browser>
                        <span className='badge margin-left-0dot25rem'>{quantity}</span>
                    </Browser>
                }
            </Link>
        </div>
    );
}

export default React.memo(CartMetaLink);
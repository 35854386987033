import React from 'react';
import useHydrated from './useHydrated';

const useHydratedRef = () => {
    const hydrated = useHydrated();
    const ref = React.useRef(0);
    React.useEffect(() => {
        // ref.current === 2 means client renders and can use cache
        ref.current = ref.current + 1; // initial + hydrated
    }, [hydrated]);
    return ref;
}

export default useHydratedRef;

const isNullOrUndefined = (value) => value === null || value === undefined;
const isSet = (value) => !isNullOrUndefined(value);
const isTrue = (value) => value === 'true';
// https://stackoverflow.com/questions/1091372/getting-the-clients-time-zone-and-offset-in-javascript
const timeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;
const length = (value) => value && !!value.length;
const nullOnEmpty = (value) => value === '' ? null : value;

const submitForm = (url, data) => {
    const form = document.createElement('form');
    const body = document.getElementsByTagName('body')[0];
    form.style.cssText = 'display: none;';
    form.setAttribute('name', 'tocheckout');
    form.setAttribute('action', url);
    form.setAttribute('method', 'POST');
    form.setAttribute('accept-charset', 'UTF-8');
    let i, field;
    i = document.createElement('input');
    i.setAttribute('type', 'submit');
    form.appendChild(i);
    for (field in data) {
        if (data.hasOwnProperty(field)) {
            i = document.createElement('input');
            i.setAttribute('type', 'text');
            i.setAttribute('name', field);
            i.setAttribute('value', data[field]);
            form.appendChild(i);
        }
    }
    body.appendChild(form);
    form.submit();
};
const minTwoDigits = (n) => {
    return (n < 10 ? '0' : '') + n;
};

export {isNullOrUndefined, isSet, isTrue, timeZone, length, nullOnEmpty, submitForm, minTwoDigits};
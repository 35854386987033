import React from 'react';
import './Shell.scss';
import {Outlet} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import searchFilter from '../../../searchFilter';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import ArrowRightSvg from '../../../assets/icons/arrow-right.svg';
// import LogoDarkModeFalseSvg from '../../../assets/logo-dark-mode-false.png';
import ShoppingBag03Svg from '../../../assets/icons0/shopping-bag-03.svg';
import classNames from 'classnames';
import LogIn04Svg from '../../../assets/icons0/log-in-04.svg';
import {CartStateContext} from '../../../contexts/cart';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {queryParameters} from '../../../qs';
import searchUnFilter from '../../../searchUnFilter';
import Button from '../../button/button';
import Footer from './Footer';
import Avatar120120Png from '../../../assets/avatar-120-120@3x.png';
import Wallet01Svg from '../../../assets/icons0/wallet-01.svg';
import Building02Svg from '../../../assets/icons0/building-02.svg';
import LogOut04Svg from '../../../assets/icons0/log-out-04.svg';
import SuspenseList from '../../../components/SuspenseList';
import Header from './Header';
import ErrorFallback from '../../ErrorFallback';
import Browser from '../../Browser';
import {graphqlWhoamiNextQuery} from '../../graphql';
import {useAuthorizationState} from '../../../contexts/Authorization';

const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';

export const ShoppingBag = React.memo(({menuFlag}) => {
    const location = useLocation();
    const state = React.useContext(CartStateContext);
    const quantity = React.useMemo(() => state.reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0), [state]);
    return (
        <Link 
            to={`/checkout-new/cart${searchFilter(location.search)}`} 
            className={classNames({'visibility-hidden': menuFlag, 'mw768-visibility-visible': true})}
        >
            <div className='height-2dot5rem width-2dot5rem display-flex justify-content-center align-items-center position-relative'>
                <ShoppingBag03Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600'/>
                {!!quantity &&
                    <Browser>
                        <div className='position-absolute color-white background-color-gray-600 border-radius-50percent text-xs medium text-align-center' style={{top: 2, left: 20, width: '18px', height: '18px'}}>{quantity}</div>
                    </Browser>
                }
            </div>
        </Link>
    );
});

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
export const User = React.memo(({userMenuButtonRef, toggleUserMenu}) => {
    const authorized = useAuthorizationState();
    const location = useLocation();
    const qps = queryParameters(location);
    const signIn = (e) => {
        e.preventDefault();
        setTimeout(() => {
            window.location.href = RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&new=true&cache=0&continue='
                + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
                + (qps.length ? '&' + qps.join('&') : '');
        }, 500);
    };
    return (
        <div ref={userMenuButtonRef} className='hide-small margin-left-1rem'>
            {authorized ?
                <img className='display-block height-2dot5rem width-2dot5rem cursor-pointer' src={Avatar120120Png} alt='' onClick={toggleUserMenu}/>
                :
                <div className='display-flex align-items-center'>
                    <Button color='secondary-gray' clickHandler={signIn} iconPosition='left' size='md'>
                        <LogIn04Svg className='display-block height-1dot25rem width-1dot25rem color-gray-700'/>
                        <FormattedMessage defaultMessage='Sign in'/>
                    </Button>
                </div>
            }
        </div>
    );
});

export const UserMenu = React.memo(({userMenuRef, userMenuFlag, toggleUserMenu}) => {
    const location = useLocation();
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    return (
        <div
            ref={userMenuRef}
            className={classNames('display-none', {'display-block': userMenuFlag})}
        >
            {whoamiNext &&
            <div className='view'>
                <div className='block'>
                    <div className='position-relative'>
                        <div className='position-absolute background-color-white border-1px-solid-black border-top-color-gray-200 border-bottom-color-gray-200 border-left-color-gray-200 border-right-color-gray-200 border-radius-0dot5rem z-index-1' style={{top: -12, right: 0}}>
                            <div className='display-flex align-items-center justify-content-center padding-left-1dot5rem padding-right-1dot5rem padding-top-0dot875rem padding-bottom-0dot875rem'>
                                <div className='text-md color-gray-700'>
                                    {whoamiNext.username || whoamiNext.email}
                                </div>
                            </div>
                            <div className='height-0dot0625rem background-color-gray-100'/>
                            <div className='padding-top-0dot25rem padding-right-1dot5rem'>
                                <Link to={`/cabinet${searchFilter(location.search)}`} onClick={toggleUserMenu}>
                                    <div className='display-flex align-items-center padding-left-1dot5rem height-2dot5rem'>
                                        <Wallet01Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600'/>
                                        <div className='padding-left-0dot75rem text-md medium color-gray-900'>
                                            <FormattedMessage defaultMessage='Wallet'/>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='padding-top-0dot25rem padding-right-1dot5rem'>
                                <Link to={`/business${searchFilter(location.search)}`} onClick={toggleUserMenu}>
                                    <div className='display-flex align-items-center padding-left-1dot5rem height-2dot5rem'>
                                        <Building02Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600'/>
                                        <div className='padding-left-0dot75rem text-md medium color-gray-900'>
                                            <FormattedMessage defaultMessage='Business cabinet'/>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='padding-top-0dot25rem padding-right-1dot5rem'>
                                <div className='opacity-0dot4 display-flex align-items-center padding-left-1dot5rem height-2dot5rem'>
                                    <Building02Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600'/>
                                    <div className='padding-left-0dot75rem text-md medium color-gray-900'>
                                        <FormattedMessage defaultMessage='Account'/>
                                    </div>
                                </div>
                            </div>
                            <div className='padding-top-0dot25rem'>
                                <div className='height-0dot0625rem background-color-gray-100'/>
                            </div>
                            <div className='padding-top-0dot25rem padding-right-1dot5rem'>
                                <Link to={`/log-out${searchFilter(location.search)}`} onClick={toggleUserMenu}>
                                    <div className='display-flex padding-left-1dot5rem height-2dot75rem padding-top-0dot5rem'>
                                        <LogOut04Svg className='display-block height-1dot5rem width-1dot5rem color-gray-500'/>
                                        <div className='padding-left-0dot75rem text-md medium color-gray-500'>
                                            <FormattedMessage defaultMessage='Log out'/>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    );
});

const Shell = () => {
    const [menuFlag, setMenuFlag] = React.useState(false);
    const location = useLocation();
    return (
        <>
        <div className='background-color-gray-800'>
            <div className='view'>
                <div className='block'>
                    <div className='min-height-2rem display-flex justify-content-center-end'>
                        <a 
                            href={formLink} 
                            target='_blank' 
                            rel='noopener noreferrer'
                            className='display-flex align-items-center text-sm medium color-gray-25'
                        >
                            <FormattedMessage defaultMessage='Use Ottry in your business'/>
                            <ArrowRightSvg className='margin-left-0dot5rem color-gray-25 height-1rem width-1rem'/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Header menuFlag={menuFlag} setMenuFlag={setMenuFlag} />
        <div className={classNames({'hide-small': menuFlag})}>
            <SuspenseList revealOrder='forwards'>
                <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                    {/* ToDo: should the shell have React.Suspense wrapper for outlet? SuspenseList? */}
                    {/* <React.Suspense fallback={'Loading...'}> */}
                        <Outlet/>
                    {/* </React.Suspense> */}
                </ErrorBoundary>
                <Footer/>
            </SuspenseList>
        </div>
        </>
    );
};

export default React.memo(Shell);
import React from 'react';
import './Scan.scss';
import {FormattedMessage} from 'react-intl';
import {parseEx} from '../../qs';
import Plant from './Plant';
import Endpoint from './Endpoint';

export default React.memo(({payload, count, fetchKey}) => {
    if (!payload)
        return (<span><FormattedMessage defaultMessage='No data for now'/></span>);
    if (payload.startsWith('plant://'))
        return (<Plant payload={payload.replace('plant://', '')} {...{count, fetchKey}}/>);
    if (payload.includes('/endpoints/') && parseEx(payload.substr(payload.indexOf('?')), 'serviceId'))
        return (
            <Endpoint
                id={payload.match(/\/endpoints\/([^?]*)/)[1]}
                serviceId={parseEx(payload.substr(payload.indexOf('?')), 'serviceId')}
            />
        );
    return (
        <div style={{overflowWrap: 'break-word'}}>{JSON.stringify(payload)}</div>
    );
});
import React from 'react';
import './seat-map.scss';
import classNames from 'classnames';
import ATLAS_SEAT_MAPS from './seats';
import getTagValue from '../../../utilities/get-tag-value';

const StaticElement = React.memo(({svgTag = 'rect', d, fill, stroke, x, y, rx, transform, width, height}) => {
    return (
        <>
            {svgTag === 'rect' && <rect {...{x, y, rx, transform, width, height, fill, stroke}} />}
            {svgTag === 'path' && <path {...{d, fill, stroke}}/>}
        </>
    )
});

const Seat = React.memo(({endpoint, priceCategories, localCartState, toggleEndpoint, x, y, rx, transform, width, height, svgTag = 'rect', d, allowMultipleBuy}) => {
    // const row = endpoint.tags.find((e) => e.includes('row')).replace('row=', '');
    // const seat = endpoint.tags.find((e) => e.includes('seat')).replace('seat=', '');
    // const priceTag = endpoint.tags.find((e) => e.includes('price'));
    // const price = priceTag ? priceTag.replace('price=', '') : null;
    const colorClass = (!endpoint || endpoint.quantity === 0) ? `${svgTag === 'rect' ? 'seat' : svgTag}--disabled` :  `${svgTag === 'rect' ? 'seat' : svgTag}--color-${priceCategories.indexOf(endpoint.price)}`;
    return (
        <>
            {!!endpoint && 
                <>
                    {svgTag === 'rect' && <rect {...{x, y, rx, transform, width, height}} className={classNames(`seat ${colorClass}`, {'seat--selected': !!localCartState[endpoint.id]})} onClick={() => toggleEndpoint(endpoint.id, allowMultipleBuy)}/>}
                    {svgTag === 'path' && <path {...{d}} className={classNames(`path ${colorClass}`, {'path--selected': !!localCartState[endpoint.id]})} onClick={() => toggleEndpoint(endpoint.id, allowMultipleBuy)}/>}
                </>
            }
            {!endpoint &&
                <>
                    {svgTag === 'rect' && <rect {...{x, y, rx, transform, width, height}} className='seat seat--disabled' />}
                    {svgTag === 'path' && <path {...{d}} className={`path path--disabled`}/>}
                </>
            }
        </>
    )
});

const SeatMap = ({service, toggleEndpoint, localCartState}) => {
    const priceCategories = [];
    const mapName = service.description ? getTagValue(service.description.split(', '), 'seatmap') : '';
    const seatMapName = mapName || 'defaultMap';
    const seatMap = ATLAS_SEAT_MAPS[seatMapName];
    service.endpoints.forEach((item) => {
        const sector = getTagValue(item.tags, 'sector', {separator: '='});
        const row = getTagValue(item.tags, 'row', {separator: '='});
        const seat = getTagValue(item.tags, 'seat', {separator: '='});
        if (sector && row && seat && seatMap.seats[sector] && seatMap.seats[sector][row - 1] && seatMap.seats[sector][row - 1][seat - 1]) {
            seatMap.seats[sector][row - 1][seat - 1].endpoint = {...item};
            if (!priceCategories.includes(item.price)) {
                priceCategories.push(item.price);
            }
        }
    });
    priceCategories.sort((a, b) => b - a);
    return(
        <div className='seat-map'>
            <div className='padding-top-1rem padding-bottom-1rem mw768-padding-bottom-2rem mw768-padding-top-1dot5rem display-grid gap-1rem-1rem price-grid'>
                {priceCategories.map((price, index) =>
                    <div key={index} className='display-flex align-items-center'>
                        <div className={`seat seat--price pointer-events-none seat--color-${index}`}></div>
                        <p className='padding-left-0dot5rem text-md color-gray-200 white-space-nowrap'>{price} ₴</p>
                    </div>
                )}
            </div>
            <div className='seat-map-container'>
                <div className='padding-top-1dot5rem margin-0-auto position-relative width-47dot93755rem'>
                    <svg className={`display-block margin-0-auto atlas-seats position-relative`} width={seatMap.width} height={seatMap.height} fill="none" xmlns="http://www.w3.org/2000/svg">
                        {
                            Object.keys(seatMap.seats).map(sectorName =>
                                seatMap.seats[sectorName].map((row, rowIndex) =>
                                    row.map(({x, y, rx, transform, width, height, endpoint, svgTag, d, allowMultipleBuy}, seatIndex) =>
                                        <Seat key={`${sectorName}-${rowIndex}-${seatIndex}`} {...{endpoint, localCartState, priceCategories, toggleEndpoint, x, y, rx, transform, width, height, svgTag, d, allowMultipleBuy}}/>
                                    )
                                )
                            )
                        }
                        {seatMap.static && seatMap.static.length &&
                            seatMap.static.map(({svgTag, d, fill, stroke, x, y, rx, transform, width, height}, index) =>
                                <StaticElement key={index} {...{svgTag, d, fill, stroke, x, y, rx, transform, width, height}}/>
                            )
                        }
                    </svg>
                </div>
            </div>
        </div>
    )
};

export default React.memo(SeatMap);
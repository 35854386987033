import React from 'react';
import {v4} from 'uuid';

export const CurrentScanStateContext = React.createContext({count: 0, payload: null});
export const CurrentScanDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return action.payload;
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

export const CurrentScanProvider = React.memo(({children}) => {
    const [state, setState] = React.useState([]);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            return nextState;
        });
    }, []);
    return (
        <CurrentScanStateContext.Provider value={state}>
            <CurrentScanDispatchContext.Provider value={dispatch}>
                {children}
            </CurrentScanDispatchContext.Provider>
        </CurrentScanStateContext.Provider>
    );
});

export const useCurrentScanState = () => React.useContext(CurrentScanStateContext);
export const useCurrentScanDispatch = () => React.useContext(CurrentScanDispatchContext);

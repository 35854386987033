import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useInstantState, useInstantDispatch} from '../../../contexts/instant';
import ImpulsePage from '../impulse/Impulse';
import useQuery from '../../../useQuery';

const TicketingInstantImpulsePage = () => {
    const state = useInstantState();
    const dispatch = useInstantDispatch();
    const subtitle = <FormattedMessage defaultMessage='To reserve your order for 15 min. please enter your contact details and click the "Proceed with ordering" button'/>;
    const message = <FormattedMessage defaultMessage='The order will be sent to the specified email'/>;
    const {showOrderItemQuantity = false} = useQuery();
    
    return (
        <ImpulsePage {...{state, dispatch, subtitle, message, showOrderItemQuantity}}/>
    )
};

export default React.memo(TicketingInstantImpulsePage);

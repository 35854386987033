import React from 'react';
import {FormattedMessage} from 'react-intl';
import useMountedState from '../../useMountedState';
import PlusIcon from '../../assets/icons0/plus.svg';
import {useLocation} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import LogoVisa from '../../assets/subscriptions/logo-visa.svg';
import LogoMastercard from '../../assets/subscriptions/logo-mastercard.svg';
import LogoCreditCard from '../../assets/subscriptions/credit-card.svg';
import './payment-method.scss';
import classNames from 'classnames';
import LogoLoadingIcon from '../../assets/subscriptions/logo-loading.svg';
import useQuery from '../../useQuery';
import Watcher from './watcher';
import {ErrorBoundary} from 'react-error-boundary';
import qs from 'qs';
import ErrorFallback from '../../components/ErrorFallback';
import Skeleton from '../../components/Skeleton';
import {submitForm} from '../../utility';

const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;
const RAZZLE_APP_RETURN_URL0 = process.env.RAZZLE_APP_RETURN_URL0;
const RAZZLE_APP_SERVER_CALLBACK_URL0 = process.env.RAZZLE_APP_SERVER_CALLBACK_URL0;

const NewPaymentMethodButton = React.memo(({serviceId, setRedirect}) => {
    const [grecaptcha, setGrecaptcha] = useMountedState(false);
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation paymentMethodCreatePooltickMutation($request: PooltickRequestInput!) {
                createPooltick(request: $request) {
                    id
                    claim
                    pooltickService {
                        mrchntd
                        service {
                            name
                        }
                    }
                }
            }
    `);
    const [sign, isSignInFlight] = useMutation(
        graphql`
            mutation paymentMethodSignPooltickMutation($request: FondySignRequestInput) {
                fondySignPooltick(request: $request) {
                    signature
                }
            }
    `);

    const addNewCard = () => {
        setGrecaptcha(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
                commit({
                    variables: {request: {reCaptcha: token, service: serviceId}},
                    onCompleted: (foo) => {
                        const payload = {
                            order_id: foo.createPooltick.id,
                            merchant_id: foo.createPooltick.pooltickService.mrchntd,
                            order_desc: foo.createPooltick.pooltickService.service.name,
                            amount: '100',
                            currency: 'UAH',
                            ...(RAZZLE_APP_RETURN_URL0 && {response_url: `${RAZZLE_APP_RETURN_URL0}?url=${document.location.origin}${location.pathname}?pooltickId=${foo.createPooltick.id}`}),
                            ...(RAZZLE_APP_SERVER_CALLBACK_URL0 && {
                                server_callback_url: RAZZLE_APP_SERVER_CALLBACK_URL0 + qs.stringify({upsert: true}, {addQueryPrefix: true})
                            }),
                            lifetime: 900, // this also means hold 7 working days for preauth: 'Y'
                            preauth: 'Y',
                            verification: 'Y',
                            lang: locale,
                            // sender_email: foo.createPooltick.pooltickClient.email,
                            required_rectoken: 'Y',
                            payment_systems: 'card'
                        };
                        sign({
                            variables: {request: {payload, claim: foo.createPooltick.claim}},
                            onCompleted: (bar) => {
                                setRedirect(true);
                                submitForm('https://pay.fondy.eu/api/checkout/redirect/', {
                                    ...payload,
                                    signature: bar.fondySignPooltick.signature
                                });
                            }
                        });
                    },
                    onError: () => {},
                    updater: () => {}
                });
                setGrecaptcha(false);
            });
        });
    }

    return (
        <button type='button' className='display-flex color-gray-500 align-items-center padding-left-1rem cursor-pointer padding-top-1rem padding-bottom-1rem line-height-0 width-100percent background-color-white' onClick={addNewCard}>
            <div className='width-2dot875rem height-2rem border-1px-solid border-color-gray-100 border-radius-0dot375rem display-flex'>
                <PlusIcon className='display-block width-1dot5rem margin-auto'/>
            </div>
            <div className='text-md padding-left-0dot75rem medium'>
                <FormattedMessage defaultMessage='Add payment method'/>
            </div>
        </button>
    )
});

const LoadingCardView = React.memo(({id}) => {
    const cardIconComponent = <LogoCreditCard className='display-block width-1dot5rem margin-auto'/>;
    return (
        <label htmlFor={id} className='display-block position-relative padding-top-1rem padding-bottom-1rem line-height-0 border-bottom-1px-solid border-color-gray-200'>
            <div className='card-indicator cursor-pointer'>
                <LogoLoadingIcon className='height-1dot5rem width-1dot5rem'/>
            </div>
            <div className='display-flex align-items-center padding-left-1rem cursor-pointer'>
                <div className='width-2dot875rem height-2rem border-1px-solid border-color-gray-100 border-radius-0dot375rem display-flex'>
                    {cardIconComponent}
                </div>
                <div className='text-md padding-left-0dot75rem medium color-gray-500'>
                    <span>&#8226;&#8226;&#8226;&#8226; &#8226;&#8226;&#8226;&#8226;</span>
                </div>
            </div>
        </label>
    )
});

const CardView = React.memo(({card, setPool, pool, id}) => {
    let cardIconComponent = null;
    const cardPan = card.cardPan.split('XXXXXX')[1];
    if (/Visa/i.test(card.cardType)) {
        cardIconComponent = <LogoVisa className='display-block width-2rem margin-auto'/>;
    } else if (/MasterCard/i.test(card.cardType)) {
        cardIconComponent = <LogoMastercard className='display-block width-1dot875rem margin-auto'/>
    }
    return (
        <label htmlFor={id} className='display-block position-relative padding-top-1rem padding-bottom-1rem line-height-0 border-bottom-1px-solid border-color-gray-200'>
            <div className='card-indicator cursor-pointer'>
                <input className='display-none subscriprions-input-radio' id={id} value={id} name='pool' type='radio' checked={pool === id} onChange={() => setPool(id)}/>
                <div className='subscriprions-input-radio-view'/>
            </div>
            <div className='display-flex align-items-center padding-left-1rem cursor-pointer'>
                <div className='width-2dot875rem height-2rem border-1px-solid border-color-gray-100 border-radius-0dot375rem display-flex'>
                    {cardIconComponent}
                </div>
                <div className={classNames('text-md padding-left-0dot75rem medium', {'color-gray-700': pool === id, 'color-gray-500': pool !== id})}>
                    <span className='text-transform-capitalize'>{card.cardType.toLowerCase()} </span>
                    <span>&#8226;&#8226;&#8226;&#8226; {cardPan}</span>
                </div>
            </div>
        </label>
    )
});

const PaymentMethod = React.memo(({setPool, pool, refreshedQueryOptions, refreshPendingPools, serviceId, setRedirect}) => {
    const {pooltickId} = useQuery();
    const {recurringPoolticksByViewer, poolsByViewer} = useLazyLoadQuery(
        graphql`
            query paymentMethodSyncPoolticksQuery($poolticksRequest: RecurringPoolticksRequestInput, $poolsRequest: PoolsRequestInput) {
                recurringPoolticksByViewer(request: $poolticksRequest) {
                    content {
                        id
                    }
                }
                poolsByViewer(request: $poolsRequest) {
                    content {
                        id
                        cardPan
                        cardType
                    }
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );

    return (
        <div className={classNames('border-1px-solid border-color-gray-200 border-radius-0dot5rem', {'mw768-border-none mw768-margin-left-minus1rem': !poolsByViewer, 'mw768-margin-top-1rem': poolsByViewer && poolsByViewer.content.length})}>
            <Watcher {...{refresh: refreshPendingPools}}/>
            {poolsByViewer && !!poolsByViewer.content.length && poolsByViewer.content.map((item, index) => (
                <CardView key={item.id} {...{card: item, loading: item.loading, setPool, pool, id: item.id}}/>
            ))}
            {pooltickId && !!recurringPoolticksByViewer.content.length && recurringPoolticksByViewer.content.some((item, index) => (item.id === pooltickId)) &&
                <LoadingCardView key={pooltickId} {...{id: pooltickId}}/>
            }
            <div>
                <NewPaymentMethodButton {...{serviceId, setRedirect}}/>
            </div>
        </div>
    )
});

const PaymentMethodContainer = React.memo(({setPool, pool, merchantId, serviceId, setRedirect}) => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {
            poolticksRequest: {
                size: 100500,
                page: 0,
                status: [0],
                merchantId: merchantId,
                fetchKey: 0
            },
            poolsRequest: {
                size: 100500,
                page: 0,
                merchantId: merchantId,
                fetchKey: 0
            }
        }
    });
    const [isPending, startTransition] = React.useTransition({timeoutMs: 5000});
    const refreshPendingPools = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    poolticksRequest: {
                        ...prev.variables.poolticksRequest,
                        fetchKey: prev.variables.poolticksRequest.fetchKey + 1
                    },
                    poolsRequest: {
                        ...prev.variables.poolsRequest,
                        fetchKey: prev.variables.poolsRequest.fetchKey + 1
                    }
                }
            }));
        });
    }, []);

    return (
        <PaymentMethod {...{setPool, pool, refreshedQueryOptions, refreshPendingPools, serviceId, setRedirect}}/>
    )
});

const PaymentContainer = ({setPool, pool, merchantId, serviceId, setRedirect}) => {
    return(
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <React.Suspense fallback={<Skeleton/>}>
                <PaymentMethodContainer {...{setPool, pool, merchantId, serviceId, setRedirect}}/>
            </React.Suspense>
        </ErrorBoundary>
    )
}

export default React.memo(PaymentContainer);
import React from 'react';
import {graphql, useMutation} from 'react-relay/hooks';
import qs from 'qs';
import {DialogDispatchContext} from '../../components/DialogContext';
import useQuery from '../../useQuery';
import {useErrorMessagesDispatch} from '../checkout/error-messages-context';
import useMountedState from '../../useMountedState';
import {submitForm} from '../../utility';
import {getIndependenceType} from '../../math';
import {PARTNER} from '../../constants';

const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;
const RAZZLE_APP_RETURN_URL = process.env.RAZZLE_APP_RETURN_URL;
const RAZZLE_APP_SERVER_CALLBACK_URL = process.env.RAZZLE_APP_SERVER_CALLBACK_URL;
const RAZZLE_APP_MASTERWASH_IMPULSE_EMAIL = process.env.RAZZLE_APP_MASTERWASH_IMPULSE_EMAIL;
const RAZZLE_APP_SERVICE_URL = process.env.RAZZLE_APP_SERVICE_URL;

const MasterWashAutoCheckout = ({children, serviceId, endpointId}) => {
    const {locale = 'uk'} = useQuery();
    const dialogDispatch = React.useContext(DialogDispatchContext);
    const [loading, setLoading] = React.useState(false);
    const [isPending, startTransition] = React.useTransition();
    const errorDispatch = useErrorMessagesDispatch();
    const [grecaptcha, setGrecaptcha] = useMountedState(false);
    const pool = '-1';
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation checkoutMasterWashPagesImpulseMutation($impulseRequest: ImpulseRequestInput!) {
                createImpulse(impulseRequest: $impulseRequest) {
                    id
                    orderDates
                    amount
                    impulseService {
                        service {
                            id
                            name
                        }
                        independenceType
                        merchantAccount
                        partnerCode
                        merchantDomainName
                        holdTimeout
                        manual
                        mrchntd
                    }
                    impulseEndpoints {
                        name
                        price
                        quantity
                    }
                    impulseClient {
                        email
                    }
                    feeAmount
                    amountAmount
                    orderReferences
                    merchantSignatures
                    claim
                }
            }
    `);

    const [sign, isSignInFlight] = useMutation(
        graphql`
            mutation checkoutMasterWashPagesFondySignMutation($request: FondySignRequestInput) {
                fondySign(request: $request) {
                    signature
                    orderId
                    reservationData
                }
            }
    `);

    const createImpulse = ({formValue, callback}) => {
        errorDispatch({type: 'reset', payload: []});
        if (!window.grecaptcha) {
            errorDispatch({type: 'add', payload: {errorCode: 'windowRecaptcha'}});
            return;
        }
        setGrecaptcha(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
                commit({
                    variables: {
                        impulseRequest: {
                            locale,
                            email: formValue.email,
                            reCaptcha: token,
                            service: serviceId,
                            impulseEndpoints: [{
                                endpoint: endpointId,
                                untie: {
                                    currency: 'uah',
                                    price: formValue.input
                                }
                            }]
                        }
                    },
                    onCompleted: (data) => {
                        startTransition(() => {
                            data.createImpulse.impulseService.manual &&
                                dialogDispatch({type: 'add', payload: {impulseId: data.createImpulse.id, claim: data.createImpulse.claim}});
                            callback({impulse: data.createImpulse});
                        })
                    },
                    onError: (error) => {
                        if (/reCaptcha/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'serverRecaptcha'}});
                        } else if (/Service is invalid/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'serviceInvalid'}});
                        } else if (/Endpoint invalid/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'endpointInvalid'}});
                        } else {
                            errorDispatch({type: 'add', payload: {errorCode: null}});
                        }
                        setLoading(false);
                    },
                    updater: store => {
                        const latestImpulses = store.get('client:root:viewer:__LatestImpulses_impulsesByViewer_connection(sent:true,status:[3,4])');
                        latestImpulses && latestImpulses.invalidateRecord();
                    }
                });
                setGrecaptcha(false);
            });
        });
    };

    const fondyPayFirstTime = ({impulse}) => {
        const payload = {
            merchant_id: impulse.impulseService.mrchntd,
            order_desc: impulse.impulseService.service.name,
            amount: impulse.amount.split('.').join(''),
            currency: 'UAH',
            ...(RAZZLE_APP_RETURN_URL && {response_url: `${RAZZLE_APP_RETURN_URL}?url=${window.location.origin}/checkout-new/vending/success?impulseId=${impulse.id}`}),
            ...(RAZZLE_APP_SERVER_CALLBACK_URL && {
                server_callback_url: RAZZLE_APP_SERVER_CALLBACK_URL + qs.stringify({upsert: false}, {addQueryPrefix: true})
            }),
            lifetime: 900, // this also means hold 7 working days for preauth: 'Y'
            preauth: 'Y',
            lang: locale,
            required_rectoken: 'N'
        };
        if (impulse.impulseClient.email !== RAZZLE_APP_MASTERWASH_IMPULSE_EMAIL) {
            payload['sender_email'] = impulse.impulseClient.email;
        }
        sign({
            variables: {request: {payload, claim: impulse.claim}},
            onCompleted: (data) => {
                submitForm('https://pay.fondy.eu/api/checkout/redirect/', {
                    ...payload,
                    signature: data.fondySign.signature,
                    order_id: data.fondySign.orderId,
                    reservation_data: data.fondySign.reservationData
                });
                setLoading(false);
            },
            onError: (error) => {
                errorDispatch({type: 'add', payload: {errorCode: 'fondy'}});
                setLoading(false);
            },
        });
    };

    const wayforpayPay = ({impulse}) => {
        new window.Wayforpay().run({
            orderDate: impulse.orderDates &&
            impulse.orderDates[impulse.orderDates.length - 1],
            currency: 'UAH',
            merchantTransactionType: 'AUTH',
            productName: 'SERVICE',
            amount: impulse.amount,
            productPrice: impulse.amount,
            productCount: 1,
            ...(getIndependenceType(impulse.impulseService.independenceType) === PARTNER && {
                partnerCode: [impulse.impulseService.merchantAccount, impulse.impulseService.partnerCode],
                partnerPrice: [impulse.feeAmount, impulse.amountAmount]
            }),
            makeRedirect: true,
            language: locale === 'uk' ? 'ua' : locale,
            orderReference: impulse.orderReferences &&
            impulse.orderReferences[impulse.orderReferences.length - 1],
            merchantAccount: impulse.impulseService.merchantAccount,
            merchantDomainName: impulse.impulseService.merchantDomainName,
            merchantSignature: impulse.merchantSignatures &&
            impulse.merchantSignatures[impulse.merchantSignatures.length - 1],
            orderTimeout: 900,
            orderLifetime: 900,
            holdTimeout: impulse.impulseService.holdTimeout || 900, // or 15 minutes
            clientEmail: impulse.impulseClient.email,
            paymentSystems: 'card;googlePay;applePay;masterPass;visaCheckout;privat24', // paymentSystems: systemToString[system],
            ...(pool !== '-1' && {recToken: pool}),
            ...(RAZZLE_APP_SERVICE_URL && {serviceUrl: RAZZLE_APP_SERVICE_URL + (pool === '-1' ? qs.stringify({upsert: false}, {addQueryPrefix: true}) : '')}),
            ...(RAZZLE_APP_RETURN_URL && {returnUrl: `${RAZZLE_APP_RETURN_URL}?url=${encodeURIComponent(`${window.location.origin}/checkout-new/vending/success?impulseId=${impulse.id}`)}`})
        });
        setLoading(false);
    };

    const pay = ({impulse}) => {
        if (impulse.impulseService.merchantAccount) {
            wayforpayPay({impulse});
        } else {
            fondyPayFirstTime({impulse});
        }
    };
    
    const autoCheckout = formValue => {
        setLoading(true);
        createImpulse({formValue, callback: pay});
    };
    return (
        <>
            {children({autoCheckout, loading})}
        </>
    )
}

export default React.memo(MasterWashAutoCheckout);
import React from 'react';
import { Link, useLocation} from 'react-router-dom';
import ArrowUpRightSvg from '../../../../assets/icons/arrow-up-right.svg';
import './top-projects.styles.scss';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import useQuery from '../../../../useQuery';
import i18n from '../../../../i18n';
import {ErrorBoundary} from 'react-error-boundary';
import SuspenseImageStaticSize from '../../../SuspenseImageStaticSize';
import moment from 'moment';
import Browser from '../../../Browser';
import ErrorFallback from '../../../ErrorFallback';
import getServiceUrl from '../../../../utilities/get-service-url';
import {getPosterUrl} from '../../../../utilities/get-tag-value';

export default React.memo(() => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query targetTop_projectsOgleRootComponentsQuery {
                closestTargetService {
                    id
                    slug
                    name
                    nameUk
                    details
                    detailsUk
                    start
                    end
                    strategy
                    every
                    warp
                    target
                    description
                }
            }
        `
    );
    const cover = React.useMemo(() => {
        const posterUrl = getPosterUrl(data.closestTargetService.description);
        if (posterUrl) {
            return posterUrl;
        } else if (data.closestTargetService && i18n(data.closestTargetService, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.closestTargetService, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data.closestTargetService, locale]);
    if (!data.closestTargetService) {
        return (
            <div className='top-projects-block display-flex'>
                <div className='top-projects-item top-projects-item--small'></div>
                <div className='top-projects-item'></div>
            </div>
        );
    }
    const serviceUrl = getServiceUrl({description: data.closestTargetService.description, id: data.closestTargetService.id, slug: data.closestTargetService.slug, warp: data.closestTargetService.warp, location});
    return (
        <>
            <div className='top-projects-block display-flex'>
                <div className='top-projects-item top-projects-item--small'></div>
                <div className='top-projects-item'>
                    <Link to={serviceUrl} className='display-block width-100percent height-100percent position-relative'>
                        {cover &&
                            <Browser>
                                <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                    <SuspenseImageStaticSize
                                        className='display-block width-100percent height-100percent object-fit-cover border-radius-0dot75rem'
                                        src={cover}
                                    />
                                </ErrorBoundary>
                            </Browser>
                        }
                        <div 
                            style={{
                                height: 189, 
                                background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%)'
                            }}
                            className='position-absolute bottom-0 width-100percent border-bottom-left-radius-0dot75rem border-bottom-right-radius-0dot75rem'
                        />
                        <div 
                            className='position-absolute bottom-0 width-100percent height-100percent display-flex flex-direction-column justify-content-flex-end border-radius-0dot75rem'
                        >
                            <div 
                                style={{borderTop: '1px solid rgba(255, 255, 255, 0.5)'}}
                                className='border-bottom-left-radius-0dot75rem border-bottom-right-radius-0dot75rem padding-top-1dot5rem padding-left-1dot25rem padding-right-1dot25rem padding-bottom-2rem backdrop-filter-blur-0dot75rem'
                            >
                                <div>
                                    <div className='display-flex justify-content-space-between'>
                                        <div className='text-2xl semibold color-white'>
                                            {i18n(data.closestTargetService, 'name', locale)}
                                        </div>
                                        <div className='width-1dot5rem height-1dot5rem display-flex justify-content-center align-items-center'>
                                            <ArrowUpRightSvg className='display-block width-0dot75rem height-0dot75rem color-gray-25'/>
                                        </div>
                                    </div>
                                    <div className='margin-top-0dot5rem text-md color-white'>
                                        {moment(data.closestTargetService.target).format('DD.MM.YYYY')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
});
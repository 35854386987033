import React from 'react';

const CompleteContainer = ({children}) => {
    return (
        <div className='checkout-content block'>
            <div className='padding-top-4rem mw768-padding-top-14rem checkout-view height-100vh display-flex flex-direction-column'>
                {children}
            </div>
        </div>
    )
};

export default React.memo(CompleteContainer);
import React from 'react';
import { useCartState } from '../contexts/cart';
import useQuery from '../useQuery';

const serviceIdEx = process.env.RAZZLE_APP_SERVICE_ID;

const useCartStateEx = (id) => {
    const {serviceId = serviceIdEx} = useQuery();
    const cartState = useCartState();
    let cs = [];
    if (id) {
        cs = React.useMemo(
            () =>  cartState.filter(e => e.serviceId === id),
            [cartState, id]
        );
    } else {
        cs = React.useMemo(
            () =>  cartState.filter(e => e.serviceId === serviceId),
            [cartState, serviceId]
        );
    }
    return cs;
};

export default useCartStateEx;
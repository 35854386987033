import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import classnames from 'classnames';
import i18n from '../../i18n';
import ChevronRightIcon from '../../assets/icons0/chevron-right.svg';
import useQuery from '../../useQuery';
import searchFilter from '../../searchFilter';

const Breadcrumbs = ({breadcrumbs}) => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    return (
        <div className='display-flex align-items-center flex-wrap-wrap margin-top-minus0dot5rem margin-right-minus0dot5rem'>
            {breadcrumbs.map((item, index) => (
                <div key={index} className='display-flex align-items-center text-sm mw768-text-md margin-top-0dot5rem'>
                    <div className={classnames('text-sm mw768-text-md', {'color-gray-500': index + 1 !== breadcrumbs.length, 'color-gray-700': index + 1 === breadcrumbs.length})}>
                        {item.route && <Link to={`${item.route}${searchFilter(location.search)}`}>{i18n(item, 'name', locale)}</Link>}
                        {!item.route && <span>{i18n(item, 'name', locale)}</span>}
                    </div>
                    {index + 1 !== breadcrumbs.length &&
                        <ChevronRightIcon className='display-block height-1rem width-1rem color-gray-600 margin-left-0dot5rem margin-right-0dot5rem'/>
                    }
                </div>
            ))}
        </div>
    )
};

export default React.memo(Breadcrumbs);
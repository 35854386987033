import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';
import ArrowUpRightSvg from '../../../../assets/icons/arrow-up-right.svg';
import './top-projects.styles.scss';
import Skeleton from '../../../Skeleton';
import Target from './target';
import searchFilter from '../../../../searchFilter';

const TopProjects = () => {
    const location = useLocation();
    return (
        <>
            <div className='top-projects-container display-flex'>
                <React.Suspense fallback={
                    <div className='top-projects-block display-flex'>
                        <div className='top-projects-item top-projects-item--small'></div>
                        <div className='top-projects-item'>
                            <div className='height-100percent display-flex flex-direction-column justify-content-flex-end'>
                                <div className='padding-top-1dot5rem padding-left-1dot25rem padding-right-1dot25rem padding-bottom-2rem'>
                                    <Skeleton/>
                                </div>
                            </div>
                        </div>
                    </div>
                }>
                    <Target/>
                </React.Suspense>
                {/* <div className='top-projects-block display-flex'>
                    <div className='top-projects-item top-projects-item--small'></div> */}
                    {/* <a target='_blank' href='https://e-aid.diia.gov.ua' rel='noopener noreferrer' className='top-projects-item top-projects-item--e-aid display-flex position-relative'>
                        <div className='top-projects-item__card align-self-flex-start display-flex'>
                            <p className='top-projects-item__card-title'><FormattedMessage defaultMessage='We accept eAid cards' /></p>
                            <img className='top-projects-item__card-img' style={{height: 110}} src={ImgPng} alt=''/>
                        </div>
                        <div className='top-projects-item__description'>
                            <p className='top-projects-item__title'><FormattedMessage defaultMessage='eAid' /></p>
                            <p className='top-projects-item__subtitle'><FormattedMessage defaultMessage='Details here' /></p>
                            <div className='top-projects-item__link'>
                                <ArrowUpRightSvg className='top-projects-item__link-img' />
                            </div>
                        </div>
                    </a> */}
                    {/* <Link className='top-projects-item top-projects-item--arsenal display-flex' to={`/services/arsenal${searchFilter(location.search)}`}>
                        <div className='top-projects-item__description'>
                            <p className='top-projects-item__title'>ARSENAL XXII</p>
                            <p className='top-projects-item__subtitle'>Cultural center</p>
                            <div className='top-projects-item__link'>
                                <ArrowUpRightSvg className='top-projects-item__link-img' />
                            </div>
                        </div>
                    </Link> */}
                {/* </div> */}
                <div className='top-projects-block display-flex'>
                    <Link className='top-projects-item top-projects-item--master-wash display-flex' to={`/master-wash/services/5f140f544c3f4efa759438ce${searchFilter(location.search)}`}>
                        <div className='top-projects-item__description'>
                            <p className='top-projects-item__title'>MasterWash</p>
                            <p className='top-projects-item__subtitle'><FormattedMessage defaultMessage='Self-service car wash' /></p>
                            <div className='top-projects-item__link'>
                                <ArrowUpRightSvg className='top-projects-item__link-img' />
                            </div>
                        </div>
                    </Link>
                    <div className='top-projects-item top-projects-item--small'></div>
                </div>
            </div>
        </>
    )
};

export default React.memo(TopProjects);
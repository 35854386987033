/**
 * @generated SignedSource<<d7f9d11521c2f3b357d0b999e7721c79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Impulse",
    "kind": "LinkedField",
    "name": "tryRecurringImpulse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentHistoryRowMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "paymentHistoryRowMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4478bea8b17707ae961b0c16953ad993",
    "id": null,
    "metadata": {},
    "name": "paymentHistoryRowMutation",
    "operationKind": "mutation",
    "text": "mutation paymentHistoryRowMutation(\n  $request: TryRecurringImpulseRequestInput!\n) {\n  tryRecurringImpulse(request: $request) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "f06103db71146574a25060cedb81249e";

module.exports = node;

/**
 * @generated SignedSource<<3e5d5e468a298750d3b49cb5c8a7b5f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "LandResponse",
    "kind": "LinkedField",
    "name": "landImpulse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Land",
        "kind": "LinkedField",
        "name": "lands",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "service",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PickEx",
            "kind": "LinkedField",
            "name": "pickExs",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "index",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Solve",
        "kind": "LinkedField",
        "name": "solves",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amountAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feeAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "serviceFeeAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "basis",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SolveCoupon",
            "kind": "LinkedField",
            "name": "coupon",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "discount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "couponRefetchCheckoutPagesQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "couponRefetchCheckoutPagesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5a94ab16f4252fab54b68cb0cbb051d5",
    "id": null,
    "metadata": {},
    "name": "couponRefetchCheckoutPagesQuery",
    "operationKind": "query",
    "text": "query couponRefetchCheckoutPagesQuery(\n  $request: LandRequestInput\n) {\n  landImpulse(request: $request) {\n    lands {\n      service\n      pickExs {\n        index\n      }\n    }\n    solves {\n      amount\n      amountAmount\n      feeAmount\n      serviceFeeAmount\n      basis\n      discountAmount\n      coupon {\n        code\n        discount\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "968f9c8b25664a3e58282a64ae3a4a86";

module.exports = node;

import React from 'react';
import {useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {RouterLinkButton} from '../../components/button/button';
import DotsVerticalIcon from '../../assets/icons0/dots-vertical.svg';
import LogOut from '../../assets/icons0/log-out-04.svg';
import searchFilter from '../../searchFilter';
import {graphqlWhoamiNextQuery} from '../../components/graphql';

const UserMenu = () => {
    const location = useLocation();
    const [dropDownVisible, setDropDownVisibility] = React.useState(false);
    const menuButtonRef = React.useRef();
    React.useEffect(() => {
        const onClickOutsideHandler = (event) => {
            if (dropDownVisible && !(
                menuButtonRef.current.contains(event.target)
            )) setDropDownVisibility(false);
        };
        document.addEventListener('click', onClickOutsideHandler, true);
        return () => {
            document.removeEventListener('click', onClickOutsideHandler, true);
        };
    }, [dropDownVisible, menuButtonRef]);

    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);

    return (
        <div className='display-flex justify-content-space-between border-bottom-1px-solid border-color-gray-200 padding-top-1rem padding-bottom-0dot875rem'>
            <p className='text-sm medium color-gray-700 mw768-text-md'>{whoamiNext.username || whoamiNext.email}</p>
            <div className='position-relative hide-large' ref={menuButtonRef}>
                <DotsVerticalIcon className='display-block color-gray-400 height-1dot25rem width-1dot25rem' onClick={() => setDropDownVisibility(true)}/>
                <div className={classNames('shadow-lg position-absolute width-max-content right-0 top-1dot75rem', {'display-block': dropDownVisible, 'display-none': !dropDownVisible})}>
                    <RouterLinkButton{...{color: 'secondary-gray', size: 'sm', iconPosition: 'left', to: `/log-out${searchFilter(location.search)}`}}>
                        <LogOut className='height-1dot25rem'/>
                        <FormattedMessage defaultMessage='Log out'/>
                    </RouterLinkButton>
                </div>
            </div>
            <div className='mxw767-hide'>
                <RouterLinkButton{...{color: 'pale', size: 'lg', iconPosition: 'left', plain: true, to: `/log-out${searchFilter(location.search)}`}}>
                    <LogOut className='height-1dot25rem'/>
                    <FormattedMessage defaultMessage='Log out'/>
                </RouterLinkButton>
            </div>
        </div>
    )
}

export default React.memo(UserMenu);
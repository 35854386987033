import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import i18n from '../../../i18n';
import useQuery from '../../../useQuery';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {ButtonIcon} from '../../../components/button-icon/button-icon.component';
import CartMinusCircle from '../../../assets/icons/cart-minus-circle.svg';
import CartAddCircle from '../../../assets/icons/cart-add-circle.svg';

const WarpOrderItem = ({endpointId, serviceById, decrementEndpoint, incrementEndpoint, cartEntry}) => {
    const {locale = 'uk'} = useQuery();
    const {endpointById} = useLazyLoadQuery(
        graphql`
            query orderItemEndpointByIdQuery($endpointId: String) {
                endpointById(id: $endpointId) {
                    id
                    name
                    price
                    nameUk
                }
            }
        `,
        {endpointId: endpointId}
    );
    const data = useLazyLoadQuery(
        graphql`
            query orderItemServiceByIdQuery($serviceId: String) {
                serviceById(id: $serviceId) {
                    id
                    name
                    target
                    nameUk
                }
            }
        `,
        {serviceId: serviceById.id}
    );
    return (
        <div className='warp-order-item padding-top-1rem padding-bottom-2rem background-color-white border-bottom-1px-solid border-color-gray-200'>
            <p className='text-md color-gray-700 mw768-text-lg'>{i18n(data.serviceById, 'name', locale)} | <span className='white-space-nowrap'>{i18n(endpointById, 'name', locale)}</span></p>
            {moment(data.serviceById.target).isValid() && <p className='text-sm color-gray-500 padding-top-0dot25rem'>{moment(data.serviceById.target).format('DD.MM.YYYY')}</p>}
            <div className='padding-top-0dot5rem padding-bottom-1rem display-flex align-items-center justify-content-space-between'>
                <div className='display-flex align-items-center'>
                    <div className='display-flex align-items-center'>
                        <ButtonIcon {...{color: 'primary', clickHandler: () => decrementEndpoint(cartEntry)}}>
                            <CartMinusCircle className='width-1dot25rem height-1dot25rem'/>
                        </ButtonIcon>
                        <p className='margin-left-0dot5rem margin-right-0dot5rem min-width-2rem text-align-center display-inline-block color-primary-600 text-md medium'>{cartEntry.quantity}</p>
                        <ButtonIcon {...{color: 'primary', clickHandler: () => incrementEndpoint(cartEntry)}}>
                            <CartAddCircle className='width-1dot25rem height-1dot25rem'/>
                        </ButtonIcon>
                    </div>
                </div>
                <p className='text-md color-gray-900 medium mw768-text-lg'>
                    {endpointById.price.toFixed(2)} <FormattedMessage defaultMessage='UAH' />
                </p>
            </div>
        </div>
    )
};

export default React.memo(WarpOrderItem);
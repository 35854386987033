import {DEFAULT_SERVICE_DESCRIPTION_SEPARATOR, DEFAULT_TAGS_SEPARATOR} from '../constants';
import {isNullOrUndefined} from '../utility';

const getTagValue = (tags, tagName, options = {separator: DEFAULT_TAGS_SEPARATOR, defaultValue: null}) => {
    const defaultValue = !isNullOrUndefined(options.defaultValue) ? options.defaultValue : null;
    const separator = options && options.separator || DEFAULT_TAGS_SEPARATOR;
    if (!tags) return defaultValue;
    const tag = tags.find(e => e.startsWith(tagName));
    if (!tag) return defaultValue;
    return tag.replace(`${tagName}${separator}`, '');
};

export const getServiceTagValue = (description, tagName, options = {separator: DEFAULT_TAGS_SEPARATOR, defaultValue: null, serviceSeparator: DEFAULT_SERVICE_DESCRIPTION_SEPARATOR}) => {
    const serviceSeparator = options && options.serviceSeparator || DEFAULT_SERVICE_DESCRIPTION_SEPARATOR;
    const tags = description && description.split(serviceSeparator) || [];
    return getTagValue(tags, tagName, options);
}

export const getPosterUrl = (description, options = {separator: DEFAULT_SERVICE_DESCRIPTION_SEPARATOR}) => {
    if (description) {
        const tags = description.split(options.separator);
        const posterUrl = getTagValue(tags, 'posterUrl');
        return posterUrl ? `https://${posterUrl}` : null;
    } else {
        return null;
    }
};

export default getTagValue;
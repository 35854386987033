/**
 * @generated SignedSource<<1c64aaf425c7c03277ab51d9892d4eea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MisterEndpoint_serviceById",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Service",
  "abstractKey": null
};

node.hash = "150f510618cb79eb4be8c860efebb791";

module.exports = node;

import React from 'react';
import {RouterLinkButton} from '../../../components/button/button';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import {useLocation} from 'react-router-dom';

const CompleteAction = () => {
    const location = useLocation();
    
    return (
        <div className='display-flex mw768-justify-content-center'>
            <div className='mxw767-flex-1 margin-right-0dot75rem'>
                <RouterLinkButton {...{color: 'secondary-gray', size: 'xl', mw768Size: 'xl-wide', fluid: 'mobile', to: `/${searchFilter(location.search)}`}} >
                    <FormattedMessage defaultMessage='To main page'/>
                </RouterLinkButton>
            </div>
            <div className='mxw767-flex-1'>
                <RouterLinkButton {...{color: 'primary-gradient', size: 'xl', mw768Size: 'xl-wide', fluid: 'mobile', to: `/cabinet${searchFilter(location.search)}`}} >
                    <FormattedMessage defaultMessage='To wallet'/>
                </RouterLinkButton>
            </div>
        </div>
    )
}

export default React.memo(CompleteAction);
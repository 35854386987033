import React from 'react';
import SignInRedirect from './sign-in-redirect';
import {useAuthorizationState} from '../contexts/Authorization';

const Private = ({children}) => {
    const authorized = useAuthorizationState();
    return (
        <>{authorized ? <>{children}</> : <>{<SignInRedirect/>}</>}</>
    );
}

export default React.memo(Private);
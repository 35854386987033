import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useCartState, useCartDispatch} from '../../../contexts/cart';
import ImpulsePage from '../impulse/Impulse';
import useQuery from '../../../useQuery';
import useCartStateEx from '../../../utilities/use-cart-state-ex';
import EmptyCartByServiceRedirect from '../empty-cart-by-service-redirect';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallback from '../../../components/ErrorFallback';
import Skeleton from '../../../components/Skeleton';

const TicketingImpulsePage = () => {
    const {impulseServiceId} = useQuery();
    const state = impulseServiceId ? useCartStateEx(impulseServiceId) : useCartState();
    const redirectToService = React.useMemo(() => {
        return state.length === 0 && !!impulseServiceId;
    }, [impulseServiceId]);
    const dispatch = useCartDispatch();
    const subtitle = <FormattedMessage defaultMessage='To reserve your order for 15 min. please enter your contact details and click the "Proceed with ordering" button'/>;
    const message = <FormattedMessage defaultMessage='The order will be sent to the specified email'/>;
    const showOrderItemQuantity = true;
    if (redirectToService) {
        return (
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='checkout-content block width-100percent margin-bottom-2rem'>
                        <div className='checkout-view display-flex flex-direction-column checkout-view--container padding-top-2rem'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                    <EmptyCartByServiceRedirect {...{serviceId: impulseServiceId}}/>
                </React.Suspense>
            </ErrorBoundary>
        )
    };

    return (
        <ImpulsePage {...{state, dispatch, subtitle, message, showOrderItemQuantity}}/>
    )
};

export default React.memo(TicketingImpulsePage);

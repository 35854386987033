import React from 'react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import i18n from '../../i18n';
import useQuery from '../../useQuery';
import Ticket01Icon from '../../assets/icons0/ticket-01.svg';
import Button from '../../components/button/button';
import {FormattedMessage} from 'react-intl';
import {minTwoDigits} from '../../utility';
import classNames from 'classnames';
import QrCode02Icon from '../../assets/icons0/qr-code-02.svg';
import CheckCircleBrokenIcon from '../../assets/icons0/check-circle-broken.svg';
import FlipBackwardIcon from '../../assets/icons0/flip-backward.svg';
import CheckIcon from '../../assets/icons0/check.svg';
import './impulse-view.scss';
import Badge from './badge';
import {useScanSettingsState} from './settings-context';
import {SCAN_MODE_DEFAULT} from '../../constants';
import PopUp from '../../components/pop-up/pop-up';
import ImpulseViewQrsPopup from './impulse-view-qrs-popup';
import ImpulseViewContacts from './impulse-view-contacts';
import {graphqlWhoamiNextQuery} from '../../components/graphql';
import {useCurrentScanDispatch, useCurrentScanState} from './current-scan-context';

const ScanIndicatorBasic = React.memo(({scanned}) => {
    return (
        <>
            {!scanned && <Ticket01Icon className='display-block width-1dot25rem height-1dot25rem color-success-600'/>}
            {scanned && <CheckCircleBrokenIcon className='display-block width-1dot25rem height-1dot25rem color-gray-600'/>}
        </>
    )
});

export const ScanIndicatorCheckMark = React.memo(({scanned}) => {
    return (
        <>
            {!scanned && <div className='display-block border-radius-50percent width-1dot25rem height-1dot25rem border-1px-solid border-color-gray-300 background-color-white'/>}
            {scanned && <CheckIcon className='display-block border-radius-50percent width-1dot25rem height-1dot25rem padding-0dot25rem color-white background-color-success-600'/>}
        </>
    )
});

const EndpointCard = ({endpoint, qrIndex, active, first, take, revertTake, applyingTake, applyingRevertTake, endpointIndex, scansAmount, maxValidScans = 0, showQrCode, children, index}) => {
    const {locale = 'uk'} = useQuery();
    const scanned = active ? scansAmount > maxValidScans : !!scansAmount;
    return (
        <div className={classNames('border-1px-solid background-color-white padding-1rem border-radius-0dot75rem display-flex align-items-flex-start', {'border-color-success-500': active && !scanned, 'border-color-error-500': active && scanned, 'border-color-gray-200': !active, 'margin-top-0dot75rem': !first})}>
            <div className='padding-0dot625rem background-color-gray-50 border-radius-0dot5rem box-shadow-xs cursor-pointer' onClick={() => showQrCode({qrIndex, endpointIndex})}>
                <QrCode02Icon className='display-block width-1dot25rem height-1dot25rem color-gray-600'/>
            </div>
            <div className='flex-1 padding-left-0dot75rem padding-ight-0dot25rem'>
                <p className='text-md medium color-gray-700 padding-bottom-0dot125rem'>{minTwoDigits(index + 1)} {i18n(endpoint, 'name', locale)}</p>
                <p className='text-md color-gray-500'>{endpoint.price} ₴</p>
            </div>
            <div className={classNames('cursor-pointer', {'opacity-0dot4 pointer-events-none': applyingTake || applyingRevertTake})} onClick={() => scanned ? revertTake({position: endpointIndex, index: qrIndex}) : take({position: endpointIndex, index: qrIndex})}>
                {children({scanned: !!scansAmount})}
            </div>
        </div>
    )
}

const ImpulseView = React.memo(({impulse, position, index, children, isPending}) => {
    const [showQrPopup, setShowQrPopup] = React.useState(false);
    const [showCheckInPopup, setShowCheckInPopup] = React.useState(false);
    const showCheckInConfirmation = React.useCallback(() => {
        setShowCheckInPopup(true);
    }, [setShowCheckInPopup]);
    const {mode, autoTake} = useScanSettingsState();
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const isMerchant = whoamiNext.id === impulse.merchant.id;
    const qrsArray = [];
    impulse.qrs.forEach((endpointQrs, endpointIndex) => {
        endpointQrs.forEach((qr, qrIndex) => {
            qrsArray.push({
                qr,
                qrIndex,
                endpointIndex,
                scansAmount: (impulse.impulseEndpoints[endpointIndex].numbers ? (impulse.impulseEndpoints[endpointIndex].numbers[qrIndex] || 0) : 0) + (impulse.number || 0)
            })
        });
    });
    const plantedQr = qrsArray.find(({endpointIndex, qrIndex}) => endpointIndex === position && index === qrIndex)
    const scansAmount = plantedQr.scansAmount;
    const maxValidScans = autoTake ? 1 : 0;
    const endpointsAmount = impulse.impulseEndpoints.reduce((amount, endpoint) => {
        return amount + endpoint.quantity;
    }, 0);
    const scannedEndpointsAmount = impulse.number ? endpointsAmount : qrsArray.reduce((amount, item) => {
        if (item.scansAmount > 0) {
            return amount + 1;
        } else {
            return amount;
        }
    }, 0);
    const [stubMutation, applyingStubMutation] = useMutation(
        graphql`
            mutation impulseViewStubMutation($id: String, $version: Int) {
                stubImpulse(id: $id, version: $version) {
                    id
                    version
                    number
                }
            }
    `);
    const stub = () => stubMutation({
        variables: {id: impulse.id, version: impulse.version},
        onCompleted: () => {},
        onError: () => {}
    });
    const refund = () => {
        console.log('refund');
    }
    const [takeMutation, applyingTake] = useMutation(
        graphql`
            mutation impulseViewTakeMutation($request: TakeImpulseRequestInput) {
                takeImpulse(request: $request) {
                    id
                    version
                    impulseEndpoints {
                        numbers
                    }
                }
            }
    `);
    const take = ({position, index}) => {
        if (isMerchant) {
            takeMutation({
                variables: { request: {
                    id: impulse.id,
                    version: impulse.version,
                    position: position,
                    index: index
                }},
                onCompleted: () => {
                    if (showCheckInPopup) {
                        setShowCheckInPopup(false);
                    }
                },
                updater: () => {
                },
                onError: () => {}
            });
        }
    }
    const [revertTakeMutation, applyingRevertTake] = useMutation(
        graphql`
            mutation impulseViewUpdateNumbersMutation($request: UpdateImpulseNumbersRequestInput) {
                updateImpulseNumbers(request: $request) {
                    id
                    version
                    impulseEndpoints {
                        numbers
                    }
                }
            }
    `);
    const revertTake = ({position, index}) => {
        if (isMerchant) {
            revertTakeMutation({
                variables: {request: {id: impulse.id, version: impulse.version, position, index, number: maxValidScans}},
                onCompleted: () => {},
                onError: () => {}
            });
        }
    };
    const showQrCode = ({qrIndex, endpointIndex}) => {
        setShowQrPopup({qrIndex, endpointIndex});
    }
    return (
        <>
            <div className={classNames('scan-impulse position-absolute width-100percent z-index-4 top-100percent', {'scan-impulse--hidden': isPending})}>
                {children({impulse, endpointsAmount, position, index, take, isTaking: applyingTake, scansAmount, isMerchant, qrsArray, showCheckInConfirmation})}
                <div className='padding-top-2rem padding-bottom-1dot25rem padding-left-1dot25rem padding-right-1dot25rem background-color-gray-50 display-flex border-bottom-0dot5rem-solid border-color-gray-200'>
                    <div className='flex-1 medium'>
                        <p className='text-xl color-gray-900 padding-bottom-0dot25rem'><FormattedMessage defaultMessage='Order'/></p>
                        <p className='text-md color-primary-600'>{impulse.id}</p>
                    </div>
                </div>
                <div className='padding-top-1dot25rem padding-left-1dot25rem padding-right-1dot25rem background-color-gray-50'>
                    <div className='display-flex'>
                        <div className='display-flex flex-1 align-items-center padding-right-0dot75rem'>
                            <p className='text-lg medium color-gray-900 padding-right-0dot5rem'>
                                <FormattedMessage defaultMessage='Tickets'/>
                            </p>
                            <Badge {...{classes: 'color-success-600 background-color-success-50 text-sm medium'}}>
                                {scannedEndpointsAmount}/{endpointsAmount}
                            </Badge>
                        </div>
                        {isMerchant && 
                            <div className=''>
                                <Button {...{color: 'secondary-gray', size: 'md', clickHandler: stub, additionalClasses: 'check-in-all-btn', isLoading: applyingStubMutation}}>
                                    <FormattedMessage defaultMessage='Check in all'/>
                                </Button>
                            </div>
                        }
                    </div>
                </div>
                <div className='padding-top-1rem padding-bottom-1dot25rem padding-left-1dot25rem padding-right-1dot25rem background-color-gray-50'>
                    {qrsArray.map(({endpointIndex, qrIndex, scansAmount}, i) => 
                        <EndpointCard key={i} {...{endpoint: impulse.impulseEndpoints[endpointIndex], endpointIndex, qrIndex, active: qrIndex === index && endpointIndex === position, first: i === 0, take, revertTake, scansAmount, maxValidScans, impulse, showQrCode, index: i}}>
                            {({scanned}) =>
                                <>
                                    {mode === SCAN_MODE_DEFAULT && <ScanIndicatorBasic {...{scanned}}/>}
                                    {mode !== SCAN_MODE_DEFAULT && <ScanIndicatorCheckMark {...{scanned}}/>}
                                </>
                            }
                        </EndpointCard>
                    )}
                </div>
                <ImpulseViewContacts {...{email: impulse.impulseClient.email, addls: impulse.impulseAddls, impulseId: impulse.id, isMerchant}}/>
                <div className='padding-top-1dot5rem padding-bottom-1dot5rem display-flex justify-content-center background-color-white'>
                    <Button {...{plain: true, color: 'error', size: 'lg', clickHandler: refund}}>
                        <div className='display-flex padding-top-0dot625rem padding-bottom-0dot625rem padding-left-1dot125rem padding-right-1dot125rem'>
                            <FlipBackwardIcon className='display-block width-1dot25rem height-1dot25rem color-error-700 margin-right-0dot5rem'/>
                            <FormattedMessage defaultMessage='Refund the ticket'/>
                        </div>
                    </Button>
                </div>
            </div>
            <PopUp {...{isVisible: !!showQrPopup}}>
               {!!showQrPopup && <ImpulseViewQrsPopup {...{qrsArray, activeQr: showQrPopup, impulse, closeHandler: () => setShowQrPopup(false), take, revertTake, applyingTake, applyingRevertTake}}/>}
            </PopUp>
            <PopUp {...{isVisible: !!showCheckInPopup, mode: 'default', icon: 'alert-triangle', title: <FormattedMessage defaultMessage='Repeated check-in'/>, message: <FormattedMessage defaultMessage='Are you sure you need to do another check-in of the ticket?'/>}}>
                <div className='flex-1'>
                    <Button {...{clickHandler: () => take({position, index}), size: 'lg', color: 'primary-gradient', fluid: 'always'}}>
                        <FormattedMessage defaultMessage='Check-in'/>
                    </Button>
                </div>
                <div className='flex-1 mw768-margin-right-0dot75rem'>
                    <Button {...{color: 'secondary-gray', size: 'lg', fluid: 'always', clickHandler: () => setShowCheckInPopup(false)}}>
                        <FormattedMessage defaultMessage='Cancel'/>
                    </Button>
                </div>
            </PopUp>
        </>
    )
});

const CurrentImpulseView = React.memo(({children, isPending}) => {
    const {impulseId, position, index} = useCurrentScanState();
    const {impulseById} = useLazyLoadQuery(
        graphql`
            query impulseViewImpulseByIdQuery($id: String) {
                impulseById(id: $id) {
                    id
                    impulseClient {
                        email
                    }
                    impulseAddls {
                        addl {
                            id
                        }
                        nameUk
                        name
                        value
                    }
                    impulseService {
                        name
                        nameUk
                        demo
                        service {
                            target
                        }
                    }
                    impulseEndpoints {
                        name
                        nameUk
                        price
                        quantity
                        numbers
                    }
                    status
                    version
                    number
                    qrs
                    merchant {
                        id
                    }
                }
            }
        `,
        {id: impulseId}
    );

    return (
        <ImpulseView {...{children, isPending, impulse: impulseById, position, index}}>
            {children}
        </ImpulseView>
    )
});

const PlantView = React.memo(({payload, fetchKey, children, isPending, setHasError}) => {
    const {autoTake} = useScanSettingsState();
    const currentStateDispatch = useCurrentScanDispatch();
    const data = useLazyLoadQuery(
        graphql`
            query impulseViewImpulseQuery($request: PlantRequestInput) {
                plantImpulse(request: $request) {
                    position
                    index
                    impulse {
                        id
                        impulseClient {
                            email
                        }
                        impulseAddls {
                            addl {
                                id
                            }
                            nameUk
                            name
                            value
                        }
                        impulseService {
                            name
                            nameUk
                            demo
                            service {
                                target
                            }
                        }
                        impulseEndpoints {
                            name
                            nameUk
                            price
                            quantity
                            numbers
                        }
                        status
                        version
                        number
                        qrs
                        merchant {
                            id
                        }
                    }
                }
            }
        `,
        {request: {value: payload, auto: autoTake}},
        {fetchKey, fetchPolicy: 'network-only'}
        );
    const impulse = data.plantImpulse.impulse;
    const position = data.plantImpulse.position;
    const index = data.plantImpulse.index;
    
    React.useEffect( () => {
        currentStateDispatch({type: 'update', payload: {position, impulseId: impulse.id, index}});
    }, []);
    return (
        <ImpulseView {...{children, isPending, impulse, position, index}}>
            {children}
        </ImpulseView>
    )
});

const ScanImpulseView = ({payload, fetchKey, children, isPending, setHasError}) => {
    const currentScan = useCurrentScanState();
    return (
        <>
            {!payload && !!currentScan.impulseId && <CurrentImpulseView {...{children, isPending}}>{children}</CurrentImpulseView>}
            {!!payload && <PlantView {...{payload: payload.replace('plant://', ''), fetchKey, children, isPending, setHasError}}>{children}</PlantView>}
        </>
    )
}

export default React.memo(ScanImpulseView);
import React from 'react';

const RulesEn = () => {
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-1dot5rem font-weight-bold'>
                ЗГОДА НА ОБРОБКУ ПЕРСОНАЛЬНИХ ДАНИХ
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                Повідомлення про права
            </div>
            <div className='margin-top-1rem'>
                На виконання вимог Закону України «Про захист персональних даних» повідомляємо, що персональні дані про Вас стають частиною баз персональних даних Товариства з обмеженою відповідальністю «Оттрі» (ідентифікаційний код юридичної особи: 41385243),  котре здійснює свою діяльність відповідно до законодавства України і зареєстроване за адресою : 04050, М. Київ, вул. Глибочицька, 16, офіс 13 (далі - Компанія). Доступ до бази персональних даних ТОВ «Оттрі» має як Компанія, так і Підприємство (суб'єкт підприємницької діяльності) на web-сайті якого через Онлайн-сервіс «Оттрі» здійснюється підприємницька діяльність. 
            </div>
            <div className='margin-top-1rem'>
                Метою отримання Ваших персональних даних є необхідність їх обробки і зберігання для здійснення підприємницької діяльності Компанії за допомогою модуля бронювання, котрий розміщений на web-сайті Підприємства. 
            </div>
            <div className='margin-top-1rem'>
                Повідомляємо, що Закон України «Про захист персональних даних» наділяє правами, що дозволяють Вам:
            </div>
            <div className='margin-top-1rem'>
                1) знати про місцезнаходження бази персональних даних Компанії, яка містить персональні дані про Вас, її призначення та найменування, місцезнаходження Компанії або розпорядника цієї бази, або дати відповідне доручення щодо отримання цієї інформації уповноваженим Вами особам, крім випадків, встановлених законом;
            </div>
            <div className='margin-top-1rem'>
                2) отримувати інформацію про умови надання доступу до персональних даних, зокрема інформацію про третіх осіб, яким передаються Ваші персональні дані;
            </div>
            <div className='margin-top-1rem'>
                3) на доступ до Ваших персональних даних;
            </div>
            <div className='margin-top-1rem'>
                4) отримувати, не пізніше ніж за тридцять календарних днів з дня надходження запиту, крім випадків, передбачених законом, відповідь про те, що Ваші персональні дані обробляються, а також отримувати зміст Ваших персональних даних; 
            </div>
            <div className='margin-top-1rem'>
                5) пред'являти вмотивовану вимогу із запереченням проти обробки Ваших персональних даних;
            </div>
            <div className='margin-top-1rem'>
                6) пред'являти вмотивовану вимогу щодо зміни або знищення Ваших персональних даних Компанією, якщо ці дані обробляються незаконно чи є недостовірними;
            </div>
            <div className='margin-top-1rem'>
                7) захищати Ваші персональні дані від незаконної обробки та випадкової втрати, знищення, пошкодження у зв'язку з умисним приховуванням, ненаданням чи несвоєчасним їх наданням, а також захищати від надання відомостей, що є недостовірними чи ганьблять Вашу честь, гідність та ділову репутацію;
            </div>
            <div className='margin-top-1rem'>
                8) звертатися з питань захисту своїх прав персональних даних до Уповноваженого Верховної Ради з прав людини або до суду;
            </div>
            <div className='margin-top-1rem'>
                9) застосовувати засоби правового захисту в разі порушення законодавства про захист персональних даних.
            </div>
            <div className='margin-top-1rem'>
                10) вносити застереження про обмеження права на обробку своїх персональних даних при наданні згоди;
            </div>
            <div className='margin-top-1rem'>
                11) відкликати згоду на обробку персональних даних;
            </div>
            <div className='margin-top-1rem'>
                12) знати механізм автоматичної обробки персональних даних;
            </div>
            <div className='margin-top-1rem'>
                13) на захист від автоматизованого рішення, яке має правові наслідки.
            </div>
            <div className='margin-top-1rem'>
                Вважаємо за необхідне повідомити Вам, що персональні дані про Вас можуть бути передані відповідним органам або особам з підстав, передбачених законом, і / або в зв'язку з реалізацією прав і обов'язків у сфері відносин між нами або сферах інших відносин, що складаються відповідно до чинного законодавства і / або умовами договорів, укладених відповідно до закону, інших угод, і зокрема
            </div>
            <div className='margin-top-1rem'>
                - Податковим органам;
            </div>
            <div className='margin-top-1rem'>
                - Органам і суб'єктам господарювання, які проводять або проводитимуть перевірки Компанії або реалізовувати вимоги закону або умови договору, укладеного з Компанією відповідно до чинного законодавства;
            </div>
            <div className='margin-top-1rem'>
                - Іншим особам або органам у сфері реалізації Ваших прав та обов'язків як споживача, і компанії, як власників бази, в яку входять Ваші персональні дані.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                Згода
            </div>
            <div className='margin-top-1rem'>
                Підписанням даної угоди Я надаю згоду на обробку моїх персональних даних відповідно до сформульованої в цьому документі метою Товариству з обмеженою відповідальністю «Оттрі» (далі - Компанія).
            </div>
            <div className='margin-top-1rem'>
                До складу персональних даних, одержуваних при підписанні даного документа входять: прізвище, ім'я, по батькові, контактний телефон, адреса електронної пошти та інші дані.
            </div>
            <div className='margin-top-1rem'>
                Підписанням цього документа я підтверджую і гарантую, що:
            </div>
            <div className='margin-top-1rem'>
                - Персональні дані про мене були надані Компанії добровільно, чим підтверджую правомірність їх отримання; персональні дані про мене достовірні, а їх склад, обсяг і зміст відповідають вимогам законодавства та вищезазначеної мети їх обробки;
            </div>
            <div className='margin-top-1rem'>
                - Компанія має право здійснювати транскордонну передачу персональних даних про мене своїм дочірнім і афілійованим компаніям і партнерам відповідно до зазначеної в документі метою без необхідності повідомлення мене про кожний випадок таких дій;
            </div>
            <div className='margin-top-1rem'>
                - Компанія має право відповідно до вимог чинного законодавства передавати розпоряднику (-ам) бази персональних даних право на обробку персональних даних про мене, що входять в ці бази персональних даних, в межах певної мети;
            </div>
            <div className='margin-top-1rem'>
                - Підтверджую отримання в дату підписання цього документа письмового повідомлення про мої права, мету збору та обробки персональних даних про мене, а також інформацію про осіб, яким можуть бути передані мої особисті дані для обробки;
            </div>
            <div className='margin-top-1rem'>
                - Компанія має право зберігати персональні дані про мене безстроково від дати отримання від мене згоди, з метою обробки та формування відповідної бази даних, якщо інше не буде витікати з вимог чинного законодавства та / або специфіки відносин між мною і компанією.
            </div>
        </div>
    );
};

export default React.memo(RulesEn);
import React from 'react';
import '../../../scan/Scan.scss';
import useMountedState from '../../../../useMountedState';
import {useLocation} from 'react-router-dom';
import searchFilter from '../../../../searchFilter';
import classNames from 'classnames';
import useQuery from '../../../../useQuery';
import {FormattedMessage} from 'react-intl';
import {isTrue} from '../../../../utility';
import Manager from '../../../scan/Manager';
import {v4} from 'uuid';

let QrReader;

const More = React.memo(() => {
    const location = useLocation();
    const {mode} = useQuery();
    return (
        <div className='padding-top-1rem border-top-1px-dotted-black'>
            <div className='font-weight-bold font-size-1dot4rem margin-bottom-1rem'>
                <FormattedMessage defaultMessage='Settings'/>
            </div>
            <div>
                <a
                    className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {
                    'font-size-1dot2rem': mode === 'auto'
                    })}
                    href={`${location.pathname}${searchFilter(location.search, {mode: 'auto'}, false)}`}
                >Yes</a>
                <a
                    className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {
                    'font-size-1dot2rem': !mode
                    })}
                    href={`${location.pathname}${searchFilter(location.search, {mode: null}, false)}`}
                >No</a>
                {/*<Link*/}
                    {/*className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {*/}
                        {/*'font-size-1dot2rem': mode === 'auto'*/}
                    {/*})}*/}
                    {/*to={`${location.pathname}${searchFilter(location.search, {mode: 'auto'}, false)}`}*/}
                {/*>Yes</Link>*/}
                {/*<Link*/}
                    {/*className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {*/}
                        {/*'font-size-1dot2rem': !mode*/}
                    {/*})}*/}
                    {/*to={`${location.pathname}${searchFilter(location.search, {mode: null}, false)}`}*/}
                {/*>No</Link>*/}
                <FormattedMessage defaultMessage='Auto take impulses'/>
            </div>
        </div>
    );
});

const Scan = React.memo(() => {
    const location = useLocation();
    const {locale = 'uk', more} = useQuery();
    const [client, setClient] = React.useState(false);
    React.useEffect(() => {
        // https://github.com/vercel/next.js/issues/219#issuecomment-258966784
        QrReader = require('react-qr-reader');
        setClient(true);
    }, []);
    const [spin, setSpin] = useMountedState(true);
    const [item, setItem] = useMountedState({count: 0, payload: null});
    const valueRef = React.useRef();
    const onScan = React.useCallback((e) => {
        if (!e) return;
        if (valueRef.current === e) {
            setItem(h => ({...h, count: h.count + 1})); // Same content counter
        } else {
            valueRef.current = e;
            const fetchKey = v4();
            setItem(f => ({...f, count: 0, payload: e, fetchKey}));
        }
    }, []);
    return (
        <div className='scan'>
            {spin && <div className='margin-top-1rem'>Loading...</div>}
            {client &&
            <div className='margin-top-1rem'>
                <div className='display-flex'>
                    <div className='flex-1'/>
                    <div className='scan__qr-reader flex-6'>
                        <QrReader
                            // delay={1000}
                            onLoad={() => setSpin(false)}
                            onError={() => {}}
                            onScan={onScan}
                            showViewFinder={false}
                        />
                    </div>
                    <div className='flex-1 display-flex justify-content-center margin-top-1rem'>
                        {!!item.count && <div><span className='badge'>{item.count}</span></div>}
                    </div>
                </div>
                <div className='margin-top-1rem'>
                    <div className='font-weight-bold font-size-1dot4rem margin-bottom-1rem'>
                        <FormattedMessage defaultMessage='Results'/>
                    </div>
                    <Manager {...item}/>
                    {/*<Manager count={item.count} payload='http://127.0.0.1:3000/endpoints/5e6157e6cfb13dd43f995b28?serviceId=5f5b469003913a3de85e3a8d'/>*/}
                    {/*<Manager count={item.count} payload='plant://eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI2MGExMTI0ZDViOWUzMzQ1ZDJlZjNmYWYuMC4wIn0.LXatYQCZXmFXFGLomWvtItZ_fAn1VF6SJFLgUPdH2lP31ZCLkoFbYnhf9nbjYvnkA6Xdrf4sZEZPbPxsmpFoKQ'/>*/}
                </div>
                {isTrue(more) && <div className='margin-top-1rem'><More/></div>}
            </div>
            }
        </div>
    );
});

export default Scan;
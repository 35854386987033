import React from 'react'
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

const Amount = ({localCartState, deferred, classes}) => {
    const request = React.useMemo(() => ({
        picks: localCartState.map((e) => ({endpoint: e.endpointId, service: e.serviceId, quantity: e.quantity}))
    }), [localCartState]);
    const {landImpulse} = useLazyLoadQuery(
        graphql`
            query amountLandImpulseQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        pickExs {
                            index
                        }
                    }
                    solves {
                        amount
                    }
                }
            }
        `,
        {request}
    );
    return (
        <span className={classNames(classes, {'opacity-0dot4 pointer-events-none' : deferred})}>
            {landImpulse && landImpulse.solves[0] ? landImpulse.solves[0].amount : '0.00'} <FormattedMessage defaultMessage='UAH'/>
        </span>
    )
}

export default React.memo(Amount);
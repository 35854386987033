import React from 'react';
import useHydrated from '../useHydrated';
import useHydratedRef from '../useHydratedRef';

const ex = (value) => value !== null && value !== undefined;

const imgCache = {
    __cache: {},
    read(src) {
        if (!ex(this.__cache[src])) {
            this.__cache[src] = new Promise((resolve) => {
                const img = new Image();
                img.onload = () => {
                    this.__cache[src] = true;
                    resolve();
                };
                img.onerror = () => {
                    this.__cache[src] = false;
                    resolve();
                };
                img.src = src;
            });
        }
        if (this.__cache[src] instanceof Promise) {
            throw this.__cache[src];
        }
        if (!this.__cache[src]) {
            throw new Error();
        }
        return this.__cache[src];
    }
};

export default React.memo((props) => {
    const {src} = props;
    const hydrated = useHydrated();
    const hydratedRef = useHydratedRef();
    if (src && hydrated && hydratedRef.current === 2) imgCache.read(src);
    return (
        <img {...props}/>
    );
});
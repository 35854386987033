import React from 'react';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {graphqlWhoamiNextQuery} from '../../components/graphql';
import useSubscription from '../../useSubscription';
import useSubscriptionStatus from '../../useSubscriptionStatus';

const Watcher = ({refresh}) => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const payload = React.useMemo(() => ({
        channel: `/merchant/${whoamiNext.id}/poolticks`,
        onNext: () => refresh(),
        onError: (error) => {}
    }), [refresh]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    return null;
};

export default React.memo(Watcher);
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {useLocation} from 'react-router-dom';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {queryParameters} from '../../../qs';
import {ErrorBoundary} from 'react-error-boundary';
import searchUnFilter from '../../../searchUnFilter';
import UserContactForm from './UserContactForm';
import {graphqlWhoamiNextQuery} from '../../../components/graphql';
import ErrorFallback from '../../../components/ErrorFallback';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const UserDetails = ({registerFormField, serviceId, formState, setValue}) => {
    const location = useLocation();
    const qps = queryParameters(location);
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    React.useEffect(() => {
        setValue('email', whoamiNext.email || whoamiNext.username)
    }, [whoamiNext]);

    return (
        <div>
            <p className='color-gray-900 text-lg medium padding-top-1dot5rem mw768-padding-top-2rem'>
                <FormattedMessage defaultMessage='Account'/>
            </p>
            <div className='color-gray-700 text-md padding-bottom-1dot5rem display-flex justify-content-space-between'>
                <p>{whoamiNext.username || whoamiNext.email}</p>
                <a className='color-gray-500 medium cursor-pointer' onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                        window.location.href = RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&new=true&continue='
                            + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
                            + (qps.length ? '&' + qps.join('&') : '');
                    }, 500);
                }}><FormattedMessage defaultMessage='Change'/></a>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <UserContactForm {...{serviceId, registerFormField, formState}}/>
            </ErrorBoundary>
        </div>
    )
};

export default React.memo(UserDetails);

import React from 'react';
import './Shell.scss';
import {Outlet} from 'react-router-dom';
import User from './User';
import MenuButton from '../menu/MenuButton';
import ProductsButton from '../products/Button';
import MenuSmall from './menu/MenuSmall';
import MenuLarge from './menu/MenuLarge';
import ProductsSmall from '../products/Small';
import ProductsLarge from '../products/Large';
import {Link, useLocation} from 'react-router-dom';
import Footer from './Footer';
import CartMetaLink from './CartMetaLink';
import {ErrorBoundary} from 'react-error-boundary';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../searchFilter';
import ErrorFallback from '../ErrorFallback';

const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';

function Shell() {
    const location = useLocation();
    const [menuFlag, setMenuFlag] = React.useState(false);
    const [productsFlag, setProductsFlag] = React.useState(false);
    const closeMenu = () => setMenuFlag((flag) => !flag);
    const toggleProducts = () => setProductsFlag((flag) => !flag);
    const menuButtonRef = React.useRef();
    const menuLargeRef = React.useRef();
    const menuSmallRef = React.useRef();
    const productsButtonRef = React.useRef();
    const productsLargeRef = React.useRef();
    const productsSmallRef = React.useRef();
    React.useEffect(() => {
        const onClickOutsideHandler = (event) => {
            if (menuFlag && !(
                menuButtonRef.current.contains(event.target)
                || menuLargeRef.current.contains(event.target)
                || menuSmallRef.current.contains(event.target)
            )) setMenuFlag(false);
            if (productsFlag && !(
                productsButtonRef.current.contains(event.target)
                || productsLargeRef.current.contains(event.target)
                || productsSmallRef.current.contains(event.target)
            )) setProductsFlag(false);
        };
        document.addEventListener('click', onClickOutsideHandler, true);
        return () => {
            document.removeEventListener('click', onClickOutsideHandler, true);
        };
    }, [menuFlag, menuButtonRef, menuLargeRef, menuSmallRef, productsFlag, productsButtonRef, productsLargeRef, productsSmallRef]);
    return (
        <div className='shell'>
            <div className={classNames('shell__promo', {
                'sticky': productsFlag
            })}>
                <ProductsButton {...{reference: productsButtonRef, flag: productsFlag, toggle: toggleProducts}}/>
                &nbsp;|&nbsp;
                <a 
                    href={formLink} 
                    target='_blank' 
                    rel='noopener noreferrer'
                ><FormattedMessage defaultMessage='I want to sell online'/></a>
            </div>
            <ProductsLarge {...{reference: productsLargeRef, flag: productsFlag, toggle: toggleProducts}}/>
            <ProductsSmall {...{reference: productsSmallRef, flag: productsFlag, toggle: toggleProducts}}/>
            <div className={classNames('shell__header-divider', {
                'sticky': menuFlag,
                'hide-small': productsFlag
            })}>
                <div className='shell__header'>
                    <div className='shell__menu-button'>
                        <MenuButton {...{menuButtonRef, menuFlag, closeMenu}}/>
                    </div>
                    <div className='shell__logo'>
                        <Link
                            to={`/${searchFilter(location.search)}`}
                        >Ottry</Link>
                    </div>
                    <div className='shell__cart'><CartMetaLink/></div>
                    <div className='shell__user'><User/></div>
                </div>
            </div>
            <MenuLarge {...{menuLargeRef, menuFlag, closeMenu}}/>
            <MenuSmall {...{menuSmallRef, menuFlag, closeMenu}}/>
            <div className={classNames({'hide-small': menuFlag || productsFlag})}>
                <div className='view'>
                    <div className='block'>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <React.Suspense fallback={'Loading...'}>
                                <Outlet/>
                            </React.Suspense>
                        </ErrorBoundary>
                    </div>
                </div>
            </div>
            <div className={classNames({'hide-small': menuFlag || productsFlag})}>
                <Footer/>
            </div>
        </div>
    );
}

export default React.memo(Shell);
import React from "react";

// ref - swipe container ref
// showNext - show next slider item callback
// showPrevious - show previous slider item callback
// sensitivity - amount of pixels needed to recognise it as swipe


const useSwipe = ({ref, showNext, showPrevious, sensitivity = 150}) => {
    const touchCoordinates = React.useRef({startX: 0, endX: 0});
    const showNextAction = React.useRef();
    const showPreviousAction = React.useRef();
    showNextAction.current = showNext;
    showPreviousAction.current = showPrevious;

    const handleTouchStart = (e) => {
        touchCoordinates.current = {startX: e.targetTouches[0].clientX, endX: e.targetTouches[0].clientX};
    }

    const handleTouchMove = (e) => {
        touchCoordinates.current = {...touchCoordinates.current, endX: e.targetTouches[0].clientX};
    }
    
    const leftSwipeAction = () => {
        if (touchCoordinates.current.startX - touchCoordinates.current.endX > sensitivity && showNextAction.current) {
            showNextAction.current();
        }
    }

    const rightSwipeAction= () => {
        if (touchCoordinates.current.startX - touchCoordinates.current.endX < 0 - sensitivity && showPreviousAction.current) {
            showPreviousAction.current();
        }
    }

    const handleTouchEnd = () => {
        leftSwipeAction();
        rightSwipeAction();
    }

    React.useEffect(() => {
        if (ref && ref.current) {
            ref.current.addEventListener("touchstart", handleTouchStart);
            ref.current.addEventListener("touchmove", handleTouchMove);
            ref.current.addEventListener("touchend", handleTouchEnd);
            return () => {
                if (ref && ref.current) {
                    ref.current.removeEventListener("touchstart", handleTouchStart);
                    ref.current.removeEventListener("touchmove", handleTouchMove);
                    ref.current.removeEventListener("touchend", handleTouchEnd);
                }
            };
        }
    }, [ref]);
    return null;
}

export default useSwipe;
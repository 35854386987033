import React from 'react';
import './Scan.scss';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import EndpointImpl from '../root/EndpointImpl';
import EndpointName from '../root/EndpointName';
import {IN, ENTITY_TYPE} from '../../constants';
import useNow from '../../useNow';
import {adopt} from '../../math';
import Mini from '../root/mini/Mini';
import ErrorFallback from '../ErrorFallback';

const EndpointAdapter = React.memo(({id, serviceId}) => {
    const data = useLazyLoadQuery(
        graphql`
            query EndpointScanImplQuery($id: String) {
                endpointById(id: $id) {
                    id
                    start
                    end
                    strategy
                    every
                    ...EndpointName_endpoint
                    ...EndpointImpl_endpoint
                }
            }
        `,
        {id}
    );
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, data.endpointById.start, data.endpointById.end, data.endpointById.strategy, data.endpointById.every),
        [now, data.endpointById]);
    return (
        <>
        <div className='font-weight-bold'>
            <EndpointName {...{endpoint: data.endpointById}}/>
        </div>
        <Mini {...{
            start: data.endpointById.start,
            end: data.endpointById.end,
            strategy: data.endpointById.strategy,
            every: data.endpointById.every,
            entityType: ENTITY_TYPE.endpoint
        }}/>
        {state === IN &&
        <EndpointImpl {...{endpoint: data.endpointById, serviceId}}/>
        }
        </>
    );
});

export default React.memo(({id, serviceId}) => {
    return (
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<Skeleton/>}>
                <EndpointAdapter {...{id, serviceId}}/>
            </React.Suspense>
        </ErrorBoundary>
    );
});
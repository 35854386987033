import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import moment from 'moment';
import {FormattedMessage} from 'react-intl';
import ButtonIcon from '../../../components/button-icon/button-icon.component';
import CartTrash from '../../../assets/icons/cart-trash.svg';
import CartAddCircle from '../../../assets/icons/cart-add-circle.svg';
import CartMinusCircle from '../../../assets/icons/cart-minus-circle.svg';
import useQuery from '../../../useQuery';
import {CartDispatchContext} from '../../../contexts/cart';
import i18n from '../../../i18n';
import splitPrice from '../splitPrice';

import './order-item.scss';

const OrderItem = ({entry: {quantity, key, endpointId, serviceId, untie}, deleteItemAction}) => {
    const {locale = 'uk'} = useQuery();
    const dispatch = React.useContext(CartDispatchContext);

    const data = useLazyLoadQuery(
        graphql`
            query orderItemCartPagesQuery($endpointId: String, $serviceId: String) {
                endpointById(id: $endpointId) {
                    id
                    name
                    price
                    nameUk
                }
                serviceById(id: $serviceId) {
                    id
                    name
                    nameUk
                    target
                }
            }
        `,
        {endpointId: endpointId, serviceId: serviceId}
    );

    const itemAction = (type) => {
        return () => {
            dispatch({type: type, payload: key});
        }
    };

    return (
        <div className='order-item padding-1dot5rem background-color-white border-radius-0dot75rem'>
            <p className='text-lg color-gray-900 medium padding-bottom-0dot5rem'>{i18n(data.serviceById, 'name', locale)} | {i18n(data.endpointById, 'name', locale)}</p>
            <p className='text-md color-gray-500'>{moment(data.serviceById.target).isValid() ? moment(data.serviceById.target).format('DD.MM.YYYY') : ''}</p>
            <div className='padding-top-1rem display-flex align-items-center'>
                {!!untie && 
                <>
                <p className='text-lg color-gray-700 medium flex-1'>{i18n(data.endpointById, 'name', locale)} {data.endpointById.price || (untie && untie.price)}</p>
                <div className='display-flex align-items-center order-item__actions'>
                    <ButtonIcon {...{color: 'error', clickHandler: () => deleteItemAction({type: 'remove', payload: key})}}>
                        <CartTrash className='width-100percent'/>
                    </ButtonIcon>
                </div>
                </>
                }
                {!untie &&
                    <>
                        <p className='text-lg color-gray-900 medium flex-1'>
                            <span>
                                <span>{splitPrice(data.endpointById.price.toString()).whole}</span>
                                {splitPrice(data.endpointById.price.toString()).fraction && 
                                    <>
                                        <span>.</span>
                                        <span className='text-xs medium'>{splitPrice(data.endpointById.price.toString()).fraction}</span>
                                    </>
                                }
                                <span>{' '}</span>
                            </span>
                            <FormattedMessage defaultMessage='UAH' />
                        </p>
                        <div className='display-flex align-items-center'>
                            <ButtonIcon {...{color: 'error', clickHandler: () => deleteItemAction({type: 'remove', payload: key})}}>
                                <CartTrash className='width-100percent'/>
                            </ButtonIcon>
                            <div className='padding-0dot4375rem border-radius-0dot5rem margin-left-1rem display-flex align-items-center border-1px-solid border-color-gray-100'>
                                <ButtonIcon {...{clickHandler: itemAction('decrement'), disabled: quantity < 2}}>
                                    <CartMinusCircle className='width-100percent'/>
                                </ButtonIcon>
                                <p className='margin-left-0dot5rem margin-right-0dot5rem min-width-2rem text-align-center display-inline-block color-gray-700 text-md'>{quantity}</p>
                                <ButtonIcon {...{clickHandler: itemAction('increment')}}>
                                    <CartAddCircle className='width-100percent'/>
                                </ButtonIcon>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
};

export default React.memo(OrderItem);
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import qs from 'qs';
import useQuery from '../../../useQuery';
import searchFilter from '../../../searchFilter';
import {ErrorBoundary} from 'react-error-boundary';
import Button from '../../../components/button/button';
import Order from '../impulse/Order';
import OrderItem from '../impulse/order-item';
import BillingInfo from './BillingInfo';
import {submitForm} from '../../../utility';
import {getIndependenceType} from '../../../math';
import {PARTNER} from '../../../constants';
import UserInfo from './UserInfo';
import MailDark from '../../../assets/icons/mail-dark.svg';
import {useErrorMessagesDispatch} from '../error-messages-context';
import {useAuthorizationState} from '../../../contexts/Authorization';
import ErrorFallback from '../../../components/ErrorFallback';
import AuthorizationError from '../../../components/AuthorizationError';

const RAZZLE_APP_SERVICE_URL = process.env.RAZZLE_APP_SERVICE_URL;
const RAZZLE_APP_RETURN_URL = process.env.RAZZLE_APP_RETURN_URL;
const RAZZLE_APP_SERVER_CALLBACK_URL = process.env.RAZZLE_APP_SERVER_CALLBACK_URL;
const RAZZLE_APP_SERVER_URL = process.env.RAZZLE_APP_SERVER_URL;

const Impulse = ({claim, successVerticalPath, subtitle, orderMessage, showOrderItemQuantity}) => {
    const {locale = 'uk'} = useQuery();
    const authorized = useAuthorizationState();
    const {register, watch, setValue} = useForm({defaultValues: {pool: '-1'}});
    const pool = watch('pool');
    const upsert = watch('upsert');
    const navigate = useNavigate();
    React.useEffect(() => {
        if (pool !== '-1') {
            setValue('upsert', false);
        }
    }, [pool]);
    const [isLoading, setLoading] = React.useState(false);
    const errorDispatch = useErrorMessagesDispatch();

    const {impulseByClaim} = useLazyLoadQuery(
        graphql`
            query ImpulseByClaimPaymentCheckoutPagesQuery($claimRequest: ClaimRequestInput) {
                impulseByClaim(request: $claimRequest) {
                    id
                    orderDates
                    amount
                    impulseService {
                        service {
                            id
                            name
                            nameUk
                            target
                        }
                        independenceType
                        merchantAccount
                        partnerCode
                        merchantDomainName
                        holdTimeout
                        mrchntd
                        lqpPublic
                    }
                    impulseAddls {
                        nameUk
                        name
                        value
                    }
                    impulseEndpoints {
                        name
                        price
                        quantity
                        nameUk
                    }
                    impulseClient {
                        email
                    }
                    impulseCoupon {
                        discount
                    }
                    serviceFeeAmount
                    feeAmount
                    amountAmount
                    orderReferences
                    merchantSignatures
                    basis
                    discountAmount
                }
            }
        `,
        {claimRequest: {value: claim}}
    );

    const [sign, isSignInFlight] = useMutation(
        graphql`
            mutation ImpulseSignPaymentCheckoutPagesMutation($request: FondySignRequestInput) {
                fondySign(request: $request) {
                    signature
                    orderId
                    reservationData
                }
            }
    `);
    const [liqPaySign, isLiqPaySignInFlight] = useMutation(
        graphql`
            mutation ImpulseLiqPaySignPaymentCheckoutPagesMutation($request: LiqPaySignRequestInput) {
                liqPaySign(request: $request) {
                    signature
                    data
                }
            }
    `);
    const [recurring, isRecurringInFlight] = useMutation(
        graphql`
            mutation ImpulseRecurringPaymentCheckoutPagesMutation($request: FondyRecurringRequestInput) {
                fondyRecurring(request: $request) {
                    response {
                        responseStatus
                    }
                }
            }
    `);

    const [liqPayRecurring, isLiqPayRecurringInFlight] = useMutation(
        graphql`
            mutation ImpulseLiqPayRecurringPaymentCheckoutPagesMutation($request: LiqPayRecurringRequestInput) {
                liqPayRecurring(request: $request) {
                    signature
                    data
                }
            }
    `);

    const wayforpayPay = () => {
        new window.Wayforpay().run({
            orderDate: impulseByClaim.orderDates &&
            impulseByClaim.orderDates[impulseByClaim.orderDates.length - 1],
            currency: 'UAH',
            merchantTransactionType: 'AUTH',
            productName: 'SERVICE',
            amount: impulseByClaim.amount,
            productPrice: impulseByClaim.amount,
            productCount: 1,
            ...(getIndependenceType(impulseByClaim.impulseService.independenceType) === PARTNER && {
                partnerCode: [impulseByClaim.impulseService.merchantAccount, impulseByClaim.impulseService.partnerCode],
                partnerPrice: [impulseByClaim.feeAmount, impulseByClaim.amountAmount]
            }),
            makeRedirect: true,
            language: locale === 'uk' ? 'ua' : locale,
            orderReference: impulseByClaim.orderReferences &&
            impulseByClaim.orderReferences[impulseByClaim.orderReferences.length - 1],
            merchantAccount: impulseByClaim.impulseService.merchantAccount,
            merchantDomainName: impulseByClaim.impulseService.merchantDomainName,
            merchantSignature: impulseByClaim.merchantSignatures &&
            impulseByClaim.merchantSignatures[impulseByClaim.merchantSignatures.length - 1],
            orderTimeout: 900,
            orderLifetime: 900,
            holdTimeout: impulseByClaim.impulseService.holdTimeout || 900, // or 15 minutes
            clientEmail: impulseByClaim.impulseClient.email,
            paymentSystems: 'card;googlePay;applePay;masterPass;visaCheckout;privat24;payPartsMono:3,6,12;payPartsPrivat:3,6,12;payPartsOschad:3,6,12;payPartsAbank:3,6,12', // paymentSystems: systemToString[system],
            ...(pool !== '-1' && {recToken: pool}),
            ...(RAZZLE_APP_SERVICE_URL && {serviceUrl: RAZZLE_APP_SERVICE_URL + (pool === '-1' ? qs.stringify({upsert}, {addQueryPrefix: true}) : '')}),
            ...(RAZZLE_APP_RETURN_URL && {returnUrl: `${RAZZLE_APP_RETURN_URL}?url=${window.location.origin}/checkout-new/${successVerticalPath}/success?impulseId=${impulseByClaim.id}`})
        });
        setLoading(false);
    };

    const fondyPayFirstTime = () => {
        const payload = {
            merchant_id: impulseByClaim.impulseService.mrchntd,
            order_desc: impulseByClaim.impulseService.service.name,
            amount: impulseByClaim.amount.split('.').join(''),
            currency: 'UAH',
            ...(RAZZLE_APP_RETURN_URL && {response_url: `${RAZZLE_APP_RETURN_URL}?url=${window.location.origin}/checkout-new/${successVerticalPath}/success?impulseId=${impulseByClaim.id}`}),
            ...(RAZZLE_APP_SERVER_CALLBACK_URL && {
                server_callback_url: RAZZLE_APP_SERVER_CALLBACK_URL + qs.stringify({upsert}, {addQueryPrefix: true})
            }),
            lifetime: 900, // this also means hold 7 working days for preauth: 'Y'
            preauth: 'Y',
            lang: locale,
            sender_email: impulseByClaim.impulseClient.email,
            required_rectoken: upsert ? 'Y' : 'N'
        };
        sign({
            variables: {request: {payload, claim: claim}},
            onCompleted: (data) => {
                submitForm('https://pay.fondy.eu/api/checkout/redirect/', {
                    ...payload,
                    signature: data.fondySign.signature,
                    order_id: data.fondySign.orderId,
                    reservation_data: data.fondySign.reservationData
                });
                setLoading(false);
            },
            onError: (error) => {
                errorDispatch({type: 'add', payload: {errorCode: 'fondy'}});
                setLoading(false);
            },
        });
    };

    const liqPayFirstTime = () => {
        const payload = {
            result_url: `${window.location.origin}/checkout-new/${successVerticalPath}/success?impulseId=${impulseByClaim.id}`,
            ...(RAZZLE_APP_SERVER_URL && {server_url: RAZZLE_APP_SERVER_URL + qs.stringify({upsert}, {addQueryPrefix: true})}),
            language: locale,
            recurringbytoken: upsert ? '1' : '0'
        };
        liqPaySign({
            variables: {request: {payload, claim: claim}},
            onCompleted: (data) => {
                submitForm('https://www.liqpay.ua/api/3/checkout/', {
                    data: data.liqPaySign.data,
                    signature: data.liqPaySign.signature
                });
                setLoading(false);
            },
            onError: (error) => {
                errorDispatch({type: 'add', payload: {errorCode: 'lqp'}});
                setLoading(false);
            },
        });
    };

    const fondyPayRecurring = () => {
        const payload = {
            merchant_id: impulseByClaim.impulseService.mrchntd,
            order_desc: impulseByClaim.impulseService.service.name,
            amount: impulseByClaim.amount.split('.').join(''),
            currency: 'UAH',
            server_callback_url: 'https://a0.ottry.com/api/impulses/callback5',
            preauth: 'Y',
            sender_email: impulseByClaim.impulseClient.email,
            rectoken: pool
        };
        recurring({
            variables: {request: {payload, claim: claim}},
            onCompleted: () => {
                navigate(`/checkout-new/${successVerticalPath}/success${searchFilter(location.search, {impulseId: impulseByClaim.id})}`);
                setLoading(false);
            },
            onError: (error) => {
                errorDispatch({type: 'add', payload: {errorCode: 'fondy'}});
                setLoading(false);
            },
        });
    };

    const liqPayR = () => {
        const payload = {
            ...(RAZZLE_APP_SERVER_URL && {server_url: RAZZLE_APP_SERVER_URL + qs.stringify({upsert}, {addQueryPrefix: true})}),
            language: locale,
            card_token: pool
        };
        liqPayRecurring({
            variables: {request: {payload, claim: claim}},
            onCompleted: () => {
                navigate(`/checkout-new/${successVerticalPath}/success${searchFilter(location.search, {impulseId: impulseByClaim.id})}`);
                setLoading(false);
            },
            onError: (error) => {
                errorDispatch({type: 'add', payload: {errorCode: 'lqp'}});
                setLoading(false);
            },
        });
    }

    const pay = () => {
        setLoading(true);
        errorDispatch({type: 'reset', payload: []});
        if (impulseByClaim.impulseService.lqpPublic) {
            ;
            pool === '-1' ? liqPayFirstTime() : liqPayR();
        } else if (impulseByClaim.impulseService.merchantAccount) {
            wayforpayPay();
        } else {
            pool === '-1' ? fondyPayFirstTime() : fondyPayRecurring();
        }
    }

    return (
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <ErrorBoundary {...{FallbackComponent: AuthorizationError}}>
                <div className='checkout-content__shadow-block block-width z-index-1 padding-top-2rem mw768-padding-top-2dot5rem'>
                    <h1 className='text-header semibold color-gray-900'>
                        <FormattedMessage defaultMessage='Ordering'/>
                    </h1>
                    {subtitle &&
                        <p className='text-md color-gray-500 padding-top-0dot75rem'>
                            {subtitle}
                        </p>
                    }
                    <div className='padding-bottom-2rem'>
                        <Order {...{total: impulseByClaim.amount, amount: impulseByClaim.amountAmount, serviceFee: impulseByClaim.serviceFeeAmount, coupon: impulseByClaim.impulseCoupon, basis: impulseByClaim.basis, discountAmount: impulseByClaim.discountAmount}}>
                            {impulseByClaim.impulseEndpoints.map((item, index) =>
                                <OrderItem {...{key: index, ...item, service: impulseByClaim.impulseService.service, showOrderItemQuantity}}/>
                            )}
                        </Order>
                    </div>
                </div>
                <div className='checkout-content__message display-flex block-width align-items-center justify-content-center background-color-gray-50'>
                    <div>
                        <MailDark className='display-block'/>
                    </div>
                    {orderMessage && <p className='text-md color-gray-600 padding-left-0dot75rem'>{orderMessage}</p>}
                </div>
                <div className='padding-bottom-2rem mw768-padding-bottom-2dot5rem'>
                    <p className='text-2xl semibold color-gray-900 padding-top-2rem mw768-padding-top-2dot5rem padding-bottom-1dot5rem mw768-padding-bottom-2rem'>
                        <FormattedMessage defaultMessage='Contact details'/>
                    </p>
                    <UserInfo {...{email: impulseByClaim.impulseClient.email, addls: impulseByClaim.impulseAddls}}/>
                </div>
                {authorized && 
                    <div className='padding-bottom-2rem mw768-padding-bottom-2dot5rem'>
                        <BillingInfo {...{setValue, register, pool, merchantAccount: impulseByClaim.impulseService.merchantAccount, merchantId: impulseByClaim.impulseService.mrchntd, lqpPublic: impulseByClaim.impulseService.lqpPublic}}/>
                    </div>
                }
                <div className='checkout-footer padding-bottom-3dot5rem mw768-padding-bottom-7dot5rem'>
                    <Button {...{fluid: 'always', color: 'primary-gradient', size: 'lg', clickHandler: () => pay(), isLoading}}> 
                        <FormattedMessage defaultMessage='Proceed to payment'/>
                    </Button>
                </div>
            </ErrorBoundary>
        </ErrorBoundary>
    )
};

export default React.memo(Impulse);
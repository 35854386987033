import { useEffect, useRef } from 'react';

const extractClientId = (credentialResponse) => credentialResponse?.clientId ?? credentialResponse?.client_id;

export default function useGoogleOneTapLogin({
  onSuccess,
  onError,
  promptMomentNotification,
  cancel_on_tap_outside,
  prompt_parent_id,
  state_cookie_domain,
  hosted_domain,
  disabled,
}) {
  const clientId = '34767676289-1p9subiftl43qk7ptos4qufoe2d9bj61.apps.googleusercontent.com';
  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;
  const onErrorRef = useRef(onError);
  onErrorRef.current = onError;
  const promptMomentNotificationRef = useRef(promptMomentNotification);
  promptMomentNotificationRef.current = promptMomentNotification;
  useEffect(() => {
    if (disabled) {
      window?.google?.accounts?.id?.cancel();
      return;
    }
    window?.google?.accounts?.id?.initialize({
      client_id: clientId,
      callback: (credentialResponse) => {
        if (!credentialResponse?.credential) {
          return onErrorRef.current?.();
        }
        const {credential, select_by} = credentialResponse;
        onSuccessRef.current({
          credential,
          clientId: extractClientId(credentialResponse),
          select_by,
        });
      },
      hosted_domain,
      cancel_on_tap_outside,
      prompt_parent_id,
      state_cookie_domain,
    });
    window?.google?.accounts?.id?.prompt(promptMomentNotificationRef.current);
    return () => {
      window?.google?.accounts?.id?.cancel();
    };
  }, [
    clientId,
    cancel_on_tap_outside,
    prompt_parent_id,
    state_cookie_domain,
    hosted_domain,
    disabled,
  ]);
}
import React from 'react';
import './Scan.scss';
import useMountedState from '../../useMountedState';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../searchFilter';
import classNames from 'classnames';
import useQuery from '../../useQuery';
import {FormattedMessage} from 'react-intl';
import Lol from '../Lol';
import {isTrue} from '../../utility';
import Manager from './Manager';
import {v4} from 'uuid';
import {QrReader} from 'react-qr-reader';
import getLocaleQueryParams from '../../utilities/get-locale-query-params';

const legal = 'https://www.ottry.com/legal';
const year = new Date().getFullYear();

const ViewFinder = () => {
    return (
        <div className=''>
            <div className='position-absolute' style={{top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>Loading...</div>
        </div>
    )
}

const More = React.memo(() => {
    const location = useLocation();
    const {mode} = useQuery();
    return (
        <div className='padding-top-1rem border-top-1px-dotted-black'>
            <div className='font-weight-bold font-size-1dot4rem margin-bottom-1rem'>
                <FormattedMessage defaultMessage='Settings'/>
            </div>
            <div>
                <a
                    className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {
                    'font-size-1dot2rem': mode === 'auto'
                    })}
                    href={`${location.pathname}${searchFilter(location.search, {mode: 'auto'}, false)}`}
                >Yes</a>
                <a
                    className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {
                    'font-size-1dot2rem': !mode
                    })}
                    href={`${location.pathname}${searchFilter(location.search, {mode: null}, false)}`}
                >No</a>
                {/*<Link*/}
                    {/*className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {*/}
                        {/*'font-size-1dot2rem': mode === 'auto'*/}
                    {/*})}*/}
                    {/*to={`${location.pathname}${searchFilter(location.search, {mode: 'auto'}, false)}`}*/}
                {/*>Yes</Link>*/}
                {/*<Link*/}
                    {/*className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {*/}
                        {/*'font-size-1dot2rem': !mode*/}
                    {/*})}*/}
                    {/*to={`${location.pathname}${searchFilter(location.search, {mode: null}, false)}`}*/}
                {/*>No</Link>*/}
                <FormattedMessage defaultMessage='Auto take impulses'/>
            </div>
        </div>
    );
});

const Scan = React.memo(() => {
    const location = useLocation();
    const {locale = 'uk', more} = useQuery();
    const [client, setClient] = React.useState(true);
    const [spin, setSpin] = useMountedState(false);
    const [item, setItem] = useMountedState({count: 0, payload: null});
    const valueRef = React.useRef();
    const onScan = React.useCallback((e) => {
        if (!e) return;
        if (valueRef.current === e.text) {
            setItem(h => ({...h, count: h.count + 1})); // Same content counter
        } else {
            valueRef.current = e.text;
            // ++fetchKey won't work as we could have cached 0-3 and on leave and return to the page we will
            // get 0-3 cached state.
            // Component here is not unmounted it is mounted all the time and will
            // reuse cache all the time.
            const fetchKey = v4();
            setItem(f => ({...f, count: 0, payload: e.text, fetchKey}));
        }
    }, []);
    return (
        <div className='scan'>
            <div className='border-bottom-1px-dotted-black'>
                <div className='display-flex align-items-center margin-right-1rem'>
                    <div className='scan__logo display-flex flex-1 align-items-baseline'>
                        <Link
                            to={`.${searchFilter(location.search)}`}
                        ><span className='scan__accent font-weight-bold'>Scan</span> at Ottry</Link>
                    </div>
                    <div>
                        <Link
                            className='focus font-weight-bold'
                            to={`/${searchFilter(location.search)}`}
                        ><FormattedMessage defaultMessage='Marketplace'/></Link>
                    </div>
                    <div className='scan__search display-flex justify-content-center'>
                        <Link
                            className='link font-weight-bold'
                            to={`/cabinet/impulses${searchFilter(location.search)}`}
                        ><FormattedMessage defaultMessage='Search'/></Link>
                    </div>
                </div>
            </div>
            <div className='view'>
                <div className='block'>
                    {spin && <div className='margin-top-1rem'>Loading...</div>}
                    {client &&
                    <div className='margin-top-1rem'>
                        <div className='display-flex'>
                            <div className='flex-1'/>
                            <div className='scan__qr-reader flex-6'>
                                <QrReader
                                    // delay={1000}
                                    onResult={onScan}
                                    showViewFinder={false}
                                    ViewFinder={ViewFinder}
                                    constraints={{ facingMode: 'environment' }}
                                />
                            </div>
                            <div className='flex-1 display-flex justify-content-center margin-top-1rem'>
                                {!!item.count && <div><span className='badge'>{item.count}</span></div>}
                            </div>
                        </div>
                        <div className='margin-top-1rem'>
                            <div className='font-weight-bold font-size-1dot4rem margin-bottom-1rem'>
                                <FormattedMessage defaultMessage='Results'/>
                            </div>
                            <Manager {...item}/>
                            {/*<Manager count={item.count} payload='http://127.0.0.1:3000/endpoints/5e6157e6cfb13dd43f995b28?serviceId=5f5b469003913a3de85e3a8d'/>*/}
                            {/*<Manager count={item.count} payload='plant://eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiI2MGExMTI0ZDViOWUzMzQ1ZDJlZjNmYWYuMC4wIn0.LXatYQCZXmFXFGLomWvtItZ_fAn1VF6SJFLgUPdH2lP31ZCLkoFbYnhf9nbjYvnkA6Xdrf4sZEZPbPxsmpFoKQ'/>*/}
                        </div>
                        {isTrue(more) && <div className='margin-top-1rem'><More/></div>}
                    </div>
                    }
                </div>
            </div>
            <div className='margin-top-1dot5rem'><Lol/></div>
            <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                <a
                    className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                    href={`${location.pathname}${getLocaleQueryParams(location.search, 'uk')}`}
                >Uk</a>
                {' '}
                <a
                    className={classNames('link', 'font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                    href={`${location.pathname}${getLocaleQueryParams(location.search, 'en')}`}
                >En</a>
            </div>
            <div className='font-size-0dot8rem margin-bottom-1rem'>
                <div className='scan__footer-content'>
                    <div className='scan__footer-info'>
                    <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                        <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                        <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                    </div>
                </div>
                <div className='scan__footer-rights'>
                    © {year} Ottry. All rights reserved.
                </div>
            </div>
        </div>
    );
});

export default Scan;
import React from 'react';
import {FormattedMessage} from 'react-intl';
import XCloseSvg from '../../../assets/icons0/x-close.svg';
import CornerDownLeftSvg from '../../../assets/icons0/corner-down-left.svg';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import {isNullOrUndefined, isSet} from '../../../utility';
import useMountedState from '../../../useMountedState';
import {fetchQuery, graphql, useRelayEnvironment} from 'react-relay/hooks';
import useQuery from '../../../useQuery';
import './coupon.scss';

const Coupon = ({coupon, setCode, picks, isRefreshing, setIsRefreshing}) => {
    const [flag, setFlag] = React.useState();
    const {landId = 0} = useQuery();
    const {register, handleSubmit, formState: {errors}, reset, setFocus, setError} = useForm();
    const submit = handleSubmit(data => refetch(data.input));
    const [isError, setIsError] = useMountedState();
    const environment = useRelayEnvironment();
    const refetch = React.useCallback((code) => {
        if (isRefreshing) 
            return;
        setIsRefreshing(true);
        setIsError(false);
        const variables = {request: {picks, landMetas: [{index: landId, code}]}};
        fetchQuery(
            environment,  
            graphql`
                query couponRefetchCheckoutPagesQuery($request: LandRequestInput) {
                    landImpulse(request: $request) {
                        lands {
                            service
                            pickExs {
                                index
                            }
                        }
                        solves {
                            amount
                            amountAmount
                            feeAmount
                            serviceFeeAmount
                            basis
                            discountAmount
                            coupon {
                                code
                                discount
                            }
                        }
                    }
                }
            `, 
            variables)
        .subscribe({
            complete: () => {
                setIsRefreshing(false);
                setIsError(false);
                setCode(code);
            },
            error: () => {
                setIsRefreshing(false);
                setIsError(true);
            }
        });
    }, [isRefreshing, setIsRefreshing, environment, setCode, setIsError, landId, picks]);
    React.useEffect(() => {
        flag && setFocus('input');
    }, [setFocus, flag]);
    React.useEffect(() => {
        isError && setError('input', {type: 'custom'});
    }, [setError, isError]);
    
    return (
        <div className='coupon'>
            <div className='form'>
                <div className={classNames({
                    'padding-top-1dot25rem padding-bottom-1dot25rem': isSet(coupon) || setCode
                })}>
                    <div className='display-flex align-items-center justify-content-space-between'>
                        {isNullOrUndefined(coupon) ?
                        <>
                            {setCode &&
                                <>
                                <div className='text-sm medium color-gray-700 height-2dot5rem display-flex align-items-center'><FormattedMessage defaultMessage='Coupon'/></div>
                                <div 
                                    style={{boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'}}
                                    className={classNames('border-radius-0dot5rem padding-top-0dot5rem padding-bottom-0dot5rem display-flex justify-content-center align-items-center cursor-pointer border-1px-solid border-color-gray-300 padding-left-0dot875rem padding-right-0dot875rem', {
                                        'display-none': flag
                                    })}
                                    onClick={() => {
                                        setFlag((flag) => !flag);
                                        reset();
                                    }}
                                >
                                    <div className='text-sm medium color-gray-700'><FormattedMessage defaultMessage='Add'/></div>
                                </div>
                                <div
                                    className={classNames('padding-0dot5rem cursor-pointer', {
                                        'display-none': !flag
                                    })}
                                    onClick={() => {
                                        setFlag((flag) => !flag);
                                        setCode();
                                        setIsError();
                                    }}
                                >
                                <XCloseSvg className='display-block height-1dot25rem width-1dot25rem color-gray-500'/>
                                </div>
                                </>
                            }
                        </>
                        :
                        <>
                        <div className='text-sm medium color-gray-700 height-2dot5rem display-flex align-items-center'><FormattedMessage defaultMessage='Coupon'/></div>
                        <div className='display-flex padding-0dot25rem border-radius-1rem background-color-indigo-50'>
                            <div className='text-sm medium color-indigo-700 padding-left-0dot5rem'>
                                -{coupon.discount}%
                            </div>
                            {setCode &&
                            <div 
                                style={{boxShadow: '0px 1px 2px 0px #1018281A'}}
                                className='cursor-pointer width-1dot25rem height-1dot25rem background-color-white border-radius-1rem margin-left-0dot5rem display-flex justify-content-center align-items-center'
                                onClick={() => {
                                    setFlag((flag) => !flag);
                                    setCode();
                                }}
                            >
                                <XCloseSvg className='display-block height-0dot75rem width-0dot75rem color-indigo-500'/>
                            </div>
                            }
                        </div>
                        </>
                        }
                    </div>
                    {flag && isNullOrUndefined(coupon) &&
                    <div className='padding-top-1rem display-flex justify-content-space-between'>
                        <div className='flex-1 padding-right-0dot75rem'>
                            <form onSubmit={submit}>
                                <div
                                    className={classNames('border-1px-solid border-color-gray-300 border-radius-0dot5rem input', {'errors': errors.input})}
                                >
                                    <div className='flex-1 padding-left-0dot875rem padding-top-0dot625rem padding-bottom-0dot625rem'>
                                        <input
                                            className='width-100percent text-md color-gray-500'
                                            placeholder='Промокод'
                                            {...register('input', {required: true})}
                                        />
                                    </div>
                                </div>
                                {errors.input  && errors.input.type === 'required' &&  <div className='color-error-500 margin-top-0dot375rem text-sm'><FormattedMessage defaultMessage='This field is required'/></div>}
                                {errors.input  && errors.input.type === 'custom' && <div className='color-error-500 margin-top-0dot375rem text-sm'><FormattedMessage defaultMessage='Coupon is invalid'/></div>}
                            </form>
                        </div>
                        <div>
                            <div
                                style={{
                                    background: 'linear-gradient(90deg, #475467 0%, #667085 100%)',
                                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                                }}
                                className='border-1px-solid border-color-transparent border-radius-0dot5rem padding-0dot75rem cursor-pointer'
                                onClick={() => submit()}
                            >
                                <CornerDownLeftSvg className='display-block height-1dot25rem width-1dot25rem color-white'/>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                {(isSet(coupon) || setCode) &&
                <div className='border-bottom-1px-solid border-bottom-color-gray-200'/>
                }
            </div>
        </div>
    );
};

export default React.memo(Coupon);
import React from 'react';
import useQuery from '../../useQuery';
import Skeleton from '../../components/Skeleton';
import EffectRedirect from '../../components/EffectRedirect';
import {useInstantDispatch} from '../../contexts/instant';
import searchFilter from '../../searchFilter';
import {useLocation} from 'react-router-dom';

// type=payment-link&c[0][s]=1&c[0][e]=11&c[0][q]=1&c[1][s]=2&c[1][e]=22&c[1][q]=2
const CheckoutIntegration = () => {
    const {type, c} = useQuery();
    const dispatch = useInstantDispatch();
    const location = useLocation();
    React.useEffect(() => {
        if (c && c.length) {
            dispatch({type: 'reset', payload: []});
            c.forEach(entry => {
                dispatch({type: 'add-several', payload: {
                    endpointId: entry.e,
                    serviceId: entry.s,
                    quantity: parseInt(entry.q) || 1
                }});
            });
        }
    }, []);
    switch (type) {
        case 'payment-link': {
            return <EffectRedirect to={`../payment-link/impulse${searchFilter(location.search)}`} replace/>;
        }
        case 'widget': {
            return <EffectRedirect to={`../ticketing-instant/impulse${searchFilter(location.search, {showOrderItemQuantity: true})}`} replace/>;
        }
        default: {
            return (
                <div className='padding-1rem'>
                    <Skeleton/>
                </div>
            )
        }
    }
}

export default React.memo(CheckoutIntegration);
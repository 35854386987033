import React from 'react';

const RulesEn = () => {
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-1dot5rem font-weight-bold'>
                INTERNET SERVICE STANDARD FORM CONTRACT
            </div>
            <div className='margin-top-1rem'>
                Ottry, Limited Liability Company (hereinafter referred to as Ottry, Company), acting under the legislation of Ukraine and registered at: 16, Glibochitskaya Street., office 13, Kyiv, 04050, guided by articles 633, 634 of the Civil Code of Ukraine, publicly offers an unlimited number of people Online Service for business activities in the Internet, to this end it publishes this Contract as follows:
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                1. SUBJECT OF THE CONTRACT
            </div>
            <div className='margin-top-1rem'>
                1.1. This Contract regulates relations of the Enterprise and the Company, according to which the Enterprise for the purpose of selling its goods and services in the Internet places on its website the Company Online Service together with the payment mechanism of the Financial Partner Ottry, and the Company provides maintenance of the Enterprise in terms of operation of the Online Service.
            </div>
            <div className='margin-top-1rem'>
                1.2. This Contract is publicly disclosed to an unlimited number of people to assist in selling goods and services in the Internet using the Company Online Service by placing (publishing) it on the website of the Enterprise. The Online Service can be available on the Company website https://ottry.com.
            </div>
            <div className='margin-top-1rem'>
                1.3. This Contract shall come into force on the date of agreement of the Enterprise with the terms of acceding to the STANDARD FORM CONTRACT on providing Online Service for business activities in the Internet, made in electronic form at https://ottry.com.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                2. TERMS AND DEFINITIONS
            </div>
            <div className='margin-top-1rem'>
                2.1. The terms not specified in this section are used in this Contract with the meaning given to them by the laws of Ukraine.
            </div>
            <div className='margin-top-1rem'>
                2.2. Enterprise shall mean a subject to entrepreneurial activity, which pursuant to this Contract places on the website using the software of Ottry the Ottry online service to sell its goods and services, as well as subject to the Contract with the Financial Partner accepts Cards for payment for goods/services on the Website.
            </div>
            <div className='margin-top-1rem'>
                2.3. Financial Partner shall mean the partner company of Ottry, which provides financial services, particularly, online payments via Visa, Master Card and other payment systems. Only a company having relevant licences for financial services can be Financial Partner. 
            </div>
            <div className='margin-top-1rem'>
                2.4. Marketing Partner shall mean a partner company of Ottry, which places advertising of its services and goods using the software of Ottry via the Ottry Online Service, placed on the website of the Enterprise.
            </div>
            <div className='margin-top-1rem'>
                2.5. Website Visitor shall mean any individual Internet user accessing the website of the Enterprise.
            </div>
            <div className='margin-top-1rem'>
                2.6. Buyer shall mean a person ordering and paying using the Cards for goods or services of the Enterprise in the Internet.
            </div>
            <div className='margin-top-1rem'>
                2.7. Business Online Service, hereinafter referred to as Online Service shall mean the Company software providing online booking of services and goods in the Internet and / or display of Endpoint accessibility.
            </div>
            <div className='margin-top-1rem'>
                2.8. Endpoint shall mean the final services or product of the Enterprise, displayed on its website, which can be available for booking or purchase.
            </div>
            <div className='margin-top-1rem'>
                2.9. Management Kit shall mean the Enterprise user account containing information about Orders for services and goods, booking status, as well as Transactions. This account also contains personal details of Buyers.
            </div>
            <div className='margin-top-1rem'>
                2.10. Payment mechanism shall mean service of the Financial Partner for organisation of acceptance of payments in the Internet to the account of the Enterprise.
            </div>
            <div className='margin-top-1rem'>
                2.11. Order shall mean a combination of goods and services of the Enterprise, their cost, method and cost of delivery, placed on the Website, as well as personal data of a Buyer.
            </div>
            <div className='margin-top-1rem'>
                2.12. Payment card, hereinafter referred to as the Card shall mean a special payment instrument in the form of a payment card issued in accordance with the legally established procedure, used for initiation of transfer of funds from a payer’s account or the respective bank account for payment for goods or services or performance of other transactions provided by the current laws.
            </div>
            <div className='margin-top-1rem'>
                2.13. Transaction shall mean a combination of transactions between a Buyer, Enterprise, and the Financial Partner for purchase of goods or services on the Website using the Card as payment instrument.
            </div>
            <div className='margin-top-1rem'>
                2.14. Operation shall mean a combination of registration acts of the Enterprise upon creation of an account on the Company website, and a Buyer upon completion of relevant data in the Online Service to view accessibility of Endpoint, booking and / or purchase of any product or service.
            </div>
            <div className='margin-top-1rem'>
                2.15. Website shall mean a combination of information and service functions accessible for Internet users, collected on one or more servers.
            </div>
            <div className='margin-top-1rem'>
                2.16. Enterprise website, hereinafter referred to as the Website shall mean a website managed and controlled by the Enterprise.
            </div>
            <div className='margin-top-1rem'>
                2.17. Account shall mean a combination of data about the Enterprise, kept in the Ottry computer system, required for identification of it and provision of access to it personal data and settings in the user account.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                3. RIGHTS AND OBLIGATIONS OF THE ENTERPRISE
            </div>
            <div className='margin-top-1rem'>
                3.1. The Enterprise may:
            </div>
            <div className='margin-top-1rem'>
                3.1.1. Receive from the Company consultations on the procedure of collection of the Online Service to the web interface and placement of it on the website, and expendable and information materials, instruction of the personnel on work with the Ottry service.
            </div>
            <div className='margin-top-1rem'>
                3.1.2. Receive from the Company information about the status of booking of a service or products, as well as a Transaction made, compare data of the Financial Partner about payment to the data provided by the Company.
            </div>
            <div className='margin-top-1rem'>
                3.1.3. Inform the Company that the displayed advertising of Marketing Partners of Ottry through the Online Service on the Website does not conform to the current laws of Ukraine on advertising.
            </div>
            <div className='margin-top-1rem'>
                3.2. The Enterprise shall:
            </div>
            <div className='margin-top-1rem'>
                3.2.1. Upon performance of a Transaction observe the working procedure given in this Contract. The Enterprise shall alone select from all its current accounts an account, to which funds received from Buyers will be transferred. Upon conclusion of the Contract with the Financial Partner the Enterprise shall indicate the details of its current account, to which transfer of funds received from Buyers will be made. The Company shall not bear any responsibility for completeness and correctness of the details provided by the Enterprise, as well as for performance of a Transaction. Responsibility for performance of a Transaction and all accompanying operations concerning performance of payments shall be borne by the Financial Partner of the Company.
            </div>
            <div className='margin-top-1rem'>
                3.2.2. Control the progress of payments on Transactions of the Enterprise and immediately give notice to the Financial Partner and/or the Company in case of detection of debt.
            </div>
            <div className='margin-top-1rem'>
                3.2.3. Place on the Website full information about the goods skid and services provided by the Enterprise.
            </div>
            <div className='margin-top-1rem'>
                3.2.4. Sale of goods/services is prohibited if:
                <div>
                    a) such sale if illegal or can damage the business reputation or have a negative impact on the Ottry brand.
                </div>
                <div>
                    b) sale, including images, being patently offensive and does not have artistic value (e.g., sexual image, sexual abuse of minor children, injury of face or organs, as well as act of cruelty);
                </div>
                <div>
                    c) sale of any other goods/services considered at the discretion of the Enterprise to unacceptable for sale under its brand;
                </div>
                <div>
                    d) goods/services are prohibited according to the laws of Ukraine.
                </div>
            </div>
            <div className='margin-top-1rem'>
                3.2.5. The Enterprise undertakes to allow demonstration of advertising of services of the Company, Financial Partner, Marketing Partner of Ottry on the Website according to the current laws of Ukraine on advertising.
            </div>
            <div className='margin-top-1rem'>
                3.2.6. The Enterprise agrees that the Company may collect, keep, and process data about any acts of Buyers on the Website, formulate statistics and analytics in relation to such data.
            </div>
            <div className='margin-top-1rem'>
                3.2.7. Provide confidentiality and non-disclosure of personal data of Buyers, information on Operations and Transactions processed through the Ottry Online Service and the Payment Mechanism of the Financial Partner, placed on the website of the Enterprise.
            </div>
            <div className='margin-top-1rem'>
                3.2.8. Provide confidentiality of all information transferred by the Company to the Enterprise under this Agreement.
            </div>
            <div className='margin-top-1rem'>
                3.2.9. Contact the support service of Ottry in case of any failure in operation of the Online Service. In case of any failure related to payment for services or goods ordered through the Ottry Online Service the Enterprise shall contact the support service of the Financial Partner and/or the Ottry support service.
            </div>
            <div className='margin-top-1rem'>
                3.2.10. Give notice to the Company within three days from occurrence of any circumstances impeding performance of this Contract.
            </div>
            <div className='margin-top-1rem'>
                3.2.11. If there are claims of any third parties against the Company, the Enterprise shall compensate for all losses incurred by the Company, arising as a result of activity of the Enterprise and/or its contractor. The Company shall not bear any responsibility for quality of the services and goods provided by the Enterprise to the Buyer.
            </div>
            <div className='margin-top-1rem'>
                3.2.12. Monitor any amendments to this Public Contract by studying official notices of the Company, published on the Ottry website in the Management Kit system 30 days prior to entry into force of any amendments to the existing Contract. If the Enterprise does not inform the Company about refusal to cooperate on the terms of the new Public Contract within 30 days in writing (by e-mail), this Contract will be automatically renewed on the new terms according to the amendments earlier published on the Ottry website.
            </div>
            <div className='margin-top-1rem'>
                3.3. Rights and obligations of the Company
            </div>
            <div className='margin-top-1rem'>
                3.3.1. The Company may:
            </div>
            <div className='margin-top-1rem'>
                3.3.2. Restrict the number of orders (bookings, purchases) of services and goods through the Online Service on the Website upon one day notice to the Enterprise by fax or e-mail.
            </div>
            <div className='margin-top-1rem'>
                3.3.3. Cooperate with Marketing Partners for the purpose of advertising of the services and goods of the Company and the Marketing Partner by relevant demonstrations on the website of the Enterprise (links, logos, other branches, pop-up windows and buttons on the software tools of the Online Service can be used).
            </div>
            <div className='margin-top-1rem'>
                3.3.4. The Company may suspend performance of Operations through the Ottry Online Service and Transactions through the web interface of the Financial Partner (upon notice of it to the Enterprise one day prior to suspension of Operations by e-mail) in the following cases:
                <div>
                    - If Operations and Transactions as of the date of performance of them raise suspicion concerning their lawfulness (also on the basis of the information received form the Financial Partner as a result of antifraud monitoring of transactions);
                </div>
                <div>
                    - In case of receipt by the Company of notices from the Financial Partner that the operations performed in the Enterprise System are fraudulent;
                </div>
            </div>
            <div className='margin-top-1rem'>
                3.3.5. Ensure the Enterprise observes this Contract.
            </div>
            <div className='margin-top-1rem'>
                3.3.6. Amend this Contract.
            </div>
            <div className='margin-top-1rem'>
                3.4. The Company shall:
            </div>
            <div className='margin-top-1rem'>
                3.4.1. Provide technological support services for placement and maintenance of operation of the Online Service on the Enterprise website.
            </div>
            <div className='margin-top-1rem'>
                3.4.2. Provide information about the status of booking of a service or product, as well as a Transaction. Provide to the Enterprise access to the personal data of Buyers.
            </div>
            <div className='margin-top-1rem'>
                3.4.3. Provide confidentiality and non-disclosure of personal data of Buyers, information about Operations and Transactions processed through the Ottry Online Service and the Payment Mechanism of the Financial Partner, placed on the Enterprise website.
            </div>
            <div className='margin-top-1rem'>
                3.4.4. Provide confidentiality of all information transferred by the Enterprise to the Company hereunder.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                4. RULES AND PROCEDURE OF USE OF SERVICES
            </div>
            <div className='margin-top-1rem'>
                4.1. Provision to the Enterprise of the Business Online Service by placing it on Website shall be made subject to creation by the Enterprise on the Company website of the respective User Account. The User Account shall conation the e-mail address of the Enterprise. The Company may request provision of additional personal data of the Enterprise by completing the relevant profile in the Management Kit through the User Account.
            </div>
            <div className='margin-top-1rem'>
                4.2. The Enterprise shall bear responsibility for confidentiality of the password. If the Enterprise detects any fact of unauthorised access to its user account, it shall as soon as possible give notice of it to the support service of the Company at: info@ottry.com.
            </div>
            <div className='margin-top-1rem'>
                4.3. After creation of a User Account and, correspondingly, accession to this Contract, using the software tools of the Company the Online Service together with the payment mechanism of the Financial Partner shall be placed on the Website.
            </div>
            <div className='margin-top-1rem'>
                4.4. Ottry Website is available at: https://ottry.com
            </div>
            <div className='margin-top-1rem'>
                4.5. Selection of goods and services, formation of their list and quantity, selection of the method of delivery, viewing of accessibility of Endpoint, order for feedback shall be made by Buyer using the software tools of Ottry through the Online Service placed on the Website.
            </div>
            <div className='margin-top-1rem'>
                4.6. In case of purchase of a product or service through the Online Service upon switching to the website of the Financial Partner the Visitor agrees with the terms of the Public Contract of the Financial Partner, creates a user account, introduces payment card details and makes payment through the payment mechanism of the Financial Partner.
            </div>
            <div className='margin-top-1rem'>
                4.7. Transfer of the amount of an Order, less the commission fee of the Financial Partner, from the Buyer’s card account to the account of the Enterprise shall be made by the Financial Partner according to the technologies adopted upon performance of mutual settlements using international payment systems.
            </div>
            <div className='margin-top-1rem'>
                4.8. The Website visitor becomes a Buyer in case of successful registration of an Orderрегистрации Заказа and payment for it.
            </div>
            <div className='margin-top-1rem'>
                4.9. If a Buyer contacts the support service of the Enterprise, for settlement of the issue the Enterprise will require from the Company relevant information about Operations, status of booking or purchase of a product / service.
            </div>
            <div className='margin-top-1rem'>
                4.10. Responsibility for delivery of an Order to the Buyer or returning (partial returning) of an Order by the Buyer shall be borne by the Enterprise.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                5. FINANCIAL CONDITIONS
            </div>
            <div className='margin-top-1rem'>
                5.1. The fee for the use of the online business service and the Internet provided by the Company shall be levied by the Company in the following amounts:
            </div>
            <div className='margin-top-1rem'>
                5.1.1. for business entities engaged in concert, entertainment and other activities – 5 %.
            </div>
            <div className='margin-top-1rem'>
                5.1.2. In exceptional cases, another amount of commission may be established, agreed by signing the agreement between the Company and the Company.
            </div>
            <div className='margin-top-1rem'>
                5.2. The settlement service fee for card transactions is charged by the Financial Partner in the amount established by a public tender of the Financial Partner's offer.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                6. RESPONSIBILITY of the PARTIES
            </div>
            <div className='margin-top-1rem'>
                6.1. The Company shall had sole responsibility for technological support services and support of the Online Service placed on the Website, as well as for processing and keeping of personal data of Buyers, the Enterprise and other information provided by the Enterprise to the Company. 
            </div>
            <div className='margin-top-1rem'>
                6.2. The Company is not liable for completeness and correctness of the details for performance of a Transaction, provided by the Enterprise. Responsibility for performance of a Transaction and all accompanying operations for performance of payments shall be borne by the Financial Partner of the Company. 
            </div>
            <div className='margin-top-1rem'>
                6.3. The Company is not liable for any responsibility for the quality of services and goods provided to the Buyer by the Enterprise.  
            </div>
            <div className='margin-top-1rem'>
                6.4. If there are claims of any third parties against the Company, the Enterprise shall compensate for all losses incurred by the Company as a result of activity of the Enterprise and/or its contractor.
            </div>
            <div className='margin-top-1rem'>
                6.5. If any of the Parties fails to fulfil or improperly fulfils its obligations, the other Party may claim fulfilment by the Party in fault of its obligations, as well as compensation for losses incurred by it.
            </div>
            <div className='margin-top-1rem'>
                6.6. If the Enterprise does not observe clause 3.2.4., it shall pay to the Company a penalty in the amount of UAH 30,000.00 (thirty thousand) for each breach (booking, order, payment).
            </div>
            <div className='margin-top-1rem'>
                6.7. The Enterprise has an absolute responsibility for the information on the Website. 
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                7. SETTLEMENT OF DISPUTES
            </div>
            <div className='margin-top-1rem'>
                7.1. All disputes and disagreements arising out of or in connection with this Contract will, where possible, be settled through negotiations between the Parties.
            </div>
            <div className='margin-top-1rem'>
                7.2. If the Parties fail to come to an amicable agreement, disputes and disagreements shall be settled in accordance with the procedure established by the current legislation of Ukraine. 
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                8. DURATION AND TERMINATION OF THE CONTRACT
            </div>
            <div className='margin-top-1rem'>
                8.1. This Contract shall come into force on the date of acceding of the Enterprise to the Contract by consenting in writing on the Company website, and shall be valid for one year. If one month prior to expiration of this Contract neither Party initiated termination of it, the Contract will be renewed for the same term.
            </div>
            <div className='margin-top-1rem'>
                8.2. This Contract shall be terminated according to the provisions of this Contract and the current legislation of Ukraine. If any of the Parties has financial or any other claims against each other, termination of the Contract shall be postponed until settlement of disputes.
            </div>
            <div className='margin-top-1rem'>
                8.3. In case of termination of this Contract the Enterprise shall remove from all pages of the website Ottry logos, the link for https://ottry.com, and any other information about cooperation with the Company under this Contract.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                9. FORCE MAJEURE CIRCUMSTANCES 
            </div>
            <div className='margin-top-1rem'>
                9.1. The Parties shall be exempted from any responsibility for complete or partial non-fulfilment of their obligations hereunder, if such non-fulfilment resulted from the prohibitive measures of the state, local public authorities or Force Majeure, including circumstances beyond control of the Parties, for occurrence of which the Parties do not bear any responsibility, such as: fire, flood, earthquake, war, strike etc.
            </div>
            <div className='margin-top-1rem'>
                9.2. The Enterprise shall give written notice (by e-mail) to the Company within 10 (ten) days from the occurrence of Force Majeure. The Company will publish information about the occurrence of Force Majeure on the website within 10 (ten) days from its occurrence.
            </div>
            <div className='margin-top-1rem'>
                9.3. If Force Majeure and its sequels last for over six months, the Parties will negotiate as soon as possible in order to determine the alternative methods of performance of this Contract and achievement of written (and/or electronic) agreements, acceptable for both Parties.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                10. MISCELLANEOUS
            </div>
            <div className='margin-top-1rem'>
                10.1. In cases not provided by this Contract the Parties shall be governed by the current legislation of Ukraine.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                11. CONTACT DETAILS OF THE COMPANY
            </div>
            <div className='margin-top-1rem'>
                <div>
                    Ottry, LLC
                </div>
                <div>
                    Legal address: 16, Gribochitskaya Street., office 13, Kyiv, 04050
                </div>
                <div>
                    VAT identification number: 41385243
                </div>
                <div>
                    Director General
                </div>
                <div>
                    V.V. Kovalchuk
                </div>
            </div>
        </div>
    );
};

export default React.memo(RulesEn);
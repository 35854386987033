/**
 * @generated SignedSource<<04eb602cc69e6bb5348b4bbb397ccdbe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Impulse",
    "kind": "LinkedField",
    "name": "takeImpulse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "ImpulseEndpoint",
        "kind": "LinkedField",
        "name": "impulseEndpoints",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numbers",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QrsImpulseImplTakeMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QrsImpulseImplTakeMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dd9c548b06df5694dc6b88d237006a03",
    "id": null,
    "metadata": {},
    "name": "QrsImpulseImplTakeMutation",
    "operationKind": "mutation",
    "text": "mutation QrsImpulseImplTakeMutation(\n  $request: TakeImpulseRequestInput\n) {\n  takeImpulse(request: $request) {\n    id\n    version\n    impulseEndpoints {\n      numbers\n    }\n  }\n}\n"
  }
};
})();

node.hash = "b5d4bf946d91845d878e8971d95b12c9";

module.exports = node;

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLazyLoadQuery} from 'react-relay/hooks';
import TicketIcon from '../../assets/icons0/ticket-01.svg';
import CreditCardRefreshIcon from '../../assets/icons0/credit-card-refresh.svg';
import SafeIcon from '../../assets/icons0/safe.svg';
import ShoppingCartIcon from '../../assets/icons0/shopping-cart-01.svg';
import Events from './categories/Events';
import Replenishments from './categories/Replenishments';
import Subscriptions from './categories/Subscriptions';
import Purchases from './categories/Purchases';
import Wallet01Svg from '../../assets/icons0/wallet-01.svg';
import Orders from './categories/Orders';
import './Wallet.scss';
import Skeleton from '../../components/Skeleton';
import {graphqlWhoamiNextQuery} from '../../components/graphql';

const WALLET_CATEGORIES = [
    // {
    //     name: 'events',
    //     title: <FormattedMessage defaultMessage='Events'/>,
    //     icon: <TicketIcon/>,
    // }, {
    //     name: 'subscriptions',
    //     title: <FormattedMessage defaultMessage='Subscriptions'/>,
    //     icon: <CreditCardRefreshIcon/>
    // }, {
    //     name: 'replenishments',
    //     title: <FormattedMessage defaultMessage='Replenishments'/>,
    //     icon: <SafeIcon/>
    // }, {
    //     name: 'purchases',
    //     title: <FormattedMessage defaultMessage='Purchases'/>,
    //     icon: <ShoppingCartIcon/>
    // },
    {
        name: 'orders',
        title: <FormattedMessage defaultMessage='Order'/>,
        icon: <Wallet01Svg/>
    }
]

const WalletCategoriesComponents = {
    // events: <Events category={WALLET_CATEGORIES[0]} key={WALLET_CATEGORIES[0].name}/>,
    // subscriptions: <Subscriptions category={WALLET_CATEGORIES[1]} key={WALLET_CATEGORIES[1].name}/>,
    // replenishments: <Replenishments category={WALLET_CATEGORIES[2]} key={WALLET_CATEGORIES[2].name}/>,
    // purchases: <Purchases category={WALLET_CATEGORIES[3]} key={WALLET_CATEGORIES[3].name}/>,
    orders: <Orders category={WALLET_CATEGORIES[0]} key={WALLET_CATEGORIES[0].name}/>
}

const WalletPage = () => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);

    return (
        <div className='background-color-gray-50 flex-1 display-flex flex-direction-column'>
            <div className='view mw768-padding-bottom-4rem display-flex flex-1 width-100percent flex-direction-column'>
                <div className='block display-flex padding-top-2rem background-color-gray-25 padding-bottom-2rem mw768-padding-bottom-4rem mw768-padding-top-4rem flex-direction-column'>
                    <p className='display-sm mw768-display-md semibold color-gray-900 padding-bottom-0dot25rem mw768-padding-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='Wallet'/>
                    </p>
                    <p className='text-lg color-gray-500'>
                        {whoamiNext.username || whoamiNext.email}
                    </p>
                </div>
                <div className='block padding-top-2rem mw768-padding-top-4rem padding-bottom-0dot5rem mw768-padding-bottom-1rem'>
                    <React.Suspense fallback={<Skeleton/>}>
                        {WALLET_CATEGORIES.map((category) => (
                            WalletCategoriesComponents[category.name]
                        ))}
                    </React.Suspense>
                </div>
            </div>
       </div>
    )
}

export default React.memo(WalletPage);
import React from 'react';
import './CabinetFooter.scss';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../searchFilter';
import useQuery from '../../useQuery';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import Lol from '../Lol';
import getLocaleQueryParams from '../../utilities/get-locale-query-params';

const legal = 'https://www.ottry.com/legal';
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const year = new Date().getFullYear();

function CabinetFooter() {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    return (
        <>
        <Lol/>
        <div className='view'>
            <div className='block'>
                <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                    <a
                        className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                        href={`${location.pathname}${getLocaleQueryParams(location.search, 'uk')}`}
                    >Uk</a>
                    {' '}
                    <a
                        className={classNames('link', 'font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                        href={`${location.pathname}${getLocaleQueryParams(location.search, 'en')}`}
                    >En</a>
                </div>
                <div className='cabinet-footer__content margin-bottom-1rem font-size-0dot8rem'>
                    <div className='cabinet-footer__info'>
                        <a 
                            href={formLink} 
                            target='_blank' 
                            rel='noopener noreferrer'
                            className='focus'
                        ><FormattedMessage defaultMessage='I want to sell online'/></a>
                        <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                            <FormattedMessage defaultMessage='Report an issue'/>
                        </a>
                        <Link
                            className='focus'
                            to={`${location.pathname}${searchFilter(location.search, {more: true}, false)}`}
                        ><FormattedMessage defaultMessage='More'/></Link>
                        <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                        <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                        <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                    </div>
                    <div className='cabinet-footer__rights'>
                        © {year} Ottry. All rights reserved. 
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default React.memo(CabinetFooter);
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Breadcrumbs from './breadcrumbs';
import PaymentMethod from './payment-method';
import Button, {RouterLinkButton} from '../../components/button/button';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import searchFilter from '../../searchFilter';
import {graphqlSubscriptionServiceByIdQuery} from './graphql';
import {FetchKeyStateContext} from '../../components/FetchKey';
import Skeleton from '../../components/Skeleton';

const RAZZLE_APP_DEMO_MERCHANT_ID = process.env.RAZZLE_APP_DEMO_MERCHANT_ID;

export const PaymentOptionsRecurringCreate = React.memo(() => {
    const {currentPoolId, serviceId} = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const breadcrumbs = [
        {name: 'Subscription', nameUk: 'Підписка', route: '../'},
        {name: 'Ordering', nameUk: 'Оформлення', route: '../activate'},
        {name: 'Confirm', nameUk: 'Підтвердити', route: `../confirm/${currentPoolId}`},
        {name: 'Payment method', nameUk: 'Спосіб оплати'}
    ];
    const {serviceById} = useLazyLoadQuery(graphqlSubscriptionServiceByIdQuery, {serviceId: serviceId});
    const merchantId = serviceById.demo ? RAZZLE_APP_DEMO_MERCHANT_ID : serviceById.mrchntd;
    const [newPoolId, setNewPoolId] = React.useState(currentPoolId);
    const setNewPoolIdCallback = React.useCallback((value) => {
        setNewPoolId(value);
    }, []);

    const confirmLink = `../confirm/${newPoolId}${searchFilter(location.search)}`;
    const cancelLink = `../confirm/${currentPoolId}${searchFilter(location.search)}`;

    const confirmAction = () => {
        navigate(confirmLink);
    }
    const confirm = {
        action: confirmAction,
        disbaled: false
    }

    return (
        <PaymentOptionsRecurring {...{breadcrumbs, confirm, cancelLink, currentPoolId, newPoolId, setNewPoolIdCallback, serviceId, merchantId}}/>
    )
});

export const PaymentOptionsRecurringUpdate = React.memo(() => {
    const {currentPoolId, recurringId} = useParams();
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    const location = useLocation();
    const navigate = useNavigate();
    const breadcrumbs = [
        {name: 'Subscription', nameUk: 'Підписка', route: '../'},
        {name: 'Payment method', nameUk: 'Спосіб оплати'}
    ];
    const {viewer} = useLazyLoadQuery(
        graphql`
            query paymentOptionsRecurringByViewerQuery($first: Int!, $after: String) {
                viewer {
                    recurringsByViewer(first: $first, after: $after) @connection(key: "Recurrings_recurringsByViewer") {
                        edges {
                            node {
                                id
                                recurringService {
                                    service {
                                        id
                                        demo
                                        mrchntd
                                    }
                                }  
                                version 
                            }
                        }
                    }
                }
            }
        `,
        {first: 100},
        {fetchKey: fetchKeyState}
    );
    const recurring = viewer.recurringsByViewer.edges.find(item => item.node.id === recurringId);
    const service = recurring.node.recurringService.service;
    const merchantId = service.demo ? RAZZLE_APP_DEMO_MERCHANT_ID : service.mrchntd;
    const [newPoolId, setNewPoolId] = React.useState(currentPoolId);
    const setNewPoolIdCallback = React.useCallback((value) => {
        setNewPoolId(value);
    }, []);

    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation paymentOptionsRecurringMutation($request: UpdateRecurringPoolRequestInput!) {
                updateRecurringPool(request: $request) {
                    id
                    pool {
                        id
                    }
                    version
                }
            }
    `);
    const cancelLink = `../${searchFilter(location.search)}`;

    const confirmAction = () => {
        commit({variables: {
            request: {
                id: recurringId,
                pool: newPoolId,
                version: recurring.node.version
            }
        },
        onCompleted: (data) => {
            navigate(`../${searchFilter(location.search)}`);
        },
        onError: () => {}
        });
    }
    const confirm = {
        action: confirmAction,
        disbaled: isMutationInFlight
    }

    return (
        <PaymentOptionsRecurring {...{breadcrumbs, confirm, cancelLink, currentPoolId, newPoolId, setNewPoolIdCallback, serviceId: service.id, merchantId}}/>
    )
});

const PaymentOptionsRecurring = ({breadcrumbs, confirm, cancelLink, currentPoolId, newPoolId, setNewPoolIdCallback, serviceId, merchantId}) => {
    const [redirect, setRedirect] = React.useState(false);
    const setRedirectCallback = React.useCallback((value) => {
        setRedirect(value);
    });
    return (
        <>{redirect
            ? <Skeleton/>
            : <div className='view'>
                <div className='padding-bottom-2rem mw768-padding-top-2rem mw768-padding-bottom-4rem'>
                    <Breadcrumbs {...{breadcrumbs}}/>
                </div>
                <h1 className='color-gray-900 text-title semibold padding-bottom-1rem'>
                    <FormattedMessage defaultMessage='Select payment method'/>
                </h1>
                <p className='color-gray-500 text-md'>
                    <FormattedMessage defaultMessage='Select the payment method that will be used to pay for the subscription'/>
                </p>
                <div className='mw768-max-width-26dot5rem padding-top-1dot5rem mw768-padding-top-2rem'>
                    <React.Suspense fallback={<Skeleton/>}>
                        <PaymentMethod {...{setPool: setNewPoolIdCallback, pool: newPoolId, serviceId, merchantId, setRedirect: setRedirectCallback}}/>
                    </React.Suspense>
                </div>
                <div className='margin-top-2rem mw768-margin-top-3rem mw768-max-width-26dot5rem'>
                    <div className='margin-bottom-0dot75rem'>
                        <Button {...{size: 'lg', color: 'primary-dark', fluid: 'always', disabled: newPoolId === currentPoolId || confirm.disabled, clickHandler: confirm.action}}>
                            <FormattedMessage defaultMessage='Update'/>
                        </Button>
                    </div>
                    <RouterLinkButton {...{size: 'lg', color: 'secondary-gray', fluid: 'always', to: cancelLink}}>
                        <FormattedMessage defaultMessage='Back'/>
                    </RouterLinkButton>
                </div>
            </div>
            
        }</>
    )
};

export default React.memo(PaymentOptionsRecurring);
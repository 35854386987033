import React from 'react';
import {Route, Routes} from 'react-router-dom';
import NotFound from '../../components/NotFound';
import WalletPage from './Wallet';
import TicketPage from './Ticket';
import WalletShell from './Shell';
import Skeleton from '../../components/Skeleton';
import Browser from '../../components/Browser';

const WalletRouting = () => {
    return (
        <div className="pages">
            <React.Suspense fallback={<div className='view'><div className='block padding-top-2rem'><Skeleton/></div></div>}>
                <Routes>
                    <Route index element={<Browser><WalletShell><WalletPage/></WalletShell></Browser>}/>
                    <Route path='ticket/:impulseId' element={<Browser><TicketPage/></Browser>}/>
                    <Route path='*' element={<NotFound/>}/>
                </Routes>
            </React.Suspense>
        </div>
    )
};

export default React.memo(WalletRouting);
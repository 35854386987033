import React from 'react';
import {Outlet, Route, Routes} from 'react-router-dom';
import NotFound from '../../components/NotFound';
import Scan from './scan';
import ScanHistory from './history';
import ScanSearch from './search';
import ScanSettings from './settings';
import ScanUpdateImpulse from './update-impulse';
import Browser from '../../components/Browser';
import {CurrentScanProvider} from './current-scan-context';

const ScanRoutes = () => {
    return (
        <div className="pages">
            <Routes>
                <Route element={<CurrentScanProvider><Outlet/></CurrentScanProvider>}>
                    <Route index element={<Browser><Scan/></Browser>}/>
                    <Route path='update-impulse/:impulseId' element={<ScanUpdateImpulse/>}/>
                </Route>
                <Route path='history' element={<ScanHistory/>}/>
                <Route path='search' element={<ScanSearch/>}/>
                <Route path='settings' element={<Browser><ScanSettings/></Browser>}/>
                <Route path='*' element={<NotFound/>}/>
            </Routes>
        </div>
    )
};

export default React.memo(ScanRoutes);
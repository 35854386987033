import React from 'react';
import './Cart.scss';
import {CartStateContext, CartDispatchContext} from '../../../contexts/cart';
import CartEntry from './CartEntry';
import CartLand from './CartLand';
import SuspenseList from '../../SuspenseList';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './../../Skeleton';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import ErrorFallback from '../../ErrorFallback';
import Browser from '../../Browser';

function Cart() {
    const state = React.useContext(CartStateContext);
    const dispatch = React.useContext(CartDispatchContext);
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-2dot5rem font-weight-bold margin-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='Cart'/>
            </div>
            <Browser>
                <div className='cart__list'>
                    {!!state.length ?
                        <SuspenseList revealOrder='forwards'>
                            <SuspenseList>
                                {state.map((entry) =>
                                    <div key={entry.key}>
                                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                            <React.Suspense fallback={
                                                <div className='padding-1rem-0 border-bottom-1px-dotted-black'>
                                                    <Skeleton/>
                                                </div>
                                            }>
                                                <div className='padding-1rem-0 border-bottom-1px-dotted-black'>
                                                    <CartEntry entry={entry}/>
                                                    <div className='display-flex margin-top-1rem'>
                                                        <div
                                                            className='button'
                                                            onClick={() => {dispatch({type: 'remove', payload: entry.key})}}
                                                        ><FormattedMessage defaultMessage='Remove'/></div>
                                                        {!entry.untie &&
                                                        <>
                                                        <div
                                                            className={classNames('button', 'margin-left-4px', {
                                                                'disabled': entry.quantity < 2
                                                            })}
                                                            onClick={() =>
                                                                entry.quantity > 1 && dispatch({type: 'decrement', payload: entry.key})}
                                                        ><FormattedMessage defaultMessage='-1'/></div>
                                                        <div
                                                            className='button primary margin-left-4px'
                                                            onClick={() => {dispatch({type: 'increment', payload: entry.key})}}
                                                        ><FormattedMessage defaultMessage='+1'/></div>
                                                        </>
                                                        }
                                                    </div>
                                                </div>
                                            </React.Suspense>
                                        </ErrorBoundary>
                                    </div>
                                )}
                            </SuspenseList>
                            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                <React.Suspense fallback={
                                    <div className='padding-1rem-0'>
                                        <Skeleton/>
                                    </div>
                                }>
                                    <div className='padding-1rem-0'>
                                        <CartLand/>
                                    </div>
                                </React.Suspense>
                            </ErrorBoundary>
                        </SuspenseList>
                        :
                        <span><FormattedMessage defaultMessage='Your cart is empty'/></span>
                    }
                </div>
            </Browser>
        </div>
    );
}

export default React.memo(Cart);
import React from 'react';
import {FormattedMessage} from 'react-intl';
import SuccessPage from '../complete/success';


const TicketingInstantSuccessPage = () => {
    const title = <FormattedMessage defaultMessage='Thank you for your order'/>;
    const message = <FormattedMessage defaultMessage='We will send an email with your order as soon as the payment is processed'/>;
    
    return (
        <SuccessPage {...{title, message}}/>
    )
};

export default React.memo(TicketingInstantSuccessPage);

import React from "react";
import {FormattedMessage} from 'react-intl';
import PaymentPage from '../payment/Payment';


const PaymentLinkPaymentPage = () => {
    const successVerticalPath = 'payment-link';
    const subtitle = <FormattedMessage defaultMessage='We have reserved your order until you complete it'/>;
    const orderMessage = <FormattedMessage defaultMessage='The order will be sent to the specified email'/>;
    const showOrderItemQuantity = true;

    return (
        <PaymentPage {...{successVerticalPath, subtitle, orderMessage, showOrderItemQuantity}}/>
    )
};

export default React.memo(PaymentLinkPaymentPage);
/**
 * @generated SignedSource<<a17dc5865eca9fc6a29e541283d0c275>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "details",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "strategy",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "every",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "nameUk",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EndpointQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Endpoint",
        "kind": "LinkedField",
        "name": "endpointById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EndpointName_endpoint"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "EndpointImpl_endpoint"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EndpointQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Endpoint",
        "kind": "LinkedField",
        "name": "endpointById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "untie",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hints",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "withCart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "withInstant",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InstantSettings",
            "kind": "LinkedField",
            "name": "instantSettings",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantity",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c60c275256e27736ce85c061642c8803",
    "id": null,
    "metadata": {},
    "name": "EndpointQuery",
    "operationKind": "query",
    "text": "query EndpointQuery(\n  $id: String\n) {\n  endpointById(id: $id) {\n    id\n    name\n    details\n    start\n    end\n    strategy\n    every\n    ...EndpointName_endpoint\n    ...EndpointImpl_endpoint\n  }\n}\n\nfragment EndpointImplAction_endpoint on Endpoint {\n  id\n  quantity\n  withCart\n  withInstant\n  instantSettings {\n    name\n    nameUk\n  }\n}\n\nfragment EndpointImplUntieAction_endpoint on Endpoint {\n  id\n  hints\n  withCart\n  withInstant\n  instantSettings {\n    name\n    nameUk\n  }\n}\n\nfragment EndpointImpl_endpoint on Endpoint {\n  id\n  untie\n  ...EndpointImplUntieAction_endpoint\n  ...EndpointImplAction_endpoint\n}\n\nfragment EndpointName_endpoint on Endpoint {\n  id\n  name\n  nameUk\n  untie\n  price\n}\n"
  }
};
})();

node.hash = "0439cf6a44b3cde4262a770260786f5d";

module.exports = node;

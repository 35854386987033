import React from 'react';
import {useSubscriptionState} from './components/SubscriptionState';

// https://github.com/facebook/relay/blob/f79f00c44c8b5b5eea565ddff0f1c4d582e309e0/packages/react-relay/relay-hooks/useSubscription.js
// https://github.com/facebook/relay/blob/f79f00c44c8b5b5eea565ddff0f1c4d582e309e0/packages/relay-runtime/subscription/requestSubscription.js#L50
const useSubscription = (payload) => {
    const subscription = useSubscriptionState();
    React.useEffect(() => {
        const {unsubscribe} = subscription
            .request({channel: payload.channel})
            .subscribe(payload.onNext, payload.onError);
        return unsubscribe;
    }, [subscription, payload]);
};

export default useSubscription;
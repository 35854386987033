/**
 * @generated SignedSource<<8c37ea842ca043ab1c1d2e68dc57fbdd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "FondySignResponse",
    "kind": "LinkedField",
    "name": "fondySignPooltick",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signature",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "paymentMethodSignPooltickMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "paymentMethodSignPooltickMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ae29959e2ba4a43c37f3b6d927b700e7",
    "id": null,
    "metadata": {},
    "name": "paymentMethodSignPooltickMutation",
    "operationKind": "mutation",
    "text": "mutation paymentMethodSignPooltickMutation(\n  $request: FondySignRequestInput\n) {\n  fondySignPooltick(request: $request) {\n    signature\n  }\n}\n"
  }
};
})();

node.hash = "9ba957cfe4648c50811a63a76c199d2b";

module.exports = node;

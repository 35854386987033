import React from 'react';
import './endpoint.scss';
import ArrowLeftSvg from '../../assets/icons0/arrow-left.svg';
import QrCode02Svg from '../../assets/icons0/qr-code-02.svg';
import {Helmet} from 'react-helmet-async';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../components/Skeleton';
import {Link, useParams, useLocation} from 'react-router-dom';
import searchFilter from '../../searchFilter';
import i18n from '../../i18n';
import useQuery from '../../useQuery';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import ErrorFallback from '../../components/ErrorFallback';
import Button from '../../components/button/button';
import {useAuthorizationState} from '../../contexts/Authorization';
import UserEmail from './user-email';
import MasterWashAutoCheckout from './checkout';
import {ErrorMessagesProvider} from '../checkout/error-messages-context';
import ErrorMessages from '../checkout/error-messages';

const defaultEmail = process.env.RAZZLE_APP_MASTERWASH_IMPULSE_EMAIL;

const config = {shouldValidate: true, shouldDirty: true};

const Endpoint = React.memo(() => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const {id} = useParams();
    const {serviceId} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query endpointMaster_washPagesQuery($id: String) {
                endpointById(id: $id) {
                    id
                    name
                    nameUk
                    hints
                    instantSettings {
                        name
                        nameUk
                    }
                }
            }
        `,
        {id}
    );
    const {register, handleSubmit, formState: {errors}, setValue, setFocus} = useForm({
        defaultValues: {
            email: defaultEmail
        }
    });
    const authorized = useAuthorizationState();
    React.useEffect(() => {
        setFocus('input');
    }, [setFocus]);
    return (
        <>
        <Helmet>
            <title>{i18n(data.endpointById, 'name', locale)}</title>
            <meta name='description' content={i18n(data.endpointById, 'name', locale)}/>
        </Helmet>
        <MasterWashAutoCheckout {...{endpointId: data.endpointById.id, serviceId}}>
            {({autoCheckout, loading}) =>
                <div className='view'>
                    <div className='padding-left-1dot5rem mw768-padding-left-2rem padding-right-1dot5rem mw768-padding-right-2rem padding-top-2rem mw768-margin-top-4rem'>
                        <form onSubmit={handleSubmit(autoCheckout)}>
                            <div className='mw768-width-63rem mw768-margin-0-auto'>
                                <Link to={`/master-wash/services/${serviceId}${searchFilter(location.search)}`}>
                                    <div className='display-flex align-items-center'>
                                        <ArrowLeftSvg className='display-block width-1dot25rem height-1dot25rem color-gray-500' />
                                        <div className='margin-left-0dot5rem text-sm medium color-gray-500'>Master Wash</div>
                                    </div>
                                </Link>
                                <div className='mw768-width-37rem mw768-margin-0-auto'>
                                    <div className='margin-top-1dot25rem mw768-margin-top-3dot25rem display-flex'>
                                        <div className='width-3dot5rem height-3dot5rem border-radius-0dot75rem background-color-success-500 display-flex justify-content-center align-items-center'>
                                            <QrCode02Svg className='display-block width-1dot75rem height-1dot75rem color-white'/>
                                        </div>
                                        <div className='margin-left-1rem display-flex flex-direction-column'>
                                            <div className='text-xl semibold color-gray-900'>
                                                {i18n(data.endpointById, 'name', locale)}
                                            </div>
                                            <div className='margin-top-0dot125rem text-md color-gray-500'>Митницька 18, м. Черкаси</div>
                                        </div>
                                    </div>
                                    <div className='form'>
                                        <div className='margin-top-2rem'>
                                            <div className='text-sm medium color-gray-700'>
                                                Введіть суму для поповнення
                                            </div>
                                            <div className='margin-top-0dot375rem'>
                                                <div className={classNames('border-1px-solid border-color-gray-300 border-radius-0dot5rem display-flex align-items-center input', {
                                                    errors: errors.input
                                                })}>
                                                    <div className='flex-1 padding-left-0dot875rem padding-top-0dot625rem padding-bottom-0dot625rem'>
                                                        <input
                                                            className='width-100percent text-md color-gray-500'
                                                            type='number'
                                                            step='any'
                                                            placeholder='0.00'
                                                            {...register('input', {required: true, pattern: /^\d+(?:[.]\d{1,2})*$/})}
                                                            inputMode='numeric'
                                                        />
                                                    </div>
                                                    <div className='width-4rem display-flex justify-content-center align-items-center text-md color-gray-900'>
                                                        UAH
                                                    </div>
                                                </div>
                                                {errors.input && <div className='color-error-500 margin-top-0dot375rem text-sm'><FormattedMessage defaultMessage='This field is required'/></div>}
                                            </div>
                                        </div>
                                    </div>
                                    {data.endpointById.hints &&
                                    <div className='margin-top-1rem'>
                                        <div 
                                            className='display-grid'
                                            style={{
                                                gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                                columnGap: '0.5rem',
                                                rowGap: '0.5rem',
                                            }}
                                        >
                                            {data.endpointById.hints.map((item, index) =>
                                                <div 
                                                    key={index}
                                                    style={{boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'}} 
                                                    className='display-flex justify-content-center padding-top-0dot5rem padding-bottom-0dot5rem border-radius-0dot5rem background-color-gray-100 text-sm medium color-gray-700 cursor-pointer'
                                                    onClick={() => {
                                                        setValue('input', item, config);
                                                        setFocus('input');
                                                    }}
                                                >
                                                    {item}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    }
                                    {authorized && <UserEmail {...{setValue}}/>}
                                    <div className='margin-top-1dot5rem'>
                                        <Button {...{color: 'primary-gradient', size: 'lg', mw768Size: 'xl', fluid: 'always', isLoading: loading, clickHandler: handleSubmit(autoCheckout)}}>
                                            <span className='text-md medium color-white'>Поповнити</span>
                                        </Button>
                                        {/* <div 
                                            style={{
                                                background: 'linear-gradient(88.92deg, #1570EF 0%, #2E90FA 100%)',
                                                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)'
                                            }}
                                            className='border-radius-0dot5rem padding-top-0dot625rem padding-bottom-0dot625rem display-flex justify-content-center align-items-center cursor-pointer'
                                            onClick={handleSubmit(instant)}
                                        >
                                            
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className='margin-top-6rem background-color-gray-200 height-0dot0625rem'/>
                    </div>
                </div>
            }
        </MasterWashAutoCheckout>
        </>
    );
});

export default React.memo(() => {
    const {serviceId} = useQuery();
    const location = useLocation();
    return (
        <div className='endpoint'>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <ErrorMessagesProvider>
                    <React.Suspense fallback={
                        <div className='view'>
                            <div className='padding-left-1dot5rem mw768-padding-left-2rem padding-right-1dot5rem mw768-padding-right-2rem padding-top-2rem mw768-margin-top-4rem'>
                                <div className='mw768-width-63rem mw768-margin-0-auto'>
                                    <Link to={`/master-wash/services/${serviceId}${searchFilter(location.search)}`}>
                                        <div className='display-flex align-items-center'>
                                            <ArrowLeftSvg className='display-block width-1dot25rem height-1dot25rem color-gray-500' />
                                            <div className='margin-left-0dot5rem text-sm medium color-gray-500'>Master Wash</div>
                                        </div>
                                    </Link>
                                    <div className='mw768-width-37rem mw768-margin-0-auto'>
                                        <div className='margin-top-1dot25rem mw768-margin-top-3dot25rem'>
                                            <Skeleton/>
                                        </div>
                                    </div>
                                </div>
                                <div className='margin-top-6rem background-color-gray-200 height-0dot0625rem'/>
                            </div>
                        </div>
                    }>
                            <Endpoint/>
                    </React.Suspense>
                    <div className='position-absolute'>
                        <ErrorMessages/>
                    </div>
                </ErrorMessagesProvider>
            </ErrorBoundary>
        </div>
    );
});
import React from 'react';
import ScanHeader from './header';
import {Link, useLocation} from 'react-router-dom';
import XCloseIcon from '../../assets/icons0/x-close.svg';
import searchFilter from '../../searchFilter';

const SEARCH_PROPS = {

}

const ScanSearch = () => {
    const [showHeader, setShowHeader] = React.useState(true);
    const location = useLocation();
    return (
        <div className='scan-search background-color-white'>
            <ScanHeader {...{show: showHeader}}>
                <Link to={`../${searchFilter(location.search)}`} className='padding-0dot25rem'>
                    <XCloseIcon className='height-1dot5rem width-1dot5rem color-gray-500 display-block'/>
                </Link>
            </ScanHeader>
            <div className='padding-left-1dot25rem padding-right-1dot25rem padding-bottom-1dot125rem'>

            </div>
        </div>
    )
};

export default React.memo(ScanSearch);
import React from 'react';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../Skeleton';
import {FormattedMessage} from 'react-intl';
import {IMPULSE_STATUS, IMPULSE_STATUS_COLOR} from '../../constants';
import useSubscription from '../../useSubscription';
import classNames from 'classnames';
import {v4} from 'uuid';
import ErrorFallback from '../ErrorFallback';
import useSubscriptionStatus from '../../useSubscriptionStatus';

const SubscriptionImpulseImpl = React.memo(({claim, refreshedQueryOptionsDeferred, flag}) => {
    const subscriptionStatus = useSubscriptionStatus();
    const data = useLazyLoadQuery(
        graphql`
            query ImpulseDialogSubscriptionImpulseImplQuery($request: ClaimRequestInput) {
                impulseByClaim(request: $request) {
                    id
                    amount
                    impulseService {
                        name
                        demo
                    }
                    status
                    version
                    withdraw
                }
            }
        `,
        {request: {value: claim}},
        refreshedQueryOptionsDeferred
    );
    const [withdraw, applyingWithdraw] = useMutation(
        graphql`
            mutation ImpulseDialogSubscriptionImpulseImplMutation($request: WithdrawImpulseByClaimRequestInput) {
                withdrawImpulseByClaim(request: $request) {
                    id
                    version
                    withdraw
                }
            }
    `);
    const onWithdraw = () => withdraw({
        variables: {request: {value: claim, version: data.impulseByClaim.version}},
        onCompleted: () => {},
        onError: () => {}
    });
    return (
        <div>
            <div className={classNames({'opacity-0dot6': flag})}>
                {data.impulseByClaim.impulseService.demo &&
                <span className='tag tag-magenta margin-right-0dot5rem font-size-0dot8rem'><FormattedMessage defaultMessage='Demo'/></span>
                }
                <span className={`tag tag-${IMPULSE_STATUS_COLOR[data.impulseByClaim.status]} font-size-0dot8rem`}>
                    {IMPULSE_STATUS[data.impulseByClaim.status]}
                </span>
                <div className='font-weight-bold margin-top-0dot4rem display-flex align-items-center'>
                    <span className={classNames('dot margin-right-0dot2rem', {
                        'green': subscriptionStatus === 0
                    })}/>
                    {data.impulseByClaim.impulseService.name}
                    {data.impulseByClaim.withdraw &&
                    <span className='badge orangered margin-left-0dot25rem'>{data.impulseByClaim.withdraw}</span>
                    }
                </div>
                {data.impulseByClaim.amount &&
                <div className='margin-top-0dot2rem'>
                    <FormattedMessage defaultMessage='Amounts'/> <span className='font-weight-bold'>{data.impulseByClaim.amount}</span> UAH
                </div>
                }
            </div>
            <div className='display-flex margin-top-0dot4rem'>
                <div
                    className={classNames('button', {
                        'disabled': data.impulseByClaim.status !== 3
                    })}
                    onClick={() => data.impulseByClaim.status === 3 && !applyingWithdraw && onWithdraw()}
                ><FormattedMessage defaultMessage='Withdraw'/>{applyingWithdraw && '...'}</div>
            </div>
        </div>
    );
});

const ImpulseDialog = React.memo(({dialog: {impulseId, claim}}) => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState();
    const refreshedQueryOptionsDeferred = React.useDeferredValue(refreshedQueryOptions);
    const refresh = React.useCallback(() => {
        setRefreshedQueryOptions(prev => ({
            // ++fetchKey may not work as we could have cached 0-3 and on leave and return to the page we will
            // get 0-3 cached state
            fetchKey: v4(),
            fetchPolicy: 'network-only'
        }));
    }, []);
    const payload = React.useMemo(() => ({
        channel: `/impulses/${impulseId}`,
        onNext: () => refresh(),
        onError: (error) => {}
    }), [refresh, impulseId]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    return (
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}} payload={refreshedQueryOptions}>
            <React.Suspense fallback={<Skeleton/>}>
                <SubscriptionImpulseImpl {...{
                    claim,
                    refreshedQueryOptionsDeferred,
                    flag: refreshedQueryOptions !== refreshedQueryOptionsDeferred
                }}/>
            </React.Suspense>
        </ErrorBoundary>
    );
});

export default ImpulseDialog;

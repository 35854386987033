import React from 'react';
import {Link, useParams, useLocation} from 'react-router-dom';
import Marked from './../Marked';
import {Helmet} from 'react-helmet-async';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../Skeleton';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import searchFilter from '../../searchFilter';
import EndpointName from './EndpointName';
import EndpointImpl from './EndpointImpl';
import useQuery from '../../useQuery';
import {isTrue} from '../../utility';
import {adopt} from '../../math';
import {IN, ENTITY_TYPE} from '../../constants';
import useNow from '../../useNow';
import Mini from './mini/Mini';
import ErrorFallback from '../ErrorFallback';
import getServiceUrl from '../../utilities/get-service-url';
import i18n from '../../i18n';
import getLocaleQueryParams from '../../utilities/get-locale-query-params';

const RAZZLE_APP_ORIGIN = process.env.RAZZLE_APP_ORIGIN;

const ServiceEndpoint = React.memo(({endpoint, serviceById}) => {
    const {more} = useQuery();
    const location = useLocation();
    const fEndpoint = useFragment(
        graphql`
            fragment ServiceEndpoint_endpoint on Endpoint {
                id
                name
                price
                start
                end
                strategy
                every
                ...EndpointName_endpoint
                ...EndpointImpl_endpoint
            }
        `,
        endpoint
    );
    const fServiceById = useFragment(
        graphql`
            fragment ServiceEndpoint_serviceById on Service {
                id
            }
        `,
        serviceById
    );
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, fEndpoint.start, fEndpoint.end, fEndpoint.strategy, fEndpoint.every),
        [now, fEndpoint]);
    return (
        <div className='margin-top-1rem'>
            <div className='font-weight-bold'>
                <Link
                    className='focus font-weight-bold'
                    to={`/endpoints/${fEndpoint.id}${searchFilter(location.search, {serviceId: fServiceById.id})}`}
                >
                    <EndpointName {...{endpoint: fEndpoint}}/>
                </Link>
            </div>
            {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{fEndpoint.id}</div>}
            <Mini {...{
                start: fEndpoint.start,
                end: fEndpoint.end,
                strategy: fEndpoint.strategy,
                every: fEndpoint.every,
                entityType: ENTITY_TYPE.endpoint
            }}/>
            {state === IN &&
            <EndpointImpl {...{endpoint: fEndpoint, serviceId: fServiceById.id}}/>
            }
        </div>
    );
});

const Service = React.memo(() => {
    const {more, locale = 'uk'} = useQuery();
    const {id} = useParams();
    const data = useLazyLoadQuery(
        graphql`
            query ServiceQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    details
                    start
                    end
                    strategy
                    every
                    description
                    slug
                    warp
                    endpoints {
                        id
                        ...ServiceEndpoint_endpoint
                    }
                    ...ServiceEndpoint_serviceById
                }
            }
        `,
        {id}
    );
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, data.serviceById.start, data.serviceById.end, data.serviceById.strategy,
        data.serviceById.every), [now, data.serviceById]);
    const serviceUrl = getServiceUrl({description: data.serviceById.description, id: data.serviceById.id, slug: data.serviceById.slug, warp: data.serviceById.warp, location: {search: ''}});
    return (
        <>
        <Helmet>
            <title>{`${i18n(data.serviceById, 'name', locale)}: Купити квитки на ottry.com`}</title>
            <meta name='description' content={data.serviceById.name}/>
            <link rel='canonical' href={`${RAZZLE_APP_ORIGIN}${serviceUrl}${getLocaleQueryParams('', locale)}`}/>
            <link href={`${RAZZLE_APP_ORIGIN}${serviceUrl}${getLocaleQueryParams(location.search, 'uk')}`} rel='alternate' hreflang="uk"/>
            <link href={`${RAZZLE_APP_ORIGIN}${serviceUrl}${getLocaleQueryParams(location.search, 'en')}`} rel='alternate' hreflang="en"/>
        </Helmet>
        <div>
            <Marked {...{content: data.serviceById.details}}/>
        </div>
        {data.serviceById.details && data.serviceById.details.endsWith('!') ?
            <>
            </>
            :
            <>
            <div className='margin-top-1rem font-weight-bold'>{data.serviceById.name}</div>
            {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{data.serviceById.id}</div>}
            <Mini {...{
                start: data.serviceById.start,
                end: data.serviceById.end,
                strategy: data.serviceById.strategy,
                every: data.serviceById.every,
                entityType: ENTITY_TYPE.service
            }}/>
            {state === IN &&
            <div className='service__endpoints'>
                {data.serviceById.endpoints.map((endpoint) =>
                    <ServiceEndpoint key={endpoint.id} endpoint={endpoint} serviceById={data.serviceById}/>
                )}
            </div>
            }
            </>
        }
        </>
    );
});

export default React.memo(() =>
    <div className='margin-top-1dot5rem'>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<Skeleton/>}>
                <Service/>
            </React.Suspense>
        </ErrorBoundary>
    </div>
);
import React from 'react';
import {graphql, useFragment} from 'react-relay/hooks';
import {CartStateContext, CartDispatchContext} from '../../contexts/cart';
import {InstantDispatchContext} from '../../contexts/instant';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../searchFilter';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import i18n from "../../i18n";
import useQuery from '../../useQuery';

const config = {shouldValidate: true, shouldDirty: true};

const UntieAction = React.memo(({endpoint, serviceId}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const cartDispatch = React.useContext(CartDispatchContext);
    const instantDispatch = React.useContext(InstantDispatchContext);
    const fEndpoint = useFragment(
        graphql`
            fragment EndpointImplUntieAction_endpoint on Endpoint {
                id
                hints
                withCart
                withInstant
                instantSettings {
                    name
                    nameUk
                }
            }
        `,
        endpoint
    );
    const state = React.useContext(CartStateContext);
    const count = React.useMemo(
        () => state
            .filter(e => e.serviceId === serviceId && e.endpointId === fEndpoint.id)
            .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
        [state, fEndpoint, serviceId]);
    const countAmount = React.useMemo(
        () => state
            .filter(e => e.serviceId === serviceId && e.endpointId === fEndpoint.id)
            .reduce((accumulator, currentValue) => accumulator + (+currentValue.untie.price), 0),
        [state, fEndpoint, serviceId]);
    const {register, handleSubmit, formState: {errors}, setValue} = useForm();
    const cart = data => {
        cartDispatch({type: 'add', payload: {
            endpointId: fEndpoint.id,
            serviceId: serviceId,
            untie: {price: data.input, currency: 'uah'}
        }});
    };
    const instant = data => {
        instantDispatch({type: 'add', payload: {
            endpointId: fEndpoint.id,
            serviceId: serviceId,
            untie: {price: data.input, currency: 'uah'}
        }});
        navigate(`/checkout-new/vending/impulse${searchFilter(location.search, {landId: 0})}`);
    };
    const noFilter = !fEndpoint.withCart && !fEndpoint.withInstant;
    const {locale = 'uk'} = useQuery();
    return (
        <>
        <form onSubmit={handleSubmit(instant)} className='margin-top-1rem form'>
            <div className='input-label'><FormattedMessage defaultMessage='Amount'/> UAH <sup>*</sup></div>
            <div className='input'>
                <input
                    className='width-100percent'
                    type='number'
                    step='any'
                    placeholder='0.00'
                    {...register('input', {required: true, pattern: /^\d+(?:[.]\d{1,2})*$/})}
                />
            </div>
            {errors.input && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
            {fEndpoint.hints &&
            <div className='display-flex flex-wrap-wrap'>
            {fEndpoint.hints.map((item, index) =>
                <div
                    key={index}
                    className='button margin-right-0dot5rem margin-bottom-0dot5rem'
                    onClick={() => setValue('input', item, config)}
                >{item}</div>
            )}
            </div>
            }
            <div className='display-flex margin-top-1rem align-items-center'>
                {(fEndpoint.withCart || noFilter) &&
                <div className='button' onClick={handleSubmit(cart)}>
                    <FormattedMessage defaultMessage='Add to cart'/>
                </div>
                }
                {(fEndpoint.withInstant || noFilter) &&
                <div
                    className={classNames('button primary', {
                        'margin-left-0dot5rem': fEndpoint.withCart || noFilter
                    })}
                    onClick={handleSubmit(instant)}
                >
                    {fEndpoint.instantSettings ?
                        i18n(fEndpoint.instantSettings, 'name', locale)
                        :
                        <FormattedMessage defaultMessage='Buy'/>
                    }
                </div>
                }
            </div>
        </form>
        {!!count &&
        <div className='margin-top-0dot5rem'>
            <span className='badge'>
                {count}
                {!!countAmount && <span>/{countAmount} UAH</span>}
            </span>
            <Link
                className='link font-weight-bold margin-left-0dot5rem'
                to={`/checkout-new/cart${searchFilter(location.search)}`}
            ><FormattedMessage defaultMessage='View in cart'/></Link>
        </div>
        }
        </>
    );
});

const Action = React.memo(({serviceId, endpoint}) => {
    const {locale = 'uk'} = useQuery();
    const navigate = useNavigate();
    const location = useLocation();
    const cartDispatch = React.useContext(CartDispatchContext);
    const instantDispatch = React.useContext(InstantDispatchContext);
    const fEndpoint = useFragment(
        graphql`
            fragment EndpointImplAction_endpoint on Endpoint {
                id
                quantity
                withCart
                withInstant
                instantSettings {
                    name
                    nameUk
                }
            }
        `,
        endpoint
    );
    const state = React.useContext(CartStateContext);
    const count = React.useMemo(() =>
            state
                .filter(e => e.serviceId === serviceId && e.endpointId === fEndpoint.id)
                .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
        [state, fEndpoint, serviceId]);
    const {register, handleSubmit, formState: {errors}} = useForm();
    const cart = data => {
        cartDispatch({type: 'add', payload: {
            endpointId: fEndpoint.id,
            serviceId: serviceId,
            quantity: +data.quantity
        }});
    };
    const instant = data => {
        instantDispatch({type: 'add', payload: {
            endpointId: fEndpoint.id,
            serviceId: serviceId,
            quantity: +data.quantity
        }});
        navigate(`/checkout-new/vending/impulse${searchFilter(location.search, {landId: 0})}`);
    };
    const noFilter = !fEndpoint.withCart && !fEndpoint.withInstant;
    return (
        <>
        <form onSubmit={handleSubmit(cart)} className='margin-top-1rem form'>
            <div className='input-label'><FormattedMessage defaultMessage='Quantity'/> <sup>*</sup></div>
            <div className='select'>
                <select
                    defaultValue={1}
                    {...register('quantity', {required: true})}
                >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16].map((i) =>
                        <option key={i} value={i}>{i}</option>
                    )}
                </select>
            </div>
            {(fEndpoint.quantity < 16) && <div className='input-warning'>{fEndpoint.quantity} <FormattedMessage defaultMessage='left'/></div>}
            {errors.quantity && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
            <div className='display-flex margin-top-1rem align-items-center'>
                {(fEndpoint.withCart || noFilter) &&
                <div
                    className='button'
                    onClick={handleSubmit(cart)}
                >
                    <FormattedMessage defaultMessage='Add to cart'/>
                </div>
                }
                {(fEndpoint.withInstant || noFilter) &&
                <div
                    className={classNames('button primary', {
                        'margin-left-0dot5rem': fEndpoint.withCart || noFilter
                    })}
                    onClick={handleSubmit(instant)}
                >
                    {fEndpoint.instantSettings ?
                        i18n(fEndpoint.instantSettings, 'name', locale)
                        :
                        <FormattedMessage defaultMessage='Buy'/>
                    }
                </div>
                }
            </div>
        </form>
        {!!count &&
        <div className='margin-top-0dot5rem'>
            <span className='badge'>{count}</span>
            <Link
                className='link font-weight-bold margin-left-0dot5rem'
                to={`/checkout-new/cart${searchFilter(location.search)}`}
            ><FormattedMessage defaultMessage='View in cart'/></Link>
        </div>
        }
        </>
    );
});

const EndpointImpl = React.memo(({endpoint, serviceId}) => {
    const fEndpoint = useFragment(
        graphql`
            fragment EndpointImpl_endpoint on Endpoint {
                id
                untie
                ...EndpointImplUntieAction_endpoint
                ...EndpointImplAction_endpoint
            }
        `,
        endpoint
    );
    return (
        <>
        {serviceId &&
        <>
        {fEndpoint.untie ?
            <UntieAction {...{endpoint: fEndpoint, serviceId}}/>
            :
            <Action {...{endpoint: fEndpoint, serviceId}}/>
        }
        </>
        }
        </>
    );
});

export default EndpointImpl;

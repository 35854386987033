import React from 'react';
import {FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {queryParameters} from '../../../qs';
import {ErrorBoundary} from 'react-error-boundary';
import searchUnFilter from '../../../searchUnFilter';
import UserContactForm from './UserContactForm';
import ErrorFallback from '../../../components/ErrorFallback';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const NewUserDetails = ({registerFormField, serviceId, formState}) => {
    const location = useLocation();
    const qps = queryParameters(location);

    return (
        <div>
            <p className='color-gray-500 text-md padding-top-0dot5rem mw768-padding-top-0dot75rem padding-bottom-1dot5rem mw768-padding-bottom-2rem'>
                <FormattedMessage defaultMessage='Want to checkout with your social or Ottry account?'/>
                {' '}
                <a className='color-primary-600 text-decoration-underline cursor-pointer' onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                        window.location.href = RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&new=true&continue='
                            + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
                            + (qps.length ? '&' + qps.join('&') : '');
                    }, 500);
                }}><FormattedMessage defaultMessage='Log in'/></a>
            </p>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <UserContactForm {...{serviceId, registerFormField,  formState}}/>
            </ErrorBoundary>
        </div>
    )
};

export default React.memo(NewUserDetails);

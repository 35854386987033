import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import useQuery from '../../../useQuery';
import {isTrue} from '../../../utility';

function CartEntry({entry}) {
    const {more} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query CartEntryQuery($endpointId: String, $serviceId: String) {
                endpointById(id: $endpointId) {
                    id
                    name
                    price
                }
                serviceById(id: $serviceId) {
                    id
                    name
                }
            }
        `,
        {endpointId: entry.endpointId, serviceId: entry.serviceId}
    );
    return (
        <>
        <div className='font-weight-bold'>
            {data.serviceById.name}
        </div>
        {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{entry.serviceId}</div>}
        <div className='font-weight-bold margin-top-0dot5rem'>
            {data.endpointById.name} {data.endpointById.price || (entry.untie && entry.untie.price)} UAH
            {!entry.untie && <span className='badge margin-left-0dot25rem'>{entry.quantity}</span>}
        </div>
        {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{entry.endpointId}</div>}
        </>
    );
}

export default React.memo(CartEntry);
import React from 'react';
import './seat-map.scss';
import classNames from 'classnames';
import Octagon from './Octagon.svg';
import FCFC_SEAT_MAPS from './seats';
import getTagValue from '../../../utilities/get-tag-value';

const ROWS_AMOUNT = 5;
const MAX_SEAT_AMOUNT = 8;
const SEATMAPS_STYLES = {
    default: {
        marginTop: '5rem',
        width: 767,
        height: 720
    },
    updated: {
        marginTop: '2rem',
        width: 767,
        height: 789
    }
}

const Seat = React.memo(({endpoint, priceCategories, localCartState, toggleEndpoint, x, y, rx, transform, width, height, svgTag = 'rect', fill, stroke, d, mapStatic}) => {
    // const row = endpoint.tags.find((e) => e.includes('row')).replace('row=', '');
    // const seat = endpoint.tags.find((e) => e.includes('seat')).replace('seat=', '');
    // const priceTag = endpoint.tags.find((e) => e.includes('price'));
    // const price = priceTag ? priceTag.replace('price=', '') : null;
    const colorClass = (!endpoint || endpoint.quantity === 0) ? `${svgTag === 'rect' ? 'seat' : svgTag}--disabled` :  `${svgTag === 'rect' ? 'seat' : svgTag}--color-${priceCategories.indexOf(endpoint.price)}`;
    return (
        <>
            {!!endpoint && 
                <>
                    {svgTag === 'rect' && <rect {...{x, y, rx, transform, width, height}} className={classNames(`seat ${colorClass}`, {'seat--selected': localCartState.includes(endpoint.id)})} onClick={() => toggleEndpoint(endpoint.id)}/>}
                    {svgTag === 'path' && mapStatic && <path {...{d, fill, stroke}}/>}
                    {svgTag === 'path' && !mapStatic && <path {...{d}} className={classNames(`path ${colorClass}`, {'path--selected': localCartState.includes(endpoint.id)})} onClick={() => toggleEndpoint(endpoint.id)}/>}
                </>
            }
            {!endpoint &&
                <>
                    {svgTag === 'rect' && <rect {...{x, y, rx, transform, width, height}} className='seat seat--disabled' />}
                    {svgTag === 'path' && mapStatic && <path {...{d, fill, stroke}}/>}
                    {svgTag === 'path' && !mapStatic && <path {...{d}} className={`path path--disabled`}/>}
                </>
            }
        </>
    )
});

const FcfcSeatMap = ({service, toggleEndpoint, localCartState}) => {
    const priceCategories = [];
    const mapName = service.description ? getTagValue(service.description.split(', '), 'seatmap') : '';
    const seatMapName = mapName || 'updated';
    const seatMap = FCFC_SEAT_MAPS[seatMapName];
    service.endpoints.forEach((item) => {
        const sector = getTagValue(item.tags, 'sector', {separator: '='});
        const row = getTagValue(item.tags, 'row', {separator: '='});
        const seat = getTagValue(item.tags, 'seat', {separator: '='});
        if (sector && row && seat && seatMap[sector] && seatMap[sector][row - 1] && (row <= ROWS_AMOUNT) && (seat <= MAX_SEAT_AMOUNT)) {
            seatMap[sector][row - 1][seat - 1].endpoint = {...item};
            if (!priceCategories.includes(item.price)) {
                priceCategories.push(item.price);
            }
        }
    });
    priceCategories.sort((a, b) => b - a);
    return(
        <div className='seat-map'>
            <div className='padding-top-1rem padding-bottom-1rem mw768-padding-bottom-2rem mw768-padding-top-1dot5rem display-grid gap-1rem-1rem price-grid'>
                {priceCategories.map((price, index) =>
                    <div key={index} className='display-flex align-items-center'>
                        <div className={`seat seat--price pointer-events-none seat--color-${index}`}></div>
                        <p className='padding-left-0dot5rem text-md color-gray-200 white-space-nowrap'>{price} ₴</p>
                    </div>
                )}
            </div>
            <div className='seat-map-container'>
                <div className='padding-top-1dot5rem margin-0-auto position-relative width-47dot93755rem'>
                    <Octagon className={`display-block position-absolute top-1dot5rem left-50percent transform-translateX-center z-index-0`}/>
                    <svg className={`display-block margin-top-${SEATMAPS_STYLES[seatMapName].marginTop} margin-0-auto fcfc-seats position-relative`} width={SEATMAPS_STYLES[seatMapName].width} height={SEATMAPS_STYLES[seatMapName].height} fill="none" xmlns="http://www.w3.org/2000/svg">
                        {
                            Object.keys(seatMap).map(sector => 
                                seatMap[sector].map((row, rowIndex) =>
                                    row.map(({x, y, rx, transform, width, height, endpoint, svgTag, fill, stroke, d, mapStatic}, seatIndex) =>
                                        <Seat key={`${sector}-${rowIndex}-${seatIndex}`} {...{endpoint, localCartState, priceCategories, toggleEndpoint, x, y, rx, transform, width, height, svgTag, fill, stroke, d, mapStatic}}/>
                                    )
                                )
                            )
                        }
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default React.memo(FcfcSeatMap);
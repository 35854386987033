import {DAILY, MONTHLY, OUT_NEGATIVE, OUT_POSITIVE, IN, PARTNER, YEARLY, COUNTDOWN_CONSTRAINTS} from './constants';
import moment from 'moment';

const getIndependenceType = (independenceType) => independenceType === null ? PARTNER : independenceType;

const adopt = (zero, one, two, three, four) => {
    const timeSpanStart = one ? moment(one) : null;
    const timeSpanEnd = two ? moment(two) : null;
    const strategy = three;
    const every = four !== null ? four : null;
    const now = moment(zero);
    let starts = null;
    let start = null;
    let ends = null;
    let end = null;
    if (timeSpanStart || timeSpanEnd) {
        if (timeSpanStart)  {
            starts = timeSpanStart; // Ongoing TimeSpan
            start = timeSpanStart; // wait TimeSpan
        }
        if (timeSpanEnd) {
            ends = timeSpanEnd;
            end = timeSpanEnd; // left to use TimeSpan
        }
        if (
            (strategy !== null  && every !== null)
            && (timeSpanStart)
            && (now.isSameOrAfter(timeSpanStart)) // Only applicable if we reached timeSpanStart.
        ) {  // Aha let's try override
            let strategyMoment = null;
            switch (strategy) {
                case DAILY: strategyMoment = 'days'; break;
                case MONTHLY: strategyMoment =  'months'; break;
                case YEARLY: strategyMoment =  'years'; break;
                default: throw new Error();
            }
            let offset = now.diff(timeSpanStart, strategyMoment);
            const steps = Math.floor(offset / every);
            const offsetCurrent = steps * every;
            let rtssCurrent = timeSpanStart.clone().add(offsetCurrent, strategyMoment);
            let rtssCurrentEnds = timeSpanEnd ? timeSpanEnd.clone().add(offsetCurrent, strategyMoment) : null;
            const offsetNext = (steps + 1) * every;
            let rtssNext = timeSpanStart.clone().add(offsetNext, strategyMoment);
            let rtssNextEnds = timeSpanEnd ? timeSpanEnd.clone().add(offsetNext, strategyMoment) : null;
            starts = rtssCurrent;
            start = (!timeSpanEnd) ?
                rtssCurrent // There is no endings. From start to start then.
                :
                (now.isBefore(rtssCurrentEnds)) ? rtssCurrent : rtssNext;
            ends = rtssCurrentEnds;
            // In case we are endable let's notify on ending
            if (timeSpanEnd)
                end = (start.isSame(rtssCurrent)) ? rtssCurrentEnds : rtssNextEnds; // current : next
        }
    }
    let from = starts, to = ends, state, timeLeft = {};
    if (end && now.isAfter(end)) {
        state = OUT_NEGATIVE;
    } else if ((!end || now.isBefore(end)) && (!start || now.isSameOrAfter(start))) {
        state = IN;
        if (end) {
            const difference = +end - +now;
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }
    } else if (start && now.isBefore(start)) {
        state = OUT_POSITIVE;
        from = start;
        to = end;
        const difference = +from - +now;
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }
    // console.log(
    //     now.isAfter(end),
    //     (!ends || now.isBefore(ends)) && (!starts || now.isSameOrAfter(starts)),
    //     now.isBefore(start)
    // );
    // console.log('adopt');
    return {from, to, timeLeft, state};
};

const checkShowCountdown = (now, state, to, from, entityType, countdownGapAmount, countdownGapUnits) => {
    const start = moment(from);
    const end = moment(to);

    if (!entityType) return true;
    if (!countdownGapAmount || !countdownGapUnits) {
        countdownGapAmount = COUNTDOWN_CONSTRAINTS[entityType].amount;
        countdownGapUnits = COUNTDOWN_CONSTRAINTS[entityType].units;
    }
    const momentNow = moment(now);

    switch (state) {
        case IN:
            return !end || momentNow.isSameOrAfter(end.subtract(countdownGapAmount, countdownGapUnits));
        case OUT_POSITIVE:
            return !start || momentNow.isSameOrAfter(start.subtract(countdownGapAmount, countdownGapUnits));
        default:
            return false;
    }
}

export {getIndependenceType, adopt, checkShowCountdown};
import React from 'react';
import {Route, Routes} from 'react-router-dom';
import Service from './service';
import Endpoint from './endpoint';

export default React.memo(() =>
    <div className='pages'>
        <div className='master-wash'>
            <Routes>
                <Route path='/'>
                    <Route path='services/:id' element={<Service/>}/>
                    <Route path='endpoints/:id' element={<Endpoint/>}/>
                    <Route path='*' element={<>Comming soon...</>}/>
                </Route>
            </Routes>
        </div>
    </div>
);
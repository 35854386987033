import React from 'react';
import i18n from '../../i18n';
import useQuery from '../../useQuery';
import Button from '../../components/button/button';
import {FormattedMessage} from 'react-intl';
import Mail01Icon from '../../assets/icons0/mail-01.svg';
import PhoneIcon from '../../assets/icons0/phone.svg';
import {useLocation, useNavigate} from 'react-router-dom';
import searchFilter from '../../searchFilter';

const RAZZLE_ADDL_PHONE_ID = process.env.RAZZLE_ADDL_PHONE_ID;

const ImpulseViewContacts = ({email, addls, impulseId, isMerchant}) => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const navigate = useNavigate();
    const phoneAddl = addls && addls.find(item => item.addl.id === RAZZLE_ADDL_PHONE_ID);
    const editAction = () => {
        navigate(`update-impulse/${impulseId}${searchFilter(location.search)}`);
    }
    return (
        <>
            <div className='padding-top-1dot25rem padding-bottom-1dot25rem padding-left-1dot25rem background-color-gray-50'>
                <div className='display-flex align-items-center padding-bottom-1rem border-bottom-1px-solid border-color-gray-200 padding-right-1dot25rem'>
                    <p className='text-lg medium color-gray-900 padding-right-1rem flex-1'><FormattedMessage defaultMessage='Contact information'/></p>
                    {isMerchant &&
                        <Button {...{color: 'pale', plain: true, size: 'md', clickHandler: editAction}}>
                            <FormattedMessage defaultMessage='Edit'/>
                        </Button>
                    }
                </div>
                <div className='display-flex align-items-center padding-top-1rem padding-bottom-1rem padding-right-1rem'>
                    <div className='padding-right-1dot5rem flex-1 text-sm'>
                        <p className='color-gray-900 medium'><FormattedMessage defaultMessage='Email'/></p>
                        <p className='color-gray-500'>{email}</p>
                    </div>
                    <a href={`mailto:${email}`} className='display-block padding-0dot625rem'>
                        <Mail01Icon className='display-block width-1dot25rem height-1dot25rem color-gray-500'/>
                    </a>
                </div>
                {phoneAddl &&
                    <div className='display-flex align-items-center padding-top-1rem padding-bottom-1rem padding-right-1rem'>
                        <div className='padding-right-1dot5rem flex-1 text-sm'>
                            <p className='color-gray-900 medium'>{i18n(phoneAddl, 'name', locale)}</p>
                            <p className='color-gray-500'>{phoneAddl.value}</p>
                        </div>
                        <a href={`tel:${phoneAddl.value}`} className='display-block padding-0dot625rem'>
                            <PhoneIcon className='display-block width-1dot25rem height-1dot25rem color-gray-500'/>
                        </a>
                    </div>
                }
            </div>
        </>
    )
}

export default React.memo(ImpulseViewContacts);
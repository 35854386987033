import {graphql} from 'react-relay/hooks';

export const graphqlSubscriptionEndpointByIdQuery = graphql`
    query graphqlSubscriptionEndpointByIdQuery($endpointId: String) {
        endpointById(id: $endpointId) {
            id
            name
            nameUk
            price
            tick
            cron
        }
    }
`;

export const graphqlSubscriptionServiceByIdQuery = graphql`
    query graphqlSubscriptionServiceByIdQuery($serviceId: String) {
        serviceById(id: $serviceId) {
            id
            demo
            mrchntd
            name
            nameUk
        }
    }
`;

export const graphqlSubscriptionRecurringsByViewerQuery = graphql`
    query graphqlSubscriptionRecurringByViewerQuery($first: Int!, $after: String) {
        viewer {
            recurringsByViewer(first: $first, after: $after) @connection(key: "Recurrings_recurringsByViewer") {
                edges {
                    node {
                        id
                        recurringEndpoints {
                            endpoint {
                                id
                            }
                        }
                        recurringService {
                            service {
                                id
                            }
                        }   
                    }
                }
            }
        }
    }
`;
import React from 'react';
import CompleteContainer from './container';
import CompleteContent from './content';
import CompleteImpulseStateIcon from './impulse-state-icon';
import CompleteDescription from './description';
import CompleteAction from './action';
import CompleteFooter from './footer';

const CompletePage = ({title, message}) => {
    return (
        <CompleteContainer>
            <CompleteContent>
                <CompleteImpulseStateIcon/>
                <CompleteDescription {...{title, message}}/>
                <CompleteAction/>
            </CompleteContent>
            <CompleteFooter/>
        </CompleteContainer>
    )
};

export default React.memo(CompletePage);
import React from 'react';
import i18n from '../../i18n';
import useQuery from '../../useQuery';
import Ticket01Icon from '../../assets/icons0/ticket-01.svg';
import XCloseIcon from '../../assets/icons0/x-close.svg';
import moment from 'moment';
import Button from '../../components/button/button';
import {FormattedMessage} from 'react-intl';
import {minTwoDigits} from '../../utility';
import Badge from './badge';

const dynamicaTranslations = {
    ticket: {
        1: {
            title: 'Tickets',
            titleUk: 'Квиток'
        },
        2: {
            title: 'Tickets',
            titleUk: 'Квитки'
        },
        3: {
            title: 'Tickets',
            titleUk: 'Квитки'
        },
        4: {
            title: 'Tickets',
            titleUk: 'Квитки'
        },
        default: {
            title: 'Tickets',
            titleUk: 'Квитків'
        }
    }
}

const IndicatorHeader = ({impulse, color, endpointsAmount, position, index, close, qrsArray}) => {
    const {locale = 'uk'} = useQuery();
    const num = qrsArray.findIndex((qr) => qr.qrIndex === index && qr.endpointIndex === position);
    const remainder = (endpointsAmount - 1) % 10;
    const translationForTicket = dynamicaTranslations.ticket[remainder] ? dynamicaTranslations.ticket[remainder] : dynamicaTranslations.ticket.default;
    return (
        <>
            <div className={`display-flex position-sticky top-0 border-top-right-radius-0dot75rem border-top-left-radius-0dot75rem padding-1dot25rem border-bottom-1px-solid align-items-center border-color-${color}-200 background-color-${color}-600`}>
                <div className={`margin-right-0dot75rem color-${color}-600 border-radius-50percent padding-0dot375rem outline-offset-minus1px outline-4px-solid outline-color-${color}-25 background-color-${color}-25 border-none`}>
                    <Ticket01Icon className='display-block width-1dot25rem height-1dot25rem'/>
                </div>
                <p className='flex-1 color-white text-sm medium'>
                    <span>{i18n(impulse.impulseService, 'name', locale)}</span>
                    {impulse.impulseService.service.target && <span> | {moment(impulse.impulseService.service.target).format('DD.MM.YYYY')}</span>}
                </p>
                <XCloseIcon onClick={() => close()} className={`display-block width-1dot25rem height-1dot25rem color-${color}-25 cursor-pointer`}/>
            </div>
            <div className={`padding-top-1dot25rem padding-bottom-2rem padding-left-1dot25rem padding-right-1dot25rem background-color-${color}-600 border-bottom-1px-solid border-color-gray-200 min-height-6dot8125rem`}>
                <p className={`text-md padding-bottom-0dot5rem color-${color}-25`}>
                    <span className='medium'>{minTwoDigits(num + 1)} {i18n(impulse.impulseEndpoints[position], 'name', locale)}</span>
                    <span> {impulse.impulseEndpoints[position].price}₴</span>
                </p>
                <div className='display-flex text-xs medium'>
                    {endpointsAmount > 1 && <Badge {...{classes: `color-white background-color-${color}-500`}}>+{endpointsAmount - 1} {i18n(translationForTicket, 'title', locale)}</Badge>}
                </div>
            </div>
        </>
    )
}

export const ImpulseViewIndicatorHeader = React.memo(IndicatorHeader);

const BasicHeader = ({impulse, endpointsAmount, position, index, take, isTaking, scansAmount, close, isMerchant, qrsArray, showCheckInConfirmation}) => {
    const {locale = 'uk'} = useQuery();
    const num = qrsArray.findIndex((qr) => qr.qrIndex === index && qr.endpointIndex === position);
    const checkIn = () => {
        if (scansAmount === 0) {
            take({position, index});
        } else {
            showCheckInConfirmation();
        }
    };
    const remainder = (endpointsAmount - 1) % 10;
    const translationForTicket = dynamicaTranslations.ticket[remainder] ? dynamicaTranslations.ticket[remainder] : dynamicaTranslations.ticket.default;
    return (
        <>
            <div className='background-color-white border-top-right-radius-0dot75rem border-top-left-radius-0dot75rem display-flex padding-1dot25rem border-bottom-1px-solid border-color-gray-200 align-items-center position-sticky top-0 z-index-1'>
                <div className='outline-4px-solid outline-color-gray-100 margin-right-0dot75rem color-gray-600 border-radius-50percent padding-0dot375rem'>
                    <Ticket01Icon className='display-block width-1dot25rem height-1dot25rem'/>
                </div>
                <p className='flex-1 color-gray-700 text-sm medium'>
                    <span>{i18n(impulse.impulseService, 'name', locale)}</span>
                    {impulse.impulseService.service.target && <span> | {moment(impulse.impulseService.service.target).format('DD.MM.YYYY')}</span>}
                </p>
                <XCloseIcon onClick={() => close()} className='display-block width-1dot25rem height-1dot25rem color-gray-500 cursor-pointer'/>
            </div>
            <div className='background-color-white padding-top-1dot25rem padding-bottom-2rem padding-left-1dot25rem padding-right-1dot25rem display-flex border-bottom-1px-solid border-color-gray-200 min-height-6dot8125rem'>
                <div className='flex-1 padding-right-0dot75rem'>
                    <p className='text-md padding-bottom-0dot5rem'>
                        <span className='medium color-gray-700'>{minTwoDigits(num + 1)} {i18n(impulse.impulseEndpoints[position], 'name', locale)}</span>
                        <span className='color-gray-500'> {impulse.impulseEndpoints[position].price}₴</span>
                    </p>
                    <div className='display-flex text-xs medium'>
                        {!scansAmount && <Badge {...{classes: 'color-success-600 background-color-success-50'}}><FormattedMessage defaultMessage='Valid'/></Badge>}
                        {!!scansAmount && <Badge {...{classes: 'color-error-25 background-color-error-600'}}><FormattedMessage defaultMessage='Used'/></Badge>}
                        {endpointsAmount > 1 && <Badge {...{classes: 'color-primary-600 background-color-primary-50'}}>+{endpointsAmount - 1} {i18n(translationForTicket, 'title', locale)}</Badge>}
                    </div>
                </div>
                <div className='flex-basis-6dot75rem'>
                    {isMerchant &&
                        <Button {...{color: `${scansAmount == 0 ? 'primary-success-gradient' : 'secondary-gray'}`, size: '2xl', fluid: 'always', clickHandler: checkIn, additionalClasses: `scan-btn ${scansAmount == 0 ? '' : 'check-in-all-btn'}`, isLoading: isTaking}}>
                            <FormattedMessage defaultMessage='Check-in'/>
                        </Button>
                    }
                </div>
            </div>
        </>
    )
}

export const ImpulseViewBasicHeader = React.memo(BasicHeader);
import React from 'react';
import {FormattedMessage} from 'react-intl';
import ImpulsePage from '../impulse/Impulse';
import {useInstantDispatch, useInstantState} from '../../../contexts/instant';
import Skeleton from '../../../components/Skeleton';

const PaymentLinkImpulsePage = () => {
    const state = useInstantState();
    const dispatch = useInstantDispatch();
    const subtitle = <FormattedMessage defaultMessage='To reserve your order for 15 min. please enter your contact details and click the "Proceed with ordering" button'/>;
    const message = <FormattedMessage defaultMessage='The order will be sent to the specified email'/>;
    const dynamicEnpointQuantity = true;
    return (
        <ImpulsePage {...{state, dispatch, subtitle, message, dynamicEnpointQuantity}}/>
    )
};

export default React.memo(PaymentLinkImpulsePage);

import React from 'react';
import '../../menu/MenuSmall.scss';
import {queryParameters} from '../../../qs';
import {Link, useLocation} from 'react-router-dom';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import searchUnFilter from '../../../searchUnFilter';
import {graphqlWhoamiNextQuery} from '../../graphql';
import {useAuthorizationState} from '../../../contexts/Authorization';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const AuthorizedUserMenu = () => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const location = useLocation();
    const qps = queryParameters(location);
    return (
        <>
            <li>
                <div
                    className='padding-left-20px padding-right-20px font-weight-bold overflow-wrap-break-word line-height-40px'
                >{whoamiNext.username || whoamiNext.email}</div>
            </li>
            <li className='menu-small__section-item menu-small__section-item-with-border'>
                <a onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                        window.location.href = RAZZLE_APP_ACCOUNTS + '/home?cache=0&new=true'
                            + (qps.length ? '&' + qps.join('&') : '');
                    }, 500);
                }}>
                    <FormattedMessage defaultMessage='Profile'/>
                </a>
            </li>
            <li className='menu-small__section-item menu-small__section-item-with-border'>
                <Link to={`/log-out${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Log out'/></Link>
            </li>
        </>
    )
}

function MenuSmall({menuSmallRef, menuFlag, closeMenu}) {
    const authorized = useAuthorizationState();
    const location = useLocation();
    const qps = queryParameters(location);
    return (
        <div className='menu-small' ref={menuSmallRef}>
            <div className={`menu-small__content hide-large ${menuFlag ? 'menu-small__content--active' : ''}`}>
                <div className='menu-small__section'>
                    <div className='menu-small__section-header'>
                        <FormattedMessage defaultMessage='E-commerce'/>
                    </div>
                    <ul className='menu-small__section-list'>
                        <li className='menu-small__section-item'>
                            <Link to={`.${searchFilter(location.search)}`} onClick={closeMenu}>
                                <FormattedMessage defaultMessage='Marketplace'/>
                            </Link>
                        </li>
                        <li className='menu-small__section-item menu-small__section-item-with-border'>
                            <Link to={`scan${searchFilter(location.search)}`} onClick={closeMenu}>
                                <FormattedMessage defaultMessage='Scan'/>
                            </Link>
                        </li>
                    </ul>
                    <div className='menu-small__section-header'>
                        <FormattedMessage defaultMessage='Miscellaneous'/>
                    </div>
                    <ul className='menu-small__section-list'>
                        <li className='menu-small__section-item'>
                            <Link to={`faq${searchFilter(location.search)}`} onClick={closeMenu}>
                                <FormattedMessage defaultMessage='FAQ'/>
                            </Link>
                        </li>
                        {authorized &&
                        <li className='menu-small__section-item menu-small__section-item-with-border'>
                            <Link to={`/cabinet${searchFilter(location.search)}`} onClick={closeMenu}>
                                <FormattedMessage defaultMessage='Cabinet'/>
                            </Link>
                        </li>
                        }
                    </ul>
                    <div className='menu-small__section-header'>
                        <FormattedMessage defaultMessage='My account'/>
                    </div>
                    <ul className='menu-small__section-list'>
                        {authorized ?
                            <AuthorizedUserMenu/>
                            :
                            <li className='menu-small__section-item'>
                                <a onClick={(e) => {
                                    e.preventDefault();
                                    setTimeout(() => {
                                        window.location.href = RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&new=true&continue='
                                            + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
                                            + (qps.length ? '&' + qps.join('&') : '');
                                    }, 500);
                                }}>
                                    <FormattedMessage defaultMessage='Sign in'/>
                                </a>
                            </li>
                        }

                    </ul>
                </div>
            </div>
        </div>
    );
}

export default React.memo(MenuSmall);

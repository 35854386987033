import React from 'react';
import './Body.scss';
import searchFilter from '../../searchFilter';
import {Link, useLocation} from 'react-router-dom';

function Body({toggle}) {
    const location = useLocation();
    return (
        <div className='products-body'>
            <div className='background-black color-white'>
                <div className='display-flex flex-direction-column padding-2rem'>
                    <div className='display-flex flex-direction-adaptive flex-wrap-wrap'>
                        <div className='card'>
                            <div className='padding-2rem background-darkslateblue'>
                                <div className='font-size-1dot4rem font-weight-bold'>Ottry</div>
                                <div className='margin-top-1rem'>
                                    Use one platform to buy and sell services, anywhere online with internet stores, with a global reach. Now sellers and buyers can meet in the virtual world, without the hindrance of location.
                                </div>
                            </div>
                        </div>
                        <div className='card'>
                            <div className='padding-2rem'>
                                <div
                                    className='border-bottom-1px-solid grey padding-bottom-0dot2rem'
                                >SERVICES</div>
                                <div className='color-lightgrey font-size-0dot8rem margin-bottom-1rem padding-top-0dot5rem'>
                                    We are constantly growing and expanding our boundaries. Any new business need? Just let us know!
                                </div>
                                <div><Link
                                    onClick={toggle}
                                    to={`/${searchFilter(location.search)}`}
                                >Ticketing</Link></div>
                                <div><Link
                                    onClick={toggle}
                                    to={`/vending-machines${searchFilter(location.search)}`}
                                >Vending machines</Link></div>
                                <div><Link
                                    onClick={toggle}
                                    to={`/${searchFilter(location.search)}`}
                                >Online shopping</Link></div>
                                <div><Link
                                    onClick={toggle}
                                    to={`/queues${searchFilter(location.search)}`}
                                >Queues</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className='display-flex flex-wrap-wrap'>
                        <div className='card four'>
                            <div className='padding-2rem'>
                                <div className='border-bottom-1px-solid grey padding-bottom-0dot2rem'>
                                    CONTACTS
                                </div>
                                <div className='color-lightgrey font-size-0dot8rem margin-bottom-1rem padding-top-0dot5rem'>
                                    Available for you 24/7 here
                                </div>
                                <div>
                                    <p><a className='focus' href='tel:+380979381162'>+38 097 938 1162</a></p>
                                    <p><a className='focus' href='mailto:info@ottry.com'>info@ottry.com</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(Body);

import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import ScanHeader from './header';
import Button from '../../components/button/button';
import IconFeature from '../../components/icon-feature/icon-feature';
import Maximize02Icon from '../../assets/icons0/maximize-02.svg';
import Speedometer03Icon from '../../assets/icons0/speedometer-03.svg';
import Rocket02Icon from '../../assets/icons0/rocket-02.svg';
import SearchMdIcon from '../../assets/icons0/search-md.svg';
import {useScanSettingsDispatch, useScanSettingsState} from './settings-context';
import Browser from '../../components/Browser';
import searchFilter from '../../searchFilter';
import {SCAN_MODE_DEFAULT, SCAN_MODE_FAST, SCAN_MODE_ROCKET} from '../../constants';

const ScanModes = ({mode, setSettings}) => {
    return (
        <>
            <div className={classNames('padding-0dot75rem border-1px-solid border-radius-0dot5rem cursor-pointer margin-bottom-0dot75rem', {'border-color-primary-300 background-color-primary-50 color-primary-800': mode === SCAN_MODE_DEFAULT, 'border-color-gray-200 color-gray-700': mode !== SCAN_MODE_DEFAULT})} onClick={() => setSettings({mode: SCAN_MODE_DEFAULT, autoTake: false})}>
                <div className='display-flex align-items-center'>
                    <IconFeature {...{color: 'primary', feature: 'outline', size: 'md'}}>
                        <Maximize02Icon className='display-block'/>
                    </IconFeature>
                    <p className='padding-left-0dot75rem text-md medium'><FormattedMessage defaultMessage='Basic'/></p>
                </div>
            </div>
            <div className={classNames('padding-0dot75rem border-1px-solid border-radius-0dot5rem cursor-pointer margin-bottom-0dot75rem', {'border-color-primary-300 background-color-primary-50 color-primary-800': mode === SCAN_MODE_FAST, 'border-color-gray-200 color-gray-700': mode !== SCAN_MODE_FAST})} onClick={() => setSettings({mode: SCAN_MODE_FAST, autoTake: true})}>
                <div className='display-flex align-items-center'>
                    <IconFeature {...{color: 'primary', feature: 'outline', size: 'md'}}>
                        <Speedometer03Icon className='display-block'/>
                    </IconFeature>
                    <p className='padding-left-0dot75rem text-md medium'><FormattedMessage defaultMessage='Fast'/></p>
                </div>
            </div>
            <div className={classNames('padding-0dot75rem border-1px-solid border-radius-0dot5rem cursor-pointer margin-bottom-0dot75rem', {'border-color-primary-300 background-color-primary-50 color-primary-800': mode === SCAN_MODE_ROCKET, 'border-color-gray-200 color-gray-700': mode !== SCAN_MODE_ROCKET})} onClick={() => setSettings({mode: SCAN_MODE_ROCKET, autoTake: true})}>
                <div className='display-flex align-items-center'>
                    <IconFeature {...{color: 'primary', feature: 'outline', size: 'md'}}>
                        <Rocket02Icon className='display-block'/>
                    </IconFeature>
                    <p className='padding-left-0dot75rem text-md medium'><FormattedMessage defaultMessage='Rocket'/></p>
                </div>
            </div>
        </>
    )
}

const ScanSettings = () => {
    const scanSettings = useScanSettingsState();
    const [settings, setSettings] = React.useState(scanSettings);
    const dispatch = useScanSettingsDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const saveSettings = () => {
        dispatch({type: 'update', payload: {...settings}});
        navigate(`../${searchFilter(location.search)}`);
    }
    const cancelSettings = () => {
        navigate(-1);
    }
    return (
        <div className='scan-settings background-color-white'>
            <ScanHeader>
                <Link to={`../search${searchFilter(location.search)}`} className='padding-0dot25rem'>
                    <SearchMdIcon className='height-1dot5rem width-1dot5rem color-gray-500 display-block'/>
                </Link>
            </ScanHeader>
            <div className='padding-left-1dot25rem padding-right-1dot25rem padding-top-2rem border-top-1px-solid border-color-gray-200'>
                <p className='text-xl color-gray-900 semibold padding-bottom-1dot5rem'><FormattedMessage defaultMessage='Scanner settings'/></p>
                <Browser>
                    <ScanModes {...{mode: settings.mode, setSettings}}/>
                </Browser>
                <div className='padding-top-0dot75rem display-flex'>
                    <div className='flex-1 margin-right-0dot75rem'>
                        <Button {...{size: 'lg', color: 'secondary-gray', fluid: 'always', clickHandler: cancelSettings}}>
                            <FormattedMessage defaultMessage='Cancel'/>
                        </Button>
                    </div>
                    <div className='flex-1'>
                        <Button {...{size: 'lg', color: 'primary-gradient', fluid: 'always', clickHandler: saveSettings}}>
                            <FormattedMessage defaultMessage='Confirm'/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default React.memo(ScanSettings);
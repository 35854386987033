import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../../Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import useSubscription from '../../../../useSubscription';
import classNames from 'classnames';
import ErrorFallback from '../../../ErrorFallback';
import useSubscriptionStatus from '../../../../useSubscriptionStatus';

const QueueImpl = React.memo(({refreshedQueryOptions, flag}) => {
    const data = useLazyLoadQuery(
        graphql`
            query QueueQueueImplQuery($request: ImpulseFragmentsRequestInput) {
                impulseFragments(request: $request) {
                    sequence
                    status
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    const verifying = React.useMemo(() =>
        data.impulseFragments
            .filter(e => e.status === 3)
            .sort((foo, bar) => foo.sequence - bar.sequence),
        [data]);
    const complete = React.useMemo(() =>
        data.impulseFragments
            .filter(e => e.status === 4)
            .sort((foo, bar) => foo.sequence - bar.sequence),
        [data]);
    return (
        <div className={classNames({'opacity-0dot6': flag})}>
            <div className='display-flex'>
                <div className='display-flex flex-direction-column'>
                    <div className='font-weight-bold padding-1rem'>Verifying</div>
                    {verifying.map(e =>
                        <div className='text-align-center' key={e.sequence}>{e.sequence}</div>
                    )}
                </div>
                <div className='display-flex flex-direction-column'>
                    <div className='font-weight-bold padding-1rem'>Complete</div>
                    {complete.map(e =>
                        <div className='text-align-center' key={e.sequence}>{e.sequence}</div>
                    )}
                </div>
            </div>
        </div>
    );
});

const Queue = React.memo(({id}) => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {request: {service: id, fetchKey: 0}}
    });
    const [isPending, startTransition] = React.useTransition();
    const refresh = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    request: {
                        ...prev.variables.request,
                        fetchKey: prev.variables.request.fetchKey + 1
                    }
                }
            }));
        });
    }, []);
    const payload = React.useMemo(() => ({
        channel: `/services/${id}`,
        onNext: () => refresh(),
        onError: (error) => {}
    }), [refresh, id]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    return (
        <QueueImpl {...{
            refreshedQueryOptions,
            flag: isPending
        }}/>
    );
});

export default React.memo(({id}) => {
    const subscriptionStatus = useSubscriptionStatus();
    return (
        <>
        <div className='font-weight-bold font-size-2dot5rem text-align-center margin-top-1rem'>
            <span className={classNames('dot margin-right-0dot2rem', {
                'green': subscriptionStatus === 0
            })}/>
            <FormattedMessage defaultMessage='Queue'/>
        </div>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<div className='margin-top-1rem'><Skeleton/></div>}>
                <Queue {...{id}}/>
            </React.Suspense>
        </ErrorBoundary>
        </>
    );
});

import React from 'react';
import {FormattedMessage} from 'react-intl';
import LogoDarkName from '../../../assets/logo-dark-name.svg';

const CompleteFooter = () => {
    return (
        <div className='display-flex align-items-center justify-content-center padding-top-2rem padding-bottom-1dot5rem'>
            <div className='margin-right-0dot25rem color-gray-500 medium text-md'><FormattedMessage defaultMessage='Powered by'/></div>
            <div><LogoDarkName className='display-block'/></div>
        </div>
    )
}

export default React.memo(CompleteFooter);
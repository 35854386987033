import React from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import {useCartDispatch} from '../../../contexts/cart';
import searchFilter from '../../../searchFilter';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import Browser from '../../../components/Browser';
import Ticket01 from '../../../assets/icons0/ticket-01.svg';
import useCartStateEx from '../../../utilities/use-cart-state-ex';
import CartButton from '../local-cart/cart';

export const WarpEndpointsHeader = ({children, serviceByIdOrSlug, theme}) => {
    const localCartState = useCartStateEx(serviceByIdOrSlug.id);
    const dispatch = useCartDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const decrementEndpoint = React.useCallback((entry) => {
        if (entry.quantity > 1) {
            dispatch({type: 'decrement', payload: entry.key});
        } else {
            dispatch({type: 'remove', payload: entry.key});
        }
    });
    const incrementEndpoint = React.useCallback((entry) => {
        dispatch({type: 'increment', payload: entry.key});
    });
    const checkout = React.useCallback(() => {
        navigate(`/checkout-new/ticketing/impulse${searchFilter(location.search, {impulseServiceId: serviceByIdOrSlug.id})}`)
    });
    return (
        <div className={classNames('padding-top-2rem position-sticky top-0 z-index-1', {'background-color-black': theme === 'dark', 'background-color-gray-50': theme === 'light'})}>
            {children({localCartState, incrementEndpoint, decrementEndpoint, checkout})}
        </div>
    )
};

const EndpointsHeader = ({serviceByIdOrSlug, decrementEndpoint, incrementEndpoint, localCartState, checkout, showCart, theme}) => {
    return (
        <div className={classNames('padding-bottom-1rem border-bottom-1px-solid display-flex justify-content-space-between align-items-center', {'border-color-gray-700' : theme == 'dark', 'border-color-gray-200' : theme == 'light'})}>
            <div className='display-flex'>
                <Ticket01 className='display-block height-1dot5rem color-primary-500'/>
                <p className={classNames('margin-left-0dot75rem text-lg medium', {'color-gray-200' : theme == 'dark', 'color-gray-700' : theme == 'light'})}><FormattedMessage defaultMessage='Tickets' /></p>
            </div>
            {showCart && <Browser fallback={<div className='display-none mw768-display-block min-width-11rem min-height-2dot75rem'></div>}>
                <CartButton {...{serviceById: serviceByIdOrSlug, localCartState, decrementEndpoint, incrementEndpoint, checkout}}/>
            </Browser>}
        </div>
    )
}

export default React.memo(EndpointsHeader);

import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import useQuery from '../../../useQuery';
import searchFilter from '../../../searchFilter';
import useMountedState from '../../../useMountedState';
import {ErrorBoundary} from 'react-error-boundary';
import Button, {RouterLinkButton} from '../../../components/button/button';
import {CheckoutDispatchContext} from '../../../components/CheckoutContext';
import {DialogDispatchContext} from '../../../components/DialogContext';
import Order from './Order';
import OrderItem, {OrderItemDynamicQuantity, OrderItemRequestData} from './order-item';
import UserDetails from './UserDetails';
import NewUserDetails from './NewUserDetails';
import MailDark from '../../../assets/icons/mail-dark.svg';
import Skeleton from '../../../components/Skeleton';
import classNames from 'classnames';
import {isSet} from '../../../utility';
import {useErrorMessagesDispatch} from '../error-messages-context';
import ErrorFallback from '../../../components/ErrorFallback';
import {useAuthorizationState} from '../../../contexts/Authorization';
import EffectRedirect from '../../../components/EffectRedirect';
import Coupon from './coupon';
import AuthorizationError from '../../../components/AuthorizationError';

const legal = 'https://www.ottry.com/legal';
const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;
const RAZZLE_TECHNICAL_MERCHANT = process.env.RAZZLE_TECHNICAL_MERCHANT;

export const getPicksByState = (state) => state.map(({endpointId, serviceId, quantity, untie}) => ({
    endpoint: endpointId,
    service: serviceId,
    quantity: quantity,
    ...(untie && {untie: {
        currency: untie.currency,
        price: untie.price
    }})
}));

const ImpulsePage = ({state, dispatch, subtitle, message, showOrderItemQuantity, dynamicEnpointQuantity}) => {
    const location = useLocation();
    const {landId = 0, locale = 'uk'} = useQuery();
    const authorized = useAuthorizationState();
    const [newImpulseId, setNewImpulseId] = React.useState();
    const [isRefreshing, setIsRefreshing] = useMountedState();
    const [code, setCode] = React.useState();
    const {register, handleSubmit, formState, setValue} = useForm();
    const [grecaptcha, setGrecaptcha] = useMountedState(false);
    const checkoutDispatch = React.useContext(CheckoutDispatchContext);
    const dialogDispatch = React.useContext(DialogDispatchContext);
    const errorDispatch = useErrorMessagesDispatch();
    const [isPending, startTransition] = React.useTransition();
    const [request, setRequest] = React.useState({picks: getPicksByState(state)});
    React.useEffect(() => {
        if (isSet(code)) {
            setRequest({picks: getPicksByState(state), landMetas: [{index: landId, code}]})
        } else {
            setRequest({picks: getPicksByState(state)});
        }
    }, [code, state]);
    const requestDeferred = React.useDeferredValue(request);
    React.useEffect(() => {
        if (request !== requestDeferred) {
            setIsRefreshing(true);
        } else {
            setIsRefreshing(false);
        }
    }, [request, requestDeferred, setIsRefreshing]);
    React.useEffect(() => {
        if (serviceById.mrchntd === RAZZLE_TECHNICAL_MERCHANT && serviceById.pools && serviceById.pools.length) {
            setP2pError(true);
            errorDispatch({
                type: 'add',
                payload: {
                    errorCode: 'p2pblock',
                    title: <FormattedMessage defaultMessage='Technical error'/>,
                    description: <FormattedMessage defaultMessage='Service temporarily unavailable'/>
                }
            });
        }
    }, [serviceById]);
    const requestData = useLazyLoadQuery(
        graphql`
            query ImpulseCheckoutPagesQuery($request: LandRequestInput) {
                landImpulse(request: $request) {
                    lands {
                        service
                        pickExs {
                            index
                        }
                    }
                    solves {
                        amount
                        amountAmount
                        feeAmount
                        serviceFeeAmount
                        basis
                        discountAmount
                        coupon {
                            code
                            discount
                        }
                    }
                }
            }
        `,
        {request: requestDeferred}
    );
    const land = requestData.landImpulse.lands[landId];
    const picks = React.useMemo(
        () =>  land ? land.pickExs.map(e => state[e.index]) : [],
        [state, land]
    );
    const serviceId = land && land.service;
    const coupon = land && requestData.landImpulse.solves[landId].coupon;
    const orderData = {
        total: land && requestData.landImpulse.solves[landId].amount,
        amount: land && requestData.landImpulse.solves[landId].amountAmount,
        serviceFee: land && requestData.landImpulse.solves[landId].serviceFeeAmount,
        basis: land && requestData.landImpulse.solves[landId].basis,
        discountAmount: land && requestData.landImpulse.solves[landId].discountAmount
    };
    const {serviceById} = useLazyLoadQuery(
        graphql`
            query ImpulseCheckoutPagesServiceQuery($serviceId: String) {
                serviceById(id: $serviceId) {
                    id
                    mrchntd
                    pools {
                        id
                    }
                }
            }
        `,
        {serviceId: serviceId}
    );
    const [p2pError, setP2pError] = React.useState(false);
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation ImpulseCheckoutPagesMutation($impulseRequest: ImpulseRequestInput!) {
                createImpulse(impulseRequest: $impulseRequest) {
                    id
                    orderDates
                    amount
                    impulseService {
                        service {
                            id
                            name
                        }
                        independenceType
                        merchantAccount
                        partnerCode
                        merchantDomainName
                        holdTimeout
                        manual
                        mrchntd
                    }
                    impulseEndpoints {
                        name
                        price
                        quantity
                    }
                    impulseClient {
                        email
                    }
                    feeAmount
                    amountAmount
                    orderReferences
                    merchantSignatures
                    claim
                }
            }
    `);
    const createImpulse = handleSubmit(formValue => {
        errorDispatch({type: 'reset', payload: []});
        if (!window.grecaptcha) {
            errorDispatch({type: 'add', payload: {errorCode: 'windowRecaptcha'}});
            return;
        }
        if (serviceById.mrchntd === RAZZLE_TECHNICAL_MERCHANT && (!serviceById.pools || (serviceById.pools && !serviceById.pools.length))) {
            errorDispatch({type: 'add', payload: {errorCode: 'technicalMerchantPools'}});
            return;
        }
        if (serviceById.mrchntd !== RAZZLE_TECHNICAL_MERCHANT && serviceById.pools && serviceById.pools.length) {
            errorDispatch({type: 'add', payload: {errorCode: 'serviceHasPools'}});
            return;
        }
        setGrecaptcha(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
                commit({
                    variables: {
                        impulseRequest: {
                            locale,
                            email: formValue.email,
                            reCaptcha: token,
                            ...(coupon && {impulseCoupon: {code: coupon.code}}),
                            service: serviceId,
                            impulseEndpoints: picks.map((item) => ({
                                endpoint: item.endpointId,
                                quantity: item.quantity,
                                ...(item.untie && {untie: {
                                    currency: item.untie.currency,
                                    price: item.untie.price
                                }})
                            })),
                            impulseAddls: formValue.addls && Object.entries(formValue.addls)
                                .map(([key, value]) => ({
                                    addl: key,
                                    value: value === true ? '+' : value === false ? '-' : value
                                }))
                        }
                    },
                    onCompleted: (data) => {
                        startTransition(() => {
                            checkoutDispatch({type: 'add', payload: {id: data.createImpulse.id, claim: data.createImpulse.claim}});
                            data.createImpulse.impulseService.manual &&
                                dialogDispatch({type: 'add', payload: {impulseId: data.createImpulse.id, claim: data.createImpulse.claim}});
                            dispatch({type: 'bulkRemove', payload: picks.map(item => item.key)});
                            setNewImpulseId(data.createImpulse.id);
                        })
                    },
                    onError: (error) => {
                        if (/reCaptcha/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'serverRecaptcha', additionalMessage: `reCaptcha error: ${error}`}});
                        } else if (/Service is invalid/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'serviceInvalid'}});
                        } else if (/Endpoint invalid/i.test(error)) {
                            errorDispatch({type: 'add', payload: {errorCode: 'endpointInvalid'}});
                        } else {
                            errorDispatch({type: 'add', payload: {errorCode: null}});
                        }
                    },
                    updater: store => {
                        const latestImpulses = store.get('client:root:viewer:__LatestImpulses_impulsesByViewer_connection(sent:true,status:[3,4])');
                        latestImpulses && latestImpulses.invalidateRecord();
                    }
                });
                setGrecaptcha(false);
            });
        });
    });

    if (newImpulseId) {
        return <EffectRedirect to={`../payment${searchFilter(location.search, {impulseId: newImpulseId})}`} replace/>;
    }

    return (
        <div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='checkout-content block width-100percent margin-bottom-2rem'>
                        <div className='checkout-view display-flex flex-direction-column checkout-view--container padding-top-2rem'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                <div className='checkout-content block impulse'>
                    <div className={classNames('checkout-view display-flex flex-direction-column checkout-view--container', {'opacity-0dot6': isRefreshing})}>
                        <ErrorBoundary {...{FallbackComponent: AuthorizationError}}>
                            {land && <>
                                <div className='checkout-content__shadow-block block-width padding-top-2rem mw768-padding-top-2dot5rem z-index-1'>
                                    <p className='text-header semibold color-gray-900'>
                                        <FormattedMessage defaultMessage='Ordering'/>
                                    </p>
                                    {subtitle &&
                                        <p className='text-md color-gray-500 padding-top-0dot75rem'>
                                            {subtitle}
                                        </p>
                                    }
                                    <Order {...{...orderData, coupon}}>
                                        {picks.map((item, index) =>
                                            <OrderItemRequestData {...{key: index, endpointId: item.endpointId, serviceId: item.serviceId}}>
                                                {({endpointById, serviceById}) => 
                                                    dynamicEnpointQuantity ?
                                                    <OrderItemDynamicQuantity {...{service: serviceById, untie: item.untie, quantity: item.quantity, ...endpointById, dispatch, stateKey: item.key}}/>
                                                    :
                                                    <OrderItem {...{service: serviceById, untie: item.untie, quantity: item.quantity, ...endpointById, showOrderItemQuantity}}/>
                                                }
                                            </OrderItemRequestData>
                                        )}
                                        <Coupon {...{coupon, setCode, isRefreshing, setIsRefreshing, picks: request.picks}}/>
                                    </Order>
                                </div>
                                <div className='checkout-content__message display-flex block-width align-items-center justify-content-center background-color-gray-50'>
                                    <div>
                                        <MailDark className='display-block'/>
                                    </div>
                                    {message && <p className='text-md color-gray-600 padding-left-0dot75rem'>{message}</p>}
                                </div>
                                {serviceId && 
                                    <div className="padding-bottom-2rem mw768-padding-bottom-2dot5rem">
                                        <p className="text-2xl semibold color-gray-900 padding-top-2rem mw768-padding-top-2dot5rem">
                                            <FormattedMessage defaultMessage='Contact details'/>
                                        </p>
                                        {authorized ?
                                            <UserDetails {...{serviceId, registerFormField: register, formState, setValue}}/>
                                            :
                                            <NewUserDetails {...{serviceId, registerFormField: register, formState}}/>
                                        }
                                    </div>
                                }
                                <div className='checkout-footer padding-bottom-3dot5rem mw768-padding-bottom-7dot5rem'>
                                    <Button {...{fluid: 'always', color: 'primary-gradient', size: 'lg', clickHandler: () => createImpulse(), isLoading: grecaptcha || isMutationInFlight || isRefreshing, disabled: p2pError}}> 
                                        <FormattedMessage defaultMessage='Proceed with ordering'/>
                                    </Button>
                                    <div className='padding-top-1dot25rem color-gray-400 text-xs'>
                                        <FormattedMessage defaultMessage='By clicking Proceed button, you agree to the'/>
                                        {' '}
                                        <Link className='color-gray-500 text-decoration-underline' to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                                        {' '}
                                        <FormattedMessage defaultMessage='and' />
                                        {' '}
                                        <Link className='color-gray-500 text-decoration-underline' to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                                        {' '}
                                        <FormattedMessage defaultMessage='and that you have read the' />
                                        {' '}
                                        <Link className='color-gray-500 text-decoration-underline' to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                                    </div>
                                </div>
                            </>}
                        </ErrorBoundary>
                        {!land &&
                            <div className='padding-top-14rem padding-bottom-10rem mw768-padding-left-5rem mw768-padding-right-5rem'>
                                <div className='text-xl color-gray-700 semibold text-align-center'>
                                    <FormattedMessage defaultMessage="We couldn't find your order"/>
                                </div>
                                <div className='text-lg color-gray-500 padding-bottom-1dot5rem text-align-center'>
                                    <FormattedMessage defaultMessage="Find out what's interesting"/>
                                </div>
                                <div className='text-align-center'>
                                    <RouterLinkButton {...{color: 'primary-gradient', size: 'xl', fluid: 'mobile', to: `/${searchFilter(location.search)}`}} >
                                        <FormattedMessage defaultMessage='Return to main page'/>
                                    </RouterLinkButton>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    )
};

export default React.memo(ImpulsePage);

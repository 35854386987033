import React from 'react';
import classNames from 'classnames';
import useSwipe from '../../use-swipe';
import {graphql, useMutation} from 'react-relay/hooks';
import qs from 'qs';
import QRCode from 'qrcode.react';
import i18n from '../../i18n';
import useQuery from '../../useQuery';
import Button from '../../components/button/button';
import Share01Icon from '../../assets/icons0/share-01.svg';
import XCloseIcon from '../../assets/icons0/x-close.svg';
import {FormattedMessage} from 'react-intl';
import {minTwoDigits} from '../../utility';
import {ScanIndicatorCheckMark} from './impulse-view';

const QrCard = ({endpointIndex, qrIndex, impulse, index, qr, scansAmount, children, take, revertTake, applyingTake, applyingRevertTake}) => {
    const {locale = 'uk'} = useQuery();
    const endpoint = impulse.impulseEndpoints[endpointIndex];
    const webShareSupported = navigator && 'canShare' in navigator;
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation impulseViewQrsPopupDownloadMutation {
                handle {
                    accessToken
                }
            }
    `);
    const shareQr = async () => {
        if (webShareSupported) {
            try {
                if (!isMutationInFlight) {
                    commit({
                        onCompleted: async (data) => {
                            const suffix = qs.stringify({
                                handle: data.handle.accessToken,
                                id: impulse.id,
                                position: endpointIndex,
                                index: qrIndex
                            });
                            const response = await fetch(`${RAZZLE_APP_PREFIX}/api/impulses/plant/download?${suffix}`);
                            const blob = await response.blob();
                            const filesArray = [
                                new File(
                                    [blob],
                                    `${impulse.id}-${endpointIndex}-${qrIndex}.png`,
                                    {
                                        type: blob.type,
                                        lastModified: new Date().getTime()
                                    }
                                )
                            ];
                            const shareData = {
                                files: filesArray,
                                title: `${i18n(impulse.impulseService, 'name', locale)} | ${i18n(endpoint, 'name', locale)}`
                            };
                            await navigator.share(shareData);
                        },
                        onError: () => {}
                    })
                }
                
            } catch (error) {
                console.log('Sharing failed!', error)
            }
        }
    }

    return (
        <div className='scan-qr-card'>
            <div className='text-xl padding-bottom-1rem'>
                <span className='medium color-gray-700'>{minTwoDigits(index + 1)} {i18n(impulse.impulseEndpoints[endpointIndex], 'name', locale)}</span>
                {' '}
                <span className='color-gray-500'>{impulse.impulseEndpoints[endpointIndex].price} ₴</span>
            </div>
            <div className='padding-1rem background-color-gray-100 border-radius-0dot5rem position-relative display-flex justify-content-center margin-bottom-1dot25rem'>
                <QRCode style={{borderRadius: '5px', backgroundColor: 'white', padding: '1.25rem', display: 'block'}} size={140} value={`plant://${qr}`}/>
                <div className={classNames('position-absolute top-1rem right-1rem cursor-pointer', {'opacity-0dot4 pointer-events-none': applyingTake || applyingRevertTake})} onClick={() => !!scansAmount ? revertTake({position: endpointIndex, index: qrIndex}) : take({position: endpointIndex, index: qrIndex})}>
                    <ScanIndicatorCheckMark {...{scanned: !!scansAmount}}/>
                </div>
            </div>
            <div className='display-flex'>
                <div className='flex-1'>
                    <Button {...{color: 'secondary-gray', size: 'lg', clickHandler: shareQr, disabled: !webShareSupported}}>
                        <Share01Icon className='display-block width-1dot25rem height-1dot25rem'/>
                    </Button>
                </div>
                {children}
            </div>
        </div>
    )
}

const ImpulseViewQrsPopup = ({qrsArray, activeQr, impulse, closeHandler, take, revertTake, applyingTake, applyingRevertTake}) => {
    const activeQrIndex = qrsArray.findIndex((item) => item.endpointIndex === activeQr.endpointIndex && item.qrIndex === activeQr.qrIndex);
    const [currentQrIndex, setCurrentQrIndex] = React.useState(activeQrIndex);
    const swipeContainer = React.useRef();
    const leftSwipeAction = () => {
        const newIndex = currentQrIndex + 1 === qrsArray.length ? currentQrIndex : currentQrIndex + 1;
        setCurrentQrIndex(newIndex);
    }

    const rightSwipeAction= () => {
        const newIndex = currentQrIndex - 1 < 0 ? currentQrIndex : currentQrIndex - 1;
        setCurrentQrIndex(newIndex);
    };
    const swipe = useSwipe({ref: swipeContainer, showNext: leftSwipeAction, showPrevious: rightSwipeAction}); 

    return (
        <div className='pop-up z-index-3'>
            <div className='display-flex justify-content-center padding-bottom-1dot5rem'>
                {qrsArray.map((item, index) =>
                    <div onClick={() => setCurrentQrIndex(index)} key={index} className={classNames('cursor-pointer width-0dot5rem height-0dot5rem border-radius-50percent', {'background-color-gray-900': index === currentQrIndex, 'background-color-gray-300': index !== currentQrIndex, 'margin-left-0dot75rem': index !== 0})}></div>
                )}
            </div>
            <div ref={swipeContainer} className='display-flex flex-direction-row' style={{transform: `translate3d(-${currentQrIndex * 100}%, 0, 0)`}}>
                {qrsArray.map(({endpointIndex, qrIndex, qr, scansAmount}, index) =>
                    <div className={classNames('min-width-100percent', {'opacity-0': index !== currentQrIndex, 'visibility-hidden': index !== currentQrIndex})} key={index}>
                        <QrCard {...{endpointIndex, qrIndex, qr, impulse, index, scansAmount, take, revertTake, applyingTake, applyingRevertTake}}>
                            <Button {...{plain: true, color: 'pale', size: 'lg', clickHandler: closeHandler}}>
                            <div className='display-flex align-items-center'>
                                    <XCloseIcon className='display-block width-1dot25rem height-1dot25rem'/>
                                    <FormattedMessage defaultMessage='Close'/>
                            </div>
                            </Button>
                        </QrCard>
                    </div>
                )}
            </div>
        </div>
    )
};

export default React.memo(ImpulseViewQrsPopup);
import React from 'react';
import '../../menu/MenuLarge.scss';
import {queryParameters} from '../../../qs';
import {Link, useLocation} from 'react-router-dom';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import searchUnFilter from '../../../searchUnFilter';
import {useAuthorizationState} from '../../../contexts/Authorization';
import {graphqlWhoamiNextQuery} from '../../graphql';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

const AuthorizedUserMenu = () => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const location = useLocation();
    const qps = queryParameters(location);
    return (
        <>
            <li>
                <div
                    className='font-weight-bold overflow-wrap-break-word line-height-24px font-size-14px'
                >{whoamiNext.username || whoamiNext.email}</div>
            </li>
            <li>
                <a onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => {
                        window.location.href = RAZZLE_APP_ACCOUNTS + '/home?cache=0&new=true'
                            + (qps.length ? '&' + qps.join('&') : '');
                    }, 500);
                }}>
                    <FormattedMessage defaultMessage='Profile'/>
                </a>
            </li>
            <li>
                <Link to={`/log-out${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Log out'/></Link>
            </li>
        </>
    )
}

function MenuLarge({menuLargeRef, menuFlag, closeMenu}) {
    const authorized = useAuthorizationState();
    const location = useLocation();
    const qps = queryParameters(location);
    return (
        <div className='menu-large' ref={menuLargeRef}>
            <div className={`menu-large__content ${menuFlag ? 'menu-large__content--active' : ''}`}>
                <ul className='menu-large__section-list'>
                    <li>
                        <Link to={`/${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Marketplace'/>
                        </Link>
                    </li>
                    <hr/>
                    <li>
                        <Link to={`/faq${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='FAQ'/>
                        </Link>
                    </li>
                    {authorized &&
                    <li>
                        <Link to={`/cabinet${searchFilter(location.search)}`} onClick={closeMenu}>
                            <FormattedMessage defaultMessage='Cabinet'/>
                        </Link>
                    </li>
                    }
                    <hr/>
                    {authorized ?
                        <AuthorizedUserMenu/>
                        :
                        <li>
                            <a onClick={(e) => {
                                e.preventDefault();
                                setTimeout(() => {
                                    window.location.href = RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&new=true&continue='
                                        + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
                                        + (qps.length ? '&' + qps.join('&') : '');
                                }, 500);
                            }}>
                                <FormattedMessage defaultMessage='Sign in'/>
                            </a>
                        </li>
                    }

                </ul>
            </div>
        </div>
    );
}

export default React.memo(MenuLarge);

import React from 'react';
import useQuery from '../../useQuery';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import Ticket01Icon from '../../assets/icons0/ticket-01.svg';
import XCloseIcon from '../../assets/icons0/x-close.svg';
import moment from 'moment';
import i18n from '../../i18n';
import {FormattedMessage, useIntl} from 'react-intl';
import {useForm} from 'react-hook-form';
import UseFormInput from '../../components/use-form-input/use-form-input';
import UseFormAddlInput from '../../components/use-form-addl-input/use-form-addl-input';
import Button from '../../components/button/button';
import {get} from '../../fh';
import searchFilter from '../../searchFilter';

const RAZZLE_ADDL_PHONE_ID = process.env.RAZZLE_ADDL_PHONE_ID;

const ScanUpdateImpulse = () => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const scanRoute = `../../${searchFilter(location.search)}`;
    const {impulseId} = useParams();
    const navigate = useNavigate();
    const intl = useIntl();
    const {impulseById} = useLazyLoadQuery(
        graphql`
            query updateImpulseQuery($id: String) {
                impulseById(id: $id) {
                    id
                    version
                    impulseService {
                        name
                        nameUk
                        service {
                            target
                        }
                    }
                    impulseAddls {
                        addl {
                            id
                            ...useFormAddlInputClose_addl
                        }
                        name
                        nameUk
                        value
                    }
                    impulseClient {
                        email
                    }
                }
            }
        `,
        {id: impulseId}
    );
    const phoneAddl = impulseById.impulseAddls && impulseById.impulseAddls.find(item => item.addl.id === RAZZLE_ADDL_PHONE_ID);
    const {register, handleSubmit, formState, setValue} = useForm({
        defaultValues: {
            email: impulseById.impulseClient.email,
            addls: {
                [RAZZLE_ADDL_PHONE_ID]: phoneAddl && phoneAddl.value
            }
        } 
    });
    const [applyEmail, applyingEmail] = useMutation(
        graphql`
            mutation updateImpulseEmailMutation($request: UpdateImpulseEmailRequestInput) {
                updateImpulseEmail(request: $request) {
                    id
                    version
                    impulseClient {
                        email
                    }
                }
            }
    `);
    const emailSubmit = handleSubmit(data => applyEmail({
        variables: {request: {id: impulseById.id, version: impulseById.version, email: data.email}},
        onCompleted: () => {navigate(scanRoute)},
        onError: () => {}
    }));
    return (
        <div className='scan-update-impulse'>
            <div className='display-flex padding-1dot25rem border-bottom-1px-solid border-color-gray-200 align-items-center'>
                <div className='outline-4px-solid outline-color-gray-100 margin-right-0dot75rem color-gray-600 border-radius-50percent padding-0dot375rem'>
                    <Ticket01Icon className='display-block width-1dot25rem height-1dot25rem'/>
                </div>
                <p className='flex-1 color-gray-700 text-sm medium'>
                    <span>{i18n(impulseById.impulseService, 'name', locale)}</span>
                    {impulseById.impulseService.service.target && <span> | {moment(impulseById.impulseService.service.target).format('DD.MM.YYYY')}</span>}
                </p>
                <XCloseIcon onClick={() => navigate(scanRoute)} className='display-block width-1dot25rem height-1dot25rem color-gray-500 cursor-pointer'/>
            </div>
            <div className='padding-top-2rem padding-bottom-2rem padding-left-1dot25rem padding-right-1dot25rem'>
                <p className='text-xl semibold color-gray-900 padding-bottom-1dot5rem'><FormattedMessage defaultMessage='Contact information'/></p>
                <UseFormInput {...{
                    label: <FormattedMessage defaultMessage='Email'/>,
                    fieldname: 'email',
                    register,
                    errors: formState.errors.email,
                    type: 'email',
                    placeholder: 'me@example.com',
                    registerOptions: {
                        required: true,
                        pattern: /^\S+@\S+\.\S+$/,
                        setValueAs: v => v ? v.trim() : v,
                        validate: {
                            checkDomain: async (v) => {
                                const [_, domainPart] = v.split('@');
                                try {
                                    const response = await get(`/api/domain?domain=${domainPart}`);
                                    const json = await response.json();
                                    if (!json) {
                                        return intl.formatMessage({defaultMessage: 'This domain is not valid'});
                                    }
                                    return json;
                                } catch (response) {
                                    return intl.formatMessage({defaultMessage: 'This domain is not valid'});
                                }
                            }
                        }
                    }
                }}/>
                {phoneAddl &&
                    <UseFormAddlInput {...{register, addl: phoneAddl.addl, error: formState.errors.addls && errors.addls[e.id], readonly: true}}/>
                }
                <div className='display-flex justify-content-flex-end padding-top-1rem'>
                    <Button {...{color: 'secondary-gray', size: 'md', clickHandler: () => {navigate(scanRoute)}}}>
                        <FormattedMessage defaultMessage='Back'/>
                    </Button>
                    <div className='padding-left-0dot75rem'>
                        <Button {...{color: 'primary-gradient', size: 'md', clickHandler: () => {emailSubmit()}, isLoading: applyingEmail}}>
                            <FormattedMessage defaultMessage='Save'/>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default React.memo(ScanUpdateImpulse);
const SEAT_MAPS = {
    ACTOR_HALL: {
        ROWS_AMOUNT: 14,
        MAX_SEATS_AMOUNT: 15,
        SEATS_LEFT_TO_RIGHT: false
    },
    VOZDVYZHENSKY_BOUTIQUE_HOTEL: {
        ROWS_AMOUNT: 8,
        MAX_SEATS_AMOUNT: 12,
        SEATS_LEFT_TO_RIGHT: true
    },
    CINEMA: {
        ROWS_AMOUNT: 3,
        MAX_SEATS_AMOUNT: 4,
        SEATS_LEFT_TO_RIGHT: false
    }
};

export default SEAT_MAPS;
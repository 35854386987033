import React, { useState, useEffect } from 'react';

function getWindowDimensions() {
    const windowWidth = typeof window !== 'undefined' && window.innerWidth || 0;
    const windowHeight = typeof window !== 'undefined' && window.innerHeight || 0;

    return {
        windowWidth,
        windowHeight
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [initialHeight, setInitialHeight] = React.useState(0);
    const [initialWidth, setInitialWidth] = React.useState(0);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        setInitialHeight(windowDimensions.windowHeight);
        setInitialWidth(windowDimensions.windowWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {...{windowDimensions, initialHeight, initialWidth}};
}
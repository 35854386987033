import React from 'react';
import classNames from 'classnames';
import {ErrorBoundary} from 'react-error-boundary';
import Header from '../../components/root/ogle/Header';
import Footer from '../../components/root/ogle/Footer';
import ErrorFallback from '../../components/ErrorFallback';

// import './Shell.scss';

const WalletShell = ({children}) => {
    const [menuFlag, setMenuFlag] = React.useState(false);
    const setMenuFlagCallback = React.useCallback((val) => setMenuFlag(val), []);
    
    return (
        <div className='display-flex flex-direction-column wallet'>
            <Header menuFlag={menuFlag} setMenuFlag={setMenuFlagCallback}/>
            <div className={classNames({'hide-small': menuFlag}, 'flex-1 display-flex flex-direction-column')}>
                <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                    {children}
                </ErrorBoundary>
            </div>
            <div className='hide-small'>
                <Footer/>
            </div>
        </div>
    )
};

export default React.memo(WalletShell);
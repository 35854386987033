import React from 'react';
import './User.scss';
import {Link, useLocation} from 'react-router-dom';
import {queryParameters} from '../../qs';
import searchFilter from '../../searchFilter';
import {FormattedMessage} from 'react-intl';
import searchUnFilter from '../../searchUnFilter';
import {useAuthorizationState} from '../../contexts/Authorization';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;

function User() {
    const authorized = useAuthorizationState();
    const location = useLocation();
    const qps = queryParameters(location);
    return (
        <div className='user'>
            {authorized ?
            <Link
                className='focus'
                to={`/cabinet${searchFilter(location.search)}`}
            ><FormattedMessage defaultMessage='Cabinet'/></Link>
            :
            <a className='focus' onClick={(e) => {
                e.preventDefault();
                setTimeout(() => {
                    window.location.href = RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&new=true&continue='
                        + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
                        + (qps.length ? '&' + qps.join('&') : '')
                    ;
                }, 500);
            }}><FormattedMessage defaultMessage='Sign in'/></a>
            }
        </div>
    );
}

export default React.memo(User);

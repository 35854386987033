import React from 'react'
import './Mister.scss';
import {Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './../../../Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import searchFilter from '../../../../searchFilter';
import {Helmet} from 'react-helmet-async';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import Dashboard from './Dashboard';
import ErrorFallback from '../../../ErrorFallback';
import getLocaleQueryParams from '../../../../utilities/get-locale-query-params';

const fancyId = process.env.NODE_ENV === 'development' ? '61711016cfda01765ad09627' : '61710e6456ad07763a191db1';
const legal = 'https://www.ottry.com/legal';
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const year = new Date().getFullYear();

const MisterDashboard = React.memo(({id}) => {
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query MisterDashboardQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                }
            }
        `,
        {id}
    );
    const {locale = 'uk'} = useQuery();
    const cover = React.useMemo(() => {
        if (i18n(data.serviceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.serviceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data, locale]);
    return (
        <>
        <Helmet>
            <title>{i18n(data.serviceById, 'name', locale)}</title>
            <meta name='description' content={i18n(data.serviceById, 'name', locale)}/>
            <meta property='og:image' content={cover}/>
        </Helmet>
        <div>
            <div className='view'>
                <div className='block'>
                    <Link
                        className='focus'
                        to={`/queues${searchFilter(location.search)}`}>
                        &#60; <FormattedMessage defaultMessage='Back'/>
                    </Link>
                    <Dashboard {...{id}}/>
                </div>
            </div>
        </div>
        </>
    )
});

export default React.memo(() => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='mister'>
            <div className='mister__header'>
                <Link to={`/${searchFilter(location.search)}`}><span className='mister__accent font-weight-bold'>Impulse</span> by Ottry</Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='view'>
                        <div className='block'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                    <MisterDashboard {...{id: fancyId}}/>
                </React.Suspense>
            </ErrorBoundary>
            <div className='mister__footer'>
                <div className='mister__footer-line'>
                    <div className='mister__footer-line-left'>&nbsp;</div>
                    <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
                    <div className='mister__footer-line-right'>&nbsp;</div>
                </div>
                <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                    <a
                        className={classNames('font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                        href={`${location.pathname}${getLocaleQueryParams(location.search, 'uk')}`}
                    >Uk</a>
                    {' '}
                    <a
                        className={classNames('font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                        href={`${location.pathname}${getLocaleQueryParams(location.search, 'en')}`}
                    >En</a>
                </div>
                <div className='mister__payment'>
                    <img
                        src='https://wfp-help.s3.eu-central-1.amazonaws.com/1568625809_payment_methods_1.png%3Fversion%3D1%26amp%3BmodificationDate%3D1510658069000%26amp%3Bapi%3Dv2'
                        alt=''/>
                </div>
                <div className='mister__footer-content'>
                    <div className='mister__footer-info'>
                        <a
                            href={formLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        ><FormattedMessage defaultMessage='I want to sell online'/></a>
                        <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                            <FormattedMessage defaultMessage='Report an issue'/>
                        </a>
                        <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                        <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                        <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                    </div>
                </div>
                <div className='mister__footer-rights'>
                    © {year} Ottry. All rights reserved.
                </div>
            </div>
        </div>
    );
});
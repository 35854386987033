import React from 'react';
import './MenuButton.scss';
import {FormattedMessage} from 'react-intl';

function MenuButton({menuButtonRef, menuFlag, closeMenu}) {
    return (
        <div className='menu-button' ref={menuButtonRef}>
            <div className={`menu-button__button hide-small ${menuFlag ? 'menu-button__button--active': ''}`} onClick={closeMenu}>
                <div className='menu-button__button-title'>&#9776;{' '}<FormattedMessage defaultMessage='Menu'/></div>
                <div className='menu-button__button-px'/>
            </div>
            <div className={`menu-button__button hide-large ${menuFlag ? 'menu-button__button--active': ''}`} onClick={closeMenu}>
                {menuFlag ? <div className='menu-button__icon'>&#10005;</div> : <div className='menu-button__icon'>&#9776;</div>}
            </div>
        </div>
    );
}

export default React.memo(MenuButton);
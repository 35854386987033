import React from 'react';
import {graphql, useFragment, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import Skeleton from '../../Skeleton';
import {ErrorBoundary} from 'react-error-boundary';
import useQuery from '../../../useQuery';
import {isTrue} from '../../../utility';
import useMountedState from '../../../useMountedState';
import {STANDALONE} from '../../../constants';
import {FormattedMessage} from 'react-intl';
import ErrorFallback from '../../ErrorFallback';
import Browser from '../../Browser';
import {graphqlWhoamiNextQuery} from '../../graphql';

const StatusByOrderReference = React.memo(({id, orderReference}) => {
    const data = useLazyLoadQuery(
        graphql`
            query IntegrationStatusImpulseImplQuery($request: StatusImpulseRequestInput) {
                statusImpulse(request: $request) {
                    card
                    status
                }
            }
        `,
        {request: {id, orderReference}}
    );
    return (
        <>
        <div className='font-size-0dot8rem font-style-italic color-grey'>
            {orderReference} / {data.statusImpulse.card} / {data.statusImpulse.status}
        </div>
        </>
    );
});

const RefundByOrderReference = React.memo(({id, orderReference}) => {
    const [data, setData] = useMountedState();
    const [refund, applyingRefund] = useMutation(
        graphql`
            mutation IntegrationRefundImpulseImplMutation($request: RefundImpulseRequestInput) {
                refundImpulse(request: $request) {
                    status
                }
            }
    `);
    const onRefund = () => refund({
        variables: {request: {id, orderReference}},
        onCompleted: (data) => {setData(data)},
        onError: () => {}
    });
    return (
        <>
        <div className='display-flex align-items-flex-start margin-top-1rem'>
            <div
                className='button'
                onClick={() => !applyingRefund && onRefund()}
            >
                <FormattedMessage defaultMessage='Refund'/>{applyingRefund && '...'}
            </div>
            {data &&
            <div className='margin-left-1rem font-size-0dot8rem font-style-italic color-grey overflow-wrap-break-word'>
                {data.refundImpulse.status}
            </div>
            }
        </div>
        </>
    );
});

const SettleByOrderReference = React.memo(({id, orderReference}) => {
    const [data, setData] = useMountedState();
    const [settle, applyingSettle] = useMutation(
        graphql`
            mutation IntegrationSettleImpulseImplMutation($request: SettleImpulseRequestInput) {
                settleImpulse(request: $request) {
                    status
                }
            }
    `);
    const onSettle = () => settle({
        variables: {request: {id, orderReference}},
        onCompleted: (data) => {setData(data)},
        onError: () => {}
    });
    return (
        <>
        <div className='display-flex align-items-flex-start margin-top-1rem'>
            <div
                className='button'
                onClick={() => !applyingSettle && onSettle()}
            >
                <FormattedMessage defaultMessage='Settle'/>{applyingSettle && '...'}
            </div>
            {data &&
            <div className='margin-left-1rem font-size-0dot8rem font-style-italic color-grey overflow-wrap-break-word'>
                {data.settleImpulse.status}
            </div>
            }
        </div>
        </>
    );
});

const Integration = React.memo(({impulse}) => {
    const fImpulse = useFragment(
        graphql`
            fragment IntegrationImpulseImpl_impulse on Impulse {
                id
                orderReference
                orderReferences
                impulseService {
                    independenceType
                }
                merchant {
                    id
                }
            }
        `,
        impulse
    );
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    return (
        <>
        {fImpulse.orderReferences.map(e =>
            <div key={e}>
                <StatusByOrderReference {...{id: fImpulse.id, orderReference: e}} />
                {whoamiNext.id === fImpulse.merchant.id && fImpulse.impulseService.independenceType === STANDALONE &&
                <>
                <RefundByOrderReference {...{id: fImpulse.id, orderReference: e}} />
                <SettleByOrderReference {...{id: fImpulse.id, orderReference: e}} />
                </>
                }
            </div>
        )}
        </>
    );
});

export default React.memo(({impulse}) => {
    const {more} = useQuery();
    return (
        <>
        {isTrue(more) &&
        <Browser>
            <div className='font-weight-bold'>Integration</div>
            <div className='inline'>
                <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                    <React.Suspense fallback={<Skeleton/>}>
                        <Integration {...{impulse}}/>
                    </React.Suspense>
                </ErrorBoundary>
            </div>
        </Browser>
        }
        </>
    );
});
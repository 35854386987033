import React from 'react';
import './launch.scss';
import './toolset.scss';
import Origin from './theme/Origin';
import Ogle from './theme/Ogle';
// import Home from './root/home/Home';
import {ErrorBoundary} from 'react-error-boundary';
import Services from './root/Services';
import Service from './root/Service';
import Warp from './root/warp/Warp';
import Brave from './root/fancy/brave/Brave';
import Povitrya from './root/fancy/povitrya/Povitrya';
import Arsenal from './root/fancy/arsenal/Arsenal';
import Shum from './root/fancy/shum/Shum';
import Mister from './root/fancy/mister/Mister';
import MisterQueue from './root/fancy/mister/MisterQueue';
import MisterDashboard from './root/fancy/mister/MisterDashboard';
import MisterScan from './root/fancy/mister/MisterScan';
import Endpoints from './root/Endpoints';
import Endpoint from './root/Endpoint';
import Callback from './Callback';
import LogOut from './LogOut';
import Cabinet from './cabinet/Cabinet';
import NotFound from './NotFound';
import Shell from './root/Shell';
import OgleShell from './root/ogle/Shell';
import OgleHome from './root/ogle/Home';
import MasterWashRoutes from '../pages/master-wash/routes';
import CheckoutRoutes from '../pages/checkout/routes';
import CookieConsent from './cookie-consent';
import VendingMachines from './products/VendingMachines';
import Queues from './products/Queues';
import {Routes, Route, Navigate, useParams, useLocation} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import Cart from './root/cart/Cart';
import Scan from './scan/Scan';
import Simple from './Simple';
import {FetchKeyProvider} from './FetchKey';
import Faq from './root/Faq';
import RulesEn from './root/rules-en';
import RulesUk from './root/rules-uk';
import TermsEn from './root/terms-en';
import TermsUk from './root/terms-uk';
import PrivacyEn from './root/privacy-en';
import PrivacyUk from './root/privacy-uk';
import Private from './private';
import {IntlProvider} from 'react-intl';
import useQuery from '../useQuery';
import en from '../../compiled-lang/en';
import uk from '../../compiled-lang/uk';
import {Outlet} from 'react-router-dom';
import WalletRouting from '../pages/wallet/Routing';
import EffectRedirect from './EffectRedirect';
import ErrorFallback from './ErrorFallback';
import ScrollState from './ScrollState';
import Chuprynenko from './root/fancy/chuprynenko/chuprynenko';
import useGoogleOneTapLogin from '../use-google-one-tap-login';
import {useAuthorizationState} from '../contexts/Authorization';
import {queryParameters} from '../qs';
import searchUnFilter from '../searchUnFilter';
import SuspenseLazy from './suspense-lazy';
import {isSet} from '../utility';
import Cinema from '../pages/fancy/cinema/cinema';
import searchFilter from '../searchFilter';
import ScanRoutes from '../pages/scan/routes';
import Fcfc from '../pages/fancy/fcfc/fcfc';
import SubscriptionsRoutes from '../pages/subscriptions/routes';
import Search from './root/ogle/search';
import SeatMapService from '../pages/fancy/seat-map/service-page';
import BasicWarp from '../pages/warp/basic/basic';
import Atlas from '../pages/fancy/atlas/atlas';
import getLocaleQueryParams from '../utilities/get-locale-query-params';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
const RAZZLE_APP_ORIGIN = process.env.RAZZLE_APP_ORIGIN;

const fancyId = process.env.NODE_ENV === 'development' ? '58b40a57cd0484f9e50041aa' : null;
const locales = {en, uk};

const sl = {
    '1': 'https://time2eat.today/?serviceId=63f0a46d7ce41267297ad280',
    '2': 'https://time2eat.today/?serviceId=64ac0b4576bb3a8b3a183381'
};

const SmartLink = React.memo(() => {
    const {id} = useParams();
    React.useEffect(() => {
        setTimeout(() => window.location.replace(sl[id]), 500);
    }, [id]);
    return (
        <>Smart link.{id}..</>
    );
});

const BusinessRoutes = React.lazy(() => import('../pages/business/routes'));
const PromoRoutes = React.lazy(() => import('../pages/promo/routes'));
const CabinetRoutes = React.lazy(() => import('./cabinet/routes'));

const GoogleOneTapImpl = React.memo(() => {
    const location = useLocation();
    const locationRef = React.useRef();
    locationRef.current = location;
    useGoogleOneTapLogin({
        onSuccess: credentialResponse => {
            const qps = queryParameters(locationRef.current);
            setTimeout(() => window.location.href = RAZZLE_APP_ACCOUNTS + '/callback2?cache=0&new=true&continue='
                + encodeURIComponent(locationRef.current.pathname + searchUnFilter(document.location.search))
                + `&token=${credentialResponse.credential}`
                + (qps.length ? '&' + qps.join('&') : ''),
                500
            );
        },
        onError: () => {}
    });
    return null;
});

const GoogleOneTapEx = React.memo(() => {
    const [show, setShow] = React.useState();
    React.useEffect(() => {
        let timeout;
        const checkCookie = () => {     
            if (isSet(window.CookieConsentApi?.get('revision'))) {
                setShow(true);
            } else {
                timeout = setTimeout(checkCookie, 1500);
            }
        };
        checkCookie();
        return () => clearTimeout(timeout)
    }, []);
    if (!show)
        return null;
    return (<GoogleOneTapImpl/>);
});

const GoogleOneTap = React.memo(() => {
    const authorized = useAuthorizationState();
    return (<>{!authorized && <GoogleOneTapEx/>}</>);
});

const DefaultSeoLinks = React.memo(() => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <Helmet>
            <link rel='canonical' href={`${RAZZLE_APP_ORIGIN}${location.pathname.replace(/\/$/, '')}${getLocaleQueryParams('', locale)}`}/>
            {/* For alternate work properly define rel after href */}
            <link href={`${RAZZLE_APP_ORIGIN}${location.pathname.replace(/\/$/, '')}${getLocaleQueryParams(location.search, 'uk')}`} rel='alternate' hreflang="uk"/>
            <link href={`${RAZZLE_APP_ORIGIN}${location.pathname.replace(/\/$/, '')}${getLocaleQueryParams(location.search, 'en')}`} rel='alternate' hreflang="en"/>
        </Helmet>
    )
});

const Application = () => {
    // When we invalidate cache after update we also need to bump fetchKey, because without fetchKey
    // change component won't be re-rendered. With new fetchKey data will be taken from cache, except
    // stale one - that one will be forced to be re-fetched.
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <>
            <Helmet>
                <title>Welcome to Ottry</title>
                <meta name='description' content='Web site of your dream'/>
                <link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.png'/>
                <link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.png'/>
                <link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.png'/>
                <link rel='manifest' href='/site.webmanifest'/>
                <link rel='mask-icon' href='/safari-pinned-tab.svg' color='#1570ef'/>
                <meta name='msapplication-TileColor' content='#da532c'/>
                <meta name='theme-color' content='#ffffff'/>
            </Helmet>
            <ScrollState/>
            <CookieConsent/>
            <GoogleOneTap/>
            <DefaultSeoLinks/>
            <Routes>
                <Route path='/' element={<Ogle><OgleShell/></Ogle>}>
                    <Route path='/' element={<OgleHome/>}/>
                    {/* For QR backward compatibility. When those will be updated to latest links, we can remove these redirects. */}
                    <Route path='endpoints'>
                        <Route path={'5f140ea84c3f4efa759438cd'} element={
                            <Navigate to={'/master-wash/endpoints/5f140ea84c3f4efa759438cd?serviceId=5f140f544c3f4efa759438ce'} replace/>
                        }/>
                        <Route path={'5fe3a71a25a4a5645d1547f7'} element={
                            <Navigate to={'/master-wash/endpoints/5fe3a71a25a4a5645d1547f7?serviceId=5f140f544c3f4efa759438ce'} replace/>
                        }/>
                        <Route path={'5fe3a74625a4a5645d1547f8'} element={
                            <Navigate to={'/master-wash/endpoints/5fe3a74625a4a5645d1547f8?serviceId=5f140f544c3f4efa759438ce'} replace/>
                        }/>
                    </Route>
                    {/* <Route path='tickets' element={<OgleTicketsHome/>}/> */}
                    <Route path='tickets' element={<Navigate to={`/events${searchUnFilter(location.search)}`}/>}/>
                    <Route path='events' element={<Search/>}/>
                </Route>
                <Route path='/' element={<Origin><Shell/></Origin>}>
                    <Route path='services'>
                        <Route index element={<Services/>}/>
                        <Route path=':id' element={<Service/>}/>
                    </Route>
                    <Route path='endpoints'>
                        <Route index element={<Endpoints/>}/>
                        <Route path=':id' element={<Endpoint/>}/>
                    </Route>
                    <Route path='cart' element={<Cart/>}/>
                    <Route path='rules' element={locale == 'uk' ? <RulesUk/> : <RulesEn/>}/>
                    <Route path='terms' element={locale == 'uk' ? <TermsUk/> : <TermsEn/>}/>
                    <Route path='privacy' element={locale == 'uk' ? <PrivacyUk/> : <PrivacyEn/>}/>
                    <Route path='faq' element={<Faq/>}/>
                </Route>
                <Route path='services' element={<Origin><Outlet/></Origin>}>
                    <Route path='warp/:payload' element={<Warp/>}/>
                    <Route path='brave' element={<Brave id={fancyId || '60f8129d2ccb0e3e9c9ddc31'}/>}/>
                    <Route path='povitrya' element={<Povitrya id={fancyId || '60f985a22ccb0e3e9c9ddd0a'}/>}/>
                    <Route path='arsenal' element={<Arsenal/>}/>
                    <Route path='chuprynenko' element={<Chuprynenko id={'6495971668c9353d437dc5ff'}/>}/>
                    <Route path='cinema/:idOrSlug' element={<Cinema/>}/>
                    <Route path='actor-hall/:idOrSlug' element={<Ogle><SeatMapService/></Ogle>}/>
                    <Route path='vozdvyzhensky-boutique-hotel/:idOrSlug' element={<Ogle><SeatMapService/></Ogle>}/>
                    <Route path='fcfc/:idOrSlug' element={<Ogle><Fcfc/></Ogle>}/>
                    <Route path='pools' element={
                        <EffectRedirect to={`/cabinet/pools${searchFilter(location.search)}`} replace/>
                    }/>
                    <Route path='shum' element={<Shum id={fancyId || '60f6b69e2ccb0e3e9c9ddb8f'}/>}/>
                    <Route path='mister'>
                        <Route index element={<Mister/>}/>
                        <Route path='queue' element={<MisterQueue/>}/>
                        <Route path='dashboard' element={<Private><MisterDashboard/></Private>}/>
                        <Route path='scan' element={<Private><MisterScan/></Private>}/>
                    </Route>
                </Route>
                <Route path='events' element={<Ogle><Outlet/></Ogle>}>
                    <Route path=':idOrSlug' element={<BasicWarp/>}/>
                    <Route path='atlas/:idOrSlug' element={<Ogle><Atlas/></Ogle>}/>
                </Route>
                <Route path='cabinet/*' element={<Origin><Cabinet/></Origin>}>
                    <Route path='*' element={<Ogle><Private><SuspenseLazy><CabinetRoutes/></SuspenseLazy></Private></Ogle>}/>
                </Route>
                <Route path='business/*'>
                    <Route path='*' element={<Ogle><Private><SuspenseLazy><BusinessRoutes/></SuspenseLazy></Private></Ogle>} />
                </Route>
                <Route path='subscriptions/*'>
                    <Route path='*' element={<Ogle><SubscriptionsRoutes/></Ogle>} />
                </Route>
                <Route path='master-wash/*' element={<Ogle><OgleShell/></Ogle>}>
                    <Route path='*' element={<MasterWashRoutes/>} />
                </Route>
                <Route path='scan/*'>
                    <Route index element={<Origin><Scan/></Origin>}/>
                    <Route path='tickets/*'>
                        <Route path='*' element={<Ogle><ScanRoutes/></Ogle>}/>
                    </Route>
                </Route>
                
                <Route path='checkout-new/*'>
                    <Route path='*' element={<Ogle><CheckoutRoutes/></Ogle>}/>
                </Route>
                <Route path='wallet/*'>
                    <Route path='*' element={<Ogle><Private><WalletRouting/></Private></Ogle>}/>
                </Route>
                <Route path='promo'>
                    <Route path='*' element={<Ogle><SuspenseLazy><PromoRoutes/></SuspenseLazy></Ogle>}/>
                </Route>
                <Route path='vending-machines' element={<Origin><VendingMachines/></Origin>}/>
                <Route path='queues' element={<Origin><Queues/></Origin>}/>
                <Route path='callback' element={<Origin><Callback/></Origin>}/>
                <Route path='log-out' element={<Origin><LogOut/></Origin>}/>
                <Route path='smart-link/:id' element={<Origin><SmartLink/></Origin>}/>
                <Route path='checkout/integration' element={
                    <EffectRedirect to={`/checkout-new/integration${location.search}`} replace/>
                }/>
                <Route path='*' element={<Origin><Simple/></Origin>}>
                    <Route path='*' element={<NotFound/>}/>
                </Route>
            </Routes>
        </>
    );
};

const IntlProviderUrlConnected = React.memo(({children}) => {
    const {locale = 'uk'} = useQuery();
    return (
        <IntlProvider locale={locale} defaultLocale='en' messages={locales[locale]}>
            {children}
        </IntlProvider>
    );
});

export default React.memo(() => (
    <FetchKeyProvider>
        <IntlProviderUrlConnected>
            <ErrorBoundary  {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={'Loading...'}>
                    <Application/>
                </React.Suspense>
            </ErrorBoundary>
        </IntlProviderUrlConnected>
    </FetchKeyProvider>
));


'http://localhost:3008/services/cinema/:slugOrId'
import React from 'react';

const RulesEn = () => {
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-1dot5rem font-weight-bold'>
                ПРАВИЛА І ПОРЯДОК ВИКОРИСТАННЯ ПОСЛУГ КОМПАНІЇ OTTRY
            </div>
            <div className='margin-top-1rem'>
                Ці правила і умови, які можуть періодично коригуватися, застосовані до Онлайн-сервісу здійснення підприємницької діяльності, що надається Товариством з обмеженою відповідальністю «Оттрі» (ідентифікаційний код юридичної особи: 41385243), котре  здійснює свою діяльність відповідно до законодавства України і зареєстроване за адресою: 04050, м. Київ, вул. Глибочицька, 16, офіс 13 (далі - Компанія). Відвідуючи, переглядаючи або використовуючи наш Онлайн-сервіс ви визнаєте, що прочитали, зрозуміли і погодилися з нижчеописаними правилами і умовами (включаючи згоду на обробку персональних даних). 
            </div>
            <div className='margin-top-1rem'>
                Онлайн-сервіс, його інформаційне наповнення і функції по відображенню доступності Ендпоінта і / або бронювання товарів і послуг на сайті Підприємства і на відповідних сторінках за допомогою web-сайту, належать, знаходяться під управлінням  та надаються Компанією згідно з правилами і умовами, викладеними нижче .
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                ВИЗНАЧЕННЯ
            </div>
            <div className='margin-top-1rem'>
                Терміни, які не вказані в даному розділі, вживаються в тексті цього Договору, в значеннях відповідно до законодавства України.
            </div>
            <div className='margin-top-1rem'>
                Підприємство - суб'єкт підприємницької діяльності, який на умовах цього Договору розміщує на Сайті шляхом програмних засобів Оттрі онлайн-сервіс Оттрі з метою реалізації своїх товарів і послуг, а також на умовах Договору з Фінансовим Партнером приймає Карти з метою здійснення оплати вартості товарів / послуг на Сайті.
            </div>
            <div className='margin-top-1rem'>
                Фінансовий Партнер - компанія-партнер Оттрі, що надає фінансові послуги, зокрема, здійснює інтернет-платежі через міжнародні платіжні системи Visa, Master Card і ін. Фінансовим Партнером може бути тільки компанія, що має відповідні ліцензії на надання фінансових послуг.
            </div>
            <div className='margin-top-1rem'>
                Відвідувач Сайту - будь-яка фізична особа - користувач мережі Інтернет, що здійснює доступ до web-сайту Підприємства.
            </div>
            <div className='margin-top-1rem'>
                Покупець - особа, яка замовляє та оплачує за допомогою Карти товари або послуги Підприємства через Інтернет.
            </div>
            <div className='margin-top-1rem'>
                Онлайн-сервіс здійснення підприємницької діяльності, далі Онлайн-сервіс - програмний засіб Компанії, який надає можливість онлайн бронювання послуг і товарів в мережі Інтернет та / або відображення доступності Ендпоінта.
            </div>
            <div className='margin-top-1rem'>
                Ендпоінт - кінцева послуга або товар Підприємства, що відображається на його web-сайті, яка може бути доступна для бронювання або придбання.
            </div>
            <div className='margin-top-1rem'>
                Платіжний механізм - сервіс Фінансового Партнера для організації прийому платежів в мережі Інтернет, що надходять на рахунок Підприємства.
            </div>
            <div className='margin-top-1rem'>
                Замовлення - сукупність товарів і послуг Підприємства, їх вартості, способи і вартості їх доставки, розміщених на Сайті, а також персональні данні Покупця.
            </div>
            <div className='margin-top-1rem'>
                Платіжна карта, далі Карта, - спеціальний платіжний засіб у вигляді емітованої в установленому законодавством порядку пластикової карти, яка використовується для ініціювання переказу коштів з рахунку платника або з відповідного рахунку в банку з метою оплати вартості товарів або послуг, або здійснення інших операцій, передбачених чинним законодавством.
            </div>
            <div className='margin-top-1rem'>
                Транзакція - сукупність операцій між Покупцем, Підприємством і Фінансовим Партнером з придбання товарів або послуг на Сайті з використанням Картки як платіжного засобу.
            </div>
            <div className='margin-top-1rem'>
                Операція - сукупність реєстраційних дій Відвідувача (Покупця) при заповненні відповідних даних в Онлайн-сервісі з метою перегляду доступності Ендпоінта, бронювання та / або купівлі товару або послуги.
            </div>
            <div className='margin-top-1rem'>
                Web-сайт - сукупність інформації і сервісних функцій, доступних користувачам мережі Інтернет, зібраних на одному або декількох серверах.
            </div>
            <div className='margin-top-1rem'>
                Web-сайт Підприємства, далі Сайт - web-сайт, керований і контрольований Підприємством.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                ОПИС ПОСЛУГ
            </div>
            <div className='margin-top-1rem'>
                Вибір товарів і послуг, формування їх переліку та кількості, вибір способу доставки, перегляд доступності Ендпоінта, замовлення зі зворотнім зв'язком Відвідувач здійснює програмними засобами Оттрі через Онлайн-сервіс, розміщений на Сайті. 
            </div>
            <div className='margin-top-1rem'>
                У разі проведення покупки товару і послуги через Онлайн-сервіс, Відвідувач при переході на web-сайт Фінансового Партнера погоджується з умовами Публічного договору Фінансового Партнера, створює обліковий запис, вводить дані Платіжної карти і здійснює оплату через Платіжний механізм Фінансового Партнера. 
            </div>
            <div className='margin-top-1rem'>
                Перерахування суми Замовлення, за вирахуванням комісійних Фінансовим Партнером, з карткового рахунку Покупця на рахунок Підприємства проводиться Фінансовим Партнером відповідно до технологій, прийнятих при проведенні взаєморозрахунків за допомогою міжнародних платіжних систем.
            </div>
            <div className='margin-top-1rem'>
                Відвідувач Сайту стає Покупцем в разі успішної реєстрації Замовлення і його оплати.
            </div>
            <div className='margin-top-1rem'>
                У разі звернення Покупця в службу підтримки Підприємства, Підприємство з метою вирішення питання Покупця здійснює запит до Компанії про надання відповідної інформації щодо Операції, статусу бронювання або покупки товару / послуги.
            </div>
            <div className='margin-top-1rem'>
                Відповідальність за Факт доставки Замовлення Покупцю або факт повернення (частини) Замовлення Покупцем лежить на Підприємстві.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                ВІДПОВІДАЛЬНІСТЬ СТОРІН
            </div>
            <div className='margin-top-1rem'>
                Компанія несе відповідальність виключно за технологічне обслуговування та підтримку Онлайн-сервісу, розміщеного на Сайті, а також за обробку та зберігання персональних даних Покупців (Відвідувачів).
            </div>
            <div className='margin-top-1rem'>
                Відповідальність за проведення Транзакції і всі супутні операції за здійснення платежів лежать на Фінансовому Партнері Компанії. 
            </div>
            <div className='margin-top-1rem'>
                Компанія не несе відповідальність за якість послуг і товарів, що надаються Підприємством Покупцеві.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                ПРО КОМПАНЮ ОТТРІ
            </div>
            <div className='margin-top-1rem'>
                Web-сайт Оттрі доступний за адресою: https://ottry.com
            </div>
        </div>
    );
};

export default React.memo(RulesEn);
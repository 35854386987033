import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import EffectRedirect from '../../components/EffectRedirect';
import getServiceUrl from '../../utilities/get-service-url';
import useQuery from '../../useQuery';

const EmptyCartByServiceRedirect = ({serviceId}) => {
    const {location = 'uk'} = useQuery();
    const {serviceById} = useLazyLoadQuery(
        graphql`
            query emptyCartByServiceRedirectServiceByIdQuery($id: String) {
                serviceById(id: $id) {
                    id
                    description
                    slug
                    warp
                }
            }
        `,
        {id: serviceId}
    );
    const serviceLink = getServiceUrl({description: serviceById.description, id: serviceById.id, slug: serviceById.slug, warp: serviceById.warp, location});
    return (
        <EffectRedirect {...{to: serviceLink, replace: true}}/>
    );
};

export default React.memo(EmptyCartByServiceRedirect);

/**
 * @generated SignedSource<<e349d6d1c862ee16c677cc373518997c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "endpointId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "endpointId"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "endpointById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameUk",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "quantity",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "atlasOrderItemQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "atlasOrderItemQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ea7222be6bedc13a9e27c4e4d1474bd1",
    "id": null,
    "metadata": {},
    "name": "atlasOrderItemQuery",
    "operationKind": "query",
    "text": "query atlasOrderItemQuery(\n  $endpointId: String\n) {\n  endpointById(id: $endpointId) {\n    id\n    name\n    price\n    nameUk\n    quantity\n  }\n}\n"
  }
};
})();

node.hash = "3c835742acc3c779c0cd3b6973971749";

module.exports = node;

/**
 * @generated SignedSource<<806bc6d9ffd78f3c0730550859c68597>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "JwtAuthenticationResponse",
    "kind": "LinkedField",
    "name": "handle",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accessToken",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QrsQDownloadMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QrsQDownloadMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "696b992911630d5e4f964d32013ccf37",
    "id": null,
    "metadata": {},
    "name": "QrsQDownloadMutation",
    "operationKind": "mutation",
    "text": "mutation QrsQDownloadMutation {\n  handle {\n    accessToken\n  }\n}\n"
  }
};
})();

node.hash = "62f05f32fdd9691af637568f51297e30";

module.exports = node;

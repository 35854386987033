/**
 * @generated SignedSource<<1925885157d778add5207ca518f62b09>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EndpointImpl_endpoint",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "untie",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EndpointImplUntieAction_endpoint"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EndpointImplAction_endpoint"
    }
  ],
  "type": "Endpoint",
  "abstractKey": null
};

node.hash = "125502f4ada01b7da31bef47f61e92eb";

module.exports = node;

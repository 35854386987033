import React from 'react';

const RulesEn = () => {
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-1dot5rem font-weight-bold'>
                RULES AND THE PROCEDURES FOR THE USE OF OTTRY SERVICES
            </div>
            <div className='margin-top-1rem'>
                These terms and conditions, as may be amended from time to time, apply to Online Service realization of entrepreneurial activity, provided by Ottri Limited Liability Partnership (legal entity identification code: 41385243) acting under the laws of Ukraine and registered at: 16, Glibochitskaya Str., office 13, Kiev, 04050 (hereinafter referred to as the Company). By accessing, browsing and using our Online Service  you acknowledge and agree to have read, understood and agreed to the terms and conditions set out below (including CONSENT FOR PROCESSING OF PERSONAL DATA).
            </div>
            <div className='margin-top-1rem'>
                Online Service, the content and functions to display availability of Endpoint or/and reservation goods and services on the website of the Enterprise and on these pages, and through the website are owned, operated and provided by Company, subject to the terms and conditions set out below.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                DEFINITIONS
            </div>
            <div className='margin-top-1rem'>
                The terms not specified in this section are used in this Contract with the meaning given to them by the laws of Ukraine.
            </div>
            <div className='margin-top-1rem'>
                Enterprise shall mean a subject to entrepreneurial activity, which pursuant to this Contract places on the website using the software of Ottry the Ottry online service to sell its goods and services, as well as subject to the Contract with the Financial Partner accepts Cards for payment for goods/services on the Website.
            </div>
            <div className='margin-top-1rem'>
                Financial Partner shall mean the partner company of Ottry, which provides financial services, particularly, online payments via Visa, Master Card and other payment systems. Only a company having relevant licences for financial services can be Financial Partner.
            </div>
            <div className='margin-top-1rem'>
                Website Visitor shall mean any individual Internet user accessing the website of the Enterprise
            </div>
            <div className='margin-top-1rem'>
                Buyer shall mean a person ordering and paying using the Cards for goods or services of the Enterprise in the Internet.
            </div>
            <div className='margin-top-1rem'>
                Business Online Service, hereinafter referred to as Online Service shall mean the Company software providing online booking of services and goods in the Internet and / or display of Endpoint accessibility.
            </div>
            <div className='margin-top-1rem'>
                Endpoint shall mean the final services or product of the Enterprise, displayed on its website, which can be available for booking or purchase.
            </div>
            <div className='margin-top-1rem'>
                Payment mechanism shall mean service of the Financial Partner for organisation of acceptance of payments in the Internet to the account of the Enterprise.
            </div>
            <div className='margin-top-1rem'>
                Order shall mean a combination of goods and services of the Enterprise, their cost, method and cost of delivery, placed on the Website, as well as personal data of a Buyer.
            </div>
            <div className='margin-top-1rem'>
                Payment card, hereinafter referred to as the Card shall mean a special payment instrument in the form of a payment card issued in accordance with the legally established procedure, used for initiation of transfer of funds from a payer’s account or the respective bank account for payment for goods or services or performance of other transactions provided by the current laws.
            </div>
            <div className='margin-top-1rem'>
                Transaction shall mean a combination of transactions between a Buyer, Enterprise, and the Financial Partner for purchase of goods or services on the Website using the Card as payment instrument.
            </div>
            <div className='margin-top-1rem'>
                Operation shall mean a combination of registration acts of the Enterprise upon creation of an account on the Company website, and a Buyer upon completion of relevant data in the Online Service to view accessibility of Endpoint, booking and / or purchase of any product or service.
            </div>
            <div className='margin-top-1rem'>
                Website shall mean a combination of information and service functions accessible for Internet users, collected on one or more servers.
            </div>
            <div className='margin-top-1rem'>
                Enterprise website, hereinafter referred to as the Website shall mean a website managed and controlled by the Enterprise.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                SCOPE OF OUR SERVICE    
            </div>
            <div className='margin-top-1rem'>
                Selection of goods and services, formation of their list and quantity, selection of the method of delivery, viewing of accessibility of Endpoint, order for feedback shall be made by Buyer using the software tools of Ottry through the Online Service placed on the Website.
            </div>
            <div className='margin-top-1rem'>
                In case of purchase of a product or service through the Online Service upon switching to the website of the Financial Partner the Visitor agrees with the terms of the Public Contract of the Financial Partner, creates a user account, introduces payment card details and makes payment through the payment mechanism of the Financial Partner.
            </div>
            <div className='margin-top-1rem'>
                Transfer of the amount of an Order, less the commission fee of the Financial Partner, from the Buyer’s card account to the account of the Enterprise shall be made by the Financial Partner according to the technologies adopted upon performance of mutual settlements using international payment systems.
            </div>
            <div className='margin-top-1rem'>
                The Website visitor becomes a Buyer in case of successful registration of an Orderрегистрации Заказа and payment for it.
            </div>
            <div className='margin-top-1rem'>
                If a Buyer contacts the support service of the Enterprise, for settlement of the issue the Enterprise will require from the Company relevant information about Operations, status of booking or purchase of a product / service.
            </div>
            <div className='margin-top-1rem'>
                Responsibility for delivery of an Order to the Buyer or returning (partial returning) of an Order by the Buyer shall be borne by the Enterprise.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                RESPONSIBILITY of the PARTIES
            </div>
            <div className='margin-top-1rem'>
                The Company shall had sole responsibility for technological support services and support of the Online Service placed on the Website, as well as for processing and keeping of personal data of Buyers (Visitors).
            </div>
            <div className='margin-top-1rem'>
                Responsibility for performance of a Transaction and all accompanying operations for performance of payments rests with the Financial Partner of the Company.
            </div>
            <div className='margin-top-1rem'>
                The Company is not responsible for the quality of services and goods provided to the Buyer by the Enterprise.
            </div>
            <div className='margin-top-1dot5rem font-weight-bold'>
                ABOUT OTTRY
            </div>
            <div className='margin-top-1rem'>
                Ottry website available at: https://ottry.com
            </div>
        </div>
    );
};

export default React.memo(RulesEn);
import React from 'react';
import {FormattedMessage} from 'react-intl';
import SuccessPage from '../complete/success';


const VendingSuccessPage = () => {
    const title = <FormattedMessage defaultMessage='Successful replenishment'/>;
    const message = <FormattedMessage defaultMessage='The replenishment will appear on the box as soon as the payment will be processed'/>;
    
    return (
        <SuccessPage {...{title, message}}/>
    )
};

export default React.memo(VendingSuccessPage);

import React from 'react';
import usePageVisibility from '../../usePageVisibility';
import {SCAN_MODE_DEFAULT} from '../../constants';

const DEFAULT_SCAN_SETTINGS = {mode: SCAN_MODE_DEFAULT, autoTake: false};

export const ScanSettingsStateContext = React.createContext({});
export const ScanSettingsDispatchContext = React.createContext(() => {});

const reducer = (state, action) => {
    switch (action.type) {
        case 'update':
            return {...state, ...action.payload};
        case 'reset':
            return action.payload;
        default: throw new Error();
    }
};

const scanSettings__ = 'scanSettings__0';
const deserialize = () => window.localStorage.getItem(scanSettings__) && JSON.parse(window.localStorage.getItem(scanSettings__)) || DEFAULT_SCAN_SETTINGS;
const serialize = (settings) => window.localStorage.setItem(scanSettings__, JSON.stringify(settings));

export const ScanSettingsProvider = React.memo(({children}) => {
    const [state, setState] = React.useState(() => deserialize());
    const onVisibilityChange = React.useCallback((visible) => {
        if (visible) {
            setState(e => reducer(e, {type: 'reset', payload: deserialize()}));
        }
    }, []);
    usePageVisibility(onVisibilityChange);
    const dispatch = React.useCallback((action) => {
        setState(e => {
            const nextState = reducer(e, action);
            serialize(nextState);
            return nextState;
        });
    }, []);
    return (
        <ScanSettingsStateContext.Provider value={state}>
            <ScanSettingsDispatchContext.Provider value={dispatch}>
                {children}
            </ScanSettingsDispatchContext.Provider>
        </ScanSettingsStateContext.Provider>
    );
});

export const useScanSettingsState = () => React.useContext(ScanSettingsStateContext);
export const useScanSettingsDispatch = () => React.useContext(ScanSettingsDispatchContext);

import React from 'react';
import ScanHeader from './header';
import {FormattedMessage} from 'react-intl';
import './history.scss';
import moment from 'moment';
import CheckIcon from '../../assets/icons0/check.svg';
import XCloseIcon from '../../assets/icons0/x-close.svg';
import classNames from 'classnames';

const MOCK = [{
    email: 'evtimchenko@gmail.com',
    date: '2024-07-29T20:59:00Z',
    status: 'checked'
}, {
    email: 'evtimchenko@gmail.com',
    date: '2024-07-29T20:58:00Z',
    status: 'repeat'
}, {
    email: 'evtimchenko@gmail.com',
    date: '2024-07-29T20:57:00Z',
    status: 'checked'
}, {
    email: 'evtimchenko@gmail.com',
    date: '2024-07-29T20:56:00Z',
    status: 'checked'
}, {
    email: 'evtimchenko@gmail.com',
    date: '2024-07-29T20:55:00Z',
    status: 'repeat'
}, {
    email: 'evtimchenko@gmail.com',
    date: '2024-07-29T20:55:00Z',
    status: 'checked'
}];

const STATUS_DESCRIPTION = {
    checked: {
        title: <FormattedMessage defaultMessage='Checked'/>,
        icon: <CheckIcon className='display-block width-0dot75rem height-0dot75rem margin-right-0dot25rem color-success-500'/>,
        theme: 'color-success-700 background-color-success-50',
    },
    repeat: {
        title: <FormattedMessage defaultMessage='Repeat'/>,
        icon: <XCloseIcon className='display-block width-0dot75rem height-0dot75rem margin-right-0dot25rem color-error-500'/>,
        theme: 'color-error-700 background-color-error-50'
    }
}

const ScanHistoryRow = ({email, status, date, even}) => {
    return (
        <>
            <div className={classNames('padding-top-1rem padding-bottom-1rem padding-left-1dot5rem padding-right-1dot25rem', {'background-color-gray-50': even})}>
                <p className='text-sm medium color-gray-900'>{moment(date).format('DD.MM.YYYY HH:mm:ss')}</p>
                <p className='text-sm color-gray-500'>{email}</p>
            </div>
            <div className={classNames('padding-top-1rem padding-bottom-1rem padding-left-1dot5rem padding-right-1dot25rem display-flex align-items-center', {'background-color-gray-50': even})}>
                <div className={`display-flex align-items-center border-radius-1rem padding-top-0dot125rem padding-bottom-0dot125rem padding-left-0dot5rem padding-right-0dot5rem text-xs medium ${STATUS_DESCRIPTION[status].theme}`}>
                    {STATUS_DESCRIPTION[status].icon}
                    {STATUS_DESCRIPTION[status].title}
                </div>
            </div>
        </>
    )
}

const ScanHistory = () => {
    return (
        <div className='scan-history background-color-white'>
            <ScanHeader title={<FormattedMessage defaultMessage='Scan history'/>}>
                <div className='height-2rem width-2rem'></div>
            </ScanHeader>
            <div className='border-top-1px-solid border-color-gray-200'>
                <div className='display-grid history-grid'>
                    <div className='padding-top-0dot75rem padding-bottom-0dot75rem padding-left-1dot5rem padding-right-1dot25rem text-xs medium color-gray-500'><FormattedMessage defaultMessage='Date'/></div>
                    <div className='padding-top-0dot75rem padding-bottom-0dot75rem padding-left-1dot5rem padding-right-1dot25rem text-xs medium color-gray-500'><FormattedMessage defaultMessage='Status'/></div>
                    {MOCK.length && MOCK.map((item, index) =>
                        <ScanHistoryRow key={index} {...{...item, even: index % 2 === 0}} />
                    )}
                </div>
            </div>
        </div>
    )
};

export default React.memo(ScanHistory);
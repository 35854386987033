import React from 'react';
import ArrowLeftIcon from '../../assets/icons0/arrow-left.svg';
import {FormattedMessage} from 'react-intl';
import {useNavigate} from 'react-router-dom';
import classNames from 'classnames';

const defaultTitle = <FormattedMessage defaultMessage='QR code scanning'/>;

const ScanHeader = ({title = defaultTitle, show = true, children}) => {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    }
    return (
        <div className={classNames('padding-1dot25rem background-color-white', {'display-none': !show})}>
            <div className='display-flex justify-content-space-between align-items-center'>
                <div className='cursor-pointer' onClick={back}>
                    <ArrowLeftIcon className='height-1dot5rem width-1dot5rem color-gray-500 display-block'/>
                </div>
                <p className='color-gray-900 text-xl'>{title}</p>
                {children}
            </div>
        </div>
    )
};

export default React.memo(ScanHeader);
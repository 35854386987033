import React from 'react';
import './Dialogs.scss';
import {DialogStateContext, DialogDispatchContext} from '../DialogContext';
import ImpulseDialog from './ImpulseDialog';
import Browser from '../Browser';

const Dialogs = React.memo(() => {
    const state = React.useContext(DialogStateContext);
    const dispatch = React.useContext(DialogDispatchContext);
    return (
        <Browser>
            {!!state.length &&
                <div className='dialogs'>
                    {state.map(dialog =>
                        <div key={dialog.key} className='dialog margin-bottom-1rem'>
                            <div className='display-flex justify-content-flex-end'>
                                <div className='padding-right-1dot5rem padding-top-1rem'>
                                    <div
                                        className='button'
                                        onClick={() => dispatch({type: 'remove', payload: dialog.key})}
                                    >&#x2715;</div>
                                </div>
                            </div>
                            <div className='padding-1rem-1dot5rem'>
                                {dialog.impulseId && <ImpulseDialog {...{dialog}}/>}
                            </div>
                        </div>
                    )}
                </div>
            }
        </Browser>
    );
});

export default Dialogs;
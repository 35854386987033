import React from 'react';
import {FormattedMessage} from 'react-intl';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import classNames from 'classnames';
import PaymentIconMaster from '../../../assets/icons/payment-icon-master.svg';
import PaymentIconVisa from '../../../assets/icons/payment-icon-visa.svg';
import PaymentIconApple from '../../../assets/icons/payment-icon-apple.svg';
import PaymentIconGoogle from '../../../assets/icons/payment-icon-google.svg';
import CheckCheckbox from '../../../assets/icons/check-checkbox.svg';
import './BillingInfo.scss';

const BillingInfo = ({register, merchantAccount, merchantId, lqpPublic, pool}) => {
    const {poolsByViewer} = useLazyLoadQuery(
        graphql`
            query BillingInfoPaymentCheckoutPagesQuery($request: PoolsRequestInput) {
                poolsByViewer(request: $request) {
                    content {
                        id
                        cardPan
                        cardType
                        recToken
                    }
                }
            }
        `,
        {request: {
            ...(lqpPublic ? { lqpPublic } : merchantAccount ? { merchantAccount } : { merchantId }),
            size: 100500, 
            page: 0
        }}
    );
    return (
        <div>
            <p className='text-2xl medium color-gray-900 padding-bottom-1dot5rem'>
                <FormattedMessage defaultMessage='Payment method'/>
            </p>
            {!!poolsByViewer.content.length &&
                <div>
                    {poolsByViewer.content.map((entry) =>
                        <label key={entry.id} htmlFor={entry.recToken} className={classNames('payment-card padding-1rem margin-bottom-0dot75rem', {'payment-card--active': pool === entry.recToken})}>
                            <div className='payment-card__radio-container position-absolute'>
                                <input className='payment-card__input ogle-radio-input' value={entry.recToken} id={entry.recToken} type='radio' {...register('pool')}/>
                                <div className='ogle-radio'></div>
                            </div>
                            <div className='display-flex align-items-center'>
                                <div className='payment-card__icon margin-right-0dot25rem'>
                                    {/Visa/i.test(entry.cardType) && <PaymentIconVisa/>}
                                    {/MasterCard/i.test(entry.cardType) && <PaymentIconMaster/>}
                                </div>
                                <div className='payment-card__info'>
                                    <p className='payment-card__title text-md medium'>{entry.cardPan.replace(/X/g, '*')}</p>
                                </div>
                            </div>
                        </label>
                    )}
                </div>
            }
            <label htmlFor='default-pool' className={classNames('payment-card padding-1rem display-block', {'payment-card--active': pool === '-1'})}>
                <div className='payment-card__radio-container position-absolute'> 
                    <input className='ogle-radio-input' value={-1} id='default-pool' type='radio' {...register('pool')}/>
                    <div className='ogle-radio'></div>
                </div>
                <div className='payment-card__input-radio'></div>
                <p className='payment-card__title padding-bottom-0dot75rem text-md medium'>
                    <FormattedMessage defaultMessage='Credit/Debit Card'/>
                </p>
                <div className='payment-card__icon display-flex'>
                    <PaymentIconMaster className='margin-right-0dot5rem display-block'/>
                    <PaymentIconVisa className='margin-right-0dot5rem display-block'/>
                    <PaymentIconApple className='margin-right-0dot5rem display-block'/>
                    <PaymentIconGoogle className='margin-right-0dot5rem display-block'/>
                </div>
                <label htmlFor='upsert' className='text-md padding-top-0dot75rem display-flex align-items-center'>
                    <input className='ogle-checkbox-input' id='upsert' type='checkbox' {...register('upsert')} disabled={pool !== '-1'}/>
                    <div className='ogle-checkbox'>
                        <CheckCheckbox className='display-block color-white'/>
                    </div>
                    <span className={classNames('margin-left-0dot5rem', {'color-primary-600': pool === '-1', 'color-gray-300': pool !== '-1'})}>
                        <FormattedMessage defaultMessage='Remember billing data'/>
                    </span>
                </label>
            </label>
        </div>
    )
}

export default React.memo(BillingInfo);
/**
 * @generated SignedSource<<813b63fc375685fdbc9f02d8b2beb9f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "LiqPayRecurringResponse",
    "kind": "LinkedField",
    "name": "liqPayRecurring",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "signature",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImpulseLiqPayRecurringPaymentCheckoutPagesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImpulseLiqPayRecurringPaymentCheckoutPagesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fdd7ad79b8de51e76592a3d5cdd9af70",
    "id": null,
    "metadata": {},
    "name": "ImpulseLiqPayRecurringPaymentCheckoutPagesMutation",
    "operationKind": "mutation",
    "text": "mutation ImpulseLiqPayRecurringPaymentCheckoutPagesMutation(\n  $request: LiqPayRecurringRequestInput\n) {\n  liqPayRecurring(request: $request) {\n    signature\n    data\n  }\n}\n"
  }
};
})();

node.hash = "a639223d41138daf48726f5f7176430c";

module.exports = node;

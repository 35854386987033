import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../searchFilter';
import useSubscription from '../../useSubscription';
import classNames from 'classnames';
import Dialogs from './Dialogs';
import useSubscriptionStatus from '../../useSubscriptionStatus';

const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';

const Faq = () => {
    const subscriptionStatus = useSubscriptionStatus();
    // https://docs.poloniex.com/#requests-and-responses
    const payload = React.useMemo(() => ({
        channel: 'faq',
        onNext: (data) => {
            // console.log('Faq::onNext', data);
        },
        onError: (error) => {
            // console.log('Faq::onError', error);
        }
    }), []);
    useSubscription(payload);
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <Dialogs/>
            <div className='font-size-2dot5rem font-weight-bold margin-bottom-0dot5rem'>
                <span className={classNames('dot margin-right-0dot2rem', {
                    'green': subscriptionStatus === 0
                })}/>
                FAQ
            </div>
            <div className='font-weight-bold'>
                <FormattedMessage defaultMessage='How do I get my receipt?'/>
            </div>
            <div className='margin-top-1rem'>
                <FormattedMessage defaultMessage='After a successful transaction, we will automatically send you email with all the details, also you can always use your'/> <Link className='focus' to={`/cabinet${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Ottry personal cabinet'/></Link>.
            </div>
            <div className='font-weight-bold margin-top-2rem'>
                <FormattedMessage defaultMessage="I can't find the letter with the ticket in the mail! What shall I do?"/>
            </div>
            <div className='margin-top-1rem'>
                <FormattedMessage defaultMessage='View all tabs of your mailbox, use the search by mail'/> <a className='focus' href='mailto:no-reply@ottry.com'>no-reply@ottry.com</a> <FormattedMessage defaultMessage='in your email inbox and in your'/> <Link className='focus' to={`/cabinet${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Ottry personal cabinet'/></Link>.
            </div>
            <div className='font-weight-bold margin-top-2rem'>
                <FormattedMessage defaultMessage='Can I exchange or return?'/>
            </div>
            <div className='margin-top-1rem'>
                <FormattedMessage defaultMessage='Ottry provides a platform for sellers. Unfortunately, platform does not support automatic return at the moment, and we are already working on the implementation of this functionality. Therefore, for return and exchange, please contact the seller immediately at contact details on the sales page.'/>
            </div>
            <div className='margin-top-1rem'>
                <FormattedMessage defaultMessage='Regarding exchange and return by the seller, sellers must work in accordance with the'/> <a className='focus' href='https://zakon.rada.gov.ua/laws/show/1023-12' target='_blank' rel='noopener noreferrer'><FormattedMessage defaultMessage='law of Ukraine on consumer protection'/></a><FormattedMessage defaultMessage=', which regulates the terms of return, return conditions, fixes the list of goods not subject to return and more.'/>
            </div>
            <div className='font-weight-bold margin-top-2rem'>
                <FormattedMessage defaultMessage='How can I start using Ottry to sell my products?'/>
            </div>
            <div className='margin-top-1rem'>
                <FormattedMessage defaultMessage='Fill out the'/> <a href={formLink} target='_blank' rel='noopener noreferrer' className='focus'><FormattedMessage defaultMessage='form'/></a> <FormattedMessage defaultMessage='or contact us at the contacts below.'/>
            </div>
            <div className='font-weight-bold margin-top-2rem'>
                <FormattedMessage defaultMessage='Can I use Ottry on my site?'/>
            </div>
            <div className='margin-top-1rem'>
                <FormattedMessage defaultMessage='Yes.'/>
            </div>
            <div className='font-weight-bold margin-top-2rem'>
                <FormattedMessage defaultMessage='Is it possible to apply promo codes, discounts?'/>
            </div>
            <div className='margin-top-1rem'>
                <FormattedMessage defaultMessage='Yes.'/>
            </div>
            <div className='font-weight-bold margin-top-2rem'>
                <FormattedMessage defaultMessage='Where can I write wishes, suggestions, complaints?'/>
            </div>
            <div className='margin-top-1rem'>
                <FormattedMessage defaultMessage='We actively communicate on'/> <a className='focus' href='https://www.facebook.com/OTTRYcom' target='_blank' rel='noopener noreferrer'>Facebook</a>, <a className='focus' href='https://t.me/Ottry_Support' target='_blank' rel='noopener noreferrer'>Telegram</a> <FormattedMessage defaultMessage='and'/> <a className='focus' href='mailto:info@ottry.com'>info@ottry.com</a>
            </div>
            <div className='font-weight-bold margin-top-2rem'><FormattedMessage defaultMessage='Contacts'/></div>
            <div className='margin-top-1rem'>
                <p><FormattedMessage defaultMessage='Ottry LLC'/></p>
                <p><FormattedMessage defaultMessage='VAT identification number is 41385243'/></p>
                <p><FormattedMessage defaultMessage='16, Hlybochytska Street, office 13, Kyiv, 04050'/></p>
                <p><a className='focus' href='tel:+380979381162'>+38 097 938 1162</a></p>
                <p><a className='focus' href='mailto:info@ottry.com'>info@ottry.com</a></p>
            </div>
        </div>
    );
};

export default React.memo(Faq);